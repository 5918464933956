import React from 'react';

import { AuthProvider } from './auth';
import { CategoriairsoProvider } from './categoriairso';
import { TipoTravaProvider } from './tipotrava';
import { TravaOpmProvider } from './travaopm';
import { TravaPmProvider } from './travapm';
import { TravaOpmHoraProvider } from './travaopmhora';
import { ProcessoProvider } from './processo';
import { EscalaProvider } from './escala';
import { FuncoesProvider } from './funcoes';
import { SubCategoriairsoProvider } from './subcategoriairso';

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <CategoriairsoProvider>
      <SubCategoriairsoProvider>
        <TravaOpmProvider>
          <TravaOpmHoraProvider>
            <TravaPmProvider>
              <ProcessoProvider>
                <FuncoesProvider>
                  <TipoTravaProvider>
                    <EscalaProvider>{children}</EscalaProvider>
                  </TipoTravaProvider>
                </FuncoesProvider>
              </ProcessoProvider>
            </TravaPmProvider>
          </TravaOpmHoraProvider>
        </TravaOpmProvider>
      </SubCategoriairsoProvider>
    </CategoriairsoProvider>
  </AuthProvider>
);

export default AppProvider;
