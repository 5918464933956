/* eslint-disable no-control-regex */
import React, { useState } from 'react';
import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';
import { Button, Divider, Flex, useDisclosure } from '@chakra-ui/react';
import {
  Box,
  useToast,
  HStack,
  Button as ButtonChacrka,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PanelBottomActions from '../../components/PanelBottomActions';
import api from '../../services/api';
import FormGroup from '../../components/form/FormGroup';
import Row from '../../components/form/Row';
import FormInput from '../../components/form/FormInput';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface ITipoTrava {
  id_tipo_trava: number;
  nome: string;
  descricao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}

const schema = Yup.object().shape({
  nome: Yup.string()
    .required('Campo Obrigatório')
    .max(200, 'Digite no máximo 200 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),

  descricao: Yup.string()
    .required('Campo Obrigatório')
    .max(350, 'Digite no máximo 350 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
});

interface ISchema {
  nome: string;
  descricao: string;
}

const ListTipoTrava: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [tipoTravaModal, setTipoTravaModal] = useState<ITipoTrava>();

  const { errors, handleSubmit, register, reset } = useForm<ISchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      nome: '',
      descricao: '',
    },
  });

  const {
    errors: errorsEditar,
    handleSubmit: handleSubmitEditar,
    control: controlEditar,
  } = useForm<ISchema>({
    resolver: yupResolver(schema),
  });

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarTipoTrava,
    onOpen: onOpenEditarTipoTrava,
    onClose: onCloseEditarTipoTrava,
  } = useDisclosure();

  const [isDisabledRequest, setIsDisabledRequest] = useState(false);

  const handleAdicionarTipoTrava = async (data: ISchema): Promise<any> => {
    const dados = {
      ...data,
      nome: String(data.nome).toUpperCase(),
    };
    setIsDisabledRequest(true);
    try {
      await api.post(`/tipotrava`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Cadastro de tipo de trava inserido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      reset();
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao inserir o tipo de trava',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleUpdateTipoTrava = async (data: ISchema): Promise<void> => {
    const dados = {
      ...data,
      nome: String(data.nome).toUpperCase(),
    };

    setIsDisabledRequest(true);
    try {
      await api.put(`/tipotrava/${tipoTravaModal?.id_tipo_trava}`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Tipo de trava atualizado com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarTipoTrava();
    } catch (error) {
      toast({
        title: 'Erro',
        description:
          error?.response.data.message ||
          'Erro ao tentar atualizar tipo de trava',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleExcluirCategoria = async (data: ITipoTrava): Promise<any> => {
    setIsDisabledRequest(true);
    try {
      await api.put(`/tipotrava/deletar/${data.id_tipo_trava}`, {
        atualizado_por: user.matricula,
      });
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const colunas: IColumns = [
    {
      field: 'nome',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/tipotrava`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ITipoTrava) => {
        //     handleClickShow(uniforme.id_tipo_trava);
        //   },
        // },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenEditarTipoTrava();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nomeCategoria'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  return (
    <>
      <>
        <TituloPagina title="Tipo Trava PM" />
        <BoxContent>
          <form
            onSubmit={handleSubmit(handleAdicionarTipoTrava)}
            style={{ marginBottom: 8 }}
          >
            <TituloPagina title="Cadastro de Tipo de Trava" />

            <Row>
              <FormGroup name="Nome do Tipo de Trava">
                <FormInput
                  name="nome"
                  register={register}
                  error={errors.nome?.message}
                />
              </FormGroup>

              <FormGroup name="Descrição">
                <FormInput
                  name="descricao"
                  register={register}
                  error={errors.descricao?.message}
                />
              </FormGroup>
            </Row>

            <Button
              leftIcon={<FaSave />}
              colorScheme="green"
              type="submit"
              isLoading={isDisabledRequest}
            >
              Salvar
            </Button>
          </form>
          <Divider orientation="horizontal" border="2px" />
          <TituloPagina title="Listagem de Tipos de Trava" />
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
          {tipoTravaModal && (
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="lg"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Categoria de irso{' '}
                <strong>{tipoTravaModal.nome}</strong> tem certeza da operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <ButtonChacrka
                    onClick={onCloseVisualizar}
                    colorScheme="green"
                  >
                    Não
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() => handleExcluirCategoria(tipoTravaModal)}
                    colorScheme="red"
                    isLoading={isDisabledRequest}
                  >
                    Sim Quero Deletar!
                  </ButtonChacrka>
                </HStack>
              </Flex>
            </Modal>
          )}
        </BoxContent>

        <Modal
          isOpen={isOpenEditarTipoTrava}
          onClose={onCloseEditarTipoTrava}
          size="lg"
          title="Editar Tipo de trava"
        >
          <form onSubmit={handleSubmitEditar(handleUpdateTipoTrava)}>
            <Row>
              <FormGroup name="Nome do Tipo de Trava">
                <Controller
                  name="nome"
                  control={controlEditar}
                  defaultValue={tipoTravaModal?.nome}
                  render={({ value, onChange }) => (
                    <FormInput
                      /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                      onChange={onChange}
                      value={value?.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      )}
                      onPaste={(e) => {
                        const a = e.currentTarget?.value.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        );

                        onChange(a);
                      }}
                      error={errorsEditar.nome?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup name="Descricao" cols={[12, 12, 12]}>
                <Controller
                  name="descricao"
                  control={controlEditar}
                  defaultValue={tipoTravaModal?.descricao}
                  render={({ value, onChange }) => (
                    <FormInput
                      /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                      onChange={onChange}
                      value={value?.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      )}
                      onPaste={(e) => {
                        const a = e.currentTarget?.value.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        );

                        onChange(a);
                      }}
                      error={errorsEditar.descricao?.message}
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <PanelBottomActions>
              <ButtonChacrka
                onClick={onCloseEditarTipoTrava}
                as="a"
                colorScheme="red"
              >
                Voltar
              </ButtonChacrka>

              <Button
                colorScheme="green"
                type="submit"
                isLoading={isDisabledRequest}
              >
                Salvar
              </Button>
            </PanelBottomActions>
          </form>
        </Modal>
      </>
    </>
  );
};

export default ListTipoTrava;
