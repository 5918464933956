/* eslint-disable import/no-extraneous-dependencies */
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { useDisclosure } from '@chakra-ui/hooks';
import { Flex } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/transition';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import React, { useCallback, useState, useEffect } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import PulseLoader from 'react-spinners/PulseLoader';
import geoJsonAIS from '../../../assets/AIS_CEARA_2022.json';

type IGeoJSONAIS = {
  features: {
    properties: {
      NM_AIS: string;
    };
  }[];
};

type IAisStatus = {
  name: string;
  isChecked: boolean;
  color: string;
};

interface IMapProps extends google.maps.MapOptions {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  geoJSON?: google.maps.Data.GeoJsonOptions;
}

const Map: React.FC<IMapProps> = ({ children, onClick, geoJSON, ...opts }) => {
  const localRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<google.maps.Map>();
  const [popUp, setPopUp] = useState<google.maps.InfoWindow>();
  const [isVisibleStroke, setIsVisibleStroke] = useState(true);
  const { isOpen, onToggle } = useDisclosure();
  const [isSelectedMarkAll, setIsSelectedMarkAll] = useState(false);

  const legend = document.getElementById('legend') as
    | HTMLDivElement
    | undefined;

  const colorsAIS = [
    '#9de2a2',
    '#8f43d5',
    '#fa1fda',
    '#c751b7',
    '#1d1ffc',
    '#cfd840',
    '#3ce138',
    '#d15248',
    '#e336d2',
    '#fadb1e',
    '#26f0f3',
    '#288af0',
    '#f94720',
    '#ee302a',
    '#ea2fe4',
    '#dc853d',
    '#d54396',
    '#4444d4',
    '#d57643',
    '#e4ce34',
    '#721bfd',
    '#a569b0',
    '#f56523',
    '#c523f6',
    '#35d8e3',
  ];

  const [aisSStatus, setAisSStatus] = useState<IAisStatus[]>(
    (geoJsonAIS as IGeoJSONAIS).features
      .map(({ properties }, index) => {
        return {
          name: properties.NM_AIS,
          color: colorsAIS[index],
          isChecked: false,
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name)) || [],
  );

  const renderStatus = useCallback((status: Status) => {
    if (status === Status.LOADING) return <PulseLoader />;

    return <div>erro no mapa</div>;
  }, []);

  const handleUpdateStatusAis = useCallback(
    (index: number, checked: boolean) => {
      if (aisSStatus[index]) {
        aisSStatus[index] = {
          ...aisSStatus[index],
          isChecked: checked,
        };

        const formatedAisSStatus = [...aisSStatus];

        setAisSStatus(formatedAisSStatus);
      }
    },
    [aisSStatus],
  );

  useEffect(() => {
    if (localRef.current && !map)
      // eslint-disable-next-line no-new
      setMap(
        new google.maps.Map(localRef.current, {
          ...opts,
          mapTypeControl: true,
          mapTypeControlOptions: {
            mapTypeIds: [
              google.maps.MapTypeId.SATELLITE,
              google.maps.MapTypeId.HYBRID,
              google.maps.MapTypeId.TERRAIN,
            ],
          },
        }),
      );
  }, [localRef, opts, map]);

  useEffect(() => {
    if (map) {
      ['click', 'idle'].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName),
      );

      if (onClick) {
        map.addListener('click', onClick);
      }

      if (geoJSON) {
        map.data.addGeoJson(geoJSON);
      }
      map.data.addGeoJson(geoJsonAIS);
    }
  }, [map, onClick, geoJSON, popUp]);

  useEffect(() => {
    if (!popUp) {
      setPopUp(new google.maps.InfoWindow());
    }
  }, [popUp]);

  useEffect(() => {
    if (map && legend) {
      legend.style.display = 'flex';
      map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
    }
  }, [map, legend]);

  useEffect(() => {
    if (map) {
      map.data.setStyle((feature) => {
        const name = feature.getProperty('NM_AIS');
        let style: google.maps.Data.StyleOptions = {
          fillColor: 'rgba(173, 216, 230, 0.04)',
          clickable: false,
          strokeWeight: 2,
        };
        if (!name) {
          style = {
            ...style,
            strokeColor: 'blue',
            fillColor: '#aaa',
            fillOpacity: 0.3,
          };
        } else {
          style = {
            ...style,
            strokeOpacity: isVisibleStroke ? 1 : 0,
            strokeColor: 'red',
          };

          const indexAisChecked = aisSStatus.findIndex(
            ({ name: nameAIS }) => nameAIS === name,
          );

          if (indexAisChecked > -1 && aisSStatus[indexAisChecked]?.isChecked) {
            style = {
              ...style,
              fillColor: aisSStatus[indexAisChecked].color,
              fillOpacity: 0.1,
            };
          }
        }

        return style;
      });
    }
  }, [map, aisSStatus, isVisibleStroke]);

  useEffect(() => {
    setAisSStatus((actualAISStatus) =>
      actualAISStatus.map((state) => ({
        ...state,
        isChecked: isSelectedMarkAll,
      })),
    );
  }, [isSelectedMarkAll]);

  return (
    <>
      <Wrapper
        apiKey={process.env.GOOGLE_API_TOKEN || ''}
        render={renderStatus}
      >
        <div
          id="google-maps"
          ref={localRef}
          style={{ width: '100%', height: '80vh' }}
        >
          {React.Children.map(children, (child): any => {
            if (React.isValidElement(child)) {
              // set the map prop on the child component
              return React.cloneElement(child, { map });
            }
            return null;
          })}
        </div>
      </Wrapper>

      <Flex
        direction="column"
        background="#fff"
        padding="10px"
        margin="10px"
        border="3px solid #000"
        id="legend"
        display="none"
        width="96px"
        justifyContent="space-evenly"
      >
        <Flex direction="row" justifyContent="space-between">
          <Checkbox
            isChecked={isVisibleStroke}
            onChange={(e) => setIsVisibleStroke(e.target.checked)}
          />
          <p>Linhas</p>
        </Flex>

        <Flex direction="row" justifyContent="space-between">
          <Checkbox
            isChecked={isSelectedMarkAll}
            onChange={(e) => {
              setIsSelectedMarkAll(e.target.checked);
            }}
          />
          <p>Todos</p>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          {aisSStatus.map(({ color, name, isChecked }, index) => (
            <Flex
              direction="row"
              key={name}
              justifyContent="space-between"
              marginTop="4px"
              alignContent="center"
              alignItems="center"
            >
              <Checkbox
                isChecked={isChecked}
                onChange={(e) => handleUpdateStatusAis(index, e.target.checked)}
                key={name}
              />
              <div style={{ background: color, width: 10, height: 10 }} />
              <p>{name}</p>
            </Flex>
          ))}
        </Collapse>
        <Button size="sm" type="button" onClick={onToggle} marginTop="0.5">
          {isOpen ? <FaArrowUp /> : <FaArrowDown />}
        </Button>
      </Flex>
    </>
  );
};

export default Map;
