import { Button, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import * as Yup from 'yup';
import { Select } from 'components/form/Select';
import { format } from 'date-fns';
import Row from 'components/form/Row';
import api from '../../services/api';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';

const criarFuncaoSchema = Yup.object()
  .shape({
    hora_validacao: Yup.number()
      .required('Este campo é requerido')
      .typeError('Este campo é requerido'),
  })
  .noUnknown()
  .required();

type IPessoa = {
  pes_nome: string;
  pessoaPM: { graduacao?: { gra_sigla: string } };
};

type IEscalaTrava = {
  id_escala_hora_validacao: number;
  hora_validacao: number;
  criado_por: string;
  criado_em: Date;
  atualizado_por: string;
  atualizado_em: Date;
  deletado: Date;
  pessoaCriadoPor: IPessoa;
  pessoaAtualizadoPor: IPessoa;
};

type ICreateEscalaTrava = { hora_validacao: number };

type OptionType = { label: string; value: string };

const ciclos = Array.from(Array(12).keys()).map<OptionType>((dias) => ({
  label: `${((dias + 1) * 24).toString()} horas - (${dias + 1} dias)`,
  value: ((dias + 1) * 24).toString(),
}));

const EscalaTravaHoraAbertura: React.FC = () => {
  const toast = useToast();

  const { control, errors, handleSubmit, reset } = useForm<ICreateEscalaTrava>({
    resolver: yupResolver(criarFuncaoSchema),
    defaultValues: { hora_validacao: Number.NaN },
  });

  const columns: IColumns = [
    // { field: 'processo.nome', text: 'Processo', type: { name: 'text' } },
    {
      field: 'hora_validacao',
      text: 'Horas validação',
      type: { name: 'text' },
    },
    {
      field: 'criado_em',
      text: 'Criado em',
      type: { name: 'date', format: 'dd/MM/yyyy' },
      tooltip: ({ pessoaCriadoPor, criado_em }: IEscalaTrava) => {
        try {
          return `As ${format(new Date(criado_em), 'HH:mm:ss')} por: ${
            pessoaCriadoPor.pessoaPM.graduacao?.gra_sigla
          } ${pessoaCriadoPor.pes_nome}`;
        } catch (error) {
          return undefined;
        }
      },
    },
    {
      field: 'deletado',
      text: 'Deletado em',
      type: { name: 'date', format: 'dd/MM/yyyy' },
      tooltip: ({ pessoaAtualizadoPor, deletado }: IEscalaTrava) => {
        try {
          return `As ${format(new Date(deletado), 'HH:mm:ss')} por: ${
            pessoaAtualizadoPor.pessoaPM.graduacao?.gra_sigla
          } ${pessoaAtualizadoPor.pes_nome}`;
        } catch (error) {
          return undefined;
        }
      },
    },
  ];

  const options = {
    serverData: {
      url: `/trava_hora_escala`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
  };

  const handleUpdateEscalaTrava = async (
    data: ICreateEscalaTrava,
  ): Promise<void> => {
    try {
      await api.post(`/trava_hora_escala`, data);
      toast({
        title: 'Sucesso.',
        description: 'Prazo para validação criadas com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      reset();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      console.error(error);
    }
  };

  return (
    <>
      <TituloPagina title="Prazo para validação" />
      <BoxContent>
        <form onSubmit={handleSubmit(handleUpdateEscalaTrava)}>
          <Row>
            <FormGroup cols={[4, 6, 12]}>
              <Controller
                control={control}
                name="hora_validacao"
                render={({ value, onChange }) => (
                  <FormGroup name="Prazo para validação" cols={[10, 10, 12]}>
                    <Select
                      options={[
                        { label: 'Selecione uma opção', value: '' },
                        ...ciclos,
                      ]}
                      name="Qtd Hora para validação"
                      onChange={onChange}
                      value={value}
                      error={errors.hora_validacao}
                    />
                  </FormGroup>
                )}
              />
            </FormGroup>
            <Button
              leftIcon={<FaSave />}
              colorScheme="green"
              type="submit"
              mt={8}
            >
              Salvar
            </Button>
          </Row>
        </form>
        <DataTable columns={columns} options={options} />
      </BoxContent>
    </>
  );
};

export default EscalaTravaHoraAbertura;
