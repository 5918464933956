import React, { useCallback } from 'react';
import {
  MdEdit,
  MdSearch,
  MdDashboard,
  MdLibraryBooks,
  MdOutlineSettings,
} from 'react-icons/md';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
} from '@chakra-ui/react';
import { RiAdminFill } from 'react-icons/ri';
import { GiBookshelf } from 'react-icons/gi';
import MenuDropdown from './MenuDropdown';
import { useAuth } from '../../../../contexts/auth';

import Logo from './Logo';
import MenuItem from './Menu';

interface ISideBarProps {
  isOpen: boolean;
  onClose(): void;
}

const cadastrosBCG = [
  { label: 'SITUAÇÂO - VOLUNTÁRIO', to: '/voluntariosituacoes' },
];

const cadastrosEscalantes = [
  { label: 'ESCALA - DRSO', to: '/criarescalairso' },
];

const consultasControladoria = [
  { label: 'ESCALA DRSO', to: '/listarescalairso' },
];

const consultaHoraUnidade = [
  { label: 'TOTAL HORAS POR PM', to: '/horas_unidade' },
];

const consultasESCALANTES = [
  { label: 'VOLUNTÁRIOS', to: '/voluntariosopm' },
  { label: 'EXTRATO', to: '/saldo' },
];

const relatoriosCoafi = [
  { label: 'PLANILHA - CONVERSAO', to: '/converterplanilha' },
  // { label: 'ESCALAS - GASTOS', to: '/relatorios/gastos' },
];

const relatoriosCGO = [
  { label: 'PLANILHA - COAFI', to: '/relatorios/pagamento_cgo' },
  { label: 'ESCALAS - IMPRESSÃO', to: '/relatorios/imprimirescalas' },
  { label: 'ESCALAS - OPERAÇÕES', to: '/relatorios/operacoes' },
  // { label: 'ESCALAS - GASTOS', to: '/relatorios/gastos' },
];

const relatoriosCGOADM = [
  { label: 'PORTARIA - FINANCEIRO', to: '/relatorios/ciclo_ano' },
  {
    label: 'PORTARIA - PROMOÇÕES',
    to: '/relatorios/atualizar_escalas_promocoes',
  },
  {
    label: 'RELATORIO DRSO - FALTAS',
    to: '/relatorios/ciclo_ano/faltas',
  },
  { label: 'QUANTITATIVO DIÁRIO', to: '/relatorios/diario' },
];

const administracao = [
  { label: 'CICLO - CŔEDITO', to: '/saldogeral/novo' },
  { label: 'CICLO - PREVISÃO', to: '/previsaosaldo' },
  { label: 'ESCALA - CATEGORIA', to: '/categoriairso' },
  { label: 'ESCALA - OPERAÇÃO', to: '/subcategoriairso' },
  { label: 'ESCALA - PROCESSO', to: '/listarprocesso' },
  { label: 'ESCALA - FUNÇÕES', to: '/funcoes' },
  { label: 'TRAVA - TIPOS', to: '/listartipotrava' },
  { label: 'TRAVA - OPM', to: '/listartravaopm' },
  { label: 'TRAVA - OPM HORA', to: '/listartravaopmhora' },
  { label: 'TRAVA - PM', to: '/listartravapm' },
  { label: 'TRAVA - ESCALA HORAS', to: '/escalatravaabertura' },
];

const cetic = [{ label: 'TIPOS OPERAÇÕES', to: '/tiposoperacoes' }];

const perfisAdministracao = ['CETIC', 'CGO'];

const SideBarMobile: React.FC<ISideBarProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();

  const MenuDropdownCadastros = useCallback(() => {
    const optionsCadastros = {
      CETIC:
        user.currentOpm?.uni_codigo.toString() !== '-1'
          ? [...cadastrosBCG, ...cadastrosEscalantes]
          : [...cadastrosBCG],
      'USUÁRIO PADRÃO': cadastrosBCG,
      ESCALANTE:
        user.currentOpm?.uni_codigo.toString() !== '-1'
          ? cadastrosEscalantes
          : undefined,
    };

    const perfis =
      optionsCadastros[
        user.currentPerfil as 'CETIC' | 'USUÁRIO PADRÃO' | 'ESCALANTE'
      ];

    return (
      perfis !== undefined && (
        <MenuDropdown label="CADASTROS" icon={MdEdit} items={perfis} />
      )
    );
  }, [user.currentPerfil, user.currentOpm]);

  const MenuDropdownRelatorios = useCallback(() => {
    const optionsPerfisRelatorios = {
      CETIC: [...relatoriosCGO, ...relatoriosCGOADM, ...relatoriosCoafi],
      CGO: [...relatoriosCGO, ...relatoriosCGOADM, ...relatoriosCoafi],
      'CGO - ADM': relatoriosCGOADM,
    };
    const relatorios =
      optionsPerfisRelatorios[user.currentPerfil as 'CGO' | 'CGO - ADM'];

    return (
      relatorios !== undefined && (
        <MenuDropdown
          label="RELATÓRIOS"
          icon={MdLibraryBooks}
          items={relatorios}
        />
      )
    );
  }, [user.currentPerfil]);

  const MenuDropdownConsultas = useCallback(() => {
    const optionsConsultas = {
      CONTROLADORIA: consultasControladoria,
      COMANDANTE: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
      ],
      ESCALANTE: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
      ],
      VALIDADOR: [...consultasControladoria, ...consultaHoraUnidade],
      CGO: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
        { label: 'ESCALAS - FERIADOS', to: '/escalasferiados' },
      ],
      CETIC: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
        { label: 'ESCALAS - FERIADOS', to: '/escalasferiados' },
      ],
    };

    const consultas =
      optionsConsultas[
        user.currentPerfil as 'CETIC' | 'CGO' | 'CONTROLADORIA' | 'VALIDADOR'
      ];

    return (
      consultas !== undefined && (
        <MenuDropdown label="CONSULTA" icon={MdSearch} items={consultas} />
      )
    );
  }, [user.currentPerfil]);

  const MenuDropdownAdministracao = useCallback(() => {
    return (
      perfisAdministracao.includes(user.currentPerfil as string) && (
        <>
          <MenuDropdown
            label="Administração"
            icon={MdOutlineSettings}
            items={administracao}
          />
        </>
      )
    );
  }, [user.currentPerfil]);

  return (
    <Drawer
      placement="left"
      onClose={onClose}
      isOpen={isOpen}
      size="xs"
      isFullHeight
    >
      <DrawerOverlay>
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <HStack justifyContent="center">
              <DrawerCloseButton />
              <Logo />
            </HStack>
          </DrawerHeader>

          <DrawerBody bg="green.500" p={0}>
            <MenuItem
              to="/home"
              label="INICIAL"
              icon={MdDashboard}
              onClose={onClose}
            />
            {MenuDropdownCadastros()}

            {MenuDropdownConsultas()}

            {MenuDropdownRelatorios()}

            {MenuDropdownAdministracao()}

            {user.currentPerfil === 'CETIC' && (
              <MenuDropdown label="CETIC" icon={RiAdminFill} items={cetic} />
            )}

            <MenuItem
              onClose={onClose}
              to="/documentos"
              label="DOCUMENTOS"
              icon={GiBookshelf}
            />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
};

export default SideBarMobile;
