import React, { createContext, useCallback, useState, useContext } from 'react';

interface ITravaOpmHoraContextData {
  idTravaOpmHora: number | undefined;
  updateIdTravaOpmHora(id: number): Promise<void>;
}

const TravaOpmHoraContext = createContext<ITravaOpmHoraContextData>(
  {} as ITravaOpmHoraContextData,
);

const TravaOpmHoraProvider: React.FC = ({ children }) => {
  const [idTravaOpmHora, setIdTravaOpmHora] = useState<number | undefined>(
    () => {
      const id = sessionStorage.getItem('@pmce-cetic-escalas:id_travaopm_hora');

      if (id) {
        return JSON.parse(id);
      }

      return undefined;
    },
  );

  const updateIdTravaOpmHora = useCallback(async (id: number) => {
    setIdTravaOpmHora(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_travaopm_hora',
      JSON.stringify(id),
    );
  }, []);

  return (
    <TravaOpmHoraContext.Provider
      value={{
        idTravaOpmHora,
        updateIdTravaOpmHora,
      }}
    >
      {children}
    </TravaOpmHoraContext.Provider>
  );
};

function useTravaOpmHora(): ITravaOpmHoraContextData {
  const context = useContext(TravaOpmHoraContext);

  if (!context) {
    throw new Error(
      'useTravaOpm must be used within an ModalidadeirsoProvider',
    );
  }

  return context;
}

export { TravaOpmHoraProvider, useTravaOpmHora };
