import React, { useCallback, useEffect, useState } from 'react';

interface IProps extends google.maps.MarkerOptions {
  onClick?: (e: any) => any;
}

const Marker: React.FC<IProps> = ({ children, onClick, ...options }) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();
  const [openInfoWindow, setOpenInfoWindow] = useState(false);

  const clickHandler = useCallback(
    (e) => {
      if (onClick) onClick(e);

      setOpenInfoWindow(true);
    },
    [onClick],
  );

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);

      ['click'].forEach((eventName) =>
        google.maps.event.clearListeners(marker, eventName),
      );

      marker.addListener('click', (e: google.maps.IconMouseEvent) =>
        clickHandler(e),
      );
    }
  }, [marker, clickHandler, options]);

  return marker ? (
    <>
      {React.Children.map(children, (child): any => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            anchor: marker,
            open: openInfoWindow,
            onClose: () => {
              setOpenInfoWindow(false);
            },
            map: options.map,
          });
        }

        return null;
      })}
    </>
  ) : null;
};
export default Marker;
