/* eslint-disable no-control-regex */
/* eslint-disable import/no-extraneous-dependencies */
import { useToast } from '@chakra-ui/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm, FieldError } from 'react-hook-form';
import * as Yup from 'yup';
import { Button } from '@chakra-ui/button';
import {
  FaArrowRight,
  FaCheck,
  FaLock,
  FaPencilAlt,
  FaPlus,
  FaSave,
  FaSearch,
  FaTimes,
  FaTrash,
} from 'react-icons/fa';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from '@chakra-ui/accordion';
import { Box, Divider, Flex, Text } from '@chakra-ui/layout';
import { FcCalendar } from 'react-icons/fc';
import {
  addHours,
  differenceInHours,
  parseISO,
  isBefore,
  isAfter,
  isSameDay,
  addMinutes,
} from 'date-fns';
import { useDisclosure } from '@chakra-ui/hooks';
import { GiPerson } from 'react-icons/gi';
import axios from 'axios';
import { Tooltip } from '@chakra-ui/tooltip';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/alert';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@chakra-ui/checkbox';
import { UseToastOptions, Switch } from '@chakra-ui/react';
import debounce from 'debounce-promise';
import TextArea from '../../../components/form/FormTextArea';
import { useEscala } from '../../../contexts/escala';
import api from '../../../services/api';
import PanelBottomActions from '../../../components/PanelBottomActions';
import BoxContent from '../../../components/BoxContent';
import Row from '../../../components/form/Row';
import FormGroup from '../../../components/form/FormGroup';
import { Select } from '../../../components/form/Select';
import Modal from '../../../components/Modal';
import DataTable, { IColumns } from '../../../components/DataTable';
import InputDataTable from '../../../components/form/InputDataTable';
import Map from '../../../components/Maps/Google';
import Marker from '../../../components/Maps/Google/Marker';
import PopUP from '../../../components/Maps/Google/PopUp';
import TituloPagina from '../../../components/TituloPagina';
import DatePicker from '../../../components/form/FormDatePicker';
import Input from '../../../components/form/FormInput';
import InputCurrency from '../../../components/form/InputCurrency';
import InputNumber from '../../../components/form/InputNumberFormat';
import ReactSelect from '../../../components/form/ReactSelect';
import { usePolicial } from '../hooks/usePolicial';
import { useEquipesEnderecos } from '../hooks/useEquipesEndereco';
import { useGoogleMaps } from '../hooks/useGoogleMaps';
import { useVeiculos } from '../hooks/useVeiculos';
import AsyncSelect from '../../../components/form/AsyncSelect';

type OptionType = { label: string; value: string };

type IAreaSubCategoria = { id_area_subcategoria: number; nome: string };

type ErroMessageValue = {
  data_fim: string;
  data_inicio: string;
  voluntario: string;
  pm_codigo: string;
  tipo_trava?: string;
  nome: string;
  unidade?: string;
};

type ErrorMessage = { message: string; values: ErroMessageValue[] };

const criarEquipeSchema = Yup.object().shape({
  nome: Yup.string()
    .required('Este campo é requerido')
    .max(100, 'Digite no máximo 100 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
  id_processo: Yup.number().required('Este campo é requerido'),
  isValidateAreas: Yup.boolean().required(),
  ids_areas: Yup.array()
    .of(Yup.number().required())
    .when('isValidateAreas', {
      is: true,
      then: Yup.array()
        .of(Yup.number().required())
        .required('Este campo é requerido')
        .min(1),
    }),
  pm_codigo_fiscal_local: Yup.string().required('Este campo é requerido'),
});

const criarEscalaSchema = Yup.object().shape({
  id_categoria: Yup.number().required('Este campo é requerido'),
  id_subcategoria: Yup.number().required('Este campo é requerido'),
  nome: Yup.string()
    .required('Este campo é requerido')
    .min(10, 'Digite pelo menos 10 caracteres')
    .max(50, 'Digite no máximo 50 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
  data_inicio: Yup.date()
    .required('Este campo é requerido')
    .typeError('Data em formato invalido'),
  quantidade_horas_escala: Yup.number()
    .required('Este campo é requerido')
    .min(2, 'No mínimo duas horas'),
  data_fim: Yup.date()
    .required('Este campo é requerido')
    .typeError('Data em formato invalido'),
  voluntario_obrigatorio: Yup.string()
    .required('Este campo é requerido')
    .oneOf(['0', '1'], 'Este campo é requerido'),

  ids_areas: Yup.array().of(Yup.number().required()).notRequired(),
  pm_codigo_fiscal_local: Yup.string().notRequired(),
  is_feriado_inicio: Yup.string().required().oneOf(['0', '1']),
  is_feriado_fim: Yup.string().required().oneOf(['0', '1']),
});

const fecharEscalaSchema = Yup.object()
  .shape({
    isEscalaAtrasada: Yup.boolean().required(),
    justificativa: Yup.string()
      .notRequired()
      .when('isEscalaAtrasada', {
        is: true,
        then: Yup.string().required('Este cammpo é requerido'),
        // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
      }),
  })
  .required();

const optionsEscalas = Array.from({ length: 11 }, (_, i) => ({
  label: (i + 2).toString(),
  value: (i + 2).toString(),
}));

type IVeiculo = { dados: string; dados1: string; vei_codigo: number };

type IEditarObsSchema = {
  observacao?: string;
  horario_inicio: Date;
  horario_fim: Date;
};

type IEnderecoSchema = google.maps.places.PlaceResult & {
  geoJSON?: Record<string, any>;
  address_type: string;
  observacao?: string;
  horario_inicio?: Date;
  horario_fim?: Date;
};

type IResponsePoliciais = {
  id_voluntario: number;
  situacao?: '0' | '1';
  opm_voluntario: number;
  data_voluntario: string;
  pm_codigo: string;
  qtd_horas: number;
  pm: {
    pm_numero: number;
    gra_codigo: number;
    pessoa: { pes_nome: string };
    graduacao: { gra_sigla: string };
    situacaoPM: { spm_tipo: '3' | '4' };
    opm: { uni_codigo: string };
  };
  valorHoraIrso: { vho_valor: number };
  data_expiracao: string;
  termo_aceite: '0' | '1';
};

type IFuncao = { id_funcao: number; nome: string; deletado: number };

interface IProcesso {
  id_processo: number;
  nome: string;
  situacao: string;
  criado_por?: string;
  criado_em?: string;
  atualizado_em?: string;
  atualizado_por?: string;
  processosFuncoes: { id_funcao: number; deletado: number; funcao: IFuncao }[];
}

type IEquipeSchema = {
  nome: string;
  id_processo: number;
  ids_areas: number[];
  isValidateAreas: boolean;
  pm_codigo_fiscal_local: string;
};

type IAdicionarVoluntarioSchema = { funcao: number; pm_codigo: string };

type IEscalaSchema = {
  id_categoria: number;
  id_subcategoria: number;
  nome: string;
  data_inicio: Date;
  data_fim: Date;
  quantidade_horas_escala: number;
  voluntario_obrigatorio: '0' | '1';
  ids_areas?: number[];
  pm_codigo_fiscal_local?: string;
  is_feriado_inicio: '0' | '1';
  is_feriado_fim: '0' | '1';
};

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
}

type IEscala = {
  id_escala: number;
  nome: string;
  data_inicio: string;
  data_fim: string;
  total_hora: number;
  valor_escala: string;
  id_categoria: number;
  id_subcategoria?: number;
  voluntario_obrigatorio: '0' | '1';
  is_already_fecahda: 0 | 1;
  pm_codigo_fiscal_local: string;
  fiscalEscala?: { dados: string };
  is_feriado_inicio: '0' | '1';
  is_feriado_fim: '0' | '1';
};

type IFecharEscalaSchema = { justificativa: string; isEscalaAtrasada: boolean };

type IEquipe = {
  nome: string;
  id_equipe: number;
  id_processo: number;
  idsAreas: number[];
};

const optionsObrigatoriedadeIrso: OptionType[] = [
  { label: 'Selecione uma opção...', value: '' },
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
];

const schemaEditarObservacao = Yup.object().shape({
  observacao: Yup.string()
    .notRequired()
    .max(77, 'O número de caracteres máximo e 77'),
  horario_inicio: Yup.date()
    .required('Este campo é requerido')
    .max(Yup.ref('horario_fim'), 'Inicio deve ser menor que o fim'),
  horario_fim: Yup.date().required('Este campo é requerido'),
});

const EditarEscalaIrso: React.FC = () => {
  const { idEscala } = useEscala();
  const history = useHistory();

  const toast = useToast();
  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
  } = useForm<IEscalaSchema>({
    resolver: yupResolver(criarEscalaSchema),
  });

  const {
    control: controlEquipe,
    errors: errorsEquipe,
    handleSubmit: handleSubmitEquipe,
    trigger: triggerEquipe,
    watch: watchEquipe,
    reset: resetEquipe,
  } = useForm<IEquipeSchema>({
    resolver: yupResolver(criarEquipeSchema),
  });

  const {
    errors: errorsFecharEscala,
    trigger: triggerFecharEscala,
    handleSubmit: handleSubmitFecharEscala,
    control: controlFecharEscala,
  } = useForm<IFecharEscalaSchema>({
    resolver: yupResolver(fecharEscalaSchema),
  });

  const {
    schema: {
      control: controlAdicionarVoluntario,
      setValue: setValueAdicionarVoluntario,
      errors: errorsAdicionarVoluntario,
      handleSubmit: handleSubmitAdicionarVoluntario,
      reset: resetAdicionarVoluntario,
    },
    equipesPoliciais,
    columnsListDataTable: columnsDatatablePoliciais,
    loadPoliciaisEquipes,
    clearPoliciaisOnChangeProcesso,
    addVoluntario,
    removeEquipePoliciais,
    removePolicial,
  } = usePolicial(idEscala as number);

  const columnsDatatablePoliciais2 = columnsDatatablePoliciais.filter(
    ({ field }) =>
      !['pm.situacaoPM.spm_nome', 'situacao', 'pm.opm.uni_sigla'].includes(
        field,
      ),
  );

  columnsDatatablePoliciais2.push({
    field: 'funcao.nome',
    text: 'Função',
    type: { name: 'text' },
  });

  const {
    control: controlEditarEndereco,
    errors: errorsEditarEndereco,
    handleSubmit: handleSubmitEditarEquipeEndereco,
    reset: resetEditarEndereco,
    trigger: triggerEditarEndereco,
  } = useForm<IEditarObsSchema>({
    resolver: yupResolver(schemaEditarObservacao),
  });

  columnsDatatablePoliciais2.push({
    field: 'unidade.uni_sigla',
    text: 'OPM',
    type: { name: 'text' },
  });

  const {
    loadEquipesVeiculos,
    columns: columnsVeiculos,
    addEquipeVeiculo,
    equipeVeiculos,
    removeEquipeVeiculos,
    removeVeiculo,
  } = useVeiculos(idEscala as number);

  const {
    schema: {
      control: controlAdicionarEnderecos,
      errors: errorsAdicionarEnderecos,
      handleSubmit: handleSubmitAdicionarEnderecos,
      setValue: setValueAdicionarEnderecos,
      reset: resetAdicionarEnderecos,
    },
    equipesEnderecos,
    optionsEnderecos,
    userPosition,
    colunasEnderecos,
    removeEquipeEnderecos,
    removeEndereco,
    handleUpdateUserPosition,
    loadEquipesEnderecos,
    loadEquipeEnderecos,
    handleFilteredAIS,
    handleLoadGeoJsonAis,
    handleUpdateColumnsByOption: handleUpdateColumnsEnderecosByOption,
    listarEnderecosEquipe,
  } = useEquipesEnderecos(idEscala as number);

  const {
    isOpen: isModalEditarObservacaoOpen,
    onOpen: onOpenModalEditarObservacao,
    onClose: onCloseModalEditarObservacao,
  } = useDisclosure();

  const [equipeEndereco, setEquipeEndereco] = useState<any>();

  const {
    handleUpdatePlaceDetails,
    handleUpdatePlaceDetailsPosition,
    placeDetails,
    placeDetaisPosition,
    mapa,
    place,
    handleUpdatePlace,
    handleUpdateNewPosition,
    handleRequestFuntionPlaceService,
    handleUpdateDetails,
  } = useGoogleMaps();

  const dataInicio = watch('data_inicio');
  const dataFim = watch('data_fim');
  const escalaObrigatoria = watch('voluntario_obrigatorio') === '1';
  const categoria = watch('id_categoria');
  const idSubcategoria = watch('id_subcategoria');
  const isSameDayEscala = isSameDay(dataInicio, dataFim);

  const {
    isOpen: isOpenEquipe,
    onOpen: onOpenEquipe,
    onClose: onCloseEquipe,
  } = useDisclosure();

  const {
    isOpen: isOpenDeletarEquipe,
    onOpen: onOpenDeletarEquipe,
    onClose: onCloseDeletarEquipe,
  } = useDisclosure();

  const {
    isOpen: isOpenAdicionarVoluntario,
    onOpen: onOpenAdicionarVoluntario,
    onClose: onCloseAdicionarVoluntario,
  } = useDisclosure();

  const {
    isOpen: isOpenRemoverVoluntario,
    onOpen: onOpenRemoverVoluntario,
    onClose: onCloseRemoverVoluntario,
  } = useDisclosure();

  const {
    isOpen: isOpenModalGoogle,
    onOpen: onOpenModalGoogle,
    onClose: onCloseModalGoogle,
  } = useDisclosure();
  const {
    isOpen: isOpenModalAdicionarEndereco,
    onOpen: onOpenModalAdicionarEndereco,
    onClose: onCloseModalAdicionarEndereco,
  } = useDisclosure();

  const {
    isOpen: isOpenModalRemoverEndereco,
    onOpen: onOpenModalRemoverEndereco,
    onClose: onCloseModalRemoverEndereco,
  } = useDisclosure();

  const {
    isOpen: isOpenModalAdicionarVeiculo,
    onOpen: onOpenModalAdicionarVeiculo,
    onClose: onCloseModalAdicionarVeiculo,
  } = useDisclosure();

  const {
    isOpen: isModalFecharEscalaOpen,
    onOpen: onOpenModalFecharEscala,
    onClose: onCloseModalFecharEscala,
  } = useDisclosure();

  const {
    isOpen: isOpenModalRemoverVeiculo,
    onOpen: onOpenModalRemoverVeiculo,
    onClose: onCloseModalRemoverVeiculo,
  } = useDisclosure();

  const {
    isOpen: isModalEscalaObrigatoriaOpen,
    onClose: onCloseModalEscalaObrigatoria,
    onOpen: onOpenModalEscalaObrigatoria,
  } = useDisclosure();

  const {
    isOpen: isModalErroOpen,
    onClose: onCloseModalErro,
    onOpen: onOpenModalErro,
  } = useDisclosure();

  const [disabledRequest, setDisabledRequest] = useState(false);
  const [areas, setAreas] = useState<IAreaSubCategoria[]>([]);

  const optionsAreas: OptionType[] = useMemo(
    () =>
      areas.map((i) => ({
        label: i.nome,
        value: i.id_area_subcategoria.toString(),
      })),
    [areas],
  );

  const [optionsAreasEscala, setOptionsAreasEscalas] = useState<OptionType[]>(
    [],
  );
  const [defaultAreasEscala, setDefaultAreasEscala] = useState<number[]>([]);
  const [optionsCategorias, setOptionsCategorias] = useState<OptionType[]>([]);
  const [funcoes, setFuncoes] = useState<OptionType[]>([]);
  const [isJustificativa, setIsJustificativa] = useState(false);

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const [errorColumns, setErrorColumns] = useState<IColumns>([
    { field: 'nome', text: 'Nome', type: { name: 'text' } },
    { field: 'motivo', text: 'Motivo', type: { name: 'text' } },
  ]);

  const handdleCloseModalError = useCallback(() => {
    onCloseModalErro();
  }, [onCloseModalErro]);

  const idEquipeEditarEndereco = equipesEnderecos.find((e) =>
    e.enderecos.find(
      (e2) => e2.id_equipe_endereco === equipeEndereco?.id_equipe_endereco,
    ),
  )?.id_equipe;

  const handleCloseModalEditarEnderecoObs = async (): Promise<void> => {
    resetEditarEndereco({ horario_inicio: undefined, horario_fim: '' });

    await loadEquipeEnderecos(idEquipeEditarEndereco as number);

    onCloseModalEditarObservacao();
  };

  const [optionsProcessosIrso, setOptionsProcessosIrso] = useState<
    OptionType[]
  >([]);

  const [escalaData, setEscalaData] = useState<
    IEscalaSchema & { valor_escala: number; is_already_fecahda: 0 | 1 }
  >();

  const [subcategorias, setSubcategorias] = useState<any[]>([]);
  const isFeriadoInicio = escalaData?.is_feriado_inicio;

  const optionsSubCategorias = useMemo(
    () =>
      subcategorias.map((item: Record<string, any>) => ({
        label: item.nome,
        value: item.id_subcategoria.toString(),
      })),
    [subcategorias],
  );

  const [equipes, setEquipes] = useState<
    (IEquipeSchema & { idEquipe: number; fiscalEquipe?: { dados: string } })[]
  >([]);

  const [equipeEditar, setEquipeEditar] = useState<
    IEquipeSchema & { idEquipe: number; fiscalEquipe?: { dados: string } }
  >();

  const policialSelecionado = useRef<
    IResponsePoliciais & { id_equipe_policial?: number }
  >();

  const [veiculoSelecionado, setVeiculoSelecionado] = useState<
    IVeiculo & { id_equipe_veiculo?: number }
  >();

  // const [
  //   aisSelected,
  //   setAisSelected,
  // ] = useState<google.maps.places.PlaceResult>();
  const aisSelected = useRef<google.maps.places.PlaceResult>();
  const [optionEndereco, setOptionEndereco] = useState<OptionType>();
  const [
    geoJSONplaceSelected,
    setGeoJSONplaceSelected,
  ] = useState<google.maps.Data.GeoJsonOptions>();

  const handleInput = async (
    input: string,
  ): Promise<google.maps.places.AutocompletePrediction[]> => {
    const types = optionEndereco?.value === '1' ? ['(regions)'] : ['address'];

    if (optionEndereco?.value === '3') {
      const filteredJSONAIS = handleFilteredAIS(input);

      return filteredJSONAIS.features.map(
        (feature) =>
          ({
            place_id: feature.properties.NM_AIS,
            description: feature.properties.NM_AIS,
            structured_formatting: { main_text: feature.properties.NM_AIS },
          } as google.maps.places.AutocompletePrediction),
      );
    }
    const service = new google.maps.places.AutocompleteService();
    const { predictions } = await service.getPlacePredictions({
      input,
      componentRestrictions: { country: 'br' },
      types,
      sessionToken: process.env.GOOGLE_API_TOKEN,
    });

    return predictions || [];
  };

  const listarEnderecosHorarioEquipe = (id: number): any[] => {
    return listarEnderecosEquipe(id).filter((e) => !!e?.horario_fim);
  };

  const equipeEnderecosHorarioEditar = listarEnderecosHorarioEquipe(
    idEquipeEditarEndereco as number,
  );

  const defaultValueAddEndereco = (id: number): Date => {
    const equipeEnderecosHorarioAdd = listarEnderecosHorarioEquipe(id);

    return equipeEnderecosHorarioAdd.length > 0
      ? parseISO(
          equipeEnderecosHorarioAdd[equipeEnderecosHorarioAdd.length - 1]
            ?.horario_fim,
        )
      : (escalaData?.data_inicio as Date);
  };

  const defaultValueEditarEndereco = equipeEndereco?.horario_fim
    ? parseISO(equipeEndereco?.horario_inicio)
    : equipeEnderecosHorarioEditar.length > 0
    ? parseISO(
        equipeEnderecosHorarioEditar[equipeEnderecosHorarioEditar.length - 1]
          ?.horario_fim,
      )
    : escalaData?.data_inicio;

  const isSameSubCategoria =
    categoria !== escalaData?.id_categoria ||
    idSubcategoria !== escalaData?.id_subcategoria;

  const loadSubCategorias = useCallback(
    async (id: number): Promise<any[]> => {
      const {
        data: { items },
      } = await api.get<{ items: any[] }>('subcategorias', {
        params: { ids_categorias: [id] },
      });

      return items.filter((item) =>
        isSameSubCategoria ? item.situacao === '1' : true,
      );
    },
    [isSameSubCategoria],
  );
  const handleCloseMap = useCallback(() => {
    handleUpdatePlaceDetailsPosition(undefined);
    setGeoJSONplaceSelected(undefined);
    handleUpdatePlaceDetails(undefined);
    onCloseModalGoogle();
  }, [
    onCloseModalGoogle,
    handleUpdatePlaceDetails,
    handleUpdatePlaceDetailsPosition,
  ]);

  const handleLoadGeoJSON = async (
    input: string,
  ): Promise<Record<string, any>> => {
    if (optionEndereco?.value === '3') {
      const { aisfiltered, lat, lng } = handleLoadGeoJsonAis(input);
      aisSelected.current = {
        address_components: [
          { short_name: aisfiltered.features[0].properties.NM_AIS },
        ],
        geometry: { location: new google.maps.LatLng({ lat, lng }) },
        formatted_address: aisfiltered.features[0].properties.NM_AIS,
      } as google.maps.places.PlaceResult;
      // setAisSelected({
      //   address_components: [
      //     { short_name: aisfiltered.features[0].properties.NM_AIS },
      //   ],
      //   geometry: { location: new google.maps.LatLng({ lat, lng }) },
      //   formatted_address: aisfiltered.features[0].properties.NM_AIS,
      // } as google.maps.places.PlaceResult);

      return {
        ...aisfiltered,
        location: new google.maps.LatLng({ lat, lng }),
      };
    }

    const { data: geoJSON } = await axios.get(
      'https://spca.sspds.ce.gov.br/api/pm/nominatim/search.php',
      {
        params: {
          polygon_geojson: 1,
          format: 'geocodejson',
          countrycodes: 'BR',
          state: 'ceara',
          city: input,
          'accept-language': 'pt',
          limit: 1,
        },
      },
    );

    return geoJSON;
  };

  const handleAddEndereco = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (data: IEnderecoSchema): void => {
      setValueAdicionarEnderecos('geoJSON', data);
      handleCloseMap();
    },
    [handleCloseMap, setValueAdicionarEnderecos],
  );

  const handleDeleteEndereco = ({
    id_equipe_endereco,
    ...rest
  }: {
    id_equipe_endereco: number;
  } & IEnderecoSchema): void => {
    setEquipeEndereco({ id_equipe_endereco, ...rest });

    onOpenModalRemoverEndereco();
  };

  const optionsTableEquipesPoliciais = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaTrash />,
          tooltip: 'Deletar Policial',
          getRow: (
            data: IResponsePoliciais & {
              id_equipe_policial: number | undefined;
            },
          ) => {
            // setPolicialSelecionado(data);
            policialSelecionado.current = data;
            onOpenRemoverVoluntario();
          },
        },
      ],
    },
  };

  const actionsTableInputEnderecos = {
    headerText: 'Ações',
    items: [
      {
        icon: <FaPencilAlt size={13} />,
        tooltip: 'Alterar Horário',

        getRow: async (
          placeSelected: google.maps.places.AutocompletePrediction,
        ) => {
          setEquipeEndereco(placeSelected);
          onOpenModalEditarObservacao();
        },
      },
      {
        icon: <FaTrash />,
        tooltip: 'Deletar Endereco',
        getRow: handleDeleteEndereco,
      },
    ],
  };

  const optionsVeiculosDataTable = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaTrash />,
          tooltip: 'Deletar Veículo',
          getRow: (data: IVeiculo) => {
            onOpenModalRemoverVeiculo();
            setVeiculoSelecionado(data);
          },
        },
      ],
    },
  };

  const handleOption = useCallback(() => {
    switch (optionEndereco?.value) {
      case '1':
        return 'Digite um bairro ou cidade';

      case '2':
        return 'Digite uma rua';

      default:
        return 'Selecione uma AIS';
    }
  }, [optionEndereco]);

  const optionsEnderecosDatatable: any = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar no mapa',

          getRow: async (
            placeSelected: google.maps.places.AutocompletePrediction,
          ) => {
            handleUpdatePlace(placeSelected);
            if (optionEndereco?.value !== '2') {
              const { location: _, ...locations } = await handleLoadGeoJSON(
                placeSelected.structured_formatting.main_text,
              );

              setGeoJSONplaceSelected(locations);
            }
            onOpenModalGoogle();
          },
        },
        {
          icon: <FaPlus size={13} />,
          tooltip: 'Adicionar',
          getRow: async ({
            place_id,
            structured_formatting,
          }: google.maps.places.AutocompletePrediction) => {
            let locations: Record<string, any> | undefined;

            if (optionEndereco?.value === '3') {
              locations = await handleLoadGeoJSON(
                structured_formatting.main_text,
              );
              const { location } = locations;

              delete locations.location;
              handleAddEndereco({
                ...aisSelected,
                geometry: { location },
                formatted_address: structured_formatting.main_text,
                address_type: optionEndereco?.label as string,
                geoJSON: locations,
              } as IEnderecoSchema);
            } else
              handleRequestFuntionPlaceService(
                place_id,
                async (placeFounded, _) => {
                  if (placeFounded) {
                    if (optionEndereco?.value === '1') {
                      locations = await handleLoadGeoJSON(
                        structured_formatting.main_text,
                      );
                      setGeoJSONplaceSelected(locations);
                    }

                    handleAddEndereco({
                      ...placeFounded,
                      address_type: optionEndereco?.label as string,
                      geoJSON: locations,
                    } as IEnderecoSchema);
                  }
                },
              );
          },
        },
      ],
    },
  };

  const options = {
    serverData: {
      url: `${api.defaults.baseURL}/voluntarios`,
      params: {
        // situacao: '1',
        ciclo: ((dataInicio?.getMonth() as number) + 1)
          .toString()
          .padStart(2, '0'),
        ano: dataInicio?.getFullYear(),
        codes: []
          .map((str) =>
            Math.random()
              .toString(36)
              .slice(2, 7)
              .concat(str)
              .concat(Math.random().toString(36).slice(2, 7)),
          )
          .join(','),
        page: 1,
        perPage: 4,
      },
      headers: api.defaults.headers,
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaPlus size={13} />,
          tooltip: 'Adicionar policial',

          getRow: async (voluntarioRow: IResponsePoliciais) => {
            const isDisponivel =
              !!voluntarioRow.pm?.situacaoPM &&
              ['3', '4'].includes(voluntarioRow.pm?.situacaoPM.spm_tipo);
            const isVoluntario =
              !!voluntarioRow.situacao && voluntarioRow.situacao === '1';
            // &&
            // voluntarioRow.termo_aceite === '1';

            const isVoluntarioAlreadyOnEquipe = equipesPoliciais.find(
              ({ policiais }) =>
                !!policiais.find(
                  ({ pm_codigo }) => pm_codigo === voluntarioRow.pm_codigo,
                ),
            );

            const isQuotaIndisponivel =
              voluntarioRow.qtd_horas +
                differenceInHours(dataFim as Date, dataInicio as Date) >
              96;

            const toastError: UseToastOptions = {
              title: 'Erro!',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            };

            let description: string | undefined;

            if (!isDisponivel)
              description =
                'Policial não pode tirar irso, situação do militar não é disponivel.';
            else if (isQuotaIndisponivel)
              description =
                'Pm não pode ser escalado devido sua quota de horas do ciclo ultrapassar 96 horas.';
            else if (isVoluntarioAlreadyOnEquipe)
              description = 'Pm já cadastrado em uma equipe desta escala';
            else if (escalaData?.voluntario_obrigatorio === '0') {
              if (!isVoluntario)
                description = 'Policial não pode tirar irso, não é voluntário.';
              else if (
                isAfter(dataInicio, parseISO(voluntarioRow.data_expiracao))
              )
                description =
                  'Policial não pode tirar irso pois não renovou sua voluntariedade.';
            }

            if (description) {
              toast({ ...toastError, description });
            } else {
              setValueAdicionarVoluntario('pm_codigo', voluntarioRow.pm_codigo);
              policialSelecionado.current = voluntarioRow;
              // setPolicialSelecionado(voluntarioRow);
            }
          },
        },
      ],
    },
  };

  const optionsVeiculos = {
    serverData: {
      url: `${api.defaults.baseURL}/veiculos`,
      headers: { ...api.defaults.headers },
      params: {
        opms: '-1',
        fields: [
          'veiculosCargas.dados', // 'veiculosCargas.numero',// 'veiculosCargas.marca',
        ],
        page: 1,
        perPage: 3,
      },
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaPlus size={13} />,
          tooltip: 'Adicionar Veiculo',

          getRow: async (veiculo: IVeiculo) => {
            // const equipes = watch('equipes');

            setVeiculoSelecionado(veiculo);

            // if (
            //   equipes.filter(
            //     (equipe) =>
            //       equipe.veiculos?.filter(
            //         (veiculoEquipe: any) =>
            //           veiculoEquipe.vei_codigo === veiculo.vei_codigo,
            //       ).length > 0,
            //   ).length > 0
            // ) {
            //   toast({
            //     title: 'Erro!',
            //     description: 'Veículo já cadastrada',
            //     status: 'error',
            //     duration: 5000,
            //     isClosable: true,
            //     position: 'top-right',
            //   });
            // } else {
            //   append(veiculo);
            //   setValue(
            //     'total_veiculos',
            //     ((watch('total_veiculos') as number) || 0) + 1,
            //   );

            //   await trigger(`equipes.${index}.veiculos`);
            // }
          },
        },
      ],
    },
  };

  const isDataSelected = useCallback((): boolean => !!dataInicio, [dataInicio]);

  const loadFuncoesByEquipe = useCallback(
    async (id: number) => {
      const {
        data: { processosFuncoes },
      } = await api.get<IProcesso>(`processo/${id}`);

      if (!processosFuncoes.length) {
        toast({
          title: 'Aviso!',
          description:
            'Não existem funções para este processo. Por favor, solicite a CGO para criar funções a este processo',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      setFuncoes([
        { label: 'Selecione', value: '' },
        ...processosFuncoes.map(({ id_funcao, funcao }) => ({
          label: funcao.nome,
          value: id_funcao.toString(),
        })),
      ]);
    },
    [toast],
  );

  const handleGetAreas = useCallback(async (id: number) => {
    const {
      data: { items },
    } = await api.get<{ items: IAreaSubCategoria[] }>(
      `subcategorias/${id}/areas`,
    );

    setAreas(items);
  }, []);

  const handleGetAreasEscala = useCallback(async (id: number) => {
    const {
      data: { items },
    } = await api.get<{ items: IAreaSubCategoria[] }>(
      `subcategorias/${id}/areas`,
    );
    setOptionsAreasEscalas(
      items.map((i) => ({
        label: i.nome,
        value: i.id_area_subcategoria.toString(),
      })),
    );
  }, []);

  const handleGetEscalaAreas = useCallback(async () => {
    const {
      data: { items: idsAreasEscala },
    } = await api.get<{ items: IAreaSubCategoria[] }>(
      `escalas/irsos/${idEscala}/areas`,
    );
    const ids = idsAreasEscala.map((i) => i.id_area_subcategoria);

    setValue('ids_areas', ids);
    setDefaultAreasEscala(ids);
  }, [idEscala, setValue]);

  const refPm = useRef<OptionType>();
  const refPmEquipe = useRef<OptionType>();

  const promiseItensFiscais = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`pessoas`, {
        params: { query: inputValue, gra_oficial: ['S', 'N'].join(',') },
      });

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.matricula,
          label: item.dados,
          opm: item.uni_codigo,
        };
      });

      // setPessoaList(response.data);

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItensFiscais(query), 500),
    [promiseItensFiscais],
  );

  const handleLoadEscala = useCallback(async () => {
    const { data } = await api.get<IEscala>(`escalas/irsos/${idEscala}`);

    if ((data as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    return data;
  }, [idEscala, history, toast]);

  const handleUpdateStateEscala = useCallback(async () => {
    const {
      data_inicio,
      data_fim,
      id_categoria,
      nome,
      valor_escala,
      voluntario_obrigatorio,
      is_already_fecahda,
      ...escala
    } = await handleLoadEscala();

    if (escala.id_subcategoria) await handleGetAreas(escala.id_subcategoria);

    const horaInicio = parseISO(data_inicio);
    const horaFim = parseISO(data_fim);
    const quantidadeHorasEscala = differenceInHours(horaFim, horaInicio);

    setEscalaData({
      data_fim: horaFim,
      data_inicio: horaInicio,
      id_categoria,
      nome,
      valor_escala: Number.parseFloat(valor_escala),
      quantidade_horas_escala: quantidadeHorasEscala,
      voluntario_obrigatorio,
      id_subcategoria: escala.id_subcategoria as number,
      is_already_fecahda,
      pm_codigo_fiscal_local: escala.pm_codigo_fiscal_local,
      is_feriado_inicio: escala.is_feriado_inicio ?? '0',
      is_feriado_fim: escala?.is_feriado_fim ?? '0',
    });
  }, [handleLoadEscala, handleGetAreas]);

  const handleCloseModalEscalaObrigatoria = (): void => {
    setValue('voluntario_obrigatorio', '1');
    onCloseModalEscalaObrigatoria();
  };

  const idsEquipesState = equipes.map((e) => e.idEquipe);

  const handleEscala = async (data: IEscalaSchema): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);
    try {
      await api.put(`escalas/irsos/${idEscala}`, data);
      if (
        data.voluntario_obrigatorio === '0' &&
        escalaData?.voluntario_obrigatorio === '1'
      )
        equipes.forEach(({ idEquipe }) => removeEquipePoliciais(idEquipe));

      handleUpdateStateEscala();

      await handleGetEscalaAreas();

      await loadEquipesEnderecos(idsEquipesState);

      toast({
        title: 'Sucesso.',
        description: `Escala atualizada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      const { message } = error.response.data;
      try {
        const parsedMessage: ErrorMessage = JSON.parse(message);
        setErrorMessage(parsedMessage);
        onOpenModalErro();
      } catch (error2) {
        toast({
          title: 'Ocorreu um erro.',
          description: message || 'Escala não pode ser atualizada!',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }

    setDisabledRequest(false);
  };

  const handleFecharEscala = async ({
    justificativa,
  }: IFecharEscalaSchema): Promise<void> => {
    setDisabledRequest(true);
    try {
      await api.put(`escalas/irsos/${idEscala}/fechar`, {
        justificativa,
      });
      toast({
        title: 'Sucesso.',
        description: `Escala fechada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      history.push('/listarescalairso');
    } catch (error) {
      const { message } = error.response.data;

      try {
        const parsedMessage: ErrorMessage = JSON.parse(message);

        setErrorMessage(parsedMessage);
        if (parsedMessage.values[0]?.pm_codigo) {
          setErrorColumns([
            {
              field: 'data_inicio',
              text: 'Data Início',
              type: { name: 'text' },
            },
            { field: 'data_fim', text: 'Data Final', type: { name: 'text' } },
            { field: 'pm_codigo', text: 'Matricula', type: { name: 'text' } },
            { field: 'voluntario', text: 'Voluntario', type: { name: 'text' } },
          ]);
        } else
          setErrorColumns([
            { field: 'nome', text: 'Nome', type: { name: 'text' } },
            { field: 'motivo', text: 'Motivo', type: { name: 'text' } },
          ]);

        onOpenModalErro();
      } catch (error2) {
        toast({
          title: 'Ocorreu um erro.',
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
      setIsJustificativa(false);
      onCloseModalFecharEscala();
      setDisabledRequest(false);
    }
  };

  const handleEquipe = async ({
    id_processo,
    nome,
    ids_areas,
    pm_codigo_fiscal_local,
  }: IEquipeSchema): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);

    try {
      if (equipeEditar) {
        const indexNewUpdatedEquipes = equipes.findIndex(
          ({ idEquipe }) => idEquipe === equipeEditar.idEquipe,
        );

        if (indexNewUpdatedEquipes < -1)
          toast({
            title: 'Erro.',
            description: 'Equipe não encontrada',
            status: 'error',
            duration: 10000,
            isClosable: true,
            position: 'top-right',
          });
        else {
          await api.put(
            `escalas/irsos/${idEscala}/equipes/${equipeEditar.idEquipe}`,
            { id_processo, nome, ids_areas, pm_codigo_fiscal_local },
          );

          // removendo policiais caso processo da equipe tenha mudado
          if (equipes[indexNewUpdatedEquipes].id_processo !== id_processo) {
            clearPoliciaisOnChangeProcesso(equipeEditar.idEquipe);
            handleUpdateStateEscala();
          }

          // removendo veiculos caso processo tenha mudado
          if (
            equipes[indexNewUpdatedEquipes].id_processo !== id_processo &&
            equipeVeiculos.length > 0
          ) {
            removeEquipeVeiculos(equipeEditar.idEquipe);
          }

          equipes[indexNewUpdatedEquipes].id_processo = id_processo;
          equipes[indexNewUpdatedEquipes].nome = nome;
          equipes[indexNewUpdatedEquipes].ids_areas = ids_areas;
          equipes[
            indexNewUpdatedEquipes
          ].pm_codigo_fiscal_local = pm_codigo_fiscal_local;
          equipes[indexNewUpdatedEquipes].fiscalEquipe = {
            dados: refPmEquipe.current?.label as string,
          };

          const newEquipes = [...equipes];

          setEquipes(newEquipes);
          toast({
            title: 'Sucesso.',
            description: `Equipe atualizada com sucesso!`,
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } else {
        const {
          data: { id_equipe },
        } = await api.post<{ id_equipe: number }>(
          `escalas/irsos/${idEscala}/equipes`,
          { nome, id_processo, idsAreas: ids_areas, pm_codigo_fiscal_local },
        );

        setEquipes((actualEquipes) => [
          ...actualEquipes,
          {
            id_processo,
            nome,
            idEquipe: id_equipe,
            ids_areas,
            pm_codigo_fiscal_local,
          } as IEquipeSchema & { idEquipe: number },
        ]);

        toast({
          title: 'Sucesso.',
          description: `Equipe criada com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }

      setEquipeEditar(undefined);
      onCloseEquipe();
    } catch (error) {
      toast({
        title: 'Erro.',
        description: error.response.data.message || 'Não pode atualizar equipe',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleFindEquipe = useCallback(
    (id: number) => {
      const equipe = equipes.find(({ idEquipe }) => idEquipe === id);

      if (!equipe) {
        toast({
          title: 'Erro.',
          description: 'Equipe não encontrada',
          status: 'error',
          duration: 10000,
          isClosable: true,
          position: 'top-right',
        });
      }
      return equipe;
    },
    [equipes, toast],
  );

  const handleOpenEditarEquipe = async (id: number): Promise<void> => {
    const equipe = handleFindEquipe(id);
    if (equipe) {
      setEquipeEditar(equipe);
      onOpenEquipe();
    }
  };

  const handleCloseModalVoluntario = useCallback(() => {
    // setPolicialSelecionado(undefined);
    policialSelecionado.current = undefined;
    setEquipeEditar(undefined);
    onCloseRemoverVoluntario();
  }, [onCloseRemoverVoluntario]);

  const handleCloseEndereco = useCallback(() => {
    setEquipeEndereco(undefined);
    setEquipeEditar(undefined);
    onCloseModalRemoverEndereco();
  }, [onCloseModalRemoverEndereco]);

  const handleCloseVeiculo = useCallback(() => {
    setVeiculoSelecionado(undefined);
    setEquipeEditar(undefined);
    onCloseModalRemoverVeiculo();
  }, [onCloseModalRemoverVeiculo]);

  const handleAdicionarVoluntario = async ({
    funcao,
    pm_codigo,
  }: IAdicionarVoluntarioSchema): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);
    try {
      const {
        data: { id_equipe_policial },
      } = await api.post(
        `escalas/irsos/${idEscala}/equipes/${equipeEditar?.idEquipe}/equipes_policiais`,
        {
          funcao,
          graduacao: policialSelecionado.current?.pm.gra_codigo,
          opm: policialSelecionado.current?.pm.opm.uni_codigo,
          pm_codigo,
        },
      );

      addVoluntario(equipeEditar?.idEquipe as number, {
        ...policialSelecionado.current,
        unidade: (policialSelecionado.current?.pm as any)?.opm,
        id_equipe_policial,
        funcao: {
          nome: funcoes.find((funcao2) => funcao2.value === funcao.toString())
            ?.label,
        },
      });

      toast({
        title: 'Sucesso.',
        description: `Policial Adicionado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      // setPolicialSelecionado(undefined);
      policialSelecionado.current = undefined;
      resetAdicionarVoluntario({
        funcao: Number.NaN,
        pm_codigo: '',
      });

      handleUpdateStateEscala();
    } catch (error) {
      toast({
        title: 'Erro.',
        description:
          error.response?.data?.message || `Policial não pode ser adicionado!`,
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleCloseAdicionarEndereco = useCallback(() => {
    setOptionEndereco(undefined);
    setEquipeEditar(undefined);
    onCloseModalAdicionarEndereco();
  }, [onCloseModalAdicionarEndereco]);

  const handleAdicionarEndereco = async ({
    geoJSON,
    address_type,
    observacao: obs,
    ...rest
  }: IEnderecoSchema): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);

    try {
      await api.post(
        `escalas/irsos/${idEscala}/equipes/${equipeEditar?.idEquipe}/equipe_enderecos`,
        {
          ...geoJSON,
          address_type,
          observacao: obs,
          horario_inicio: rest?.horario_inicio,
          horario_fim: rest?.horario_fim,
        },
      );
      loadEquipeEnderecos(equipeEditar?.idEquipe as number);
      setOptionEndereco(undefined);
      resetAdicionarEnderecos({ observacao: '' });
      setValueAdicionarEnderecos('horario_inicio', rest.horario_fim);
    } catch (error) {
      toast({
        title: 'Erro.',
        description:
          error.response?.data?.message || `Endereço não pode ser adicionado`,
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleSubmitEditarEndereco = async (
    data: IEditarObsSchema,
  ): Promise<void> => {
    const indexEquipe = equipesEnderecos.findIndex(
      ({ enderecos }) =>
        !!enderecos.find(
          ({ id_equipe_endereco }) =>
            equipeEndereco?.id_equipe_endereco === id_equipe_endereco,
        ),
    );

    if (indexEquipe < 0)
      toast({
        title: 'Erro!',
        description: 'Equipe não encontrada',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      try {
        const obj = { ...equipeEndereco, ...data };
        setDisabledRequest(true);
        await api.put(
          `escalas/irsos/${idEscala}/equipes/${equipesEnderecos[indexEquipe]?.id_equipe}/equipe_enderecos/${equipeEndereco?.id_equipe_endereco}`,
          obj,
        );

        if (obj.horario_inicio)
          obj.horario_inicio = obj.horario_inicio.toISOString();
        if (obj.horario_fim) obj.horario_fim = obj.horario_fim.toISOString();

        toast({
          title: 'Sucesso!',
          description: 'Observação atualizada',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        toast({
          title: 'Erro!',
          description: error.response.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setDisabledRequest(false);
      }
    }
  };

  const handleAdicionarVeiculo = async (): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);
    try {
      const {
        data: { id_equipe_veiculo },
      } = await api.post(
        `escalas/irsos/${idEscala}/equipes/${equipeEditar?.idEquipe}/equipe_veiculos`,
        { id_veiculo: veiculoSelecionado?.vei_codigo },
      );

      addEquipeVeiculo(equipeEditar?.idEquipe as number, {
        id_equipe_veiculo,
        ...(veiculoSelecionado as IVeiculo),
      });

      setVeiculoSelecionado(undefined);
    } catch (error) {
      toast({
        title: 'Erro.',
        description:
          error.response?.data?.message || `Veículo não pode ser adicionado`,
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleOpenDeletarEquipe = async (id: number): Promise<void> => {
    const equipe = handleFindEquipe(id);

    if (equipe) {
      onOpenDeletarEquipe();
      setEquipeEditar(equipe);
    }
  };

  const handleOpenAdicionarPolicialEquipe = async (
    id: number,
  ): Promise<void> => {
    const equipe = handleFindEquipe(id);

    if (equipe) {
      loadFuncoesByEquipe(equipe.id_processo);
      setEquipeEditar(equipe);
      onOpenAdicionarVoluntario();
    }
  };

  const handleCloseAdicionarEquipe = useCallback(() => {
    resetEquipe({
      ids_areas: undefined,
    });
    setEquipeEditar(undefined);
    onCloseEquipe();
  }, [onCloseEquipe, resetEquipe]);

  const handleCloseDeletarEquipe = useCallback(() => {
    setEquipeEditar(undefined);
    onCloseDeletarEquipe();
  }, [onCloseDeletarEquipe]);

  const handleOpenAdicionarEndereco = async (id: number): Promise<void> => {
    const equipe = handleFindEquipe(id);

    if (equipe) {
      setEquipeEditar(equipe);
      onOpenModalAdicionarEndereco();
    }
  };

  const handleOpenAdicionarVeiculo = async (id: number): Promise<void> => {
    const equipe = handleFindEquipe(id);

    if (equipe) {
      setEquipeEditar(equipe);
      onOpenModalAdicionarVeiculo();
    }
  };

  const handleDeletarEquipe = async (): Promise<void> => {
    if ((escalaData as any)?.deletado === '1') {
      toast({
        title: 'Erro.',
        description: 'Escala Já está deletada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
      history.push('/listarescalairso');
    }

    setDisabledRequest(true);

    try {
      await api.delete(
        `escalas/irsos/${idEscala}/equipes/${equipeEditar?.idEquipe}`,
      );

      setEquipes(
        equipes.filter(({ idEquipe }) => equipeEditar?.idEquipe !== idEquipe),
      );

      toast({
        title: 'Sucesso.',
        description: `Equipe deletada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      removeEquipePoliciais(equipeEditar?.idEquipe as number);
      removeEquipeEnderecos(equipeEditar?.idEquipe as number);

      handleCloseDeletarEquipe();

      handleUpdateStateEscala();
    } catch (error) {
      toast({
        title: 'Erro.',
        description:
          error.response.data?.message ??
          'Não podemos deletar a equipe no momento',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleDeletarPolicial = async (): Promise<void> => {
    setDisabledRequest(true);

    try {
      const isPolicialRemoved = await removePolicial(
        policialSelecionado.current?.id_equipe_policial as number,
      );
      if (isPolicialRemoved) {
        const valorPM =
          (escalaData?.quantidade_horas_escala || 0) *
          (Number.parseFloat(
            policialSelecionado.current?.valorHoraIrso.vho_valor?.toString() as string,
          ) || 0);
        setEscalaData((st) => ({
          ...st,
          ...(escalaData as IEscalaSchema & { is_already_fecahda: 0 | 1 }),
          valor_escala: (escalaData?.valor_escala || 0) - valorPM,
        }));
        toast({
          title: 'Sucesso.',
          description: `Equipe deletada com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        handleCloseModalVoluntario();
        handleUpdateStateEscala();
      }
    } catch (error) {
      toast({
        title: 'Erro.',
        description: 'Não podemos deletar o policial no momento',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleDeletarEndereco = async (): Promise<void> => {
    await removeEndereco(equipeEndereco?.id_equipe_endereco as number);
    toast({
      title: 'Sucesso.',
      description: `Endereco deletado com sucesso!`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
    handleCloseEndereco();
  };

  const handleDeletarVeiculo = async (): Promise<void> => {
    await removeVeiculo(veiculoSelecionado?.id_equipe_veiculo as number);
    toast({
      title: 'Sucesso.',
      description: `Ve´piculo deletado com sucesso!`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });
    handleCloseVeiculo();
  };

  useEffect(() => {
    optionEndereco &&
      handleUpdateColumnsEnderecosByOption(optionEndereco.value);
  }, [optionEndereco, handleUpdateColumnsEnderecosByOption]);

  useEffect(() => {
    if (!!mapa && !!place) {
      const { place_id } = place;

      handleRequestFuntionPlaceService(place_id, (placeFounded, _) => {
        if (optionEndereco?.value === '3') {
          handleUpdateDetails(aisSelected.current);
        } else {
          handleUpdateUserPosition({
            lat: placeFounded?.geometry?.location?.lat() as number,
            lng: placeFounded?.geometry?.location?.lng() as number,
          });

          handleUpdateDetails(placeFounded || undefined);
        }
      });
    }
  }, [
    place,
    isOpenModalGoogle,
    mapa,
    handleRequestFuntionPlaceService,
    optionEndereco,
    aisSelected,
    handleUpdateUserPosition,
    handleUpdateDetails,
  ]);

  const handleClick = async (e: google.maps.MapMouseEvent): Promise<void> => {
    if (optionEndereco?.value === '2') {
      handleUpdateNewPosition(e);
    }
  };

  useEffect(() => {
    const loadOptionsAreasEscalas = async (): Promise<void> => {
      await handleGetAreasEscala(idSubcategoria);
    };

    if (idSubcategoria) loadOptionsAreasEscalas();
  }, [idSubcategoria, handleGetAreasEscala]);

  const popText = useCallback(() => {
    return (
      <>
        <Text>Deseja selecionar esta localizacao?</Text>
        <PanelBottomActions>
          <Button
            colorScheme="green"
            onClick={() => {
              handleAddEndereco({
                ...placeDetails,
                geoJSON: geoJSONplaceSelected,
                address_type: optionEndereco?.label as string,
              } as IEnderecoSchema);
            }}
          >
            Sim
          </Button>
        </PanelBottomActions>
      </>
    );
  }, [handleAddEndereco, geoJSONplaceSelected, optionEndereco, placeDetails]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      toast({
        title: 'Erro.',
        description:
          'Campos obrigatórios não preenchidos ou campos obrigatórios em formato inválido',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [errors, toast]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      const {
        data: { items: categorias },
      } = await api.get<{ items: ICategoria[] }>('categorias');

      const filteredCategorias = categorias.filter(
        (categoriaAtiva) => categoriaAtiva.situacao === '1',
      );

      setOptionsCategorias(
        filteredCategorias.map((categoriaAtiva) => ({
          label: categoriaAtiva.nome,
          value: categoriaAtiva.id_categoria.toString(),
        })),
      );

      const {
        data: { items: processos },
      } = await api.get<{ items: IProcesso[] }>('processo');

      setOptionsProcessosIrso(
        processos
          .filter((processo) => processo.situacao === '1')
          .map((processo) => ({
            label: processo.nome,
            value: processo.id_processo.toString(),
          })),
      );

      const {
        data_fim,
        data_inicio,
        id_categoria,
        nome,
        voluntario_obrigatorio,
        valor_escala,
        is_already_fecahda,
        ...escala
      } = await handleLoadEscala();

      const horaInicio = parseISO(data_inicio);
      const horaFim = parseISO(data_fim);
      const quantidadeHorasEscala = differenceInHours(horaFim, horaInicio);
      await handleGetEscalaAreas();

      if (escala.id_subcategoria) await handleGetAreas(escala.id_subcategoria);

      setEscalaData({
        data_fim: horaFim,
        data_inicio: horaInicio,
        id_categoria,
        nome,
        valor_escala: Number.parseFloat(valor_escala),
        quantidade_horas_escala: quantidadeHorasEscala,
        voluntario_obrigatorio,
        is_already_fecahda,
        id_subcategoria: escala.id_subcategoria as number,
        pm_codigo_fiscal_local: escala.pm_codigo_fiscal_local,
        is_feriado_inicio: escala.is_feriado_inicio ?? '0',
        is_feriado_fim: escala?.is_feriado_fim ?? '0',
      });

      if (escala.fiscalEscala && escala.pm_codigo_fiscal_local) {
        refPm.current = {
          value: escala.pm_codigo_fiscal_local,
          label: escala.fiscalEscala.dados,
        };
        setValue('pm_codigo_fiscal_local', escala.pm_codigo_fiscal_local);
      }

      setValue('nome', nome);
      setValue(
        'id_categoria',
        filteredCategorias.find(
          (categoriaAtiva) => categoriaAtiva.id_categoria === id_categoria,
        )?.id_categoria,
      );

      setValue('data_inicio', horaInicio);
      setValue('quantidade_horas_escala', quantidadeHorasEscala);
      setValue('data_fim', horaFim);
      setValue('voluntario_obrigatorio', voluntario_obrigatorio);
      setValue('is_feriado_inicio', escala?.is_feriado_inicio ?? '0');
      setValue('is_feriado_fim', escala?.is_feriado_fim ?? '0');

      setValue('id_subcategoria', escala?.id_subcategoria as number);

      const {
        data: { items },
      } = await api.get<{ items: IEquipe[] }>(
        `escalas/irsos/${idEscala}/equipes`,
      );

      const equipeAreasResponse = await Promise.all(
        items.map(async (i) => {
          const {
            data: { items: idsAreas },
          } = await api.get<{ items: { id_area_subcategoria: number }[] }>(
            `escalas/irsos/${idEscala}/equipes/${i.id_equipe}/areas`,
          );

          return idsAreas.map(
            ({ id_area_subcategoria }) => id_area_subcategoria,
          );
        }),
      );

      setEquipes(
        items.map(
          ({ id_equipe, ...rest }, index) =>
            ({
              ...rest,
              idEquipe: id_equipe,
              ids_areas: equipeAreasResponse[index],
            } as any),
        ),
      );

      const idsEquipe = items.map(({ id_equipe }) => id_equipe);
      loadEquipesEnderecos(idsEquipe);
      loadPoliciaisEquipes(idsEquipe);
      loadEquipesVeiculos(idsEquipe);
    };

    load();
  }, [
    idEscala,
    setValue,
    loadPoliciaisEquipes,
    loadEquipesEnderecos,
    loadEquipesVeiculos,
    handleLoadEscala,
    handleGetAreas,
    handleGetEscalaAreas,
  ]);

  const subCategoriaDefault = subcategorias.find(
    (s) => s.id_subcategoria === escalaData?.id_subcategoria,
  );

  useEffect(() => {
    const loadSubCategorias2 = async (): Promise<void> => {
      const items = await loadSubCategorias(categoria);
      setSubcategorias(items);
    };

    if (categoria) loadSubCategorias2();
  }, [categoria, loadSubCategorias]);

  useEffect(() => {
    const triggerJustificativa = async (): Promise<void> => {
      await triggerFecharEscala('justificativa');
    };

    if (isModalFecharEscalaOpen && isJustificativa) triggerJustificativa();
  }, [triggerFecharEscala, isModalFecharEscalaOpen, isJustificativa]);

  const isValidateArea = watchEquipe('isValidateAreas');

  useEffect(() => {
    const triggerEquipeAreas = async (): Promise<void> => {
      await triggerEquipe('ids_areas');
    };
    if (isOpenEquipe && !!isValidateArea) {
      triggerEquipeAreas();
    }
  }, [isOpenEquipe, triggerEquipe, isValidateArea]);

  useEffect(() => {
    if (equipeEditar && equipeEditar.fiscalEquipe) {
      refPmEquipe.current = {
        label: equipeEditar.fiscalEquipe.dados,
        value: equipeEditar.pm_codigo_fiscal_local,
      };
    }
  }, [equipeEditar]);

  useEffect(() => {
    if (!isSameDayEscala) setValue('is_feriado_fim', '0');
    else setValue('is_feriado_fim', isFeriadoInicio);
  }, [setValue, isSameDayEscala, isFeriadoInicio]);

  return (
    <>
      <TituloPagina title="Cadastro de escala" />
      <BoxContent>
        <Box>
          <Accordion allowMultiple defaultIndex={[0, 1]}>
            <AccordionItem border="1px solid #ddd" marginBottom="4">
              {({ isExpanded }) => (
                <>
                  <h2>
                    <AccordionButton background="#eee">
                      <Box flex="1" textAlign="left">
                        Configurações da Escala
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4} minH={isExpanded ? 400 : undefined}>
                    <form
                      onSubmit={handleSubmit(handleEscala)}
                      acceptCharset="iso-8859-1"
                    >
                      <Row>
                        <FormGroup name="Categoria" cols={[4, 6, 12]} required>
                          <Controller
                            control={control}
                            name="id_categoria"
                            render={({ onChange, value }) => (
                              <ReactSelect
                                optionsSelect={optionsCategorias}
                                onChange={(option: OptionType) => {
                                  onChange(Number(option.value));
                                  setValue('id_subcategoria', undefined);
                                }}
                                value={optionsCategorias.find(
                                  (option) =>
                                    option.value === value?.toString(),
                                )}
                                error={errors.id_categoria?.message}
                              />
                            )}
                          />
                        </FormGroup>

                        <FormGroup name="Operação" cols={[4, 6, 12]} required>
                          <Controller
                            control={control}
                            name="id_subcategoria"
                            defaultValue={escalaData?.id_subcategoria}
                            render={({ onChange, value }) => (
                              <ReactSelect
                                optionsSelect={optionsSubCategorias}
                                onChange={(option: OptionType) => {
                                  const parsed = Number(option.value);
                                  onChange(parsed);

                                  setValue('ids_areas', undefined);
                                }}
                                value={optionsSubCategorias.find(
                                  (option) =>
                                    option.value === value?.toString(),
                                )}
                                error={errors.id_subcategoria?.message}
                                isDisabled={!categoria}
                              />
                            )}
                          />
                        </FormGroup>

                        <FormGroup
                          name="Nome da Escala"
                          cols={[4, 6, 12]}
                          required
                        >
                          <Controller
                            control={control}
                            name="nome"
                            render={({ onChange, value }) => (
                              <Input
                                /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                                onChange={onChange}
                                value={value?.replaceAll(
                                  // eslint-disable-next-line no-control-regex
                                  /[^\x00-\x7F]/g,
                                  '',
                                )}
                                onPaste={(e) => {
                                  const a = e.currentTarget?.value.replaceAll(
                                    // eslint-disable-next-line no-control-regex
                                    /[^\x00-\x7F]/g,
                                    '',
                                  );

                                  onChange(a);
                                }}
                                error={errors.nome?.message}
                                placeholder="Digite apenas letras, sem caracteres especiais"
                              />
                            )}
                          />
                        </FormGroup>
                      </Row>

                      <TituloPagina title="Data e horario" />
                      <Row>
                        <FormGroup name="Inicio" cols={[3, 6, 12]} required>
                          <Flex direction="row" width="100%">
                            <div style={{ width: '90%', marginRight: '3%' }}>
                              <Controller
                                control={control}
                                name="data_inicio"
                                render={({ onChange, value }) => (
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    selected={value}
                                    onChange={(e: Date) => {
                                      const dateSelected = new Date(
                                        e.getFullYear(),
                                        e.getMonth(),
                                        e.getDate(),
                                        e.getHours(),
                                        e.getMinutes(),
                                      );
                                      onChange(dateSelected);

                                      const qtdHorasEscalas = watch(
                                        'quantidade_horas_escala',
                                      );
                                      if (qtdHorasEscalas) {
                                        setValue(
                                          'data_fim',
                                          addHours(
                                            dateSelected,
                                            qtdHorasEscalas,
                                          ),
                                        );
                                      }
                                    }}
                                    showTimeSelect
                                    timeIntervals={60}
                                    error={errors.data_inicio?.message}
                                  />
                                )}
                              />
                            </div>
                            <FcCalendar size={32} />
                          </Flex>
                        </FormGroup>

                        <FormGroup name="Feriado" cols={[1, 3, 6]}>
                          <Controller
                            control={control}
                            name="is_feriado_inicio"
                            render={({ onChange, value }) => (
                              <Switch
                                onChange={(e) => {
                                  onChange(value === '1' ? '0' : '1');
                                }}
                                isChecked={value === '1'}
                              />
                            )}
                          />
                        </FormGroup>

                        <FormGroup
                          name="Quantidade Horas"
                          cols={[2, 6, 12]}
                          required
                        >
                          <Controller
                            name="quantidade_horas_escala"
                            control={control}
                            render={({ onChange, value }) => (
                              <>
                                <Select
                                  name="quantidade_horas_escala"
                                  options={optionsEscalas}
                                  onChange={(e) => {
                                    const formatedValue = Number(
                                      e.currentTarget.value,
                                    );

                                    onChange(formatedValue);
                                    if (dataInicio) {
                                      setValue(
                                        'data_fim',
                                        addHours(dataInicio, formatedValue),
                                      );
                                    }
                                  }}
                                  value={value}
                                  error={errors.quantidade_horas_escala}
                                />
                              </>
                            )}
                          />
                        </FormGroup>

                        <FormGroup name="Fim" cols={[3, 6, 12]} required>
                          <Flex direction="row" width="100%">
                            <div style={{ width: '90%', marginRight: '3%' }}>
                              <Controller
                                control={control}
                                name="data_fim"
                                render={({ onChange, value }) => (
                                  <DatePicker
                                    dateFormat="dd/MM/yyyy HH:mm:ss"
                                    minDate={new Date(watch('data_inicio'))}
                                    selected={value}
                                    onChange={(e: Date) =>
                                      console.log('not changeable')
                                    }
                                    showTimeSelect
                                    disabled
                                    error={errors.data_fim?.message}
                                    timeIntervals={60}
                                  />
                                )}
                              />
                            </div>
                            <FcCalendar size={32} />
                          </Flex>
                        </FormGroup>

                        <FormGroup
                          name={isSameDayEscala ? '' : 'Feriado'}
                          cols={[1, 3, 6]}
                        >
                          <Controller
                            control={control}
                            name="is_feriado_fim"
                            render={({ onChange, value }) => (
                              <Switch
                                onChange={(e) => {
                                  onChange(value === '1' ? '0' : '1');
                                }}
                                isChecked={value === '1'}
                                hidden={isSameDayEscala}
                              />
                            )}
                          />
                        </FormGroup>

                        <FormGroup
                          name="Escala Obrigatória"
                          cols={[2, 6, 12]}
                          required
                        >
                          <Controller
                            name="voluntario_obrigatorio"
                            control={control}
                            render={({ value }) => (
                              <>
                                <Select
                                  name="voluntario_obrigatorio"
                                  defaultValue={
                                    escalaData?.voluntario_obrigatorio
                                  }
                                  options={optionsObrigatoriedadeIrso}
                                  onChange={onOpenModalEscalaObrigatoria}
                                  value={value}
                                  error={errors.voluntario_obrigatorio}
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <FormGroup
                          name="Setores da Operação (Adicione os setores da operação cujas equipes irão trabalhar)"
                          cols={[12, 12, 12]}
                        >
                          <Controller
                            control={control}
                            name="ids_areas"
                            render={({ onChange, value }) => (
                              <>
                                <ReactSelect
                                  isMulti
                                  onChange={(e: OptionType[]) => {
                                    onChange(
                                      e?.map((opt) =>
                                        Number.parseInt(opt.value, 10),
                                      ),
                                    );
                                  }}
                                  value={optionsAreasEscala.filter(
                                    (o) =>
                                      !!value?.find(
                                        (v: any) => v.toString() === o.value,
                                      ),
                                  )}
                                  optionsSelect={optionsAreasEscala}
                                  error={
                                    (errors?.ids_areas as
                                      | FieldError
                                      | undefined)?.message
                                  }
                                  isDisabled={optionsAreasEscala.length < 1}
                                />
                              </>
                            )}
                          />
                        </FormGroup>
                      </Row>

                      <Row>
                        <FormGroup
                          name="Fiscal Local (Preencha com o nome do oficial que vai fiscalizar a irso na sua OPM, estando ou não, ele de irso)"
                          cols={[4, 12, 12]}
                        >
                          <Controller
                            name="pm_codigo_fiscal_local"
                            control={control}
                            render={({ onChange }) => (
                              <AsyncSelect
                                name="pm_codigo"
                                label="Pesquisar Pm"
                                value={refPm.current}
                                loadOptions={(inputValue: any) =>
                                  delayedQuery(inputValue)
                                }
                                onChange={(value: OptionType) => {
                                  refPm.current = value;
                                  onChange(value.value);
                                }}
                                error={errors.pm_codigo_fiscal_local?.message}
                              />
                            )}
                          />
                        </FormGroup>
                      </Row>

                      <PanelBottomActions>
                        <Button
                          leftIcon={<FaSave />}
                          colorScheme="green"
                          type="submit"
                          isLoading={disabledRequest}
                        >
                          Salvar
                        </Button>
                      </PanelBottomActions>
                    </form>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>

            <AccordionItem border="1px solid #ddd">
              <h2>
                <AccordionButton
                  background="#eee"
                  _hover={{ background: '#ddd' }}
                >
                  <Box flex="1" textAlign="left">
                    Equipes
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Row>
                  <FormGroup name="Efetivo total" cols={[1.5, 6, 12]} required>
                    <Flex direction="row" width="100%">
                      <div style={{ width: '92%', marginRight: '1%' }}>
                        <InputNumber
                          // onChange={(e) => onChange(Number(e.target.value))}
                          disabled
                          value={equipesPoliciais.reduce(
                            (total, { policiais }) =>
                              total + (policiais?.length || 0),
                            0,
                          )}
                          error={undefined}
                        />
                      </div>
                      <GiPerson size={38} />
                    </Flex>
                  </FormGroup>

                  <FormGroup name="Valor total" cols={[3, 6, 12]}>
                    <InputCurrency
                      value={escalaData?.valor_escala || 0}
                      disabled
                      onChange={() => undefined}
                    />
                  </FormGroup>

                  <FormGroup name="Veiculos" cols={[3, 6, 12]}>
                    <InputNumber
                      value={
                        equipeVeiculos?.reduce(
                          (total, equipe) => total + equipe.veiculos.length,
                          0,
                        ) || 0
                      }
                      disabled
                      onChange={() => undefined}
                      error={undefined}
                    />
                  </FormGroup>
                </Row>
                <Button
                  leftIcon={<FaPlus />}
                  onClick={() => {
                    refPmEquipe.current = refPm.current;
                    onOpenEquipe();
                  }}
                  colorScheme="blue"
                  type="button"
                  marginBottom="4"
                  marginTop="4"
                >
                  Adicionar Equipe
                </Button>

                {equipes.length > 0 && (
                  <Accordion allowToggle>
                    {equipes.map(
                      ({ id_processo, nome, idEquipe, ids_areas }, index) => (
                        <AccordionItem
                          key={`${nome}${index}`}
                          border="1px solid #ddd"
                          marginTop={index === 0 ? undefined : '4'}
                        >
                          <h2>
                            <AccordionButton
                              background="#eee"
                              _hover={{ background: '#ddd' }}
                            >
                              <Box flex="1" textAlign="left">
                                <Text>
                                  Equipe {index + 1} - {equipes[index].nome}
                                  {!!ids_areas?.length && (
                                    <>
                                      {' '}
                                      - Áreas:{' '}
                                      {areas
                                        .filter((a) =>
                                          ids_areas.includes(
                                            a.id_area_subcategoria,
                                          ),
                                        )
                                        .map((a) => a.nome)
                                        .join(',')}
                                    </>
                                  )}
                                </Text>
                              </Box>

                              <Tooltip label="Editar">
                                <Button
                                  color="#00A0DC"
                                  background="none"
                                  _hover={{ background: 'none' }}
                                  onClick={() =>
                                    handleOpenEditarEquipe(idEquipe as number)
                                  }
                                >
                                  <FaPencilAlt />
                                </Button>
                              </Tooltip>

                              <Tooltip label="Deletar">
                                <Button
                                  color="red"
                                  background="none"
                                  _hover={{ background: 'none' }}
                                  onClick={() =>
                                    handleOpenDeletarEquipe(idEquipe as number)
                                  }
                                >
                                  <FaTrash />
                                </Button>
                              </Tooltip>

                              <AccordionIcon />
                            </AccordionButton>
                          </h2>

                          <AccordionPanel>
                            <Row>
                              <FormGroup
                                name="Processo"
                                cols={[4, 6, 12]}
                                required
                              >
                                {
                                  optionsProcessosIrso.find(
                                    ({ value }) =>
                                      value === id_processo.toString(),
                                  )?.label
                                }
                              </FormGroup>

                              <FormGroup name="Nome" cols={[4, 6, 12]} required>
                                {nome}
                              </FormGroup>
                            </Row>

                            <Divider
                              orientation="horizontal"
                              border="2px solid"
                              marginBottom="4"
                            />

                            <Button
                              leftIcon={<FaPlus />}
                              colorScheme="blue"
                              type="button"
                              onClick={() =>
                                handleOpenAdicionarPolicialEquipe(
                                  idEquipe as number,
                                )
                              }
                            >
                              Adicionar policiais
                            </Button>

                            <DataTable
                              columns={columnsDatatablePoliciais2}
                              options={optionsTableEquipesPoliciais}
                              data={
                                equipesPoliciais.find(
                                  ({ id_equipe }) => id_equipe === idEquipe,
                                )?.policiais || []
                              }
                            />

                            <Divider
                              orientation="horizontal"
                              border="2px solid"
                              marginBottom="4"
                              marginTop="4"
                            />

                            <Button
                              leftIcon={<FaPlus />}
                              colorScheme="linkedin"
                              type="button"
                              onClick={() =>
                                handleOpenAdicionarEndereco(idEquipe as number)
                              }
                            >
                              Adicionar endereços
                            </Button>

                            <DataTable
                              columns={[
                                {
                                  field: 'formatted_address',
                                  text: 'Local',
                                  type: { name: 'text' },
                                },
                                {
                                  field: 'address_type',
                                  text: 'Tipo Endereco',
                                  type: { name: 'text' },
                                },
                                {
                                  field: 'horario_inicio',
                                  text: 'Início',
                                  type: { name: 'date', format: 'HH:mm:ss' },
                                },
                                {
                                  field: 'horario_fim',
                                  text: 'Fim',
                                  type: { name: 'date', format: 'HH:mm:ss' },
                                },
                                {
                                  field: 'observacao',
                                  text: 'Observação',
                                  type: { name: 'text' },
                                },
                              ]}
                              data={listarEnderecosEquipe(idEquipe as number)}
                              options={{ actions: actionsTableInputEnderecos }}
                            />

                            {id_processo === 2 && (
                              <>
                                <Divider
                                  orientation="horizontal"
                                  border="2px solid"
                                  marginBottom="4"
                                  marginTop="4"
                                />
                                <Button
                                  leftIcon={<FaPlus />}
                                  colorScheme="linkedin"
                                  type="button"
                                  onClick={() =>
                                    handleOpenAdicionarVeiculo(
                                      idEquipe as number,
                                    )
                                  }
                                >
                                  Adicionar veículos
                                </Button>
                                <DataTable
                                  data={
                                    equipeVeiculos.find(
                                      ({ id_equipe }) => id_equipe === idEquipe,
                                    )?.veiculos || []
                                  }
                                  columns={columnsVeiculos}
                                  options={optionsVeiculosDataTable}
                                />
                              </>
                            )}
                          </AccordionPanel>
                        </AccordionItem>
                      ),
                    )}
                  </Accordion>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>

          <Button
            leftIcon={<FaTimes />}
            type="button"
            colorScheme="red"
            marginTop="4"
            marginRight="4"
            onClick={() => history.push('listarescalairso')}
          >
            Salvar sem fechar
          </Button>

          <Button
            leftIcon={<FaCheck />}
            type="button"
            colorScheme="green"
            marginTop="4"
            onClick={async () => {
              setIsJustificativa(escalaData?.is_already_fecahda === 0);
              onOpenModalFecharEscala();
            }}
          >
            Salvar e fechar escala
          </Button>
        </Box>
      </BoxContent>

      <Modal
        isOpen={isModalEscalaObrigatoriaOpen}
        onClose={handleCloseModalEscalaObrigatoria}
        size="lg"
        title="Atenção"
      >
        {watch('voluntario_obrigatorio') === '0'
          ? `A obrigatoriedade de cumprimento em uma escala DRSO só poderá acontecer
        nos moldes do §10º, art. 217 do Estatuto da PMCE.`
          : `Ao tornar a escala não obrigatória os policiais serão deletados`}

        <PanelBottomActions>
          <Button
            colorScheme="yellow"
            onClick={handleCloseModalEscalaObrigatoria}
            leftIcon={<FaTimes />}
          >
            Cancelar
          </Button>

          <Button
            colorScheme="green"
            onClick={() => {
              setValue('voluntario_obrigatorio', escalaObrigatoria ? '0' : '1');
              onCloseModalEscalaObrigatoria();
            }}
            leftIcon={<FaArrowRight />}
          >
            Continuar
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        isOpen={isOpenEquipe}
        title={`${equipeEditar ? 'Editar' : 'Adicionar'} Equipe`}
        onClose={handleCloseAdicionarEquipe}
        size="xl"
      >
        <form onSubmit={handleSubmitEquipe(handleEquipe)}>
          <Row>
            <FormGroup name="Processo" cols={[8, 8, 12]} required>
              <Controller
                defaultValue={equipeEditar?.id_processo}
                control={controlEquipe}
                name="id_processo"
                render={({ onChange, value }) => (
                  <ReactSelect
                    optionsSelect={optionsProcessosIrso}
                    onChange={async (option: OptionType) => {
                      onChange(Number(option.value));
                    }}
                    value={optionsProcessosIrso.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errorsEquipe?.id_processo?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup name="Nome" cols={[12, 12, 12]} required>
              <Controller
                control={controlEquipe}
                defaultValue={equipeEditar?.nome}
                name="nome"
                render={({ onChange, value }) => (
                  <Input
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    error={errorsEquipe?.nome?.message}
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                  />
                )}
              />

              <Controller
                control={controlEquipe}
                defaultValue={subCategoriaDefault?.has_areas !== '0'}
                name="isValidateAreas"
                render={({ value }) => (
                  <Checkbox
                    readOnly
                    onChange={() => {
                      console.log('botao desativado');
                    }}
                    value={value}
                    hidden
                  />
                )}
              />
            </FormGroup>
            {subCategoriaDefault?.has_areas !== '0' && (
              <FormGroup
                name="Setores da Operação"
                cols={[12, 12, 12]}
                required
              >
                <Controller
                  control={controlEquipe}
                  name="ids_areas"
                  defaultValue={
                    equipeEditar?.ids_areas &&
                    equipeEditar?.ids_areas?.length > 0
                      ? equipeEditar?.ids_areas
                      : defaultAreasEscala
                  }
                  render={({ onChange, value }) => (
                    <>
                      <ReactSelect
                        isMulti
                        onChange={(e: OptionType[]) => {
                          onChange(
                            e?.map((opt) =>
                              Number.parseInt(opt.value || (opt as any), 10),
                            ),
                          );
                        }}
                        value={value?.map((v: number) =>
                          optionsAreas.find(
                            (o) => v === Number.parseInt(o.value, 10),
                          ),
                        )}
                        optionsSelect={optionsAreas}
                        error={
                          (errorsEquipe?.ids_areas as FieldError | undefined)
                            ?.message
                        }
                      />
                    </>
                  )}
                />
              </FormGroup>
            )}

            <FormGroup name="Fiscal Local" required cols={[12, 12, 12]}>
              <Controller
                name="pm_codigo_fiscal_local"
                control={controlEquipe}
                defaultValue={
                  equipeEditar
                    ? equipeEditar.pm_codigo_fiscal_local
                    : refPm.current?.value
                }
                render={({ onChange, name }) => (
                  <AsyncSelect
                    name={name}
                    value={refPmEquipe.current}
                    label="Pesquisar Pm"
                    loadOptions={(inputValue: any) => delayedQuery(inputValue)}
                    onChange={(value: OptionType) => {
                      refPmEquipe.current = value;
                      onChange(value.value);
                    }}
                    error={errorsEquipe.pm_codigo_fiscal_local?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>

          {equipeEditar && (
            <Alert
              status="warning"
              width="60%"
              alignContent="center"
              alignItems="center"
              textAlign="center"
            >
              <AlertIcon />
              <AlertDescription>
                Ao mudar o processo de uma equipe os militares serão removidos.
              </AlertDescription>
            </Alert>
          )}
          <PanelBottomActions>
            <Button
              type="button"
              leftIcon={<FaTimes />}
              onClick={handleCloseAdicionarEquipe}
              colorScheme="yellow"
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              leftIcon={<FaPlus />}
              isLoading={disabledRequest}
              colorScheme="green"
            >
              Salvar
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        isOpen={isOpenDeletarEquipe}
        title="Editar Equipe"
        onClose={handleCloseDeletarEquipe}
        size="xl"
      >
        Você tem certeza de deletar esta equipe?
        <PanelBottomActions>
          <Button
            type="button"
            leftIcon={<FaTimes />}
            onClick={handleCloseDeletarEquipe}
            colorScheme="yellow"
          >
            Cancelar
          </Button>
          <Button
            type="button"
            leftIcon={<FaTrash />}
            colorScheme="red"
            onClick={handleDeletarEquipe}
            isLoading={disabledRequest}
          >
            Deletar
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        isOpen={isOpenAdicionarVoluntario}
        onClose={() => {
          handleCloseModalVoluntario();
          onCloseAdicionarVoluntario();
        }}
        title="Adicionar Voluntário"
        size="6xl"
      >
        <InputDataTable
          columns={columnsDatatablePoliciais}
          options={options}
          isTableActive
          canRequest={isDataSelected()}
          fieldManipulateArray="items"
          placeholder="Procure por matricula, nome"
          errorMessage={errorsAdicionarVoluntario.pm_codigo?.message}
        />
        <Row>
          <Controller
            control={controlAdicionarVoluntario}
            name="pm_codigo"
            defaultValue={policialSelecionado.current?.pm_codigo}
            render={({ value }) => (
              <>
                <FormGroup cols={[6, 6, 12]} name="Nome">
                  {policialSelecionado.current?.pm.pessoa.pes_nome}
                </FormGroup>

                <FormGroup cols={[3, 3, 12]} name="Matricula">
                  {value}
                </FormGroup>

                <FormGroup cols={[3, 3, 12]} name="Qtd horas">
                  {policialSelecionado.current?.qtd_horas}
                </FormGroup>
              </>
            )}
          />
        </Row>

        <FormGroup name="Função" cols={[3, 3, 12]}>
          <Controller
            control={controlAdicionarVoluntario}
            name="funcao"
            render={({ onChange, value }) => (
              <Select
                name="funcao"
                options={funcoes}
                value={value?.toString()}
                onChange={(e) =>
                  onChange(Number.parseInt(e.currentTarget?.value, 10))
                }
                error={errorsAdicionarVoluntario.funcao}
              />
            )}
          />
        </FormGroup>

        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            type="button"
            colorScheme="yellow"
            onClick={() => {
              handleCloseModalVoluntario();
              onCloseAdicionarVoluntario();
            }}
          >
            Cancelar
          </Button>

          <Button
            leftIcon={<FaPlus />}
            colorScheme="green"
            type="button"
            onClick={async () => {
              await handleSubmitAdicionarVoluntario(
                handleAdicionarVoluntario,
              )();
            }}
            isLoading={disabledRequest}
          >
            Adicionar Policial
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        size="md"
        isOpen={isOpenRemoverVoluntario}
        onClose={handleCloseModalVoluntario}
        title="Remover Voluntário"
      >
        Você tem certeza que deseja remover o policial{' '}
        {policialSelecionado.current?.pm.pessoa.pes_nome}
        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            type="button"
            onClick={handleCloseModalVoluntario}
            colorScheme="yellow"
          >
            Cancelar
          </Button>
          <Button
            leftIcon={<FaTrash />}
            type="button"
            onClick={handleDeletarPolicial}
            isLoading={disabledRequest}
            colorScheme="red"
          >
            Remover Policial
          </Button>
        </PanelBottomActions>
      </Modal>

      <div id="mapa" style={{ visibility: 'hidden' }} />
      <Modal
        size="2xl"
        isOpen={isOpenModalAdicionarEndereco}
        onClose={handleCloseAdicionarEndereco}
        title="Adicionar Endereço de atuação"
      >
        <Row>
          <FormGroup name="Tipo" cols={[4, 6, 12]} required>
            <Controller
              control={controlAdicionarEnderecos}
              name="address_type"
              render={({ onChange }) => (
                <ReactSelect
                  optionsSelect={optionsEnderecos}
                  onChange={(option: OptionType) => {
                    setOptionEndereco(option);
                    onChange(option.label);

                    if (option.value === '4')
                      setValueAdicionarEnderecos('geoJSON', {});
                  }}
                  value={optionEndereco}
                  error={errorsAdicionarEnderecos.address_type?.message}
                />
              )}
            />
          </FormGroup>
        </Row>
        {!!optionEndereco && (
          <>
            <InputDataTable
              columns={colunasEnderecos}
              options={optionsEnderecosDatatable}
              isTableActive={optionEndereco.value !== '4'}
              errorMessage={errorsAdicionarEnderecos.geoJSON?.message}
              handleInput={handleInput}
              placeholder={handleOption()}
              canRequest={optionEndereco.value !== '4'}
              inputLabel="Endereco"
            />
          </>
        )}
        <Controller
          control={controlAdicionarEnderecos}
          name="geoJSON"
          render={({ value }) => (
            <Text visibility={value?.formatted_address ? 'visible' : 'hidden'}>
              {value?.formatted_address}
            </Text>
          )}
        />

        <Row>
          <FormGroup name="Horário Inicio" required cols={[3, 6, 12]}>
            <Controller
              control={controlAdicionarEnderecos}
              defaultValue={defaultValueAddEndereco(
                equipeEditar?.idEquipe as number,
              )}
              name="horario_inicio"
              render={({ value, ...props }) => (
                <DatePicker
                  {...props}
                  onChange={() => {
                    console.log('not changeable');
                  }}
                  selected={value}
                  dateFormat="HH:mm"
                  showTimeSelect
                  timeIntervals={10}
                  disabled
                  error={errorsAdicionarEnderecos.horario_inicio?.message}
                />
              )}
            />
          </FormGroup>

          <FormGroup name="Horário Fim" required cols={[3, 6, 12]}>
            <Controller
              control={controlAdicionarEnderecos}
              name="horario_fim"
              render={({ value, onChange, ...props }) => (
                <DatePicker
                  {...props}
                  onChange={(e: Date) => {
                    e.setMilliseconds(0);
                    onChange(e);
                  }}
                  selected={value}
                  minDate={escalaData?.data_inicio}
                  maxDate={escalaData?.data_fim}
                  dateFormat="HH:mm"
                  timeIntervals={10}
                  showTimeSelect
                  error={errorsAdicionarEnderecos.horario_fim?.message}
                  filterTime={(d) => {
                    return (
                      isAfter(d, escalaData?.data_inicio as Date) &&
                      isBefore(d, addMinutes(escalaData?.data_fim as Date, 1))
                    );
                  }}
                >
                  {' '}
                  <Text color="red.400" textAlign="center">
                    Clique na data e horário
                  </Text>
                </DatePicker>
              )}
            />
          </FormGroup>
        </Row>

        <FormGroup name="Observação" cols={[12, 12, 12]}>
          <Controller
            control={controlAdicionarEnderecos}
            name="observacao"
            render={(props) => (
              <Input
                {...props}
                error={errorsAdicionarEnderecos.observacao?.message}
              />
            )}
          />
        </FormGroup>

        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            type="button"
            onClick={handleCloseAdicionarEndereco}
            colorScheme="yellow"
          >
            Cancelar
          </Button>
          <Button
            isLoading={disabledRequest}
            type="button"
            colorScheme="green"
            leftIcon={<FaPlus />}
            onClick={async () => {
              await handleSubmitAdicionarEnderecos(handleAdicionarEndereco)();
            }}
          >
            Adicionar
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        title={'Alterar Horário'.toUpperCase()}
        size="md"
        onClose={handleCloseModalEditarEnderecoObs}
        isOpen={isModalEditarObservacaoOpen}
      >
        <Box
          as="form"
          onSubmit={handleSubmitEditarEquipeEndereco(
            handleSubmitEditarEndereco,
          )}
        >
          <Row>
            <FormGroup cols={[6, 12, 12]} name="Horário inicio" required>
              <Controller
                control={controlEditarEndereco}
                defaultValue={defaultValueEditarEndereco}
                name="horario_inicio"
                render={({ value, onChange, ...props }) => (
                  <DatePicker
                    {...props}
                    onChange={async (e: Date) => {
                      onChange(e);
                      await triggerEditarEndereco('horario_inicio');
                    }}
                    disabled
                    selected={value}
                    dateFormat="HH:mm"
                    timeIntervals={10}
                    minTime={escalaData?.data_inicio}
                    maxTime={escalaData?.data_fim}
                    showTimeSelect
                    error={errorsEditarEndereco.horario_inicio?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup cols={[6, 12, 12]} name="Horário final" required>
              <Controller
                control={controlEditarEndereco}
                defaultValue={
                  equipeEndereco?.horario_fim
                    ? parseISO(equipeEndereco?.horario_fim)
                    : undefined
                }
                name="horario_fim"
                render={({ value, onChange, ...props }) => (
                  <DatePicker
                    {...props}
                    onChange={async (e: Date) => {
                      e.setMilliseconds(0);
                      onChange(e);
                    }}
                    selected={value}
                    dateFormat="HH:mm"
                    timeIntervals={10}
                    showTimeSelect
                    error={errorsEditarEndereco.horario_fim?.message}
                    minTime={escalaData?.data_inicio}
                    maxTime={escalaData?.data_fim}
                    filterTime={(d) => {
                      return (
                        isAfter(d, escalaData?.data_inicio as Date) &&
                        isBefore(d, addMinutes(escalaData?.data_fim as Date, 1))
                      );
                    }}
                    filterDate={(d) =>
                      isSameDay(d, escalaData?.data_inicio as Date) ||
                      isSameDay(d, escalaData?.data_fim as Date)
                    }
                  >
                    <Text color="red.400" textAlign="center">
                      Clique na data e horário
                    </Text>
                  </DatePicker>
                )}
              />
            </FormGroup>
          </Row>

          <FormGroup cols={[12, 12, 12]} name="Observação">
            <Controller
              control={controlEditarEndereco}
              defaultValue={equipeEndereco?.observacao}
              name="observacao"
              render={(props) => (
                <Input
                  {...props}
                  error={errorsEditarEndereco.observacao?.message}
                />
              )}
            />
          </FormGroup>
          <PanelBottomActions>
            <Button
              type="button"
              leftIcon={<FaTimes />}
              onClick={handleCloseModalEditarEnderecoObs}
              colorScheme="yellow"
            >
              Cancelar
            </Button>

            <Button
              type="submit"
              leftIcon={<FaPlus />}
              isLoading={disabledRequest}
              colorScheme="green"
            >
              Salvar
            </Button>
          </PanelBottomActions>
        </Box>
      </Modal>

      <Modal
        title="Visualizar mapa"
        isOpen={isOpenModalGoogle}
        onClose={onCloseModalGoogle}
        size="6xl"
      >
        <Map
          center={userPosition}
          zoom={optionEndereco?.value !== '2' ? 12 : 16}
          onClick={handleClick}
          geoJSON={geoJSONplaceSelected}
        >
          <Marker
            position={placeDetaisPosition}
            title={placeDetails?.address_components?.[0].short_name}
          >
            <PopUP content={popText() as any} />
          </Marker>
        </Map>
      </Modal>

      <Modal
        size="md"
        isOpen={isOpenModalRemoverEndereco}
        onClose={handleCloseEndereco}
        title="Remover Endereço"
      >
        Você tem certeza que deseja remover{' '}
        {equipeEndereco?.formatted_address ? 'o local' : 'o tipo de endereço'}
        <b>
          {' '}
          {equipeEndereco?.formatted_address ?? equipeEndereco?.address_type}
        </b>
        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            type="button"
            onClick={handleCloseEndereco}
            colorScheme="yellow"
          >
            Cancelar
          </Button>
          <Button
            leftIcon={<FaTrash />}
            type="button"
            onClick={handleDeletarEndereco}
            colorScheme="red"
          >
            Remover Endereco
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        title="Visualizar mapa"
        isOpen={isOpenModalAdicionarVeiculo}
        onClose={onCloseModalAdicionarVeiculo}
        size="xl"
      >
        <InputDataTable
          columns={columnsVeiculos}
          options={optionsVeiculos}
          isTableActive
          fieldManipulateArray="items"
          placeholder="Pesquise por placa, prefixo, modelo, número"
          canRequest
          inputLabel="Veiculo"
        />

        {veiculoSelecionado && (
          <>
            <p>{veiculoSelecionado.dados1}</p>
            <PanelBottomActions>
              <Button
                isLoading={disabledRequest}
                type="button"
                colorScheme="green"
                leftIcon={<FaPlus />}
                onClick={handleAdicionarVeiculo}
              >
                Adicionar
              </Button>
            </PanelBottomActions>
          </>
        )}
      </Modal>

      <Modal
        size="md"
        isOpen={isOpenModalRemoverVeiculo}
        onClose={handleCloseVeiculo}
        title="Remover veículo"
      >
        Você tem certeza que deseja remover o veículo{' '}
        <b>{veiculoSelecionado?.dados1}</b>
        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            type="button"
            onClick={handleCloseVeiculo}
            colorScheme="yellow"
          >
            Cancelar
          </Button>
          <Button
            leftIcon={<FaTrash />}
            type="button"
            onClick={handleDeletarVeiculo}
            colorScheme="red"
          >
            Remover veículo
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        title="Fechar Escala"
        isOpen={isModalFecharEscalaOpen}
        onClose={onCloseModalFecharEscala}
        size={isJustificativa ? '6xl' : 'md'}
      >
        <p style={{ textAlign: 'center' }}>
          {isJustificativa
            ? 'Esta escala está atrasada e ao fechá-la você precisa aguardar a autorização da CGO. Digite abaixo a justificativa do atraso'
            : 'Você realmente deseja fechar esta escala?'}
        </p>

        <form onSubmit={handleSubmitFecharEscala(handleFecharEscala)}>
          {isJustificativa && (
            <Controller
              control={controlFecharEscala}
              name="justificativa"
              render={({ value, onChange }) => (
                <TextArea
                  /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                  onChange={onChange}
                  value={value?.replaceAll(
                    // eslint-disable-next-line no-control-regex
                    /[^\x00-\x7F]/g,
                    '',
                  )}
                  error={errorsFecharEscala.justificativa?.message}
                  disabled={!isJustificativa}
                  placeholder="Insira aqui sua justificativa"
                  onPaste={(e) => {
                    const a = e.currentTarget?.value.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    );

                    onChange(a);
                  }}
                />
              )}
            />
          )}

          <Controller
            control={controlFecharEscala}
            name="isEscalaAtrasada"
            defaultValue={isJustificativa}
            render={({ value }) => (
              <Checkbox isChecked={!!value} visibility="hidden">
                Sim
              </Checkbox>
            )}
          />

          <PanelBottomActions>
            <Button
              leftIcon={<FaTimes />}
              colorScheme="red"
              onClick={onCloseModalFecharEscala}
            >
              Não
            </Button>
            <Button
              leftIcon={<FaLock />}
              colorScheme="green"
              type="submit"
              isLoading={disabledRequest}
            >
              Sim
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        isOpen={isModalErroOpen}
        onClose={handdleCloseModalError}
        size="4xl"
        title="Erro"
      >
        {errorMessage?.message as string}
        <DataTable columns={errorColumns} data={errorMessage?.values || []} />
      </Modal>
    </>
  );
};

export default EditarEscalaIrso;
