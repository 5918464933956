import React from 'react';
import { Box } from '@chakra-ui/react';
import { useAuth } from '../../contexts/auth';
import api from '../../services/api';
import TituloPagina from '../../components/TituloPagina';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';

type OptionType = { label: string; value: string };

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

const anos = Array.from(Array(6).keys())
  .map((i) =>
    i < 5 ? new Date().getFullYear() - i : new Date().getFullYear() + 1,
  )
  .sort()
  .map<OptionType>((ano) => ({ label: ano.toString(), value: ano.toString() }));

const ciclos = Array.from(Array(12).keys()).map<OptionType>((ciclo) => ({
  label: (ciclo + 1).toString().padStart(2, '0'),
  value: (ciclo + 1).toString(),
}));

const defaultFilters: IFilters[] = [
  {
    type: 'select' as 'date' | 'select',
    field: 'ano',
    label: 'Ano',
    options: anos,
    defaultOption: new Date().getFullYear().toString(),

    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'select' as 'date' | 'select',
    field: 'ciclo',
    label: 'Mês',
    options: ciclos,
    defaultOption: (new Date().getMonth() + 1).toString(),

    cols: [2, 6, 12] as [number, number, number],
  },
];

export const RelatorioTotalHorasPmsUnidades: React.FC = () => {
  const { user } = useAuth();

  const columns: IColumns = [
    {
      field: 'gra_sigla',
      text: 'Graduação',
      type: { name: 'text' },
      alias: 'lista.gra_prioridade',
    },
    { field: 'pes_nome', text: 'Nome', type: { name: 'text' } },
    { field: 'pm_codigo', text: 'Matrícula', type: { name: 'text' } },
    {
      field: 'total_horas',
      text: 'Total de Horas',
      type: { name: 'text' },
      alias: 'lista.total_horas',
    },
    { field: 'uni_sigla', text: 'Unidade', type: { name: 'text' } },
  ];

  const options = {
    serverData: {
      url: `/escalas/irsos/relatorios/horas/${user.currentOpm?.uni_codigo}`,
      params: `subunidades=${user.verSubunidade}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
    },

    filters: defaultFilters,

    columnOrder: { visible: true, label: 'Ordem' },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['lista.pes_nome', 'lista.pm_codigo'],
      cols: [4, 6, 12] as [number, number, number],
    },

    exportCsv: {
      visible: true,
      label: 'Escala',
      filename: 'escalas',
      serverPagination: false,
      columns: [
        { field: 'gra_sigla', title: 'Graduação' },
        { field: 'pes_nome', title: 'Nome' },
        { field: 'pm_codigo', title: 'Matrícula' },
        { field: 'total_horas', title: 'Total de Horas' },
      ],
    },
  };

  return (
    <>
      <TituloPagina title="Total de Horas dos Pms " />
      <BoxContent>
        <Box>
          <DataTable columns={columns} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};
