import React, { createContext, useCallback, useState, useContext } from 'react';
import api from 'services/api';

export interface IProcesso {
  id_processo: number;
  nome: string;
  situacao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
  processosFuncoes: { id_funcao: number; prioridade?: number }[];
  qtd_max_policial?: number;
}

interface IProcessoContextData {
  idProcesso: number | undefined;
  loadProcesso(): Promise<IProcesso>;
  updateIdProcesso(id: number): Promise<void>;
}

const ProcessoContext = createContext<IProcessoContextData>(
  {} as IProcessoContextData,
);

const ProcessoProvider: React.FC = ({ children }) => {
  const [idProcesso, setIdProcesso] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-escalas:id_processo');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdProcesso = useCallback(async (id: number) => {
    setIdProcesso(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_processo',
      JSON.stringify(id),
    );
  }, []);

  const loadProcesso = useCallback(async (): Promise<IProcesso> => {
    const { data } = await api.get<IProcesso>(`/processo/${idProcesso}`);

    return data;
  }, [idProcesso]);

  return (
    <ProcessoContext.Provider
      value={{
        idProcesso,
        loadProcesso,
        updateIdProcesso,
      }}
    >
      {children}
    </ProcessoContext.Provider>
  );
};

function useProcesso(): IProcessoContextData {
  const context = useContext(ProcessoContext);

  if (!context) {
    throw new Error(
      'useProcesso must be used within an ModalidadeirsoProvider',
    );
  }

  return context;
}

export { ProcessoProvider, useProcesso };
