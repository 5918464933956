import { Button, useToast, Box, Flex } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useState, useEffect, useCallback } from 'react';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { useAuth } from '../../contexts/auth';
import BoxContent from '../../components/BoxContent';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import AsyncSelect from '../../components/form/AsyncSelect';
import Select from '../../components/form/ReactSelect';

type IUnidade = { uni_codigo: number; uni_sigla: string };

type OptionType = { label: string; value: string };

export const converSionSchema = yup.object().shape({
  selected_date: yup.date().required('Este campo é requerido'),
  idCategoria: yup.number().required('Este campo é requerido'),
  idSubcategoria: yup.number().required('Este campo é requerido'),
  idUnidade: yup.number().notRequired(),
});

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
}

type IForm = {
  selected_date: Date;
  idSubcategoria: number;
  idCategoria: number;
  idUnidade: number;
};

export const OperacoesIrsoCicloAno: React.FC = () => {
  const toast = useToast();
  const { user } = useAuth();
  const { handleSubmit, control, errors, watch, setValue } = useForm<IForm>({
    resolver: yupResolver(converSionSchema),
  });

  const [isLoading, setIsLoading] = useState(false);
  const [optionsCategorias, setOptionsCategorias] = useState<OptionType[]>([]);
  const [optionsSubCategoriaIrso, setOptionsSubCategoriaIrso] = useState<
    OptionType[]
  >([]);

  const [unidadeSelecionada, setUnidadeSelecionada] = useState<OptionType>();

  const loadSubCategorias = useCallback(async (id: number): Promise<void> => {
    const {
      data: { items },
    } = await api.get<{ items: any[] }>('subcategorias', {
      params: { ids_categorias: [id] },
    });

    setOptionsSubCategoriaIrso(
      items
        .filter((item) => item.situacao === '1')
        .map((item) => ({
          label: item.nome,
          value: item.id_subcategoria.toString(),
        })),
    );
  }, []);

  const delayedQuery = debounce(async (query: string) => {
    const { data: unidades } = await api.get<IUnidade[]>('unidades', {
      params: { query, opm_usuario: user.opm?.uni_codigo },
    });

    return unidades?.map<OptionType>((unidade) => ({
      label: unidade.uni_sigla,
      value: unidade.uni_codigo.toString(),
    }));
  }, 500);

  const handleRequestRelatorioCiclo = async ({
    idSubcategoria,
    selected_date,
    idUnidade,
  }: IForm): Promise<void> => {
    setIsLoading(true);
    try {
      const { data: documento } = await api.get(
        `/escalas/irsos/relatorios/operacoes/${idSubcategoria}`,
        {
          params: {
            date: selected_date,
            id_opm: idUnidade === -1 ? undefined : idUnidade,
          },
          responseType: 'blob',
        },
      );

      if (documento) {
        const url = window.URL.createObjectURL(new Blob([documento]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `relatorio-${format(selected_date, 'dd-MM-yyyy')}.pdf`,
        );

        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      }
    } catch (error) {
      if (error.response?.data) {
        const response = JSON.parse(await (error.response.data as Blob).text());
        toast({
          title: 'Ocorreu um erro.',
          description: response.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const loadCategorias = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: ICategoria[] }>('categorias');
      setOptionsCategorias(
        items
          .filter((item) => item.situacao === '1')
          .map(({ nome, id_categoria }) => ({
            label: nome,
            value: id_categoria.toString(),
          })),
      );
    };

    loadCategorias();
  }, []);

  return (
    <>
      <TituloPagina title="Relatório Finaneiro - Portaria" />
      <BoxContent>
        <TituloPagina title="Selecione o ciclo e o ano para obter o relatório" />
        <Box as="form" onSubmit={handleSubmit(handleRequestRelatorioCiclo)}>
          <Flex>
            <FormGroup name="Data" required cols={[2, 2, 12]}>
              <Controller
                control={control}
                name="selected_date"
                render={({ onChange, value, ...rest }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e) => onChange(e as Date)}
                    dateFormat="dd/MM/yyyy"
                    showFullMonthYearPicker
                    error={errors.selected_date?.message}
                    {...rest}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Categoria" required cols={[3, 6, 12]}>
              <Controller
                control={control}
                name="idCategoria"
                render={({ onChange, value }) => (
                  <Select
                    onChange={(e: OptionType) => {
                      onChange(Number.parseInt(e.value, 10));
                      setValue('idSubcategoria', undefined);
                      loadSubCategorias(Number.parseInt(e.value, 10));
                    }}
                    value={optionsCategorias.find(
                      (option) => option.value === value?.toString(),
                    )}
                    options={optionsCategorias}
                    error={errors.idCategoria?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Operação" required cols={[3, 6, 12]}>
              <Controller
                control={control}
                name="idSubcategoria"
                render={({ onChange, value }) => (
                  <Select
                    options={optionsSubCategoriaIrso}
                    onChange={(option: OptionType) =>
                      onChange(Number(option.value))
                    }
                    value={optionsSubCategoriaIrso.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errors.idSubcategoria?.message}
                    isDisabled={!watch('idCategoria')}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Pesquisar Opm" cols={[3, 6, 12]}>
              <Controller
                control={control}
                name="idUnidade"
                render={({ onChange, name }) => (
                  <AsyncSelect
                    name={name}
                    label="Pesquisar Opm"
                    value={unidadeSelecionada}
                    loadOptions={(value2: any) => delayedQuery(value2)}
                    onChange={(option: OptionType) => {
                      onChange(Number.parseInt(option.value, 10));
                      setUnidadeSelecionada(option);
                    }}
                    error={errors.idUnidade?.message}
                  />
                )}
              />
            </FormGroup>
          </Flex>
          <Button
            leftIcon={<HiOutlineDocumentReport />}
            colorScheme="blue"
            type="submit"
            isLoading={isLoading}
            loadingText="Gerando Relatorio..."
            ml="4"
            mt={7}
          >
            Gerar Escalas
          </Button>
        </Box>
      </BoxContent>
    </>
  );
};
