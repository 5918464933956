import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  useToast,
  Button,
  Text,
  Flex,
  HStack,
  SimpleGrid,
  useDisclosure,
  Alert,
  ListItem,
} from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { format } from 'date-fns-tz';
import orderBy from 'lodash/orderBy';
import { parseISO, addDays, endOfDay } from 'date-fns';
import Modal from '../../components/Modal';
import { CheckBox } from '../../components/form/CheckBox';
import PanelBottomActions from '../../components/PanelBottomActions';
import TituloPagina from '../../components/TituloPagina';
import BoxContent from '../../components/BoxContent';
import api from '../../services/api';
import { useAuth } from '../../contexts/auth';
import DataTable, { IColumns } from '../../components/DataTable';

interface ISituacoesVoluntario {
  id_voluntario: number;
  opm_voluntario: number;
  criado_em: string;
  data_voluntario: string;
  situacao: '0' | '1';
  text_termo_declaracao?: string;
  data_expiracao?: string;
}

const textoGenerico = ` Declaro não estar inserido em nenhuma das situações de impedimento para
executar serviços mediante Indenização de Reforço ao Serviço Operacional
- DRSO, contidas no Art. 217, §6º, I a IV da Lei 13.729/06 (Estatuto do
Militares do Estado do Ceará), estando sujeito, em caso falsa
declaração, às responsabilidades nas esferas administrativa, civil e
criminal.`;

const textArtigo = ` Art. 217... §6º. Não participará do reforço ao serviço operacional o
militar quando estiver nas seguintes situações: (Parágrafo acrescido
pela Lei nº 16.009, 05 de maio de 2016)`;

const artigos = [
  ` denunciado em processo-crime, enquanto a sentença final não
transitar em julgado, salvo quando o fato ocorrer no exercício
de missão de natureza ou interesse militar estadual, ainda que
durante o período de folga, e não envolver suposta prática de
improbidade administrativa ou crime hediondo;`,
  `  respondendo a procedimento administrativo disciplinar, mesmo que
este esteja sobrestado, salvo quando o fato ocorrer no exercício
de missão de natureza ou interesse militar estadual;`,
  `  afastado do serviço por motivo saúde, férias ou licença, na
forma deste Estatuto; `,
  `cumprindo sanções disciplinares.`,
];

const Voluntarios: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();

  const {
    isOpen: isOpenModalServoluntario,
    onOpen: onOpenModalServoluntario,
    onClose: onCloseModalServoluntario,
  } = useDisclosure();

  const textTermo = textoGenerico.concat(textArtigo).concat(artigos.join());

  const refCheckBox = useRef<HTMLInputElement | null>(null);

  const [situacoesVoluntario, setSituacoesVoluntario] = useState<
    ISituacoesVoluntario[]
  >([]);

  const isUsuarioVoluntario =
    !!situacoesVoluntario.length && situacoesVoluntario[0].situacao === '1';

  const isTermoSituacaoAceite =
    isUsuarioVoluntario && !!situacoesVoluntario[0]?.text_termo_declaracao;
  const [disabledRequest, setDisabledRequest] = useState(false);

  const alterarSituacaoVoluntario = async (
    situacao: '0' | '1',
  ): Promise<void> => {
    try {
      const dataAtual = new Date();
      setDisabledRequest(true);
      const { data: novaSituacao } = await api.post<ISituacoesVoluntario>(
        'voluntarios',
        {
          pm_codigo: user.id_usuario,
          data_voluntario: format(dataAtual, 'yyyy-MM-dd HH:mm:ss', {
            timeZone: 'America/Fortaleza',
          }),
          situacao,
          text_termo_declaracao: situacao !== '1' ? undefined : textTermo,
        },
      );
      toast({
        title: 'Sucesso.',
        description: 'Situação do voluntário alterada com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      setSituacoesVoluntario((situacoes) => [novaSituacao, ...situacoes]);
      onCloseModalServoluntario();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  useEffect(() => {
    const loadSituacoesUsuario = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: ISituacoesVoluntario[] }>(
        `voluntarios/situacoes/${user.id_usuario}`,
      );

      if (items.length > 0)
        setSituacoesVoluntario(orderBy(items, ['data_voluntario'], ['desc']));
    };

    loadSituacoesUsuario();
  }, [user.id_usuario]);

  const columns: IColumns = [
    {
      field: 'data_voluntario',
      text: 'Data da situação',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
    },
    {
      field: 'situacao',
      text: 'Situação',
      type: {
        name: 'enum',
        enum: { '0': 'Não voluntário', '1': 'Voluntário' },
      },
    },
  ];

  const voluntariedade = isUsuarioVoluntario ? 'Voluntário' : 'Não Voluntário';
  const termoAceite = isTermoSituacaoAceite ? 'Sim' : 'Não';
  const dataExpiração = situacoesVoluntario[0]?.criado_em
    ? format(
        endOfDay(addDays(parseISO(situacoesVoluntario[0].criado_em), 90)),
        'dd/MM/yyyy HH:mm:ss',
      )
    : undefined;

  return (
    <>
      <SimpleGrid>
        <Flex pl={{ sm: '0px', lg: '8px' }} mb="8px">
          <Text
            color="#666"
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            Situação da Voluntariedade atual: &nbsp;
          </Text>
          <Text
            color={isUsuarioVoluntario ? 'green' : 'red'}
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            {voluntariedade}
          </Text>
        </Flex>

        <Flex pl={{ sm: '0px', lg: '8px' }} mb="8px">
          <Text
            color="#666"
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            Termo de declaração aceite: &nbsp;
          </Text>
          <Text
            color={isTermoSituacaoAceite ? 'green' : 'red'}
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            {termoAceite}
          </Text>
        </Flex>

        <Flex pl={{ sm: '0px', lg: '8px' }} mb="8px">
          <Text
            color="#666"
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            Data expiração: &nbsp;
          </Text>
          <Text fontWeight="600" fontSize={{ sm: '1rem', lg: '1.3rem' }}>
            {dataExpiração}
          </Text>
        </Flex>
      </SimpleGrid>

      <BoxContent>
        <TituloPagina title="Alterar Situação de voluntariedade" />
        <HStack>
          <Button
            colorScheme="red"
            icon={FaPlus}
            type="button"
            onClick={() => alterarSituacaoVoluntario('0')}
            isLoading={disabledRequest}
            disabled={!isUsuarioVoluntario}
          >
            Não quero ser voluntário
          </Button>

          <Button
            colorScheme="green"
            icon={FaPlus}
            type="button"
            onClick={onOpenModalServoluntario}
            // isLoading={disabledRequest}
            disabled={isUsuarioVoluntario}
          >
            Quero ser voluntário
          </Button>

          <Button
            colorScheme="blue"
            icon={FaPlus}
            type="button"
            onClick={onOpenModalServoluntario}
            // isLoading={disabledRequest}
            disabled={!isUsuarioVoluntario}
          >
            Renovar voluntariedade
          </Button>
        </HStack>

        <Box>
          <DataTable columns={columns} data={situacoesVoluntario} />
        </Box>
      </BoxContent>

      <Modal
        isOpen={isOpenModalServoluntario}
        onClose={onCloseModalServoluntario}
        title="Confirmar voluntariedade"
        size="4xl"
      >
        {textoGenerico}
        <Alert status="warning" my={4}>
          <Text>
            {textArtigo}
            <ol type="I" style={{ marginLeft: '2rem' }}>
              {artigos.map((artigo, indexArt) => (
                <ListItem key={indexArt.toString()}>{artigo}</ListItem>
              ))}
            </ol>
          </Text>
        </Alert>

        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await alterarSituacaoVoluntario('1');
          }}
        >
          <CheckBox
            name="termo"
            mb={4}
            px={2}
            required
            isRequired
            ref={refCheckBox}
          >
            Confirmo que não estou inserido(a) nas condições impeditivas citadas
            acima
          </CheckBox>
          <PanelBottomActions>
            <Button
              colorScheme="green"
              icon={FaPlus}
              type="submit"
              isLoading={disabledRequest}
            >
              {isTermoSituacaoAceite ? 'Renovar' : 'Quero ser voluntário'}
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>
    </>
  );
};

export default Voluntarios;
