import { useToast } from '@chakra-ui/react';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import api from '../services/api';
import { useAuth } from './auth';

type IFuncao = { id_funcao: number; nome: string; deletado: number };

interface IFuncoesContextData {
  funcoes: IFuncao[];
  loadFuncoes(): Promise<void>;
}

const FuncoesContext = createContext<IFuncoesContextData>(
  {} as IFuncoesContextData,
);

const FuncoesProvider: React.FC = ({ children }) => {
  const [funcoes, setFuncoes] = useState<IFuncao[]>([]);
  const { user } = useAuth();
  const toast = useToast();

  const loadFuncoes = useCallback(async () => {
    try {
      const {
        data: { items },
      } = await api.get<{ items: IFuncao[] }>('funcoes');
      setFuncoes(items);
    } catch (error) {
      toast({
        title: 'Erro',
        description:
          error.response.data?.message || 'Funções não foram carregadas',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [toast]);

  useEffect(() => {
    if (user) loadFuncoes();
  }, [user, loadFuncoes]);

  return (
    <FuncoesContext.Provider value={{ funcoes, loadFuncoes }}>
      {children}
    </FuncoesContext.Provider>
  );
};

function useFuncoes(): IFuncoesContextData {
  const context = useContext(FuncoesContext);

  if (!context) {
    throw new Error('useFuncoes must be used within an FuncoesProvider');
  }

  return context;
}
export { FuncoesProvider, useFuncoes };
