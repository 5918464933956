import React from 'react';
import { Switch } from 'react-router-dom';
import ListEscalasFeriados from 'pages/ListarEscalasFeriados';
import EscalaTravaHoraAbertura from 'pages/EscalaTravaHora';
import { FiscalOperacao } from '../pages/FiscalizacaoOperacao';
import { OperacoesIrsoCicloAno } from '../pages/OperacoesIrso';
import { RelatorioFinanceiroAtualizarIrsoPromocao } from '../pages/RelatorioFinanceiroAtualizarIrsoPromocao';
import { RelatorioDiario } from '../pages/RelatorioIrsoDiario';
import { SubCategoriaIrso } from '../pages/SubCategorias/ListSubCategoriaIrso';
import { ConverterPlanilha } from '../pages/ConverterPlanilha';
import RelatorioFaltasIrsoCicloAno from '../pages/RelatorioIrsoFaltasCicloAno';
import EditarEscalaIrso from '../pages/EscalaIrso/editar';
import ListTipoOperacao from '../pages/TiposOperacoes';
import Home from '../pages/Home';
import Route from './Route';
import ListCategoriaIrso from '../pages/categorias/ListCategoriaIrso';
import ShowCategoriaIrso from '../pages/categorias/ShowCategoriaIrso';
import ListTipoTrava from '../pages/tipoTrava/ListTipoTrava';
import ListTravaOpm from '../pages/travaOpm/ListTravaOpm';
import ShowTipoTrava from '../pages/tipoTrava/showTipoTrava';
import ShowTravaOpm from '../pages/travaOpm/showTravaOpm';
import ShowTravaOpmHora from '../pages/travaOpmHora/showTravaOpmHora';
import ListTravaOpmHora from '../pages/travaOpmHora/ListTravaOpmHora';
import ListProcesso from '../pages/processo/ListProcessoIrso';
import ShowProcesso from '../pages/processo/ShowProcessoIrso';
import ListTravaPm from '../pages/travaPm/ListTravaPm';
import ShowTravaPm from '../pages/travaPm/showTravaPm';
import SignIn from '../pages/SignIn';
import NovoSaldoGeral from '../pages/SaldoGeral/novo';
import Saldos from '../pages/Saldo';
import Voluntarios from '../pages/Voluntarios';
import ListarEscalasIso from '../pages/Escalas';
import PrevisaoSaldo from '../pages/PrevisaoSaldo';
import RelatorioIrsoCicloAno from '../pages/RelatorioIrsoCicloAno';
import Funcoes from '../pages/Funcoes';
import NovaEscalaIrso from '../pages/EscalaIrso/novo';
import RelatorioCGOIrsoCicloAno from '../pages/RelatorioCGOIrsoCicloAno';
import VoluntariosOpms from '../pages/VoluntariosOpms';
import { Documentos } from '../pages/Documentos';
import RelatorioImprimirEscalasDateInterval from '../pages/RelatorioImprimirEscalasDateInterval';
import RelatorioUnidadeGastoOficialPracaCiclo from '../pages/RelatorioUnidadeGastoOficialPracaCiclo';
import { RelatorioTotalHorasPmsUnidades } from '../pages/RelatorioTotalHorasPmsUnidades';

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/home" component={Home} isPrivate />

      <Route
        path="/categoriairso"
        component={ListCategoriaIrso}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />
      <Route
        path="/showcategoriairso"
        component={ShowCategoriaIrso}
        isPrivate
      />

      <Route
        path="/subcategoriairso"
        component={SubCategoriaIrso}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/listartipotrava"
        component={ListTipoTrava}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/listartravaopm"
        component={ListTravaOpm}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/showtipotrava"
        component={ShowTipoTrava}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />
      <Route
        path="/showtravaopm"
        component={ShowTravaOpm}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />
      <Route
        path="/showtravaopmhora"
        component={ShowTravaOpmHora}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />
      <Route
        path="/listartravaopmhora"
        component={ListTravaOpmHora}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/listartravapm"
        component={ListTravaPm}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/showtravapm"
        component={ShowTravaPm}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/listarprocesso"
        component={ListProcesso}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />
      <Route
        path="/showprocesso"
        component={ShowProcesso}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/tiposoperacoes"
        component={ListTipoOperacao}
        isPrivate
        perfis={['CETIC']}
      />

      <Route path="/saldo" component={Saldos} exact isPrivate />

      <Route
        path="/saldogeral/novo"
        component={NovoSaldoGeral}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route path="/voluntariosituacoes" component={Voluntarios} isPrivate />
      <Route path="/voluntariosopm" component={VoluntariosOpms} isPrivate />
      <Route path="/criarescalairso" component={NovaEscalaIrso} isPrivate />
      <Route path="/listarescalairso" component={ListarEscalasIso} isPrivate />

      <Route path="/editarescalairso" component={EditarEscalaIrso} isPrivate />

      <Route
        path="/previsaosaldo"
        component={PrevisaoSaldo}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route
        path="/relatorios/ciclo_ano"
        component={RelatorioIrsoCicloAno}
        isPrivate
        exact
        perfis={['CGO', 'CETIC', 'GRANDE COMANDO']}
      />

      <Route
        path="/relatorios/gastos"
        component={RelatorioUnidadeGastoOficialPracaCiclo}
        isPrivate
        exact
        perfis={['CGO', 'CETIC', 'GRANDE COMANDO']}
      />

      <Route
        path="/relatorios/ciclo_ano/faltas"
        component={RelatorioFaltasIrsoCicloAno}
        isPrivate
        perfis={['CGO', 'CETIC', 'GRANDE COMANDO']}
      />

      <Route
        path="/relatorios/diario"
        component={RelatorioDiario}
        isPrivate
        perfis={['CGO', 'CETIC', 'GRANDE COMANDO']}
      />

      <Route
        path="/horas_unidade"
        component={RelatorioTotalHorasPmsUnidades}
        isPrivate
        perfis={['CGO', 'CETIC', 'ESCALANTE', 'VALIDADOR', 'COMANDANTE']}
      />

      <Route
        path="/relatorios/atualizar_escalas_promocoes"
        component={RelatorioFinanceiroAtualizarIrsoPromocao}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/relatorios/operacoes"
        component={OperacoesIrsoCicloAno}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/relatorios/pagamento_cgo"
        component={RelatorioCGOIrsoCicloAno}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/relatorios/imprimirescalas"
        component={RelatorioImprimirEscalasDateInterval}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/converterplanilha"
        component={ConverterPlanilha}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/funcoes"
        component={Funcoes}
        isPrivate
        perfis={['CGO', 'CETIC']}
      />

      <Route path="/documentos" component={Documentos} isPrivate />

      <Route
        path="/fiscaisoperacao"
        component={FiscalOperacao}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/escalasferiados"
        component={ListEscalasFeriados}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />

      <Route
        path="/escalatravaabertura"
        component={EscalaTravaHoraAbertura}
        isPrivate
        perfis={['CGO', 'CETIC']}
        exact
      />
    </Switch>
  );
};

export default Routes;
