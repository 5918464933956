import { Box, Button, useToast } from '@chakra-ui/react';
import React, { useState } from 'react';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import BoxContent from '../../components/BoxContent';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import Row from '../../components/form/Row';

type IFormInputs = { start_date: Date; end_date: Date };

const schema = yup.object().shape({
  start_date: yup.date().required(),
  end_date: yup
    .date()
    .required()
    .min(
      yup.ref('start_date'),
      'Data final deve ser no minimo igual a data inicial',
    ),
});

const RelatorioImprimirEscalasDateInterval: React.FC = () => {
  const toast = useToast();

  const { handleSubmit, control, errors, reset } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleRequestRelatorioCiclo = async (
    form: IFormInputs,
  ): Promise<void> => {
    setIsLoading(true);
    try {
      const { data } = await api.post<string>('escalas/irsos/pdfs', form, {
        // responseType: 'blob',
      });

      const str = window.atob(data); // creates a ASCII string
      const buffer = new ArrayBuffer(str.length);
      const view = new Uint8Array(buffer);
      for (let i = 0; i < str.length; i++) {
        view[i] = str.charCodeAt(i);
      }

      toast({
        title: 'Sucesso.',
        description: 'Escalas Baixadas sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      if (view) {
        const url = window.URL.createObjectURL(new Blob([view]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Relatório.zip`);

        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode?.removeChild(link);
      }

      reset();
      // window.open(url, '__blank');
    } catch (error) {
      if (error.response?.data) {
        toast({
          title: 'Aviso.',
          description:
            error.response?.data.message || 'Não podemos no momento atualizar',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <TituloPagina title="Relatório Impressão de escalas" />
      <BoxContent>
        <TituloPagina title="Selecione a Data inicial e final  para gerar o relatório" />
        <Box as="form" onSubmit={handleSubmit(handleRequestRelatorioCiclo)}>
          <Row>
            <FormGroup name="Data início" cols={[4, 6, 12]} required>
              <Controller
                control={control}
                name="start_date"
                render={({ value, onChange }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e) => onChange(e as Date)}
                    dateFormat="dd/MM/yyyy"
                    error={errors.start_date?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Data fim" required cols={[4, 6, 12]}>
              <Controller
                control={control}
                name="end_date"
                render={({ value, onChange }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e: Date) =>
                      onChange(
                        new Date(
                          e.getFullYear(),
                          e.getMonth(),
                          e.getDate(),
                          23,
                          59,
                          59,
                        ),
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    error={errors.end_date?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>

          <Button
            leftIcon={<HiOutlineDocumentReport />}
            colorScheme="blue"
            type="submit"
            isLoading={isLoading}
            loadingText="Gerando Relatorio..."
          >
            Gerar Pdfs
          </Button>
        </Box>
      </BoxContent>
    </>
  );
};

export default RelatorioImprimirEscalasDateInterval;
