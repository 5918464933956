import { Box, HStack, IconProps, StackProps, VStack } from '@chakra-ui/react';
import React from 'react';

type IBoxProps = {
  orientation: 'vertical' | 'horizontal';
  icon?: React.ReactElement<IconProps>;
  bgIcon?: StackProps['bg'];
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const BoxPanel: React.FC<IBoxProps> = ({
  children,
  orientation,
  icon,
  bgIcon,
  onClick,
}) => {
  const Component = orientation === 'vertical' ? VStack : HStack;

  return (
    <Component
      bg="white"
      border="1px solid #ccc"
      boxShadow="0px 2px 3px #0000004B"
      minW={230}
      _hover={{
        transform: 'scale(1.1)',
        transition: 'transform ease 0.5s',
      }}
      textAlign="center"
      pl={2}
      pb={2}
      pt={2}
      cursor={onClick ? 'pointer' : 'auto'}
      onClick={onClick}
    >
      {icon && (
        <Box
          bg={bgIcon}
          p={2}
          borderRadius={4}
          boxShadow="0px 2px 3px #0000004B"
          color="whiteAlpha.900"
        >
          {icon}
        </Box>
      )}

      <VStack
        mb={orientation === 'vertical' ? 16 : undefined}
        alignItems="center"
        pl={6}
      >
        {children}
      </VStack>
    </Component>
  );
};
