import { Button, Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FaMinus, FaPlus, FaSave, FaTimes } from 'react-icons/fa';
import * as Yup from 'yup';
import api from '../../services/api';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import FormGroup from '../../components/form/FormGroup';
import FormInput from '../../components/form/FormInput';
import Modal from '../../components/Modal';
import PanelBottomActions from '../../components/PanelBottomActions';
import TituloPagina from '../../components/TituloPagina';

const criarFuncaoSchema = Yup.object()
  .shape({
    nomes: Yup.array()
      .of(
        Yup.string()
          .required('Este campo é requerido')
          .max(50, 'Digite no máximo 50 caracteres'),
        // eslint-disable-next-line no-control-regex
        // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
      )
      .required()
      .min(1),
  })
  .noUnknown()
  .required();

type ICreateFuncao = {
  nomes: string[];
};

type IFuncao = {
  id_funcao: number;
  nome: string;
  deletado: number;
};

const Funcoes: React.FC = () => {
  const toast = useToast();

  const { control, errors, handleSubmit, reset } = useForm<ICreateFuncao>({
    resolver: yupResolver(criarFuncaoSchema),
    defaultValues: { nomes: [''] },
  });

  const { append, remove, fields } = useFieldArray({
    name: 'nomes',
    control,
  });

  const {
    isOpen: isOpenModalCriarFuncoes,
    onOpen: onOpenModalCriarFuncoes,
    onClose: onCloseModalCriarFuncoes,
  } = useDisclosure();

  const {
    isOpen: isOpenModalDeletarFuncoes,
    onOpen: onOpenModalDeletarFuncoes,
    onClose: onCloseModalDeletarFuncoes,
  } = useDisclosure();

  const [funcoes, setFuncoes] = useState<IFuncao[]>([]);
  const [funcoesSelected, setFuncoesSelected] = useState<IFuncao[]>([]);

  const columns: IColumns = [
    // { field: 'processo.nome', text: 'Processo', type: { name: 'text' } },
    { field: 'nome', text: 'Função', type: { name: 'text' } },
  ];

  const options = {
    selectMultiline: {
      visible: true,
      primaryColumn: 'id_funcao',
      stateSelectedRows: [funcoesSelected, setFuncoesSelected],
    },
  };

  const handleCreateFuncoes = async ({
    nomes,
  }: ICreateFuncao): Promise<void> => {
    try {
      const { data: funcoesCriadas } = await api.post<IFuncao[]>(
        `/funcoes`,
        nomes,
      );
      toast({
        title: 'Sucesso.',
        description: 'Funções criadas com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      setFuncoes((funcoesAtuais) => [...funcoesAtuais, ...funcoesCriadas]);
      reset();
      onCloseModalCriarFuncoes();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      console.error(error);
    }
  };

  useEffect(() => {
    const loadFuncoes = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IFuncao[] }>('funcoes');
      setFuncoes(items);
    };

    loadFuncoes();
  }, []);

  return (
    <>
      <TituloPagina title="Irsos Funções" />
      <BoxContent>
        <FormGroup>
          <Button leftIcon={<FaPlus />} onClick={onOpenModalCriarFuncoes}>
            Adicionar Funções
          </Button>
        </FormGroup>
        <DataTable columns={columns} options={options} data={funcoes} />

        {funcoesSelected.length > 0 && (
          <Button
            leftIcon={<FaTimes size={13} />}
            colorScheme="red"
            onClick={onOpenModalDeletarFuncoes}
          >
            Deletar Funcoes
          </Button>
        )}
      </BoxContent>

      <Modal
        isOpen={isOpenModalCriarFuncoes}
        onClose={onCloseModalCriarFuncoes}
        size="xl"
        title="Criar Funções"
      >
        <form onSubmit={handleSubmit(handleCreateFuncoes)}>
          <Button onClick={() => append('' as any)} leftIcon={<FaPlus />}>
            Adicionar Função
          </Button>

          {fields.map((field, index) => (
            <Flex direction="row" key={field.id}>
              <Controller
                key={field.id}
                control={control}
                name={`nomes.${index}`}
                render={({ value, onChange }) => (
                  <FormGroup name={`Função ${index + 1}`} cols={[10, 10, 12]}>
                    <FormInput
                      /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                      onChange={onChange}
                      value={value}
                      onPaste={(e) => {
                        const a = e.currentTarget?.value.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        );

                        onChange(a);
                      }}
                      error={errors.nomes?.[index]?.message}
                    />
                  </FormGroup>
                )}
              />

              <Button onClick={() => remove(index)}>
                <FaMinus />
              </Button>
            </Flex>
          ))}

          <PanelBottomActions>
            <Button leftIcon={<FaSave />} colorScheme="green" type="submit">
              Salvar
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        isOpen={isOpenModalDeletarFuncoes}
        onClose={onCloseModalDeletarFuncoes}
        size="xl"
        title="Criar Funções"
      >
        Você tem certeza de deletar estas funções?
        <PanelBottomActions>
          <Button onClick={onCloseModalDeletarFuncoes} colorScheme="green">
            Não
          </Button>

          <Button
            onClick={async () => {
              try {
                const { data } = await api.delete<IFuncao[]>(`funcoes/`, {
                  data: funcoesSelected.map((funcao) => funcao.id_funcao),
                });
                onCloseModalDeletarFuncoes();
                toast({
                  title: 'Sucesso.',
                  description: `Funções deletadas com sucesso!`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });

                setFuncoes((funcoesAtuais) =>
                  funcoesAtuais.filter(
                    (funcao) =>
                      data.findIndex(
                        (funcaoDeletada) =>
                          funcaoDeletada.id_funcao === funcao.id_funcao,
                      ) < 0,
                  ),
                );
              } catch (error) {
                toast({
                  title: 'Ocorreu um erro.',
                  description: error.response.data.message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            }}
            colorScheme="red"
          >
            Sim Quero Deletar!
          </Button>
        </PanelBottomActions>
      </Modal>
    </>
  );
};

export default Funcoes;
