// eslint-disable-next-line import/no-extraneous-dependencies
import { useToast } from '@chakra-ui/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import * as Yup from 'yup';
import api from '../../../services/api';
import { IColumns } from '../../../components/DataTable';
import geoJsonAIS from '../../../assets/AIS_CEARA_2022.json';

type OptionType = { label: string; value: string };

type IGeoJSONAIS = {
  features: {
    properties: { NM_AIS: string };
    geometry: any;
    // geometry: {
    //   coordinates: [number, number][][]
    // };
  }[];
};

type IEnderecoSchema = google.maps.places.PlaceResult & {
  geoJSON?: Record<string, any>;
  address_type: string;
  observacao?: string;
  horario_inicio?: Date;
  horario_fim?: Date;
};

type IHandleGeoJSON = { aisfiltered: IGeoJSONAIS; lat: number; lng: number };

type IContextEndereco = {
  schema: UseFormMethods<IEnderecoSchema>;
  equipesEnderecos: { id_equipe: number; enderecos: any[] }[];
  userPosition: google.maps.LatLngLiteral | undefined;
  optionsEnderecos: OptionType[];
  colunasEnderecos: IColumns;
  loadEquipeEnderecos(id: number): Promise<void>;
  loadEquipesEnderecos: (idsEquipes: number[]) => Promise<void>;
  listarEnderecosEquipe(id: number): any[];
  addEquipeEndereco: (
    idEquipe: number,
    data: IEnderecoSchema & { id_equipe_endereco: number },
  ) => void;

  updateEquipeEndereco(indexEquipe: number, endereco: any): void;

  removeEquipeEnderecos: (idEquipe: number) => void;
  removeEndereco: (idEndereco: number) => Promise<boolean>;

  handleUpdateUserPosition: (data: google.maps.LatLngLiteral) => void;
  handleUpdateColumnsByOption: (option: string) => void;

  handleLoadGeoJsonAis: (input: string) => IHandleGeoJSON;
  handleFilteredAIS: (input: string) => IGeoJSONAIS;
};

const adicionarEnderecosSchema = Yup.object().shape({
  address_type: Yup.string().required('Este campo é requerido'),
  geoJSON: Yup.mixed().required('Este campo é requerido'),
  observacao: Yup.string()
    .notRequired()
    .max(77, 'O número de caracteres máximo e 77'),
  horario_inicio: Yup.date()
    .required('Este campo é requerido')
    .max(Yup.ref('horario_fim'), 'Inicio deve ser menor que o fim'),
  horario_fim: Yup.date().required('Este campo é requerido'),
});

export const useEquipesEnderecos = (idEscala: number): IContextEndereco => {
  const formAdicionarPolicial = useForm<IEnderecoSchema>({
    resolver: yupResolver(adicionarEnderecosSchema),
  });
  const toast = useToast();

  const [equipesEnderecos, setEquipesEnderecos] = useState<
    { id_equipe: number; enderecos: any[] }[]
  >([]);

  const [userPosition, setUserPosition] = useState<google.maps.LatLngLiteral>();

  const [colunasEnderecos, setColunasEnderecos] = useState<IColumns>([]);

  const optionsEnderecos = useMemo(
    () =>
      [
        { label: 'Cidade/Bairro', value: '1' },
        { label: 'Rua', value: '2' },
        { label: 'AIS', value: '3' },
        { label: 'S49', value: '4' },
      ] as OptionType[],
    [],
  );

  const handleUpdateUserPosition = useCallback(
    (data: google.maps.LatLngLiteral): void => {
      setUserPosition(data);
    },
    [],
  );

  const handleUpdateColumnsByOption = useCallback((option: string) => {
    switch (option) {
      case '1':
        setColunasEnderecos([
          {
            field: 'description',
            text: 'Bairro/Cidade',
            type: { name: 'text' },
          },
        ] as IColumns);

        break;

      case '2':
        setColunasEnderecos([
          { field: 'description', text: 'Rua', type: { name: 'text' } },
        ] as IColumns);

        break;

      default:
        setColunasEnderecos([
          { field: 'description', text: 'AIS', type: { name: 'text' } },
        ] as IColumns);
        break;
    }
  }, []);

  const listarEnderecosEquipe = useCallback(
    (id: number) => {
      return equipesEnderecos.find((e) => e.id_equipe === id)?.enderecos ?? [];
    },
    [equipesEnderecos],
  );

  const addEquipeEndereco = useCallback(
    (
      idEquipe: number,
      {
        address_type,
        id_equipe_endereco,
        ...rest
      }: IEnderecoSchema & { id_equipe_endereco: number },
    ) => {
      const indexEquipeEndereco = equipesEnderecos.findIndex(
        ({ id_equipe }) => idEquipe === id_equipe,
      );

      if (indexEquipeEndereco < 0)
        setEquipesEnderecos([
          ...equipesEnderecos,
          {
            id_equipe: idEquipe as number,
            enderecos: [{ ...rest, address_type, id_equipe_endereco }],
          },
        ]);
      else {
        equipesEnderecos[indexEquipeEndereco].enderecos = [
          ...equipesEnderecos[indexEquipeEndereco].enderecos,
          { ...rest, address_type, id_equipe_endereco },
        ];

        const updatedEquipesEnderecos = [...equipesEnderecos];

        setEquipesEnderecos(updatedEquipesEnderecos);
      }

      toast({
        title: 'Sucesso.',
        description: `Endereço adicionado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    [equipesEnderecos, toast],
  );

  const requestEnderecosEquipe = useCallback(
    async (id: number) => {
      const {
        data: { items: enderecos },
      } = await api.get<{
        items: {
          id_equipe_endereco: number;
          referencia_api: Record<string, any>;
          horario_inicio?: string;
          horario_fim?: string;
        }[];
      }>(`escalas/irsos/${idEscala}/equipes/${id}/equipe_enderecos`);

      return {
        id_equipe: id,
        enderecos: enderecos.map(
          ({ id_equipe_endereco, referencia_api, ...rest }) => {
            return {
              id_equipe_endereco,
              ...referencia_api,
              horario_inicio: rest?.horario_inicio,
              horario_fim: rest?.horario_fim,
            };
          },
        ),
      };
    },
    [idEscala],
  );

  const loadEquipeEnderecos = useCallback(
    async (id: number) => {
      const response = await requestEnderecosEquipe(id);

      setEquipesEnderecos((e) => {
        const indexEquipeEndereco = e.findIndex(
          ({ id_equipe }) => id === id_equipe,
        );
        if (indexEquipeEndereco < 0) e.push(response);
        else e[indexEquipeEndereco] = response;

        return [...e];
      });
    },
    [requestEnderecosEquipe],
  );

  const loadEquipesEnderecos = useCallback(
    async (idsEquipes: number[]) => {
      let equipesEnderecosResponse = await Promise.all(
        idsEquipes.map(async (idEquipe) => requestEnderecosEquipe(idEquipe)),
      );

      setEquipesEnderecos(equipesEnderecosResponse);

      equipesEnderecosResponse = [];
    },
    [requestEnderecosEquipe],
  );

  const updateEquipeEndereco = useCallback(
    (indexEquipe: number, endereco: any) => {
      if (indexEquipe < 0)
        toast({
          title: 'Erro.',
          description: `Equipe não encontrada`,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      else {
        const newEnderecos = equipesEnderecos[indexEquipe].enderecos;

        const indexEndereco = newEnderecos.findIndex(
          ({ id_equipe_endereco }) =>
            id_equipe_endereco === endereco?.id_equipe_endereco,
        );

        if (indexEndereco < 0)
          toast({
            title: 'Erro.',
            description: `Endereço não encontrado`,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        else {
          newEnderecos[indexEndereco] = endereco;

          equipesEnderecos[indexEquipe] = {
            ...equipesEnderecos[indexEquipe],
            enderecos: newEnderecos,
          };

          setEquipesEnderecos(equipesEnderecos);
        }
      }
    },
    [equipesEnderecos, toast],
  );

  const removeEquipeEnderecos = useCallback(
    (idEquipe: number) => {
      setEquipesEnderecos(
        equipesEnderecos.filter(({ id_equipe }) => id_equipe !== idEquipe),
      );
    },
    [equipesEnderecos],
  );

  const removeEndereco = async (idEndereco: number): Promise<boolean> => {
    const indexEquipe = equipesEnderecos.findIndex(
      ({ enderecos }) =>
        !!enderecos.find(
          ({ id_equipe_endereco }) => id_equipe_endereco === idEndereco,
        ),
    );

    if (indexEquipe < 0) {
      toast({
        title: 'Erro.',
        description: 'Equipe não encontrada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });

      return false;
    }

    await api.delete(
      `escalas/irsos/${idEscala}/equipes/${equipesEnderecos[indexEquipe].id_equipe}/equipe_enderecos/${idEndereco}`,
    );

    equipesEnderecos[indexEquipe].enderecos = equipesEnderecos[
      indexEquipe
    ].enderecos.filter(
      ({ id_equipe_endereco }) => id_equipe_endereco !== idEndereco,
    );
    const updatedequipesEnderecos = [...equipesEnderecos];

    setEquipesEnderecos(updatedequipesEnderecos);

    toast({
      title: 'Sucesso.',
      description: `Endereço deletado com sucesso!`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

    return true;
  };

  const handleFilteredAIS = useCallback((input: string) => {
    const filteredJSONAIS = (geoJsonAIS as IGeoJSONAIS).features.filter((ais) =>
      ais.properties.NM_AIS.toLowerCase().includes(input.toLowerCase()),
    );

    return {
      ...(geoJsonAIS as IGeoJSONAIS),
      features: filteredJSONAIS,
    };
  }, []);

  const generateRandomFloat = useCallback(
    (min: number, max: number, decimals: number) => {
      const str = (Math.random() * (max - min) + min).toFixed(decimals);

      return parseFloat(str);
    },
    [],
  );

  const isPointInsidePolygon = useCallback(
    (marker: [number, number], coordinates: [number, number][]) => {
      const x = marker[0];
      const y = marker[1];

      let inside = false;

      for (
        let i = 0, j = coordinates.length - 1;
        i < coordinates.length;
        j = i++
      ) {
        const xi = coordinates[i][1];
        const yi = coordinates[i][0];
        const xj = coordinates[j][1];
        const yj = coordinates[j][0];

        const intersect =
          yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) inside = !inside;
      }

      return inside;
    },
    [],
  );

  const handleLoadGeoJsonAis = (input: string): IHandleGeoJSON => {
    const aisfiltered = handleFilteredAIS(input);

    const Xs = aisfiltered.features[0].geometry.coordinates[0][0].map(
      ([lat, _]: [number, number]) => {
        return Number(lat);
      },
    );

    const Ys = aisfiltered.features[0].geometry.coordinates[0][0].map(
      ([_, long]: [number, number]) => Number(long),
    );

    const sortedLats = Ys.sort();
    const sortedLong = Xs.sort();

    let randomLat = generateRandomFloat(
      sortedLats[0],
      sortedLats[sortedLats.length - 1],
      15,
    );

    let randomLong = generateRandomFloat(
      sortedLong[0],
      sortedLong[sortedLong.length - 1],
      15,
    );

    while (
      !isPointInsidePolygon(
        [randomLat, randomLong],
        aisfiltered.features[0].geometry.coordinates[0][0],
      )
    ) {
      randomLat = generateRandomFloat(
        sortedLats[0],
        sortedLats[sortedLats.length - 1],
        15,
      );

      randomLong = generateRandomFloat(
        sortedLong[0],
        sortedLong[sortedLong.length - 1],
        15,
      );
    }

    handleUpdateUserPosition({
      lat: randomLat,
      lng: randomLong,
    });

    return {
      aisfiltered,
      lat: randomLat,
      lng: randomLong,
    };
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        handleUpdateUserPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      undefined,
      { enableHighAccuracy: true },
    );
  }, [handleUpdateUserPosition]);

  return {
    schema: formAdicionarPolicial,
    loadEquipeEnderecos,
    listarEnderecosEquipe,
    userPosition,
    equipesEnderecos,
    optionsEnderecos,
    colunasEnderecos,
    addEquipeEndereco,
    updateEquipeEndereco,
    loadEquipesEnderecos,
    handleUpdateUserPosition,
    handleLoadGeoJsonAis,
    handleFilteredAIS,
    handleUpdateColumnsByOption,
    removeEquipeEnderecos,
    removeEndereco,
  };
};
