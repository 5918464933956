import { Box, Flex, useDisclosure, useToast, Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FaEdit, FaPencilAlt, FaSave } from 'react-icons/fa';
import Modal from '../../components/Modal';
import FormInput from '../../components/form/FormInput';
import api from '../../services/api';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import TituloPagina from '../../components/TituloPagina';
import FormGroup from '../../components/form/FormGroup';

interface ITiposOperacao {
  id_tipo: number;
  nome_tipo: string;
  criado_por: string;
  atualizado_por: string;
}

interface IFormTipoOperacao {
  nome: string;
}

const schema = Yup.object().shape({
  nome: Yup.string()
    .required('Este campo é requerido')
    .max(60, 'Digite no máximo 60 caracteres'),
});

const ListTipoOperacao: React.FC = () => {
  const [tiposOperacoes, setTiposOperacoes] = useState<ITiposOperacao[]>([]);
  const [tipoOperacao, setTipoOperacao] = useState<ITiposOperacao>(
    {} as ITiposOperacao,
  );
  const toast = useToast();
  const { control, errors, handleSubmit, reset } = useForm<IFormTipoOperacao>({
    defaultValues: {
      nome: '',
    },
    resolver: yupResolver(schema),
  });

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDisabledRequest, setIsDisabledRequest] = useState(false);

  useEffect(() => {
    const loadTiposOperacoes = async (): Promise<void> => {
      const { data } = await api.get('/tipos_operacoes');
      setTiposOperacoes(data.items);
    };
    loadTiposOperacoes();
  }, []);

  const handleClickEdit = (row: ITiposOperacao): void => {
    setTipoOperacao(row);
    onOpen();
  };

  const columns: IColumns = [
    {
      field: 'nome_tipo',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },
    {
      field: 'criado_por',
      text: 'Criado Por',
      type: {
        name: 'text',
      },
    },
  ];

  const options = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: handleClickEdit,
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nomeCategoria'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  const onSubmit = async ({ nome }: IFormTipoOperacao): Promise<void> => {
    setIsDisabledRequest(true);
    try {
      const { data: novoTipoOperacao } = await api.post<ITiposOperacao[]>(
        '/tipos_operacoes',
        { nome },
      );
      toast({
        title: 'Sucesso!',
        description: 'Tipo de operação cadastrado com sucesso!',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setTiposOperacoes([...tiposOperacoes, ...novoTipoOperacao]);
      reset();
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const updateTipoOperacao = async ({
    nome,
  }: IFormTipoOperacao): Promise<void> => {
    const indexTipoOperacao = tiposOperacoes.findIndex(
      (tipoOperacao2) => tipoOperacao2.id_tipo === tipoOperacao.id_tipo,
    );
    if (indexTipoOperacao < 0) {
      toast({
        title: 'Erro!',
        description: 'Tipo de operação não encontrado!',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } else {
      setIsDisabledRequest(true);
      try {
        const { data: updatedTipoOperacao } = await api.put<ITiposOperacao>(
          `/tipos_operacoes/${tipoOperacao.id_tipo}`,
          { nome },
        );

        toast({
          title: 'Sucesso!',
          description: 'Tipo de operação atualizado com sucesso!',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        tiposOperacoes[indexTipoOperacao] = {
          ...updatedTipoOperacao,
        };

        const updatedArray = [...tiposOperacoes];

        setTiposOperacoes(updatedArray);
        onClose();
      } catch (error) {
        toast({
          title: 'Erro!',
          description: error.response.data.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setIsDisabledRequest(false);
      }
    }
  };

  return (
    <>
      <TituloPagina title="Tipos Operacoes" />
      <TituloPagina title="Cadastrar tipo de operação" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Flex flexDirection="column">
            <Controller
              name="nome"
              control={control}
              render={({ onChange, value }) => (
                <FormInput
                  style={{ minWidth: 350, marginBottom: '10px' }}
                  placeholder="Digite o nome da Categoria"
                  onChange={(e) => onChange(e.target.value.toUpperCase())}
                  value={value}
                  error={errors.nome?.message}
                />
              )}
              error={errors.nome?.message}
            />
          </Flex>
          <Button
            colorScheme="green"
            leftIcon={<FaSave />}
            type="submit"
            isLoading={isDisabledRequest}
          >
            Incluir
          </Button>
        </FormGroup>
      </form>
      <BoxContent>
        <Box>
          <DataTable
            columns={columns}
            options={options}
            data={tiposOperacoes}
          />
        </Box>
      </BoxContent>

      <Modal isOpen={isOpen} onClose={onClose} size="md" title="Editar">
        <form onSubmit={handleSubmit(updateTipoOperacao)}>
          <FormGroup name="Nome">
            <Flex flexDirection="column">
              <Controller
                name="nome"
                defaultValue={tipoOperacao.nome_tipo}
                control={control}
                render={({ onChange, value }) => (
                  <FormInput
                    style={{ minWidth: 350, marginBottom: '10px' }}
                    placeholder="Digite o nome da Categoria"
                    onChange={(e) => onChange(e.target.value.toUpperCase())}
                    value={value}
                    error={errors.nome?.message}
                  />
                )}
                error={errors.nome?.message}
              />
            </Flex>
            <Button
              colorScheme="green"
              leftIcon={<FaPencilAlt />}
              type="submit"
              isLoading={isDisabledRequest}
            >
              Editar
            </Button>
          </FormGroup>
        </form>
      </Modal>
    </>
  );
};

export default ListTipoOperacao;
