import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

interface IProps
  extends google.maps.InfoWindowOptions,
    google.maps.InfoWindowOpenOptions {
  onClose?: () => void;
  open?: boolean;
}

const PopUP: React.FC<IProps> = ({
  open = false,
  anchor,
  content,
  map,
  onClose,
  ...options
}) => {
  const [popUp, setPopUp] = useState<google.maps.InfoWindow>();

  useEffect(() => {
    if (!popUp) {
      setPopUp(new google.maps.InfoWindow());
    }
  }, [popUp]);

  useEffect(() => {
    if (popUp) {
      popUp.setOptions({
        disableAutoPan: true,
        ...options,
      });

      ['closeclick'].forEach((eventName) =>
        window.google.maps.event.clearListeners(popUp, eventName),
      );
      if (onClose) popUp.addListener('closeclick', () => onClose());
    }
  }, [popUp, options, onClose]);

  useEffect(() => {
    if (open && popUp) {
      const id = `infowindow_${Math.random() * 1000000}`;
      popUp.setContent(`<div id=${id}></div>`);

      // Ugly hack to rander react element to infowindow
      setTimeout(() => {
        ReactDOM.render(content as any, document.getElementById(id));
      });

      popUp.open({ ...options, anchor, map });
    }
  }, [open, popUp, options, anchor, map, content]);

  return null;
};

export default PopUP;
