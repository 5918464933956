import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tooltip,
  useDisclosure,
  useToast,
  Text,
  Alert,
  AlertIcon,
  AlertDescription,
  Spacer,
} from '@chakra-ui/react';
import { compareAsc, format, parse } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FaArrowLeft,
  FaCheck,
  FaLock,
  FaPencilAlt,
  FaPlus,
  FaRegFilePdf,
  FaSearch,
  FaTimes,
  FaTrash,
  FaUnlockAlt,
} from 'react-icons/fa';
import {
  BsFileEarmarkCheck,
  BsFillPersonCheckFill,
  BsPrinter,
} from 'react-icons/bs';
import { GiPoliceOfficerHead } from 'react-icons/gi';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { MdBlock, MdPerson, MdPersonOff } from 'react-icons/md';
import TextArea from '../../components/form/FormTextArea';
import PanelBottomActions from '../../components/PanelBottomActions';
import { useAuth } from '../../contexts/auth';
import { useEscala } from '../../contexts/escala';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import FormGroup from '../../components/form/FormGroup';
import Row from '../../components/form/Row';
import Modal from '../../components/Modal';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import { InputFile } from '../../components/form/InputFile';
import './styles.css';
import Map from '../../components/Maps/Google';
import Marker from '../../components/Maps/Google/Marker';
import PopUp from '../../components/Maps/Google/PopUp';
import ModalAssinatura from '../../components/ModalAssinatura';
import AsyncSelect from '../../components/form/AsyncSelect';

type IGrandesComando = { uni_codigo: number; uni_sigla: string };

type ErroMessageValue = {
  data_fim: string;
  data_inicio: string;
  voluntario: string;
  pm_codigo: string;
  tipo_trava?: string;
  nome: string;
  unidade?: string;
};

type ErrorMessage = { message: string; values: ErroMessageValue[] };

type IPessoa = {
  pes_nome: string;
  pessoaPM: { graduacao?: { gra_sigla: string } };
};

type IPM = { pm_numero: number; pessoa: IPessoa };

type IVoluntario = { pm_codigo: string; pm: IPM };

type IEquipeVoluntario = {
  id_equipe_policial: number;
  graduacao: { gra_codigo: number };
  opm_policial: number;
  status_policial: number;
  voluntario: IVoluntario;
  deletado: number;
};

type IResponseEquipeFiscal = {
  id_equipe: number;
  nome: string;
  pm_codigo_fiscal_local: string;
  fiscalEquipe: { dados: string };
};

type IEquipeVeiculo = {
  id_veiculo: number;
  placa: string;
  veiculoModelo: { nome: string };
  identificador: { identificador: string };
  deletado: '0' | 1;
};

type IEquipeEndereco = {
  referencia_api: Record<string, any>;
  id_equipe: number;
  address_type: string;
  deletado: 0 | 1;
};

type IEquipe = {
  id_equipe: number;
  id_processo: number;
  nome: string;
  equipesVoluntarios: IEquipeVoluntario[];
  equipeEnderecos: IEquipeEndereco[];
  veiculos?: IEquipeVeiculo[];
  deletado: '0' | 1;
  pessoaAtualizadoPor?: IPessoa;
  atualizado_em?: string;
};

type OptionType = { label: string; value: string };

type IEscala = {
  id_escala: number;
  nome: string;
  sequencia: string;
  uni_codigo: number;
  uni_sigla: string;
  uni_nome: string;
  data_inicio: string;
  data_fim: string;
  situacao_escala: number;
  total_horas: number;
  valor_escala: string;
  total_equipes: number;
  total_policial: number;
  criado_em: number;
  pes_nome_criado_por: string;
  gra_sigla_criado_por: string;
  atualizado_em?: string;
  pes_nome_atualizado_por?: string;
  gra_sigla_atualizado_por?: string;
  anexos: any;
  justificativa?: string;
  is_faltas_resolved: boolean;
  is_already_fecahda: 0 | 1;
  equipes: IEquipe[];
};

const timesMegaByte = 6;

const fecharEscalaSchema = Yup.object()
  .shape({
    isEscalaAtrasada: Yup.boolean().required(),
    justificativa: Yup.string()
      .notRequired()
      .when('isEscalaAtrasada', {
        is: true,
        then: Yup.string().required('Este cammpo é requerido'),
        // eslint-disable-next-line no-control-regex
        // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
      }),
  })
  .required();

const schemaFiscais = Yup.object()
  .shape({
    fiscais: Yup.array().of(
      Yup.object({
        pm_codigo_fiscal_local: Yup.string().notRequired().nullable(),
        id: Yup.number().required('Este campo é requerido'),
      }),
    ),
  })
  .required();

const maxFileUploadSize = timesMegaByte * 1024 * 1024;
const concluieEscalaSchema = Yup.object()
  .shape({
    file: Yup.mixed<File>()
      .required('Este campo é requerido')
      .test(
        'isCorrectFileSizer',
        `Tamanho superior a ${timesMegaByte}MB`,
        (value: File): boolean => {
          return value && value.size <= maxFileUploadSize;
        },
      )
      .test(
        'isCorrectMimeType',
        'Formato de arquivo inválido, apenas PDF',
        (value: File): boolean => {
          return value && !!value.type.match(/^(application\/pdf)$/);
        },
      ),
  })
  .required();

type IFecharEscalaSchema = { justificativa: string; isEscalaAtrasada: boolean };

type IFiscaisSchema = {
  fiscais: { id: number; pm_codigo_fiscal_local?: string }[];
};

type IConcluirEscalaSchema = { file: File };

type IRedirectFilters = {
  id_status_escala?: number;
  data_inicio?: Date;
  data_fim?: Date;
};

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

// eslint-disable-next-line no-shadow
enum StatusEscalas {
  Aberta = 1,
  Atrasada = 2,
  Fechada = 3,
  Confirmada = 4,
  Homologada = 5,
  'Não Autorizada' = 6,
}

const profilesHomologarEscalas = ['CGO', 'CETIC'];

const dataAtual = new Date();

dataAtual.setDate(1);
dataAtual.setHours(0);
dataAtual.setMinutes(0);
dataAtual.setSeconds(0);
dataAtual.setMilliseconds(0);

const dataFim = new Date(
  dataAtual.getFullYear(),
  dataAtual.getMonth() + 1,
  0,
  23,
  59,
  59,
);

const ListarEscalasIso: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user } = useAuth();
  const { updateIdEscala, idEscala } = useEscala();
  const history = useHistory();
  const location = useLocation<IRedirectFilters>();
  const toast = useToast();

  const defaultFilters: IFilters[] = [
    {
      type: 'select' as 'date' | 'select',

      field: 'id_status_escala',
      label: 'Situação',
      options: [
        { value: 'TODOS', label: 'TODOS' },
        { value: '1', label: 'Aberta' },
        { value: '2', label: 'Atrasada' },
        { value: '3', label: 'Fechada' },
        { value: '4', label: 'Confirmada' },
        { value: '5', label: 'Homologada' },
        { value: '6', label: 'Não Autorizada' },
      ],
      defaultOption: location.state?.id_status_escala?.toString() || '',
      cols: [2, 6, 12] as [number, number, number],
    },
    {
      type: 'date' as 'date' | 'select',
      field: 'data_inicio',
      label: 'Data Início',
      options: [],
      cols: [2, 6, 12] as [number, number, number],
      defaultOption: (location.state?.data_inicio ?? dataAtual) as any,
    },

    {
      type: 'date' as 'date' | 'select',
      field: 'data_fim',
      label: 'Data Final',
      options: [],
      cols: [2, 6, 12] as [number, number, number],
      defaultOption: (location.state?.data_fim ?? dataFim) as any,
    },
  ];

  const {
    errors,
    trigger,
    handleSubmit,
    control,
    setValue,
  } = useForm<IFecharEscalaSchema>({
    resolver: yupResolver(fecharEscalaSchema),
  });

  const {
    errors: errorsFormConcluido,
    handleSubmit: handleSubmitFormConcluido,
    watch: watchFormConcluido,
    control: controlFormConcluido,
  } = useForm<IConcluirEscalaSchema>({
    resolver: yupResolver(concluieEscalaSchema),
    defaultValues: {
      file: undefined,
    },
  });

  const {
    handleSubmit: handleSubmitFiscais,
    control: controlFiscais,
    errors: errorsFiscais,
    reset: resetFormFiscais,
  } = useForm<IFiscaisSchema>({
    resolver: yupResolver(schemaFiscais),
  });

  const {
    isOpen: isModalDeletarOpen,
    onOpen: onOpenModalDeletar,
    onClose: onCloseModalDeletar,
  } = useDisclosure();

  const {
    isOpen: isModalAbrirEscalaOpen,
    onOpen: onOpenModalAbrirEscala,
    onClose: onCloseModalAbrirEscala,
  } = useDisclosure();

  const {
    isOpen: isModalFecharEscalaOpen,
    onOpen: onOpenModalFecharEscala,
    onClose: onCloseModalFecharEscala,
  } = useDisclosure();

  const {
    isOpen: isModalErroOpen,
    onClose: onCloseModalErro,
    onOpen: onOpenModalErro,
  } = useDisclosure();

  const {
    isOpen: isModalConcluirEscalaOpen,
    onOpen: onOpenModalConcluirEscala,
    onClose: onCloseModalConcluirEscala,
  } = useDisclosure();

  const {
    isOpen: isModalAssinaturaConcluirEscalaOpen,
    onOpen: onOpenModalAssinaturaConcluirEscala,
    onClose: onCloseModalAssinaturaConcluirEscala,
  } = useDisclosure();

  const {
    isOpen: isModalRegistrarFaltasOpen,
    onClose: onCloseModalRegistrarFaltas,
    onOpen: onOpenModalRegistrarFaltas,
  } = useDisclosure();

  const {
    isOpen: isModalHomologarManyEscalaOpen,
    onOpen: onOpenModalHomologarManyEscalaEscala,
    onClose: onCloseModalHomologarManyEscalaEscala,
  } = useDisclosure();

  const {
    isOpen: isModalConfirmarManyEscalaAtrasadaOpen,
    onOpen: onOpenModalConfirmarManyEscalaAtrasada,
    onClose: onCloseModalConfirmarManyEscalaAtrasada,
  } = useDisclosure();

  const {
    isOpen: isOpenVisualizarMapa,
    onOpen: onOpenVisualizarMapa,
    onClose: onCloseVisualizarMapa,
  } = useDisclosure();

  const {
    onClose: onCloseModalFiscaisEscala,
    ...modalFiscaisEscala
  } = useDisclosure();

  const [escala, setEscala] = useState<IEscala>();
  const [equipesEnderecos, setEquipesEnderecos] = useState<IEquipeEndereco[]>(
    [],
  );
  const [escalasSelectedDatatable, setEscalasSelectedDatatable] = useState<
    IEscala[]
  >([]);
  const [disabledRequest, setDisabledRequest] = useState(false);

  const [
    disabledRequestDesfazerFalta,
    setDisabledRequestDesfazerFalta,
  ] = useState(false);
  const [
    disabledRequestTodosPresentes,
    setDisabledRequestTodosPresentes,
  ] = useState(false);

  const fiscais = useRef<(IResponseEquipeFiscal & { areas: string[] })[]>([]);
  const [grandesComandos, setGrandesComandos] = useState<OptionType[]>([]);
  const [filters, setFilters] = useState(defaultFilters);

  const [voluntarios, setVoluntarios] = useState<
    (IEquipeVoluntario & { nome: string })[][]
  >([]);
  const [
    voluntariosFaltasPresencasSelected,
    setVoluntariosFaltasPresencasSelected,
  ] = useState<IEquipeVoluntario[]>([]);

  const [
    columnsEquipesVoluntarios,
    setColumnsEquipesVoluntarios,
  ] = useState<IColumns>([
    { field: 'graduacao.gra_sigla', text: 'Graduação', type: { name: 'text' } },

    { field: 'pm_codigo', text: 'Matricula', type: { name: 'text' } },
    { field: 'pm.pm_numero', text: 'Numeral', type: { name: 'text' } },
    { field: 'pm.pessoa.pes_nome', text: 'Matricula', type: { name: 'text' } },
    { field: 'funcao.nome', text: 'Função', type: { name: 'text' } },
    {
      field: 'status_policial',
      text: 'Status',
      type: {
        name: 'enum',
        enum: { 0: 'Escalado', 1: 'Presente', 2: 'Faltou' },
      },
    },
  ]);

  const optionsEquipesVoluntarios = {
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['voluntario.pm.pessoa.pes_nome'],
      cols: [4, 6, 12] as [number, number, number],
    },
    itemsPerPage: [5, 10, 15, 20],
  };

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>();
  const [errorColumns, setErrorColumns] = useState<IColumns>([
    { field: 'nome', text: 'Nome', type: { name: 'text' } },
    {
      field: 'motivo',
      text: 'Motivo',
      type: { name: 'text' },
    },
  ]);

  const handdleCloseModalError = useCallback(() => {
    onCloseModalErro();
    setEscalasSelectedDatatable([]);
    setVoluntariosFaltasPresencasSelected([]);
  }, [onCloseModalErro]);

  const [isJustificativa, setIsJustificativa] = useState(false);

  const userPosition = useRef<[number, number]>([-4.7973763, -40.6543304]);

  const handleRequestEquipeVoluntarios = useCallback(
    async (equipes: IEquipe[]) => {
      return Promise.all(
        equipes.map(async ({ id_equipe }, index) => {
          const { data } = await api.get<IEquipeVoluntario[]>(
            `equipe/${id_equipe}/equipes_voluntarios`,
          );

          return data.map((eqpVoluntario) => ({
            ...eqpVoluntario,
            nome: equipes[index].nome,
          }));
        }),
      );
    },
    [],
  );

  const handleAbrirEscala = async (id: number): Promise<void> => {
    try {
      setDisabledRequest(true);
      await api.put(`escalas/irsos/${id}/abrir`);
      toast({
        title: 'Sucesso.',
        description: `Escala aberta com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);

      onCloseModalAbrirEscala();
    }
  };

  const handleGerarPdfEscala = async (id: number): Promise<void> => {
    // window.open(`${api.defaults.baseURL}/escalas/irsos/${id}/pdf`, '_blank');

    try {
      const { data: documento } = await api.get(`/escalas/irsos/${id}/pdf`, {
        responseType: 'blob',
      });

      if (documento) {
        const url = window.URL.createObjectURL(
          new Blob([documento], { type: 'application/pdf' }),
        );

        window.open(url, '_blank')?.focus();

        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', `Escala-${id}.pdf`);

        // document.body.appendChild(link);

        // // Start download
        // link.click();

        // Clean up and remove the link
        // link.parentNode?.removeChild(link);
      }
    } catch (error) {
      const response = JSON.parse(await (error.response.data as Blob).text());
      toast({
        title: 'Ocorreu um erro.',
        description: response.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleCloseFecharVisualizarEscala = useCallback(() => {
    onCloseVisualizarMapa();
    onClose();
  }, [onClose, onCloseVisualizarMapa]);

  const handleMostrarEscalaAssinada = useCallback(
    async ({ anexos }: IEscala) => {
      try {
        if (anexos) {
          const anexo = anexos[anexos.length - 1];
          const { data: documento } = await api.get(anexo.url, {
            responseType: 'blob',
          });

          if (documento) {
            const url = window.URL.createObjectURL(
              new Blob([documento], { type: 'application/pdf' }),
            );

            window.open(url, '_blank')?.focus();
          }
        }
      } catch (error) {
        const response = JSON.parse(await (error.response.data as Blob).text());
        toast({
          title: 'Ocorreu um erro.',
          description: response.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    [toast],
  );

  const handleCloseFiscaisEscala = useCallback(() => {
    fiscais.current = [];
    resetFormFiscais({ fiscais: [] });
    onCloseModalFiscaisEscala();
  }, [onCloseModalFiscaisEscala, resetFormFiscais]);

  const handleEditarEquipesFiscais = async (
    data: IFiscaisSchema,
  ): Promise<void> => {
    try {
      const fiscaisToUpdate = data.fiscais.filter(
        (f) => !!f.pm_codigo_fiscal_local,
      );
      if (fiscaisToUpdate.length > 0) {
        await api.patch('equipe', fiscaisToUpdate);

        toast({
          title: 'Sucesso.',
          description: `Fiscais atualizados com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        handleCloseFiscaisEscala();
        return;
      }

      toast({
        title: 'Erro.',
        description: `Coloque em pelo menos uma equipe o fiscal!`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data?.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleFecharEscala = async ({
    justificativa,
  }: IFecharEscalaSchema): Promise<void> => {
    try {
      setDisabledRequest(true);
      await api.put(`escalas/irsos/${escala?.id_escala || idEscala}/fechar`, {
        justificativa,
      });
      toast({
        title: 'Sucesso.',
        description: `Escala fechada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      const { message } = error.response.data;

      try {
        const parsedMessage: ErrorMessage = JSON.parse(message);

        setErrorMessage(parsedMessage);
        if (parsedMessage.values[0]?.pm_codigo) {
          setErrorColumns([
            {
              field: 'data_inicio',
              text: 'Data Início',
              type: { name: 'text' },
            },
            { field: 'data_fim', text: 'Data Final', type: { name: 'text' } },
            { field: 'pm_codigo', text: 'Matricula', type: { name: 'text' } },
            { field: 'voluntario', text: 'Voluntario', type: { name: 'text' } },
          ]);
        } else
          setErrorColumns([
            { field: 'nome', text: 'Nome', type: { name: 'text' } },
            {
              field: 'motivo',
              text: 'Motivo',
              type: { name: 'text' },
            },
          ]);

        onOpenModalErro();
      } catch (error2) {
        toast({
          title: 'Ocorreu um erro.',
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setIsJustificativa(false);
      onCloseModalFecharEscala();
      setDisabledRequest(false);
    }
  };

  const handleLoadEquipesFiscaisLocais = async (
    idEscalaFiscal: number,
  ): Promise<void> => {
    const {
      data: { items: equipesFiscais },
    } = await api.get<{ items: IResponseEquipeFiscal[] }>(
      `escalas/irsos/${idEscalaFiscal}/equipes`,
    );

    const areas = await Promise.all(
      equipesFiscais.map(async (e) => {
        const {
          data: { items },
        } = await api.get<{ items: { area: { nome: string } }[] }>(
          `escalas/irsos/${idEscalaFiscal}/equipes/${e.id_equipe}/areas`,
        );

        return items.map((i) => i.area.nome);
      }),
    );

    fiscais.current = equipesFiscais.map((e, i) => ({ ...e, areas: areas[i] }));
  };

  const refDocumento = useRef<File | null>(null);
  const handleConcluirEscala = async ({
    file,
  }: IConcluirEscalaSchema): Promise<void> => {
    refDocumento.current = file;

    onOpenModalAssinaturaConcluirEscala();
    onCloseModalConcluirEscala();
  };

  const handleConluirEscalaAssinatura = async (
    data: Record<string, any>,
  ): Promise<void> => {
    const formData = new FormData();
    formData.append('documentos_assinados', refDocumento.current as File);
    formData.append('assinatura', data.assinatura);

    try {
      await api.put(`escalas/irsos/${escala?.id_escala}/concluir`, formData);
      toast({
        title: 'Sucesso.',
        description: `Fase da  escala atualizada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      onCloseModalAssinaturaConcluirEscala();
      onCloseModalConcluirEscala();
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleConfirmarEscala = async ({
    justificativa,
  }: IFecharEscalaSchema): Promise<void> => {
    try {
      setDisabledRequest(true);
      await api.post(
        `escalas/irsos/${escala?.id_escala || idEscala}/confirmar`,
        { justificativa, situacao_escala: justificativa ? 6 : 3 },
      );
      toast({
        title: 'Sucesso.',
        description: `Escala confirmada com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsJustificativa(false);
      onCloseModalConfirmarManyEscalaAtrasada();
      setDisabledRequest(false);
    }
  };

  const handlePoliciaisEscala = useCallback(async () => {
    const {
      data: { items: equipes },
    } = await api.get<{ items: IEquipe[] }>(
      `escalas/irsos/${idEscala}/equipes`,
    );

    const filteredEquipes = equipes.sort((a, b) =>
      a.id_equipe < b.id_equipe ? -1 : 1,
    );

    setVoluntarios(await handleRequestEquipeVoluntarios(filteredEquipes));
  }, [idEscala, handleRequestEquipeVoluntarios]);

  const handleRegistrarPresencasEscala = async (): Promise<void> => {
    try {
      setDisabledRequestTodosPresentes(true);
      await api.put(
        `escalas/irsos/${idEscala}/presencas`,
        voluntariosFaltasPresencasSelected.map(
          ({ id_equipe_policial }) => id_equipe_policial,
        ),
      );

      toast({
        title: 'Sucesso.',
        description: `Presenças registradas com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      handlePoliciaisEscala();
    } catch (error) {
      const { message } = error.response.data;

      try {
        const parsedMessage: ErrorMessage = JSON.parse(message);

        setErrorMessage(parsedMessage);
        onOpenModalErro();
      } catch (error2) {
        toast({
          title: 'Ocorreu um erro.',
          description: message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } finally {
      setVoluntariosFaltasPresencasSelected([]);
      // onCloseModalRegistrarFaltas();
      setDisabledRequestTodosPresentes(false);
    }
  };

  const handleDesfazerFaltasVoluntarioEscala = async (): Promise<void> => {
    if (voluntariosFaltasPresencasSelected.length < 1)
      toast({
        title: 'Ocorreu um erro.',
        description:
          'Selecione pelo menos um voluntario para desfazer a(s) falta(s)',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      try {
        setDisabledRequestDesfazerFalta(true);
        await api.put(
          `escalas/irsos/${idEscala}/converter_faltas`,
          voluntariosFaltasPresencasSelected.map(
            ({ id_equipe_policial }) => id_equipe_policial,
          ),
        );
        toast({
          title: 'Sucesso.',
          description: `Falta(s) desfeita(s) com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });

        handlePoliciaisEscala();
      } catch (error) {
        const { message } = error.response.data;

        try {
          const parsedMessage: ErrorMessage = JSON.parse(message);

          setErrorMessage(parsedMessage);
          onOpenModalErro();
        } catch (error2) {
          toast({
            title: 'Ocorreu um erro.',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } finally {
        setVoluntariosFaltasPresencasSelected([]);
        setDisabledRequestDesfazerFalta(false);
      }
    }
  };

  const handleCriarFaltasVoluntarioEscala = async (): Promise<void> => {
    if (voluntariosFaltasPresencasSelected.length < 1)
      toast({
        title: 'Ocorreu um erro.',
        description:
          'Selecione pelo menos um voluntario para registrar as faltas',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      try {
        setDisabledRequest(true);
        await api.put(
          `escalas/irsos/${idEscala}/faltas`,
          voluntariosFaltasPresencasSelected.map(
            ({ id_equipe_policial }) => id_equipe_policial,
          ),
        );
        toast({
          title: 'Sucesso.',
          description: `Faltas inseridas com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        handlePoliciaisEscala();
      } catch (error) {
        const { message } = error.response.data;

        try {
          const parsedMessage: ErrorMessage = JSON.parse(message);
          setErrorMessage(parsedMessage);
          onOpenModalErro();
        } catch (error2) {
          toast({
            title: 'Ocorreu um erro.',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } finally {
        setVoluntariosFaltasPresencasSelected([]);
        // onCloseModalRegistrarFaltas();
        setDisabledRequest(false);
      }
    }
  };

  const escalasFiltered = escalasSelectedDatatable.filter(
    ({ id_escala }) => !!id_escala,
  );
  const handleCriarHomologacoesEscalas = async (): Promise<void> => {
    if (escalasFiltered.length < 1)
      toast({
        title: 'Ocorreu um erro.',
        description: 'Selecione pelo menos uma escala para homologar',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      try {
        setDisabledRequest(true);
        await api.post(
          `escalas/irsos/homologacoes`,
          escalasFiltered.map((escalaSelected) => escalaSelected.id_escala),
        );
        toast({
          title: 'Sucesso.',
          description: `Escalas homologadas com sucesso!`,
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } catch (error) {
        const { message } = error.response.data;

        try {
          const parsedMessage: ErrorMessage = JSON.parse(message);

          setErrorMessage(parsedMessage);
          onOpenModalErro();
        } catch (error2) {
          toast({
            title: 'Ocorreu um erro.',
            description: message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } finally {
        onCloseModalHomologarManyEscalaEscala();
        setDisabledRequest(false);
      }
    }
  };

  const handleEscalaStarted = useCallback(
    (dataInicio: Date) => {
      const horaAtual = new Date();

      const isEscalaAlreadyStarted = compareAsc(dataInicio, horaAtual) < 0;

      const isNotEscalaAlreadyStartedButHasProfile =
        isEscalaAlreadyStarted && user.currentPerfil === 'CGO';

      return !isEscalaAlreadyStarted || isNotEscalaAlreadyStartedButHasProfile;
    },
    [user.currentPerfil],
  );

  const handleEscalaEnded = useCallback((dataFinal: Date) => {
    const horaAtual = new Date();

    return compareAsc(dataFinal, horaAtual) < 0;
  }, []);

  const handleShowIconAbrirEscala = useCallback(
    ({ situacao_escala }: IEscala) => {
      const blockedProfiles = ['VALIDADOR', 'CONTROLADORIA', 'GRANDE COMANDO'];

      return (
        situacao_escala === 1 &&
        !blockedProfiles.includes(user.currentPerfil as string)
      );
    },
    [user.currentPerfil],
  );

  const handleShowIconDeletarEscala = useCallback(
    ({ situacao_escala }: IEscala) => {
      const allowedProfiles = ['ESCALANTE', 'CGO', 'CETIC'];

      return (
        situacao_escala === 1 &&
        allowedProfiles.includes(user.currentPerfil as string)
      );
    },
    [user],
  );

  const handleShowIconRequestAbrirEscala = useCallback(
    ({ situacao_escala, data_inicio }: IEscala) => {
      // const horaInicio = parse(data_inicio, 'dd/MM/yyyy HH:mm:ss', new Date());

      const isAllowedSuperUser = ['CGO', 'CETIC'].includes(
        user.currentPerfil as string,
      );

      const isEscalante = ['ESCALANTE'].includes(user.currentPerfil as string);

      return (
        !!situacao_escala &&
        (([3, 4].includes(situacao_escala) && isEscalante) ||
          (isAllowedSuperUser && situacao_escala !== 1))
      );

      // return (
      //   !!situacao_escala &&
      //   (([3, 4].includes(situacao_escala) &&
      //     !!handleEscalaStarted(horaInicio)) ||
      //     (allowedProfiles.includes(user.currentPerfil as string) &&
      //       situacao_escala !== 1))
      // );
    },
    [user.currentPerfil],
    // [handleEscalaStarted, user.currentPerfil],
  );

  const handleShowIconFecharEscala = useCallback(
    ({ situacao_escala }: IEscala) => {
      const isClosedStatus = situacao_escala > 1;

      const blockedProfiles = ['VALIDADOR', 'CONTROLADORIA', 'GRANDE COMANDO'];

      return (
        !!situacao_escala &&
        !isClosedStatus &&
        !blockedProfiles.includes(user.currentPerfil as string)
      );
    },
    [user.currentPerfil],
  );

  const handleShowIconConcluirEscala = useCallback(
    ({ situacao_escala, data_fim, is_faltas_resolved }: IEscala) => {
      const isFechadaStatus = situacao_escala === 3;
      const allowedProfilesConcluirEscala = ['VALIDADOR', 'CGO', 'CETIC'];

      const horaFim = parse(data_fim, 'dd/MM/yyyy HH:mm:ss', new Date());

      return (
        isFechadaStatus &&
        handleEscalaEnded(horaFim) &&
        allowedProfilesConcluirEscala.includes(user.currentPerfil as string) &&
        is_faltas_resolved
      );
    },
    [user, handleEscalaEnded],
  );

  const handleShowIconConfirmarEscala = useCallback(
    ({ situacao_escala }: IEscala) => {
      const isHomologadaStatus = situacao_escala === 2;
      const allowedProfiles = ['CGO', 'CETIC'];

      return (
        isHomologadaStatus &&
        allowedProfiles.includes(user.currentPerfil as string)
      );
    },
    [user.currentPerfil],
  );

  const handleShowIconRegistrarFaltaEscala = useCallback(
    ({ situacao_escala, data_inicio }: IEscala) => {
      const isFechadaStatus = situacao_escala === 3;
      const isConfirmadaStatus = situacao_escala === 4;

      const allowedProfilesBeforeConfirmar = ['CETIC', 'VALIDADOR'];
      const allowedProfilesAfterConfirmar = ['CGO', 'CETIC'];

      const isAllowedBeforeConfirmar =
        isFechadaStatus &&
        allowedProfilesBeforeConfirmar.includes(user.currentPerfil as string);
      const isAllowedAfterConfirmar =
        isConfirmadaStatus &&
        allowedProfilesAfterConfirmar.includes(user.currentPerfil as string);

      const horaInicio = parse(data_inicio, 'dd/MM/yyyy HH:mm:ss', new Date());

      return (
        (isAllowedAfterConfirmar && (isFechadaStatus || isConfirmadaStatus)) ||
        (isAllowedBeforeConfirmar && !handleEscalaStarted(horaInicio))
      );
    },
    [handleEscalaStarted, user.currentPerfil],
  );

  const delayedQuery = useCallback(
    debounce(async (query: string) => {
      try {
        const response = await api.get(`pessoas`, {
          params: { query, gra_oficial: ['S', 'N'].join(',') },
        });

        const data = response.data || [];

        const responseFormated = data.map((item: any) => {
          return {
            value: item.matricula,
            label: item.dados,
            opm: item.uni_codigo,
          };
        });

        // setPessoaList(response.data);

        return responseFormated;
      } catch (error) {
        return undefined;
      }
    }, 500),
    [],
  );

  const handleCloseConfirmarEscala = useCallback(() => {
    setIsJustificativa(false);
    onCloseModalConfirmarManyEscalaAtrasada();
  }, [onCloseModalConfirmarManyEscalaAtrasada]);

  useEffect(() => {
    if (isOpen) {
      setColumnsEquipesVoluntarios((equipesVoluntarios) => {
        return equipesVoluntarios.slice(0, equipesVoluntarios.length - 1);
      });
    } else {
      setColumnsEquipesVoluntarios((equipesVoluntarios) => {
        return [
          ...equipesVoluntarios,
          { field: 'nome', text: 'Nome da Equipe', type: { name: 'text' } },
        ];
      });
    }
  }, [isOpen]);

  const handleShowEnderecos = useCallback(async () => {
    if (escala) {
      const { equipes } = escala;
      const filteredEquipes = equipes.filter(
        ({ deletado }) => deletado === '0',
      );

      const formatedEquipesEnderecos = await Promise.all(
        filteredEquipes.map(async ({ id_equipe }) => {
          const { data: equipeEnderecos } = await api.get<IEquipeEndereco[]>(
            `equipe/${id_equipe}/enderecos`,
          );
          return equipeEnderecos;
        }),
      );

      setEquipesEnderecos(
        formatedEquipesEnderecos.reduce(
          (previousArray, actualElement) => [
            ...previousArray,
            ...actualElement.filter(({ deletado }) => deletado === 0),
          ],
          [],
        ),
      );
    }
  }, [escala]);

  const handleShowEscala = useCallback(
    async ({ id_escala, ...restEscala }: IEscala) => {
      const {
        data: { items: equipes },
      } = await api.get<{ items: IEquipe[] }>(
        `escalas/irsos/${id_escala}/equipes`,
      );

      let equipesWithVeiculosAndAddresses = equipes;

      try {
        equipesWithVeiculosAndAddresses = await Promise.all(
          equipesWithVeiculosAndAddresses.map(
            async ({ id_equipe, ...restEquipe }) => {
              let veiculosFormated: any[] | undefined;
              const {
                data: { items: veiculos },
              } = await api.get<{
                items: {
                  id_equipe_veiculo: number;
                  id_veiculo: number;
                }[];
              }>(
                `escalas/irsos/${idEscala}/equipes/${id_equipe}/equipe_veiculos`,
              );

              if (veiculos) {
                veiculosFormated = await Promise.all(
                  veiculos.map(async (veiculo) => {
                    // const [
                    //   { data: dadosVeiculo },
                    //   {
                    //     data: { items: identificadores },
                    //   },
                    // ] = await Promise.all([
                    //   api.get(
                    //     `https://api-sav2-dev.pm.ce.gov.br/veiculos/${veiculo.id_veiculo}`,
                    //   ),
                    //   await api.get(
                    //     `https://api-sav2-dev.pm.ce.gov.br/veiculos/${veiculo.id_veiculo}/identificadores`,
                    //   ),
                    // ]);

                    const { data: dadosVeiculo } = await api.get(
                      `veiculos/${veiculo.id_veiculo}`,
                    );

                    return {
                      ...dadosVeiculo,
                      id_veiculo: veiculo.id_veiculo,
                      //  identificador: identificadores[0],
                    };
                  }),
                );
              }

              return {
                ...restEquipe,
                id_equipe,
                veiculos: veiculosFormated,
                equipeEnderecos: [],
              };
            },
          ),
        );

        setVoluntarios(await handleRequestEquipeVoluntarios(equipes));

        setEscala({
          ...restEscala,
          equipes: equipesWithVeiculosAndAddresses,
          id_escala,
        });
        onOpen();
      } catch (error) {
        console.log(error);
        toast({
          title: 'Ocorreu um erro.',
          description: error.response?.data?.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },

    [onOpen, toast, handleRequestEquipeVoluntarios, idEscala],
  );

  const columnsVeiculos: IColumns = [
    { field: 'id_veiculo', text: 'Id Veiculo', type: { name: 'text' } },
    { field: 'dados1', text: 'Dados', type: { name: 'text' } },
    // { field: 'marca', text: 'Nome', type: { name: 'text' } },
    // { field: 'numero', text: 'Nome', type: { name: 'text' } },
    // {
    //   field: 'identificador.identificador',
    //   text: 'Nome',
    //   type: { name: 'text' },
    // },
  ];

  const columns: IColumns = [
    {
      field: 'sequencia',
      text: 'Id°',
      type: { name: 'text' },
      alias: 'escalas.id_escala',
      tooltip: ({ nome }: IEscala) =>
        nome ? `Nome da escala: ${nome}` : undefined,
    },
    {
      field: 'uni_sigla',
      text: 'Unidade',
      type: { name: 'text' },
      tooltip: ({ uni_nome }: IEscala) => uni_nome,
    },
    {
      field: 'data_inicio',
      text: 'Início',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'escalas.data_inicio',
    },
    {
      field: 'data_fim',
      text: 'Fim',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'escalas.data_fim',
    },
    { field: 'total_horas', text: 'Total Hora', type: { name: 'text' } },

    {
      field: 'situacao_escala',
      alias: `statusEscala.situacao_escala`,
      text: 'Status',
      type: {
        name: 'enum',
        enum: {
          1: 'Aberta',
          2: 'Atrasada',
          3: 'Fechada',
          4: 'Confirmada',
          5: 'Homologada',
          6: 'Não Autorizada',
        },
      },
    },
    {
      field: 'valor_escala',
      alias: 'escalas.valor_escala',
      text: 'Valor',
      type: { name: 'currency' },
    },
    { field: 'total_policial', text: 'Policiais', type: { name: 'text' } },
    { field: 'total_equipes', text: 'Equipes', type: { name: 'text' } },
    {
      field: 'criado_em',
      text: 'Criado',
      type: { name: 'date', format: 'dd/MM/yyyy' },
      tooltip: ({
        pes_nome_criado_por,
        gra_sigla_criado_por,
        criado_em,
      }: IEscala) => {
        try {
          return `As ${format(
            new Date(criado_em),
            'HH:mm:ss',
          )} por: ${gra_sigla_criado_por} ${pes_nome_criado_por}`;
        } catch (error) {
          return undefined;
        }
      },
    },
    {
      field: 'atualizado_em',
      text: 'Atualizado',
      type: { name: 'date', format: 'dd/MM/yyyy' },
      tooltip: ({
        pes_nome_atualizado_por,
        gra_sigla_atualizado_por,
        atualizado_em,
      }: IEscala) => {
        if (!pes_nome_atualizado_por || !atualizado_em) return undefined;

        return `As ${format(
          new Date(atualizado_em),
          'HH:mm:ss',
        )} por: ${gra_sigla_atualizado_por}
         ${pes_nome_atualizado_por}`;
      },
    },
  ];

  const optionsRegistrarFaltas = {
    selectMultiline: {
      visible: true,
      primaryColumn: 'pm_codigo',
      stateSelectedRows: [
        voluntariosFaltasPresencasSelected,
        setVoluntariosFaltasPresencasSelected,
      ],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  const options = {
    serverData: {
      url: `/escalas/irsos`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
    },
    selectMultiline: {
      visible: true,
      primaryColumn: 'id_escala',
      stateSelectedRows: [
        escalasSelectedDatatable,
        setEscalasSelectedDatatable,
      ],
    },
    exportCsv: {
      visible: true,
      label: 'Escala',
      filename: 'escalas',
      serverPagination: false,
      columns: [
        { field: 'sequencia', title: 'Id' },
        { field: 'unidade.uni_sigla', title: 'Unidade' },
        {
          field: 'data_inicio',
          title: 'Data Início',
          date: { parseFormat: 'dd/MM/yyyy HH:mm:ss', format: 'dd/MM/yyyy' },
        },
        {
          field: 'data_inicio',
          title: 'Hora Início',
          date: { parseFormat: 'dd/MM/yyyy HH:mm:ss', format: 'HH:mm:ss' },
        },
        {
          field: 'data_fim',
          title: 'Data Fim',
          date: { parseFormat: 'dd/MM/yyyy HH:mm:ss', format: 'dd/MM/yyyy' },
        },
        {
          field: 'data_fim',
          title: 'Hora Fim',
          date: { parseFormat: 'dd/MM/yyyy HH:mm:ss', format: 'HH:mm:ss' },
        },
        { field: 'total_horas', title: 'Horas' },
        { field: 'valor_escala', title: 'Valor', number: true },
        {
          field: 'situacao_escala',
          title: 'Status',
          enum: {
            1: 'Aberta',
            2: 'Atrasada',
            3: 'Fechada',
            4: 'Confirmada',
            5: 'Homologada',
            6: 'Não Autorizada',
          },
        },

        { field: 'total_equipes', title: 'Equipes', number: true },
        { field: 'total_policial', title: 'Policiais', number: true },

        { field: 'criado_em', title: 'Data Criação', dateFormat: 'dd/MM/yyyy' },
        { field: 'criado_em', title: 'Hora Criação', dateFormat: 'HH:mm:ss' },
        { field: 'criado_por', title: 'Criado Por' },

        { field: 'pes_nome_criado_por', title: 'Atualizado Por' },
      ],
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',
          getRow: handleShowEscala,
          handleShowAction: (row: IEscala) => !!row?.id_escala,
        },

        {
          icon: <FaPencilAlt size={13} />,
          tooltip: 'Editar Escala',
          getRow: async ({ id_escala }: IEscala) => {
            updateIdEscala(id_escala);
            history.push('editarescalairso');
          },
          handleShowAction: handleShowIconAbrirEscala,
        },

        {
          icon: <FaUnlockAlt size={13} />,
          tooltip: 'Abrir Escala',
          getRow: async ({ id_escala }: IEscala) => {
            updateIdEscala(id_escala);
            onOpenModalAbrirEscala();
          },
          handleShowAction: handleShowIconRequestAbrirEscala,
        },

        {
          icon: <FaLock size={13} />,
          tooltip: 'Fechar Escala',
          getRow: async ({ data_inicio, ...rest }: IEscala) => {
            setEscala({
              ...rest,
              data_inicio,
            });

            // const { data } = await api.get<string>('/time');
            // const horaAtual = parse(data, 'dd/MM/yyyy HH:mm:ss', new Date());

            // const dataInicio = parse(
            //   data_inicio as string,
            //   'dd/MM/yyyy HH:mm:ss',
            //   new Date(),
            // );

            // const isLate = compareAsc(dataInicio, horaAtual) <= 0;
            let alreadyHasJustificativa = false;

            if (rest.is_already_fecahda === 0) alreadyHasJustificativa = true;

            setIsJustificativa(alreadyHasJustificativa);

            onOpenModalFecharEscala();
          },
          handleShowAction: handleShowIconFecharEscala,
        },

        {
          icon: <GiPoliceOfficerHead size={16} />,
          tooltip: 'Relação de fiscais locais',
          getRow: async ({ id_escala }: IEscala) => {
            await handleLoadEquipesFiscaisLocais(id_escala);

            modalFiscaisEscala.onOpen();
          },
          handleShowAction: (esc: IEscala) => {
            return (
              !!esc?.id_escala &&
              [
                StatusEscalas.Aberta,
                StatusEscalas.Atrasada,
                StatusEscalas.Fechada,
              ].includes(esc.situacao_escala) &&
              !['COMANDANTE', 'CONTROLADORIA'].includes(
                user.currentPerfil as string,
              ) &&
              Number.parseInt(esc.total_equipes.toString(), 10) > 0
            );
          },
        },

        {
          icon: <BsFileEarmarkCheck size={15} />,
          tooltip: 'Confirmar escala',
          getRow: (escalaRow: IEscala) => {
            setEscala(escalaRow);
            onOpenModalConcluirEscala();
          },
          handleShowAction: handleShowIconConcluirEscala,
        },

        {
          icon: <BsPrinter size={15} />,
          tooltip: 'Imprimir escala',
          getRow: async ({ id_escala }: IEscala) =>
            handleGerarPdfEscala(id_escala),
          handleShowAction: (escalaToTest: IEscala) => {
            const ultimaSituacaoEscala = escalaToTest?.situacao_escala;
            return !!ultimaSituacaoEscala && ultimaSituacaoEscala === 3;
          },
        },

        {
          icon: <FaRegFilePdf />,
          tooltip: 'Visualizar escala assinada',
          getRow: async (escalaRow: IEscala) =>
            handleMostrarEscalaAssinada(escalaRow),
          handleShowAction: (escalaToTest: IEscala) =>
            escalaToTest.situacao_escala > 3 &&
            escalaToTest.situacao_escala < 6,
        },

        {
          icon: <MdPersonOff size={17} />,
          tooltip: 'Registrar Falta',
          getRow: async ({ id_escala, ...rest }: IEscala) => {
            setEscala({ id_escala, ...rest });

            const {
              data: { items: equipes },
            } = await api.get<{ items: IEquipe[] }>(
              `escalas/irsos/${id_escala}/equipes`,
            );

            const filteredEquipes = equipes.sort((a, b) =>
              a.id_equipe < b.id_equipe ? -1 : 1,
            );

            setVoluntarios(
              await handleRequestEquipeVoluntarios(filteredEquipes),
            );

            updateIdEscala(id_escala);
            onOpenModalRegistrarFaltas();
          },
          handleShowAction: handleShowIconRegistrarFaltaEscala,
        },

        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',
          getRow: async (escalaRow: IEscala) => {
            setEscala(escalaRow);
            onOpenModalDeletar();
          },
          handleShowAction: handleShowIconDeletarEscala,
        },

        {
          icon: <FaCheck size={15} />,
          tooltip: 'Autorizar Escala',
          getRow: async (escalaRow: IEscala) => {
            setEscala(escalaRow);
            onOpenModalConfirmarManyEscalaAtrasada();
            setIsJustificativa(false);
          },
          handleShowAction: handleShowIconConfirmarEscala,
        },
      ],
    },
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['escalas.sequencia', 'equipePoliciais.pm_codigo'],
      cols: [4, 6, 12] as [number, number, number],
    },
    filters,
    highlightRow: ({ situacao_escala, data_inicio }: IEscala) => {
      const dataInicio = parse(data_inicio, 'dd/MM/yyyy HH:mm:ss', new Date());

      return situacao_escala === 2 ||
        (compareAsc(dataInicio, new Date()) <= 0 && situacao_escala === 1)
        ? '#f22929'
        : undefined;
    },
  };

  useEffect(() => {
    const triggerJustificativa = async (): Promise<void> => {
      await trigger('justificativa');
    };

    triggerJustificativa();
  }, [trigger]);

  useEffect(() => {
    const loadGrandesComandos = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IGrandesComando[] }>(
        '/unidades/grandes_comandos',
      );

      setGrandesComandos(
        items.map((item) => ({
          label: item.uni_sigla,
          value: item.uni_codigo.toString(),
        })),
      );
    };

    loadGrandesComandos();
  }, []);

  useEffect(() => {
    if (Number(user.currentOpm?.uni_codigo as string) === -1) {
      setFilters([
        {
          type: 'select' as 'date' | 'select',
          field: 'grandes_comandos',
          label: 'Grande Comando',
          options: [{ label: 'Todos', value: '' }, ...grandesComandos],
          defaultOption: '',

          cols: [2, 6, 12] as [number, number, number],
        },
        ...defaultFilters,
      ]);
    } else setFilters(defaultFilters);
  }, [user.currentOpm, grandesComandos]);

  // useEffect(() => {
  //   const triggerJustificativa = async (): Promise<void> => {
  //     setValue('isEscalaAtrasada', true);
  //   };
  //   !!isJustificativa && triggerJustificativa();
  // }, [trigger, isJustificativa, setValue]);

  return (
    <>
      <TituloPagina title="Escala DRSO " />
      <BoxContent>
        <Box>
          <DataTable columns={columns} options={options} />
          {!!escalasFiltered.length && (
            <PanelBottomActions>
              {profilesHomologarEscalas.includes(
                user.currentPerfil as string,
              ) && (
                <Button
                  leftIcon={<FaCheck size={13} />}
                  colorScheme="green"
                  onClick={onOpenModalHomologarManyEscalaEscala}
                >
                  Homologar Escalas
                </Button>
              )}
            </PanelBottomActions>
          )}

          <TituloPagina title="Legenda" />
          <Flex
            direction="column"
            margin="10px"
            width="15%"
            justifyContent="space-evenly"
          >
            <Flex
              direction="row"
              justifyContent="space-evenly"
              marginTop="4px"
              alignContent="center"
              alignItems="center"
            >
              <div
                style={{
                  background: 'red',
                  width: 20,
                  height: 20,
                  borderRadius: '50%',
                }}
              />
              <Text textAlign="justify" color="red">
                Escala atrasada e aberta
              </Text>
            </Flex>
          </Flex>
        </Box>
      </BoxContent>

      <Modal
        isOpen={isModalDeletarOpen}
        onClose={onCloseModalDeletar}
        size="md"
        title="Deletar escala"
      >
        <h1>
          Voce esta prestes a deletar a escala do dia{' '}
          <strong>{escala?.data_inicio}</strong>
          {' - '}
          <strong>{escala?.data_fim}</strong>. Você tem certeza da operação?
        </h1>
        <Flex mt="8" justify="center">
          <HStack spacing="4">
            <Button onClick={onCloseModalDeletar} colorScheme="green">
              Não
            </Button>

            <Button
              isLoading={disabledRequest}
              onClick={async () => {
                setDisabledRequest(true);
                try {
                  await api.delete(`escalas/irsos/${escala?.id_escala}`);
                  toast({
                    title: 'Sucesso.',
                    description: `Escala Deletada com sucesso!`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                  });
                  onCloseModalDeletar();
                } catch (error) {
                  toast({
                    title: 'Error',
                    description:
                      error.response?.data?.message ||
                      `Escala não pode ser deletada!`,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right',
                  });
                } finally {
                  setDisabledRequest(false);
                }
              }}
              colorScheme="red"
            >
              Sim Quero Deletar!
            </Button>
          </HStack>
        </Flex>
      </Modal>

      <Modal
        isOpen={isOpen}
        onClose={handleCloseFecharVisualizarEscala}
        size="6xl"
        title={`Nome da escala: ${escala?.nome} - Id: ${
          escala?.sequencia
        } - Status: ${
          StatusEscalas[escala?.situacao_escala as number]
        } - Valor: ${new Intl.NumberFormat('pt-BR', {
          currency: 'BRL',
          style: 'currency',
        }).format(Number(escala?.valor_escala) as number)}`}
      >
        <>
          <TituloPagina title="Data e horario" />

          <Row>
            <FormGroup name="Horario de Início" cols={[3, 4, 4]}>
              {escala?.data_inicio}
            </FormGroup>

            <FormGroup name="Horario Final" cols={[3, 4, 4]}>
              {escala?.data_fim}
            </FormGroup>

            <FormGroup name="Total de horas" cols={[3, 4, 4]}>
              {escala?.total_horas}
            </FormGroup>
          </Row>
          <Row>
            <TituloPagina title="Mapa de atuação das equipes" />
            <Tooltip label="Abrir mapa" placement="bottom">
              <Button
                type="button"
                marginLeft="2"
                borderRadius="50%"
                onClick={async () => {
                  onOpenVisualizarMapa();
                  handleShowEnderecos();
                }}
              >
                <FaPlus />
              </Button>
            </Tooltip>
          </Row>

          <>
            <TituloPagina title="Dados do Serviço por equipe" />
            <Tabs isFitted variant="enclosed">
              <TabList>
                {escala?.equipes?.map((equipe, index) => (
                  <Tooltip key={JSON.stringify(equipe)} label={equipe.nome}>
                    <Tab>Equipe {index + 1}</Tab>
                  </Tooltip>
                ))}
              </TabList>

              <TabPanels>
                {escala?.equipes?.map((equipe, index) => (
                  <TabPanel key={JSON.stringify(equipe)}>
                    <Text as="b">Nome</Text>
                    <Text>{equipe.nome}</Text>
                    <Divider mt={4} mb={4} />
                    {equipe.veiculos && equipe.veiculos.length > 0 && (
                      <>
                        <FormGroup name="Veiculos" cols={[12, 12, 12]} />
                        <DataTable
                          columns={columnsVeiculos}
                          data={equipe?.veiculos}
                        />

                        <Divider orientation="horizontal" />
                      </>
                    )}

                    {/* <FormGroup name="Endereços" cols={[12, 12, 12]} />
                    <DataTable
                      columns={columnsEnderecos}
                      data={equipe.equipeEnderecos}
                    /> */}

                    <FormGroup name="Voluntarios" cols={[12, 12, 12]} />
                    <DataTable
                      columns={columnsEquipesVoluntarios}
                      options={optionsEquipesVoluntarios}
                      data={voluntarios[index]}
                    />
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </>
        </>
      </Modal>

      <Modal
        title="Dados do Serviço por equipe"
        size="4xl"
        isOpen={isOpenVisualizarMapa}
        onClose={onCloseVisualizarMapa}
      >
        <Map
          center={{
            lat: userPosition.current[0],
            lng: userPosition.current[1],
          }}
          zoom={14}
        >
          {equipesEnderecos.map(
            ({ referencia_api, id_equipe: idEquipeEscala }) => (
              <Marker
                position={referencia_api.geometry?.location}
                title="gege"
                key={JSON.stringify(referencia_api.geometry?.location)}
              >
                <PopUp
                  content={
                    escala?.equipes.find(
                      ({ id_equipe }) => id_equipe === idEquipeEscala,
                    )?.nome
                  }
                />
              </Marker>
            ),
          )}
        </Map>
      </Modal>

      <Modal
        title="Fechar Escala"
        isOpen={isModalFecharEscalaOpen}
        onClose={onCloseModalFecharEscala}
        size={isJustificativa ? '6xl' : 'md'}
      >
        <p style={{ textAlign: 'center' }}>
          {isJustificativa
            ? 'Esta escala está atrasada e ao fechá-la você precisa aguardar a autorização da CGO. Digite abaixo a justificativa do atraso'
            : 'Você realmente deseja fechar esta escala?'}
        </p>

        <form onSubmit={handleSubmit(handleFecharEscala)}>
          {isJustificativa && (
            <Controller
              control={control}
              name="justificativa"
              render={({ value, onChange }) => (
                <TextArea
                  /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                  onChange={onChange}
                  value={value?.replaceAll(
                    // eslint-disable-next-line no-control-regex
                    /[^\x00-\x7F]/g,
                    '',
                  )}
                  error={errors.justificativa?.message}
                  disabled={!isJustificativa}
                  placeholder="Insira aqui sua justificativa"
                  onPaste={(e) => {
                    const a = e.currentTarget?.value.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    );

                    onChange(a);
                  }}
                />
              )}
            />
          )}

          <Controller
            control={control}
            name="isEscalaAtrasada"
            defaultValue={isJustificativa}
            render={({ value }) => (
              <Checkbox isChecked={!!value} visibility="hidden">
                Sim
              </Checkbox>
            )}
          />

          <PanelBottomActions>
            <Button leftIcon={<FaTimes />} colorScheme="red">
              Não
            </Button>
            <Button
              leftIcon={<FaLock />}
              colorScheme="green"
              type="submit"
              isLoading={disabledRequest}
            >
              Sim
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        title="Concluir Escala"
        isOpen={isModalConcluirEscalaOpen}
        onClose={onCloseModalConcluirEscala}
        size="6xl"
      >
        <form onSubmit={handleSubmitFormConcluido(handleConcluirEscala)}>
          {!!watchFormConcluido('file') && (
            <iframe
              src={window.URL.createObjectURL(watchFormConcluido('file'))}
              title="teste"
              width="100%"
              height="720px"
              style={{ marginBottom: '4%' }}
            />
          )}

          <Text textAlign="center">
            Por favor, envie o documento contendo as assinaturas
            <span style={{ color: 'red' }}>*</span>
          </Text>
          <Controller
            name="file"
            control={controlFormConcluido}
            render={({ onChange }) => (
              <>
                <InputFile
                  name="teste"
                  accept="application/pdf"
                  onChange={(e) => {
                    const file = e.currentTarget.files?.item(0);
                    const element = e;

                    if (file) {
                      if (file.size > maxFileUploadSize) {
                        toast({
                          title: 'Erro.',
                          description: `Tamanho do arquivo não pode ser superior a ${timesMegaByte}MB`,
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                          position: 'top-right',
                        });
                        onChange(undefined);
                        element.currentTarget.value = '';
                      } else if (
                        !file.type.match(
                          /^(application\/pdf)|(image\/((pn|(jpe?))g))$/,
                        )
                      ) {
                        toast({
                          title: 'Erro.',
                          description:
                            'Arquivo invalido. Apenas PDF/PNG/JPG/JPEG',
                          status: 'error',
                          duration: 5000,
                          isClosable: true,
                          position: 'top-right',
                        });
                        onChange(undefined);

                        element.currentTarget.value = '';
                      } else {
                        const file2 = new File(
                          [file.slice(0, file.size)],
                          file.name?.replaceAll(
                            // eslint-disable-next-line no-control-regex
                            /[^\x00-\x7F]/g,
                            '',
                          ),

                          { type: file.type },
                        );
                        onChange(file2);
                      }
                    }
                  }}
                  error={errorsFormConcluido.file as FieldError | undefined}
                />
              </>
            )}
          />

          <Alert
            status="warning"
            width="30%"
            alignContent="center"
            alignItems="center"
            textAlign="center"
            marginTop="4"
          >
            <AlertIcon />
            <AlertDescription>Pdf de no máximo 1MB</AlertDescription>
          </Alert>

          <PanelBottomActions>
            <Button
              leftIcon={<FaTimes />}
              colorScheme="red"
              onClick={onCloseModalConcluirEscala}
              type="button"
            >
              Fechar
            </Button>
            <Button
              leftIcon={<FaCheck />}
              colorScheme="green"
              type="submit"
              isLoading={disabledRequest}
            >
              Concluir Escala
            </Button>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        title="Homologar Escalas"
        isOpen={isModalHomologarManyEscalaOpen}
        onClose={onCloseModalHomologarManyEscalaEscala}
        size="md"
      >
        <p style={{ textAlign: 'center' }}>
          Você realmente deseja homologar estas escalas?
        </p>

        <PanelBottomActions>
          <Button leftIcon={<FaTimes />} colorScheme="red">
            Não
          </Button>
          <Button
            leftIcon={<FaCheck />}
            colorScheme="green"
            onClick={handleCriarHomologacoesEscalas}
            isLoading={disabledRequest}
          >
            Sim
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        title="Abrir Escala"
        isOpen={isModalAbrirEscalaOpen}
        onClose={onCloseModalAbrirEscala}
        size="md"
      >
        <p style={{ textAlign: 'center' }}>
          Você realmente deseja abrir esta escala?
        </p>

        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            colorScheme="red"
            onClick={onCloseModalAbrirEscala}
          >
            Não
          </Button>
          <Button
            leftIcon={<FaUnlockAlt />}
            colorScheme="green"
            onClick={() => handleAbrirEscala(idEscala as number)}
            isLoading={disabledRequest}
          >
            Sim
          </Button>
        </PanelBottomActions>
      </Modal>

      <Modal
        title="Autorizar Escala"
        isOpen={isModalConfirmarManyEscalaAtrasadaOpen}
        onClose={handleCloseConfirmarEscala}
        size={isJustificativa ? '6xl' : '2xl'}
      >
        <p style={{ textAlign: 'center' }}>
          {isJustificativa
            ? 'Esta escala está atrasada e a precisa da confirmação da CGO. Digite abaixo a justificativa da não autorização'
            : 'Você realmente deseja autorizar esta escala?'}
        </p>

        <form onSubmit={handleSubmit(handleConfirmarEscala)}>
          <FormGroup cols={[12, 12, 12]} name="Justificativa do escalante">
            <TextArea disabled value={escala?.justificativa} />
          </FormGroup>
          {!isJustificativa && (
            <PanelBottomActions>
              <Button leftIcon={<FaTimes />} colorScheme="yellow">
                Fechar
              </Button>

              <Button
                leftIcon={<MdBlock />}
                colorScheme="red"
                onClick={async () => {
                  setValue('isEscalaAtrasada', true);
                  setIsJustificativa(true);
                }}
              >
                Não autorizo
              </Button>
              <Button
                leftIcon={<FaLock />}
                colorScheme="green"
                type="submit"
                isLoading={disabledRequest}
              >
                Autorizo
              </Button>
            </PanelBottomActions>
          )}

          <Controller
            control={control}
            name="isEscalaAtrasada"
            defaultValue={isJustificativa}
            render={({ value }) => (
              <Checkbox isChecked={!!value} visibility="hidden">
                Sim
              </Checkbox>
            )}
          />

          {isJustificativa && (
            <>
              <Controller
                control={control}
                name="justificativa"
                render={({ value, onChange }) => (
                  <TextArea
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    error={errors.justificativa?.message}
                    disabled={!isJustificativa}
                    placeholder="Insira aqui sua justificativa"
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                  />
                )}
              />

              <PanelBottomActions>
                <Button
                  leftIcon={<FaArrowLeft />}
                  colorScheme="yellow"
                  onClick={() => {
                    setValue('isEscalaAtrasada', false);

                    setIsJustificativa(false);
                  }}
                >
                  Voltar
                </Button>
                <Button
                  leftIcon={<MdBlock />}
                  colorScheme="red"
                  type="submit"
                  isLoading={disabledRequest}
                >
                  Não Autorizo
                </Button>
              </PanelBottomActions>
            </>
          )}
        </form>
      </Modal>

      <Modal
        isOpen={isModalErroOpen}
        onClose={handdleCloseModalError}
        size="4xl"
        title="Erro"
      >
        {errorMessage?.message as string}
        <DataTable columns={errorColumns} data={errorMessage?.values || []} />
      </Modal>

      <Modal
        isOpen={isModalRegistrarFaltasOpen}
        onClose={onCloseModalRegistrarFaltas}
        size="6xl"
        title="Registrar Faltas"
      >
        <DataTable
          columns={columnsEquipesVoluntarios}
          data={voluntarios.reduce(
            (newArrEquipeVoluntarios, equipeVoluntarios) => [
              ...newArrEquipeVoluntarios,
              ...equipeVoluntarios,
            ],
            [],
          )}
          options={optionsRegistrarFaltas}
        />

        <PanelBottomActions>
          <Button
            leftIcon={<FaTimes />}
            colorScheme="red"
            onClick={onCloseModalRegistrarFaltas}
          >
            Cancelar
          </Button>
          <Button
            leftIcon={<MdPersonOff />}
            colorScheme="yellow"
            onClick={handleCriarFaltasVoluntarioEscala}
            isLoading={disabledRequest}
            disabled={
              disabledRequestDesfazerFalta || disabledRequestTodosPresentes
            }
          >
            Registrar faltas
          </Button>

          <Button
            leftIcon={<MdPerson />}
            colorScheme="blue"
            onClick={handleDesfazerFaltasVoluntarioEscala}
            isLoading={disabledRequestDesfazerFalta}
            isDisabled={disabledRequestTodosPresentes || disabledRequest}
          >
            Desfazer faltas/presenças
          </Button>

          <Button
            leftIcon={<BsFillPersonCheckFill />}
            colorScheme="green"
            onClick={handleRegistrarPresencasEscala}
            isLoading={disabledRequestTodosPresentes}
            isDisabled={disabledRequestDesfazerFalta || disabledRequest}
          >
            Todos Presentes
          </Button>
        </PanelBottomActions>
      </Modal>

      <ModalAssinatura
        isOpen={isModalAssinaturaConcluirEscalaOpen}
        onClose={onCloseModalAssinaturaConcluirEscala}
        onSubmit={handleConluirEscalaAssinatura}
        size="2xl"
        agreementTerm="Confirmo que analisei a escala, conferi se todos os PMs presentes assinaram, bem como marquei no sistema o registro de falta caso tenha havido."
      />

      <Modal
        isOpen={modalFiscaisEscala.isOpen}
        isCentered
        size="2xl"
        title="Relação de fiscais locais"
        onClose={handleCloseFiscaisEscala}
      >
        <Box
          as="form"
          onSubmit={handleSubmitFiscais(handleEditarEquipesFiscais)}
        >
          {fiscais.current.map((f, index) => (
            <Flex key={JSON.stringify(f)} mt={4} direction="column">
              <Controller
                control={controlFiscais}
                name={`fiscais.${index}.id`}
                defaultValue={f.id_equipe}
                render={() => (
                  <Text
                    colorScheme={
                      errorsFiscais.fiscais?.[index]?.id?.message
                        ? 'red'
                        : 'blackAlpha'
                    }
                    fontWeight="bold"
                  >
                    Equipe {(index + 1).toString().padStart(2, '0')}/
                    {fiscais.current.length.toString().padStart(2, '0')} -{' '}
                    {f.nome} - {f.areas.join(',')}
                  </Text>
                )}
              />
              <Spacer />
              <Controller
                control={controlFiscais}
                name={`fiscais.${index}.pm_codigo_fiscal_local`}
                defaultValue={f.pm_codigo_fiscal_local}
                render={({ onChange }) => (
                  <AsyncSelect
                    name="pm_codigo"
                    label="Pesquisar Pm"
                    value={{
                      label: f.fiscalEquipe?.dados,
                      value: f.pm_codigo_fiscal_local,
                    }}
                    loadOptions={(inputValue: any) => delayedQuery(inputValue)}
                    onChange={(valueOpm: OptionType) => {
                      // onChange(valueOpm.value);
                      onChange(valueOpm.value);
                      // eslint-disable-next-line no-param-reassign
                      f = {
                        ...f,
                        fiscalEquipe: { dados: valueOpm.label },
                        pm_codigo_fiscal_local: valueOpm.value,
                      };
                    }}
                    isDisabled={
                      !['CGO', 'CETIC', 'ESCALANTE'].includes(
                        user.currentPerfil as string,
                      )
                    }
                    error={
                      errorsFiscais.fiscais?.[index]?.pm_codigo_fiscal_local
                        ?.message
                    }
                  />
                )}
              />
            </Flex>
          ))}

          <PanelBottomActions>
            <Button
              type="button"
              colorScheme="yellow"
              leftIcon={<FaTimes />}
              onClick={handleCloseFiscaisEscala}
            >
              Fechar
            </Button>

            {['CETIC', `CGO`, 'ESCALANTE'].includes(
              user.currentPerfil as string,
            ) && (
              <Button
                type="submit"
                colorScheme="green"
                leftIcon={<FaPencilAlt />}
              >
                Salvar
              </Button>
            )}
          </PanelBottomActions>
        </Box>
      </Modal>
    </>
  );
};

export default ListarEscalasIso;
