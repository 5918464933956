import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Divider } from '@chakra-ui/react';
import { Box, Text } from '@chakra-ui/react';
import api from '../../services/api';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type OptionType = { label: string; value: string };

interface ITipotrava {
  nome: string;
}

interface IUnidade {
  uni_nome: string;
}

interface ITravaPm {
  id_trava_pm: number;
  pm_codigo: number;
  tipo_trava: number;
  tipoTrava: ITipotrava;
  unidade: IUnidade;
  criado_em: Date;
  criado_por: string;
  pessoaPm: { pessoa: { pes_nome: string } };
  data_inicio: string;
  data_fim: string;
  observacao?: string;
}

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

const defaultFilters: IFilters[] = [
  {
    type: 'date' as 'date' | 'select',
    field: 'data_inicio',
    label: 'Data Início',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'date' as 'date' | 'select',
    field: 'data_fim',
    label: 'Data Final',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },
];

const ListEscalasFeriados: React.FC = () => {
  const { user } = useAuth();

  const [, setTipoTravaModal] = useState<ITravaPm>();
  const [grandesComandos] = useState<OptionType[]>([]);

  const [filters, setFilters] = useState<IFilters[]>(defaultFilters);

  const colunas: IColumns = [
    {
      field: 'sequencia',
      text: 'Sequência',
      type: { name: 'text' },
    },
    {
      field: 'uni_sigla',
      text: 'Unidade',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
    {
      field: 'data_inicio',
      text: 'Data Inicial',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
    {
      field: 'data_fim',
      text: 'Data Final',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
    {
      field: 'feriado_inicio',
      text: 'Feriado Início',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
    {
      field: 'feriado_fim',
      text: 'Feriado Fim',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
  ];

  const options = {
    serverData: {
      url: `/escalas/irsos/feriados`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },

    filters,

    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  // useEffect(() => {
  //   if (Number(user.currentOpm?.uni_codigo as string) === -1) {
  //     setFilters([
  //       {
  //         type: 'select' as 'date' | 'select',
  //         field: 'grandes_comandos',
  //         label: 'Grande Comando',
  //         options: [{ label: 'Todos', value: '' }, ...grandesComandos],
  //         defaultOption: '',

  //         cols: [2, 6, 12] as [number, number, number],
  //       },
  //       ...defaultFilters,
  //     ]);
  //   }
  // }, [user.currentOpm, grandesComandos]);

  return (
    <>
      <>
        <TituloPagina title="Escalas Feriados" />
        <BoxContent>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        </BoxContent>
      </>
    </>
  );
};

export default ListEscalasFeriados;
