import { Box, Button, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaSearch } from 'react-icons/fa';
import {
  HiOutlineDocumentReport,
  HiOutlineDocumentSearch,
} from 'react-icons/hi';
import * as yup from 'yup';
import api from '../../services/api';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import Row from '../../components/form/Row';
import TituloPagina from '../../components/TituloPagina';

type IFormInputs = { data_inicio: Date; data_fim: Date };

type IResponse = {
  id_escala_anexo: number;
  data_inicio: Date;
  data_fim: Date;
  url: string;
  criado_por: string;
  criado_em: string;
  atualizado_por: string;
  atualizado_em: string;
  deletado: number;
};

const schema = yup.object().shape({
  data_inicio: yup.date().required(),
  data_fim: yup
    .date()
    .required()
    .min(
      yup.ref('data_inicio'),
      'Data final deve ser no minimo igual a data inicial',
    ),
});

export const RelatorioFinanceiroAtualizarIrsoPromocao: React.FC = () => {
  const toast = useToast();

  const { handleSubmit, control, errors, reset } = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });

  const [documentos, setDocumentos] = useState<IResponse[]>([]);

  const columns: IColumns = [
    {
      field: 'data_inicio',
      text: 'Data Início',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
    {
      field: 'data_fim',
      text: 'Data Início',
      type: { name: 'date', format: 'dd/MM/yyyy' },
    },
  ];

  const options = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <HiOutlineDocumentSearch size={18} />,
          tooltip: 'Visualizar documento',
          getRow: (data: IResponse) => window.open(data.url, '__blank'),
        },
      ],
    },
  };

  const urlRelatorio = 'escalas/irsos/relatorios/atualizar_escalas_promocoes';

  const handleAtualizarIrsoPromocoes = async (
    form: IFormInputs,
  ): Promise<void> => {
    try {
      const {
        data: [{ url }],
      } = await api.post<{ url: string }[]>(urlRelatorio, form);

      toast({
        title: 'Sucesso.',
        description:
          'Escalas atualizadas com promoções de policiais atualizada.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      toast({
        title: 'Sucesso.',
        description: 'Gerando Documento dos policiais...',
        status: 'info',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      window.open(url, '__blank');
    } catch (error) {
      if (error.response?.data) {
        toast({
          title: 'Aviso.',
          description:
            error.response?.data.message || 'Não podemos no momento atualizar',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    }
  };

  const handleVisualizarDocumentosByInterval = async (
    data: IFormInputs,
  ): Promise<void> => {
    try {
      const {
        data: { total, items },
      } = await api.get<{ items: IResponse[]; total: number }>(urlRelatorio, {
        params: data,
      });

      switch (total) {
        case 0:
          toast({
            title: 'Aviso',
            description: 'Anexos não encontrados para este intervalo de data',
            status: 'warning',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          break;

        case 1:
          window.open(items[0].url, '__blank');
          break;

        default:
          setDocumentos(items || []);
          break;
      }

      reset();
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description:
          error.response?.data.message || 'Não podemos no momento atualizar',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  return (
    <>
      <TituloPagina title="Saldo Monetário irso" />
      <BoxContent>
        <TituloPagina title="Selecione o Data inicial para gerar o relatório" />
        <Box as="form" onSubmit={handleSubmit(handleAtualizarIrsoPromocoes)}>
          <Row>
            <FormGroup name="Data início" cols={[4, 6, 12]} required>
              <Controller
                control={control}
                name="data_inicio"
                render={({ value, onChange }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e) => onChange(e as Date)}
                    dateFormat="dd/MM/yyyy"
                    error={errors.data_inicio?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Data fim" required cols={[4, 6, 12]}>
              <Controller
                control={control}
                name="data_fim"
                render={({ value, onChange }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e: Date) =>
                      onChange(
                        new Date(
                          e.getFullYear(),
                          e.getMonth(),
                          e.getDate(),
                          23,
                          59,
                          59,
                        ),
                      )
                    }
                    dateFormat="dd/MM/yyyy"
                    error={errors.data_fim?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>
          <Button
            leftIcon={<FaSearch />}
            colorScheme="linkedin"
            onClick={async () =>
              handleSubmit(handleVisualizarDocumentosByInterval)()
            }
            mr={4}
          >
            Buscar por documentos
          </Button>

          <Button
            leftIcon={<HiOutlineDocumentReport />}
            colorScheme="blue"
            type="submit"
          >
            Atualizar Irso Promocoes
          </Button>
        </Box>

        {documentos.length > 0 && (
          <DataTable columns={columns} options={options} data={documentos} />
        )}
      </BoxContent>
    </>
  );
};
