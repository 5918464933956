import {
  Text,
  Box,
  Divider,
  useDisclosure,
  useToast,
  Button as ButtonChakra,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import {
  FaArrowLeft,
  FaExpandAlt,
  FaFileUpload,
  FaMoneyBill,
  FaRegFileExcel,
  FaTrash,
  FaUndo,
} from 'react-icons/fa';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns-tz';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useAuth } from '../../../contexts/auth';
import TextArea from '../../../components/form/FormTextArea';
import Modal from '../../../components/Modal';
import DataTable, { IColumns } from '../../../components/DataTable';
import api from '../../../services/api';
import BoxContent from '../../../components/BoxContent';
import FormGroup from '../../../components/form/FormGroup';
import FormInput from '../../../components/form/FormInput';
import Row from '../../../components/form/Row';
import TituloPagina from '../../../components/TituloPagina';
import PanelBottomActions from '../../../components/PanelBottomActions';
import FormDatePicker from '../../../components/form/FormDatePicker';
import InputCurrency from '../../../components/form/InputCurrency';
import ReactSelect from '../../../components/form/ReactSelect';
import { InputFile } from '../../../components/form/InputFile';
import { FormTransferirDinheiro } from '../components/FormTransferirDinheiro';

const dataAtual = new Date();

type ICicloSchema = {
  uni_codigo: number;
  ano_ciclo: Date;
  id_categoria: number;
  valor_saldo_Geral: number;
};

// type IGrandesComando = {
//   uni_codigo: number;
//   uni_sigla: string;
// };

type IOption = { value: string; label: string };

type IResponseCategoria = {
  items: { id_categoria: number; nome: string; situacao: '0' | '1' }[];
};

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: IOption[];
  defaultOption?: string;
  cols: [number, number, number];
};

const ciclos = Array.from(Array(12).keys()).map<IOption>((ciclo) => ({
  label: (ciclo + 1).toString().padStart(2, '0'),
  value: (ciclo + 1).toString().padStart(2, '0'),
}));

const anos = Array.from(Array(6).keys())
  .map((i) =>
    i < 5 ? new Date().getFullYear() - i : new Date().getFullYear() + 1,
  )
  .sort()
  .map<IOption>((ano) => ({ label: ano.toString(), value: ano.toString() }));

const defaultFilters: IFilters[] = [
  {
    type: 'select' as 'date' | 'select',

    field: 'ciclo',
    label: 'ciclo',
    options: [{ label: 'Todos', value: '' }, ...ciclos],
    defaultOption: '',
    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'select' as 'date' | 'select',

    field: 'ano',
    label: 'ano',
    options: [{ label: 'Todos', value: '' }, ...anos],
    defaultOption: new Date().getFullYear().toString(),
    cols: [2, 6, 12] as [number, number, number],
  },
];

const estornoSaldo = Yup.object().shape({
  valor: Yup.number().required('Este campo é requerido'),
  observacao: Yup.string()
    .required('Este campo é requerido')
    .min(10, 'Texto muito pequeno'),
  // eslint-disable-next-line no-control-regex
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
});

const createCicloSchema = Yup.object().shape({
  uni_codigo: Yup.number().default(-1).required('Este campo é requerido'),
  ano_ciclo: Yup.date()
    .required('Este campo é requerido')
    .min(new Date(dataAtual.getFullYear(), 0, 1))
    .max(new Date(dataAtual.getFullYear() + 1, 11, 31))
    .typeError('Formato inválido de data'),

  id_categoria: Yup.number().required('Este campo é requerido'),
  valor_saldo_Geral: Yup.number().required('Este campo é requerido'),
});

type IFormTransferenciaSaldo = {
  transferencias: {
    unidade_destino: number;
    valor: number;
    observacao?: string;
  }[];
};

type IFormEstornoSaldo = {
  valor: number;
  observacao: string;
};

type ISaldo = {
  uni_sigla: string;
  nome_categoria: string;
  id_categoria: number;
  ano_ciclo_tran: string;
  mes_ciclo_tran: string;
  saldo: number;
  uni_codigo: number;
  parents: number[];
};

type IPrevisaoSaldo = {
  mes_ciclo_previsao: string;
  ano_ciclo_previsao: string;
  id_categoria: number;
  valor_previsao: number;
  id_previsao_saldo: number;
};

type IResponsePrevisao = {
  items: IPrevisaoSaldo[];
};

type IUnidade = {
  uni_codigo: number;
  uni_sigla: string;
  uni_nome: string;
  grandeComando?: IUnidade;
};

type IUnidadeSaldo = {
  unidade: string;
  valor: number;
  uni_codigo: number;
  nome: string;
  uni_nome_grande_comando?: string;
};

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';

const NovoSaldoGeral: React.FC = () => {
  const toast = useToast();
  const { user } = useAuth();

  const { handleSubmit, errors, control, reset, watch } = useForm<ICicloSchema>(
    {
      resolver: yupResolver(createCicloSchema),
      defaultValues: {
        ano_ciclo: undefined,
        valor_saldo_Geral: undefined,
        id_categoria: undefined,
      },
    },
  );

  const {
    isOpen: isOpenModalEnviarCSV,
    onOpen: onOpenModalEnviarCSV,
    onClose: onCloseModalEnviarCSV,
  } = useDisclosure();

  const history = useHistory();

  const [categorias, setCategorias] = useState<IOption[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenEstorno,
    onOpen: onOpenEstorno,
    onClose: onCloseEstorno,
  } = useDisclosure();

  const [canReload, setCanReload] = useState(true);
  const [dadosSaldo, setDadosSaldo] = useState<ISaldo>({} as ISaldo);
  const [saldoPrevisto, setSaldoPrevisto] = useState(0);
  // const [filters, setFilters] = useState(defaultFilters);
  // const [grandesComandos, setGrandesComandos] = useState<IOption[]>([]);
  const [handleRequest, setHandleRequest] = useState(true);

  const {
    control: controlEstorno,
    errors: errorsEstorno,
    handleSubmit: handleSubmitEstorno,
    watch: watchEstorno,
    reset: resetEstorno,
  } = useForm<IFormEstornoSaldo>({
    resolver: yupResolver(estornoSaldo),
    defaultValues: {
      valor: undefined,
    },
  });

  const unidadeRef = useRef<IUnidadeSaldo[]>([]);
  const [unidadesSaldo, setUnidadesSaldo] = useState<
    (IOption & { valor: number })[]
  >([]);

  const handleCloseModal = (): void => {
    onClose();
    setCanReload(true);
  };

  const handleCloseModalEnviarArquivo = (): void => {
    setUnidadesSaldo([]);
    onCloseModalEnviarCSV();
  };

  const handleCloseModalEstorn0 = (): void => {
    onCloseEstorno();
    setCanReload(true);
  };

  const columns: IColumns = [
    {
      field: 'uni_sigla',
      text: 'Opm',
      type: { name: 'text' },
      alias: 'saldosOpms.uni_prioridade',
    },
    {
      field: 'nome_categoria',
      text: 'Categoria',
      type: { name: 'text' },
      alias: 'saldosOpms.nome_categoria',
    },
    {
      field: 'mes_ciclo_tran',
      text: 'Mês',
      type: { name: 'text' },

      alias: 'saldosOpms.mes_ciclo_tran',
    },
    {
      field: 'ano_ciclo_tran',
      text: 'Ano',
      type: { name: 'text' },

      alias: 'saldosOpms.ano_ciclo_tran',
    },
    {
      field: 'saldo',
      text: 'Saldo',
      type: { name: 'currency' },
      alias: 'saldosOpms.saldo',
    },
  ];

  const handleRequestOpms = async (): Promise<IUnidadeSaldo[]> => {
    const {
      data: { unidades },
    } = await api.get<{ unidades: IUnidade[] }>('/unidades/async');

    return unidades
      .filter(({ uni_codigo }) => uni_codigo !== -1)
      .map(({ uni_sigla, uni_codigo, uni_nome, grandeComando }) => ({
        unidade: uni_sigla,
        valor: 0,
        uni_codigo,
        nome: uni_nome,
        uni_nome_grande_comando: grandeComando?.uni_nome,
      }));
  };

  const handleGenerateCSV = async ({
    mes_ciclo_tran,
    ano_ciclo_tran,
  }: ISaldo): Promise<void> => {
    if (!unidadeRef.current.length) {
      unidadeRef.current = await handleRequestOpms();
    }

    const ws = XLSX.utils.json_to_sheet(
      unidadeRef.current.map(
        ({ unidade, valor, nome, uni_nome_grande_comando }) => ({
          nome,
          unidade,
          'grande comando': uni_nome_grande_comando,
          valor,
        }),
      ),
    );

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(
      data,
      `${mes_ciclo_tran}-${ano_ciclo_tran}${fileExtension}`,
    );
  };

  const options = {
    isOpenFetch: canReload,
    serverData: {
      url: '/saldos/geral',
      headers: { Authorization: api.defaults.headers.authorization },
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
      serverPagination: true,
    },
    filters: defaultFilters,
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaArrowLeft size={13} />,
          handleShowAction: (row: ISaldo) => {
            const idOpm = Number(user.currentOpm?.uni_codigo);

            return idOpm === -1 && row.uni_codigo === -1;
          },

          getRow: (data: ISaldo) => {
            setCanReload(false);
            if (data.uni_codigo !== -1) {
              toast({
                title: 'Erro!',
                description: 'Somente a unidade PMCE pode realizar estorno!',
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
              setCanReload(true);
            } else {
              setDadosSaldo(data);
              onOpenEstorno();
            }
          },
          tooltip: 'Estornar',
        },
        {
          icon: <FaMoneyBill size={18} />,
          // handleShowAction: (row: ISaldo) => {
          //   const idOpm = Number(user.currentOpm?.uni_codigo);

          //   return (
          //     row.parents.includes(idOpm) ||
          //     idOpm === -1 ||
          //     row.uni_codigo === idOpm
          //   );
          // },
          getRow: (data: ISaldo) => {
            setDadosSaldo(data);
            setCanReload(false);
            onOpen();
          },
          tooltip: 'Transferir',
        },

        {
          icon: <FaRegFileExcel size={18} />,
          getRow: handleGenerateCSV,
          tooltip: 'Gerar planilha para transferencia',
          handleShowAction: (row: ISaldo) => {
            const idOpm = Number(user.currentOpm?.uni_codigo);

            return idOpm === -1 && row.uni_codigo === -1;
          },
        },
        {
          icon: <FaFileUpload size={18} />,
          getRow: async (dados: ISaldo) => {
            setDadosSaldo(dados);

            if (!unidadeRef.current.length) {
              const dataCsv = await handleRequestOpms();

              unidadeRef.current = dataCsv;
            }

            onOpenModalEnviarCSV();
          },
          tooltip: 'Enviar planilha para transferencia',
          handleShowAction: (row: ISaldo) => {
            const idOpm = Number(user.currentOpm?.uni_codigo);

            return idOpm === -1 && row.uni_codigo === -1;
          },
        },
      ],
    },
  };

  const handleEstornoSaldo = async ({
    valor,
    observacao,
  }: IFormEstornoSaldo): Promise<void> => {
    const { uni_codigo, ...rest } = dadosSaldo;
    try {
      setHandleRequest(false);

      await api.post('/saldos/geral/estorno', {
        valor,
        observacao,
        ...rest,
        unidade_origem: uni_codigo,
        data_operacao: new Date(),
      });

      resetEstorno();
      toast({
        title: 'Sucesso.',
        description: 'Estorno criada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setHandleRequest(true);

      handleCloseModalEstorn0();
    } catch (error) {
      setHandleRequest(true);

      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const createTransferencias = async ({
    transferencias,
  }: IFormTransferenciaSaldo): Promise<boolean> => {
    try {
      setHandleRequest(false);
      await api.post('/saldos', {
        ano: dadosSaldo.ano_ciclo_tran,
        mes: dadosSaldo.mes_ciclo_tran,
        id_categoria: dadosSaldo.id_categoria,
        unidade_origem: dadosSaldo.uni_codigo,
        transferencias: transferencias.map((transferencia) => ({
          ...transferencia,
          data_transferencia: new Date(),
        })),
      });
      toast({
        title: 'Sucesso.',
        description: 'Transferência criada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setHandleRequest(true);

      handleCloseModal();
      return true;
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setHandleRequest(true);
      return false;
    }
  };

  const createSaldoGeral = async ({
    ano_ciclo,
    ...rest
  }: ICicloSchema): Promise<void> => {
    try {
      setHandleRequest(false);
      await api.post('saldos/geral', {
        ano_ciclo_ger: ano_ciclo.getFullYear().toString(),
        mes_ciclo_ger: (ano_ciclo.getMonth() + 1).toString().padStart(2, '0'),
        data_operacao_saldo_geral: new Date(),
        id_tipo_operacao: 3,
        ...rest,
      });
      const categoria = categorias.find(
        (categoria2) => categoria2.value === rest.id_categoria.toString(),
      );

      toast({
        title: 'Sucesso.',
        description: `Saldo ${
          categoria && `da ${categoria.label}`
        } criado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      reset();
      setCanReload(true);
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setHandleRequest(true);
    }
  };

  const handleFileImportTransferencia = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    e.stopPropagation();
    e.preventDefault();

    const file = e.target.files?.[0];
    const element = e;

    try {
      if (!file) {
        element.currentTarget.value = '';
        throw new Error('Arquivo não enviado');
      }

      if (
        ![
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(file.type as string)
      ) {
        element.currentTarget.value = '';
        throw new Error('Arquivo não e csv');
      }

      const f = await file.arrayBuffer();
      const wb = XLSX.read(f, { type: 'array' });

      const unidades: IUnidadeSaldo[] = (XLSX.utils.sheet_to_json(
        wb.Sheets['data' as string],
      ) as IUnidadeSaldo[]).filter(({ unidade }) => unidade !== 'PMCE');

      if (
        Object.keys(unidades[0]).some(
          (colName) =>
            !['nome', 'unidade', 'grande comando', 'valor'].includes(colName),
        )
      ) {
        e.target.value = '';
        throw new Error(
          `Cabeçalho não identificados. Apenas unidade e valor como nomes de cabeçalhos`,
        );
      }

      const unidadeNotSanitizedSaldo = unidades.find((unidade) => {
        const valor = Number.parseFloat(unidade?.valor.toString() || '');

        return Number.isNaN(valor);
      });

      if (unidadeNotSanitizedSaldo)
        throw new Error(
          `Unidade ${unidadeNotSanitizedSaldo.unidade} não possui saldo no formato numérico`,
        );

      const unidadesNaoEncontradas = unidades
        .filter(
          ({ unidade: unidadeUploaded, uni_codigo }) =>
            !unidadeRef.current.find(
              ({ unidade }) => unidadeUploaded === unidade,
            ) && uni_codigo !== -1,
        )
        .map(({ unidade }) => unidade);

      if (unidadesNaoEncontradas.length)
        throw new Error(
          `Os saldos não foram inseridos pois as seguintes unidades não existentem: ${unidadesNaoEncontradas.join(
            ',',
          )}`,
        );

      setUnidadesSaldo(
        unidades.map(({ unidade, valor }) => ({
          label: unidade,
          valor,
          value: unidadeRef.current
            .find(({ unidade: unidade2 }) => unidade === unidade2)
            ?.uni_codigo.toString() as string,
        })),
      );
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description:
          (error as Error).message ||
          `Ocorreu um erro inesperado ao enviar o arquivo. Por favor, tente mais tarde`,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    const loadCategorias = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<IResponseCategoria>('categorias');
      setCategorias(
        items
          .filter((item) => item.situacao === '1')
          .map((item) => ({
            label: item.nome,
            value: item.id_categoria.toString(),
          })),
      );
    };

    loadCategorias();
  }, []);

  const anoCiclo = watch('ano_ciclo');
  const idCategoria = watch('id_categoria');

  useEffect(() => {
    const loadPrevisaoSaldo = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<IResponsePrevisao>('previsoes', {
        params: {
          mes: format(anoCiclo, 'MM', { timeZone: 'America/Fortaleza' }),
          ano: format(anoCiclo, 'yyyy', { timeZone: 'America/Fortaleza' }),
          id_categoria: idCategoria,
        },
      });

      if (items.length < 1)
        toast({
          title: 'Aviso',
          description:
            'Não existe uma previsão de saldo para esta categoria no ciclo escolhido',
          status: 'warning',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      else setSaldoPrevisto(Number(items[0]?.valor_previsao));
    };

    if (anoCiclo && idCategoria) {
      loadPrevisaoSaldo();
    }
  }, [anoCiclo, idCategoria, toast]);

  // useEffect(() => {
  //   const loadGrandesComandos = async (): Promise<void> => {
  //     const {
  //       data: { items },
  //     } = await api.get<{ items: IGrandesComando[] }>(
  //       '/unidades/grandes_comandos',
  //     );

  //     setGrandesComandos(
  //       items.map((item) => ({
  //         label: item.uni_sigla,
  //         value: item.uni_codigo.toString(),
  //       })),
  //     );
  //   };

  //   loadGrandesComandos();
  // }, []);

  // useEffect(() => {
  //   if (Number(user.currentOpm?.uni_codigo as string) === -1) {
  //     setFilters([
  //       {
  //         type: 'select' as 'date' | 'select',
  //         field: 'grandes_comandos',
  //         label: 'Grande Comando',
  //         options: [{ label: 'Todos', value: '' }, ...grandesComandos],
  //         defaultOption: '',

  //         cols: [2, 6, 12] as [number, number, number],
  //       },
  //       ...defaultFilters,
  //     ]);
  //   } else setFilters(defaultFilters);
  // }, [user.currentOpm, grandesComandos]);

  return (
    <>
      <TituloPagina title="Saldo DRSO - PMCE" />

      <BoxContent>
        <TituloPagina title="Inserção de Saldo para ciclo" />
        <Divider orientation="horizontal" />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            setCanReload(true);
            handleSubmit(createSaldoGeral)();
          }}
        >
          <Row>
            <FormGroup name="Opm" cols={[2, 6, 12]}>
              <FormInput disabled value="Polícia Militar do Ceará" />
            </FormGroup>

            <FormGroup name="Ciclo/Ano" required cols={[2, 6, 6]}>
              <Controller
                name="ano_ciclo"
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <FormDatePicker
                      selected={value}
                      onChange={(e) => {
                        setCanReload(false);
                        onChange(e);
                      }}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                      minDate={new Date(dataAtual.getFullYear(), 0, 1)}
                      maxDate={new Date(dataAtual.getFullYear() + 1, 11, 31)}
                      error={errors.ano_ciclo?.message}
                    />
                  );
                }}
              />
            </FormGroup>

            <FormGroup name="Categoria" required cols={[2, 6, 12]}>
              <Controller
                name="id_categoria"
                control={control}
                render={({ onChange, value }) => (
                  <ReactSelect
                    optionsSelect={categorias}
                    error={errors.id_categoria?.message}
                    onChange={(option: IOption) => {
                      setCanReload(false);
                      onChange(Number(option.value));
                    }}
                    value={categorias.find(
                      (categoria) => categoria.value === value?.toString(),
                    )}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Valor Previsto" cols={[3, 6, 12]}>
              <InputCurrency
                value={saldoPrevisto}
                disabled
                error={errors.valor_saldo_Geral?.message}
                onChange={(e, value) => console.log(value)}
              />
            </FormGroup>

            <FormGroup name="Valor" required cols={[3, 6, 12]}>
              <Controller
                name="valor_saldo_Geral"
                control={control}
                render={({ onChange, value }) => (
                  <InputCurrency
                    value={value}
                    onChange={(e, value2) => onChange(value2)}
                    error={errors.valor_saldo_Geral?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>
          <PanelBottomActions>
            <ButtonChakra
              leftIcon={<FaExpandAlt />}
              type="button"
              onClick={() => history.push('/saldogeral')}
              colorScheme="blue"
            >
              {' '}
              Detalhes
            </ButtonChakra>

            <ButtonChakra
              leftIcon={<FaTrash />}
              type="button"
              onClick={() => {
                reset();
              }}
              colorScheme="yellow"
            >
              {' '}
              Limpar
            </ButtonChakra>

            <ButtonChakra
              leftIcon={<FaMoneyBill />}
              type="submit"
              colorScheme="green"
              isLoading={!handleRequest}
            >
              Inserir
            </ButtonChakra>
          </PanelBottomActions>
        </form>

        <Divider orientation="horizontal" border="2px" />
        <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
          <Text
            color="#666"
            fontWeight="600"
            fontSize={{ sm: '1rem', lg: '1.3rem' }}
          >
            Listagem de Saldos - {user.currentOpm?.uni_sigla}{' '}
            {user.verSubunidade === '1' && 'e subunidades'}
          </Text>
        </Box>

        <Box>
          <DataTable columns={columns} options={options} />
        </Box>
      </BoxContent>

      <Modal
        size="2xl"
        title="Transferências de Saldo em lote"
        isOpen={isOpen}
        onClose={handleCloseModal}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Categoria:</p>
            <p>{dadosSaldo.nome_categoria}</p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Saldo:</p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              }).format(dadosSaldo.saldo)}
            </p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Ciclo:</p>
            <p>{`${dadosSaldo.mes_ciclo_tran}/${dadosSaldo.ano_ciclo_tran}`}</p>
          </div>
        </div>
        <FormTransferirDinheiro
          saldo={dadosSaldo.saldo}
          handleValidForm={createTransferencias}
          isLoadingSubmit={!handleRequest}
        />
      </Modal>

      <Modal
        isOpen={isOpenEstorno}
        onClose={handleCloseModalEstorn0}
        title="Estorno"
        size="xl"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Categoria:</p>
            <p>{dadosSaldo.nome_categoria}</p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Saldo:</p>
            <p>
              {new Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              }).format(dadosSaldo.saldo)}
            </p>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p style={{ fontWeight: 'bold' }}> Ciclo:</p>
            <p>{`${dadosSaldo.mes_ciclo_tran}/${dadosSaldo.ano_ciclo_tran}`}</p>
          </div>
        </div>
        <form onSubmit={handleSubmitEstorno(handleEstornoSaldo)}>
          <Row>
            <FormGroup name="Valor" required cols={[12, 12, 12]}>
              <Controller
                name="valor"
                control={controlEstorno}
                render={({ onChange, value }) => (
                  <InputCurrency
                    value={value}
                    onChange={(e, value2) => onChange(value2)}
                    error={errorsEstorno.valor?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup name="Justificativa" required cols={[12, 12, 12]}>
              <Controller
                name="observacao"
                control={controlEstorno}
                render={({ onChange, value }) => (
                  <TextArea
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                    error={errorsEstorno.observacao?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>
          <Text>
            Saldo:{' '}
            <span
              style={{
                color:
                  dadosSaldo.saldo - (watchEstorno('valor') || 0) >= 0
                    ? 'green'
                    : 'red',
              }}
            >
              {new Intl.NumberFormat('pt-BR', {
                currency: 'BRL',
                style: 'currency',
              }).format(dadosSaldo.saldo - (watchEstorno('valor') || 0))}
            </span>
          </Text>
          <PanelBottomActions>
            <ButtonChakra
              leftIcon={<FaMoneyBill />}
              type="submit"
              colorScheme="green"
              isLoading={!handleRequest}
            >
              Estornar
            </ButtonChakra>
          </PanelBottomActions>
        </form>
      </Modal>

      <Modal
        size="2xl"
        title="Transferências de Saldo em lote via CSV"
        isOpen={isOpenModalEnviarCSV}
        onClose={handleCloseModalEnviarArquivo}
      >
        <InputFile
          name="Enviar arquivo"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          required
          onChange={handleFileImportTransferencia}
        />

        {!!unidadesSaldo.length && (
          <PanelBottomActions>
            <ButtonChakra
              colorScheme="yellow"
              type="button"
              onClick={handleCloseModalEnviarArquivo}
              leftIcon={<FaUndo />}
            >
              Fechar
            </ButtonChakra>

            <ButtonChakra
              leftIcon={<FaMoneyBill />}
              type="button"
              onClick={() => {
                createTransferencias({
                  transferencias: unidadesSaldo
                    .filter(({ valor }) => valor > 0)
                    .map(({ value, valor }) => ({
                      unidade_destino: Number.parseInt(value, 10),
                      valor,
                    })),
                });
              }}
              colorScheme="green"
              // isLoading={!handleRequest}
              isLoading={!handleRequest}
            >
              Transferir
            </ButtonChakra>
          </PanelBottomActions>
        )}
      </Modal>
    </>
  );
};

export default NovoSaldoGeral;
