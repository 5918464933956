/* eslint-disable import/no-extraneous-dependencies */
import { useToast } from '@chakra-ui/toast';
import { useCallback, useMemo, useState } from 'react';
import { IColumns } from '../../../components/DataTable';
import api from '../../../services/api';

type IVeiculo = {
  dados: string;
  dados1: string;
  vei_codigo: number;
};

type IContextVeiculos = {
  equipeVeiculos: {
    id_equipe: number;
    veiculos: (IVeiculo & { id_equipe_veiculo: number })[];
  }[];
  columns: IColumns;

  loadEquipesVeiculos: (idsEquipes: number[]) => Promise<void>;

  addEquipeVeiculo: (
    idEquipe: number,
    data: IVeiculo & { id_equipe_veiculo: number },
  ) => void;
  removeEquipeVeiculos: (idEquipe: number) => void;
  removeVeiculo: (idVeiculo: number) => Promise<boolean>;
};

export const useVeiculos = (idEscala: number): IContextVeiculos => {
  const toast = useToast();

  const [equipeVeiculos, setEquipeVeiculos] = useState<
    {
      id_equipe: number;
      veiculos: (IVeiculo & { id_equipe_veiculo: number })[];
    }[]
  >([]);

  const columns = useMemo(
    () =>
      [
        {
          field: 'dados1',
          text: 'Dados',
          type: { name: 'text' },
        },

        // {
        //   field: 'modelo',
        //   text: 'Modelo',
        //   type: { name: 'text' },
        // },

        // {
        //   field: 'placa',
        //   text: 'Placa',
        //   type: { name: 'text' },
        // },

        // {
        //   field: 'numero',
        //   text: 'Identificador',
        //   type: { name: 'text' },
        // },
      ] as IColumns,
    [],
  );

  const addEquipeVeiculo = useCallback(
    (idEquipe: number, data: IVeiculo & { id_equipe_veiculo: number }) => {
      const indexEquipeVeiculo = equipeVeiculos.findIndex(
        ({ id_equipe }) => idEquipe === id_equipe,
      );

      if (indexEquipeVeiculo < 0)
        setEquipeVeiculos([
          ...equipeVeiculos,
          {
            id_equipe: idEquipe as number,
            veiculos: [data],
          },
        ]);
      else {
        equipeVeiculos[indexEquipeVeiculo].veiculos = [
          ...equipeVeiculos[indexEquipeVeiculo].veiculos,
          data,
        ];

        const updatedequipeVeiculos = [...equipeVeiculos];

        setEquipeVeiculos(updatedequipeVeiculos);
      }

      toast({
        title: 'Sucesso.',
        description: `Veículo adicionado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    },
    [equipeVeiculos, toast],
  );

  const loadEquipesVeiculos = useCallback(
    async (idsEquipes: number[]) => {
      let equipesVeiculosResponse = await Promise.all(
        idsEquipes.map(async (idEquipe) => {
          const {
            data: { items: veiculos },
          } = await api.get<{
            items: {
              id_equipe_veiculo: number;
              id_veiculo: number;
            }[];
          }>(`escalas/irsos/${idEscala}/equipes/${idEquipe}/equipe_veiculos`);

          return {
            id_equipe: idEquipe,
            veiculos: await Promise.all(
              veiculos.map(async ({ id_equipe_veiculo, id_veiculo }) => {
                const { data } = await api.get(`veiculos/${id_veiculo}`);

                return {
                  id_equipe_veiculo,
                  ...data,
                };
              }),
            ),
          };
        }),
      );

      setEquipeVeiculos(equipesVeiculosResponse);

      equipesVeiculosResponse = [];
    },
    [idEscala],
  );

  const removeEquipeVeiculos = useCallback(
    (idEquipe: number) => {
      setEquipeVeiculos(
        equipeVeiculos.filter(({ id_equipe }) => id_equipe !== idEquipe),
      );
    },
    [equipeVeiculos],
  );

  const removeVeiculo = async (idVeiculo: number): Promise<boolean> => {
    const indexEquipe = equipeVeiculos.findIndex(
      ({ veiculos }) =>
        !!veiculos.find(
          ({ id_equipe_veiculo }) => id_equipe_veiculo === idVeiculo,
        ),
    );

    if (indexEquipe < 0) {
      toast({
        title: 'Erro.',
        description: 'Equipe não encontrada',
        status: 'error',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });

      return false;
    }

    await api.delete(
      `escalas/irsos/${idEscala}/equipes/${equipeVeiculos[indexEquipe].id_equipe}/equipe_veiculos/${idVeiculo}`,
    );

    equipeVeiculos[indexEquipe].veiculos = equipeVeiculos[
      indexEquipe
    ].veiculos.filter(
      ({ id_equipe_veiculo }) => id_equipe_veiculo !== idVeiculo,
    );
    const updatedequipeVeiculos = [...equipeVeiculos];

    setEquipeVeiculos(updatedequipeVeiculos);

    toast({
      title: 'Sucesso.',
      description: `Veículo deletado com sucesso!`,
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top-right',
    });

    return true;
  };

  return {
    equipeVeiculos,
    columns,
    addEquipeVeiculo,
    loadEquipesVeiculos,
    removeEquipeVeiculos,
    removeVeiculo,
  };
};
