import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  HStack,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';

import {
  FaCashRegister,
  FaCheck,
  FaCheckDouble,
  FaList,
  FaLock,
  FaMoneyBillAlt,
  FaTimes,
  FaUnlockAlt,
} from 'react-icons/fa';
import { GiPayMoney, GiPoliceOfficerHead } from 'react-icons/gi';
import { BsClock } from 'react-icons/bs';
import { compareAsc, parseISO } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { ListarEscalasPolicial } from '../EscalasPolicial';
import api from '../../services/api';
import FormDatePicker from '../../components/form/FormDatePicker';
import { Select } from '../../components/form/Select';
import { BoxPanel } from '../../components/BoxPanel';
import BoxContent from '../../components/BoxContent';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type IResponseQuantitativo = {
  uni_codigo: number;
  situacao_escala: number;
  qtd_situacao: string;
  data_inicio: Date;
};

type IResponseFinanceiro = {
  total_recebido: string;
  total_escalas: string;
  total_transferido: string;
  total_pmce_recebido: string;
  total_pmce_estorno: string;
  uni_codigo: number;
  data_inicio?: string;
  mes_ciclo_tran: string;
  ano_ciclo_tran: string;
  id_categoria: number;
};

type IResponseQuantitativoPms = {
  total_escalados: number;
  total_presentes: number;
  data_inicio: string;
  is_now: '0' | '1';
};

type IResponseCategoria = {
  id_categoria: number;
  nome: string;
};

type IDadosAgora = {
  qtdEscalas: number;
  totalPoliciais: number;
};

type IDadosHoje = IDadosAgora & { totalGasto: number };

type OptionType = { label: string; value: string };

const ciclos = Array.from(Array(12).keys()).map<OptionType>((ciclo) => ({
  label: (ciclo + 1).toString().padStart(2, '0'),
  value: (ciclo + 1).toString(),
}));

const anos = Array.from(Array(6).keys())
  .map((i) =>
    i < 5 ? new Date().getFullYear() - i : new Date().getFullYear() + 1,
  )
  .sort()
  .map<OptionType>((ano) => ({ label: ano.toString(), value: ano.toString() }));

const Home: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const toast = useToast();

  const [ciclo, setCiclo] = useState<string>(
    (new Date().getMonth() + 1).toString().padStart(2, '0'),
  );

  const [ano, setAno] = useState<string>(new Date().getFullYear().toString());
  const [dataInicial, setDataInicial] = useState<Date>();
  const [dataFinal, setDataFinal] = useState<Date>();

  const [statusEscalas, setStatusEscalas] = useState<IResponseQuantitativo[]>(
    [],
  );

  const [categorias, setCategorias] = useState<OptionType[]>([]);
  const [categoria, setCategoria] = useState<string>();

  const [financeirosOpm, setFinanceirosOpm] = useState<IResponseFinanceiro[]>(
    [],
  );

  const [dadosHoje, setDadosHoje] = useState<IDadosHoje>({
    totalGasto: 0,
    totalPoliciais: 0,
    qtdEscalas: 0,
  });

  const [dadosAgora, setDadosAgora] = useState<IDadosAgora>({
    qtdEscalas: 0,
    totalPoliciais: 0,
  });

  const countStatus = useCallback(
    (idStatus: number) =>
      statusEscalas.reduce(
        (total, { situacao_escala, qtd_situacao }) =>
          situacao_escala === idStatus
            ? total + Number.parseInt(qtd_situacao, 10)
            : total,
        0,
      ),
    [statusEscalas],
  );

  const totalFinanceiroRecebido = financeirosOpm.reduce(
    (total, { total_recebido, total_pmce_recebido }) => {
      const recebidoOpm =
        user.currentOpm?.uni_codigo.toString() === '-1'
          ? Number.parseFloat(total_pmce_recebido)
          : Number.parseFloat(total_recebido);

      return total + recebidoOpm;
    },
    0,
  );

  const totalFinanceiroGastoEscalas = financeirosOpm.reduce(
    (total, { total_escalas }) => {
      const recebidoOpm = Number.parseFloat(total_escalas);
      return total + recebidoOpm;
    },
    0,
  );

  const totalTransferido = financeirosOpm.reduce(
    (total, { total_transferido }) =>
      total + Number.parseFloat(total_transferido),
    0,
  );

  let totalFinanceiroDisponivel =
    totalFinanceiroRecebido -
    totalFinanceiroGastoEscalas -
    financeirosOpm.reduce((total, { total_pmce_estorno }) => {
      let recebidoOpm = 0;

      if (user.currentOpm?.uni_codigo.toString() === '-1')
        recebidoOpm += Number.parseFloat(total_pmce_estorno);

      return total + recebidoOpm;
    }, 0);

  if (user.currentOpm?.uni_codigo.toString() !== '-1')
    totalFinanceiroDisponivel -= totalTransferido;

  const totalAbertas = countStatus(1);
  const totalAtrasadas = countStatus(2);
  const totalFechadas = countStatus(3);
  const totalNaoAutorizadas = countStatus(6);
  const totalConfirmadas = countStatus(4);
  const totalHomologadas = countStatus(5);

  const loadCounts = useCallback(async (): Promise<void> => {
    try {
      const {
        data: { items },
      } = await api.get<{ items: IResponseQuantitativo[] }>(
        `escalas/irsos/relatorios/quantitativos/${user.currentOpm?.uni_codigo}`,
        {
          params: {
            subunidades: user.verSubunidade,
            ciclo: ciclo && ciclo !== '' ? ciclo : undefined,
            ano: ano && ano !== '' ? ano : undefined,
            data_inicio: dataInicial,
            data_fim: dataFinal,
            ids_categorias:
              categoria && categoria !== '' ? categoria : undefined,
          },
        },
      );

      setStatusEscalas(items);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Internal Server Error';
      toast({
        title: 'Sucesso.',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [
    user.currentOpm,
    user.verSubunidade,
    ciclo,
    ano,
    dataInicial,
    dataFinal,
    categoria,
    toast,
  ]);

  const loadFinanceiro = useCallback(async (): Promise<void> => {
    try {
      const {
        data: { items },
      } = await api.get<{ items: IResponseFinanceiro[] }>(
        `escalas/irsos/relatorios/financeiros/${user.currentOpm?.uni_codigo}`,
        {
          params: {
            subunidades: user.verSubunidade,
            ciclo: ciclo !== '' ? ciclo?.padStart(2, '0') : undefined,
            ano: ano !== '' ? ano : undefined,
            ids_categorias:
              categoria && categoria !== '' ? categoria : undefined,
          },
        },
      );

      setFinanceirosOpm(items);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message || 'Internal Server Error';
      toast({
        title: 'Sucesso.',
        description: errorMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [user.currentOpm, user.verSubunidade, ciclo, ano, categoria, toast]);

  useEffect(() => {
    if (user.currentPerfil !== 'USUÁRIO PADRÃO') loadCounts();
  }, [loadCounts, user.currentPerfil]);

  useEffect(() => {
    if (user.currentPerfil !== 'USUÁRIO PADRÃO') loadFinanceiro();
  }, [loadFinanceiro, user.currentPerfil]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      const dataAtualInicio = new Date();
      const dataAtualFinal = new Date();

      dataAtualInicio.setHours(0, 0, 0, 0);
      dataAtualFinal.setHours(23, 59, 59, 59);

      const {
        data: { items },
      } = await api.get<{ items: IResponseQuantitativo[] }>(
        `escalas/irsos/relatorios/quantitativos/${user.currentOpm?.uni_codigo}`,
        {
          params: {
            subunidades: user.verSubunidade,
            data_inicio: dataAtualInicio,
            data_fim: dataAtualFinal,
            ids_categorias:
              categoria && categoria !== '' ? categoria : undefined,
          },
        },
      );

      const {
        data: { items: itemsFinanceiros },
      } = await api.get<{ items: IResponseFinanceiro[] }>(
        `escalas/irsos/relatorios/financeiros/${user.currentOpm?.uni_codigo}`,
        {
          params: {
            subunidades: user.verSubunidade,
            ciclo: (dataAtualInicio.getMonth() + 1).toString().padStart(2, '0'),
            ano: dataAtualInicio.getFullYear(),
            ids_categorias:
              categoria && categoria !== '' ? categoria : undefined,
          },
        },
      );

      const {
        data: { items: quantitativos },
      } = await api.get<{ items: IResponseQuantitativoPms[] }>(
        `escalas/irsos/relatorios/pms/${user.currentOpm?.uni_codigo}`,
        {
          params: {
            subunidades: user.verSubunidade,
            data_inicio: dataAtualInicio,
            data_fim: dataAtualFinal,
            ids_categorias:
              categoria && categoria !== '' ? categoria : undefined,
          },
        },
      );

      const filteredEscalas = items.filter(
        ({ situacao_escala }) => situacao_escala !== 6,
      );

      setDadosHoje({
        qtdEscalas: filteredEscalas.length,
        totalGasto: itemsFinanceiros.reduce(
          (total, { total_escalas, data_inicio }) => {
            if (data_inicio) {
              dataAtualInicio.setHours(0, 0, 0, 0);
              const dataItem = parseISO(data_inicio);

              dataItem.setHours(0, 0, 0, 0);

              if (compareAsc(dataAtualInicio, dataItem) === 0)
                return total + Number.parseFloat(total_escalas);
            }

            return total;
          },
          0,
        ),
        totalPoliciais: quantitativos.reduce(
          (total, { total_escalados, total_presentes }) =>
            total + total_escalados + total_presentes,
          0,
        ),
      });

      setDadosAgora({
        qtdEscalas: quantitativos.filter(({ is_now }) => is_now === '1').length,

        totalPoliciais: quantitativos.reduce(
          (total, { total_escalados, total_presentes, is_now }) =>
            is_now === '1' ? total_escalados + total_presentes + total : total,

          0,
        ),
      });
    };

    if (user.currentPerfil !== 'USUÁRIO PADRÃO') load();
  }, [categoria, user.currentOpm, user.verSubunidade, user.currentPerfil]);

  useEffect(() => {
    const loadCategorias = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IResponseCategoria[] }>('categorias');
      setCategorias([
        { label: 'Todas', value: '' },
        ...items.map(({ nome, id_categoria }) => ({
          label: nome,
          value: id_categoria.toString(),
        })),
      ]);
    };
    if (user.currentPerfil !== 'USUÁRIO PADRÃO') loadCategorias();
  }, [user.currentPerfil]);

  const handleDateStatus = useCallback(() => {
    const dataInicio = new Date();
    const dataFim = new Date();

    const newYear = Number.parseInt(ano, 10);

    dataInicio.setDate(1);
    dataInicio.setFullYear(newYear);
    dataInicio.setHours(0, 0, 0, 0);

    dataFim.setFullYear(newYear);
    dataFim.setHours(23, 59, 59);

    const newCiclo2 = Number.parseInt(String(ciclo), 10);

    if (Number.isNaN(newCiclo2)) {
      dataInicio.setMonth(0);
      dataFim.setMonth(0);

      return {
        dataInicio: new Date(newYear, 0, 1, 0, 0, 0),
        dataFim: new Date(newYear + 1, 0, 0, 23, 59, 59),
      };
    }

    return {
      dataInicio: new Date(newYear, newCiclo2 - 1, 1, 0, 0, 0),
      dataFim: new Date(newYear, newCiclo2, 0, 23, 59, 59),
    };
  }, [ano, ciclo]);

  const handleRedirectListarEscalaIrso = useCallback(
    (idStatus: number) => {
      let { dataFim, dataInicio } = handleDateStatus();

      if (dataInicial) dataInicio = dataInicial;
      if (dataFinal) dataFim = dataFinal;

      history.push('listarescalairso', {
        id_status_escala: idStatus,
        data_inicio: dataInicio,
        data_fim: dataFim,
      });
    },
    [history, dataInicial, dataFinal, handleDateStatus],
  );

  return user.currentPerfil === 'USUÁRIO PADRÃO' ? (
    <ListarEscalasPolicial />
  ) : (
    <>
      <TituloPagina title="Painel Principal" />
      <BoxContent>
        <Tabs variant="enclosed" colorScheme="blackAlpha">
          <TabList borderBottom="2px solid">
            <Tab bg="#ccc">Resumo</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <Box border="0.5px solid #ccc" h="100%" p="4" mb={4}>
                <SimpleGrid gap={4} columns={{ sm: 1, md: 3, lg: 5 }}>
                  <FormControl>
                    <FormLabel>Ciclo</FormLabel>
                    <Select
                      name="ciclo"
                      options={[
                        ...ciclos,
                        { label: 'Todos', value: '' as any },
                      ]}
                      value={ciclo}
                      onChange={(e) => setCiclo(e.currentTarget.value)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Ano</FormLabel>
                    <Select
                      name="ano"
                      options={anos}
                      onChange={(e) => setAno(e.currentTarget.value)}
                      value={ano}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Categorias</FormLabel>
                    <Select
                      name="categorias"
                      options={categorias}
                      onChange={(e) => setCategoria(e.currentTarget.value)}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Data Inicial</FormLabel>
                    <FormDatePicker
                      name="data_inicial"
                      selected={dataInicial}
                      onChange={(e: Date) => setDataInicial(e)}
                      maxDate={dataFinal}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel>Data Final</FormLabel>
                    <FormDatePicker
                      name="data_final"
                      selected={dataFinal}
                      onChange={(e: Date) => setDataFinal(e)}
                      minDate={dataInicial}
                      dateFormat="dd/MM/yyyy"
                      isClearable
                    />
                  </FormControl>
                </SimpleGrid>
              </Box>

              <TituloPagina title="Quantitativos de escala" />
              <Divider
                orientation="horizontal"
                border="1px solid"
                mt={-2}
                mb={2}
              />

              <HStack mb={4} justifyContent="space-between">
                <VStack spacing={8}>
                  <BoxPanel
                    icon={<FaUnlockAlt size={32} />}
                    bgIcon="yellow.300"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(1)}
                  >
                    <Text pl="7">
                      {totalAbertas.toString().padStart(2, '0')}
                    </Text>
                    <Text pl="5">Abertas</Text>
                  </BoxPanel>

                  <BoxPanel
                    icon={<FaCheck size={32} />}
                    bgIcon="blue.500"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(4)}
                  >
                    <Text>{totalConfirmadas.toString().padStart(2, '0')}</Text>
                    <Text>Confirmadas</Text>
                  </BoxPanel>
                </VStack>

                <VStack spacing={8}>
                  <BoxPanel
                    icon={<BsClock size={32} />}
                    bgIcon="orange"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(2)}
                  >
                    <Text pl="3">
                      {totalAtrasadas.toString().padStart(2, '0')}
                    </Text>
                    <Text pl="3">Atrasadas</Text>
                  </BoxPanel>

                  <BoxPanel
                    icon={<FaCheckDouble size={32} />}
                    bgIcon="green.500"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(5)}
                  >
                    <Text>{totalHomologadas.toString().padStart(2, '0')}</Text>
                    <Text>Homologadas</Text>
                  </BoxPanel>
                </VStack>

                <VStack spacing={8}>
                  <BoxPanel
                    icon={<FaLock size={32} />}
                    bgIcon="purple.500"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(3)}
                  >
                    <Text>{totalFechadas.toString().padStart(2, '0')}</Text>
                    <Text>Fechadas</Text>
                  </BoxPanel>

                  <BoxPanel
                    icon={<FaTimes size={32} />}
                    bgIcon="red.500"
                    orientation="horizontal"
                    onClick={() => handleRedirectListarEscalaIrso(6)}
                  >
                    <Text>
                      {totalNaoAutorizadas.toString().padStart(2, '0')}
                    </Text>
                    <Text>Não autorizadas</Text>
                  </BoxPanel>
                </VStack>
              </HStack>

              <TituloPagina title="Financeiro" />
              <Divider
                orientation="horizontal"
                border="1px solid"
                mt={-2}
                mb={2}
              />
              <HStack mb={4} justifyContent="space-between">
                <BoxPanel
                  icon={<FaMoneyBillAlt size={32} />}
                  bgIcon="facebook.500"
                  orientation="horizontal"
                >
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalFinanceiroRecebido)}
                  </Text>
                  <Text>Recebido</Text>
                </BoxPanel>

                <BoxPanel
                  icon={<GiPayMoney size={32} />}
                  bgIcon="red.500"
                  orientation="horizontal"
                >
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalFinanceiroGastoEscalas)}
                  </Text>

                  <Text>Gasto</Text>
                </BoxPanel>

                <BoxPanel
                  icon={<FaCashRegister size={32} />}
                  bgIcon="green.500"
                  orientation="horizontal"
                >
                  <Text mr={4}>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(totalFinanceiroDisponivel)}
                  </Text>

                  <Text mr={4}>Caixa</Text>
                </BoxPanel>
              </HStack>

              <TituloPagina title="Hoje" />
              <Divider
                orientation="horizontal"
                border="1px solid"
                mt={-2}
                mb={2}
              />

              <HStack mb={4} justifyContent="space-between">
                <BoxPanel
                  icon={<FaList size={32} />}
                  bgIcon="gray.500"
                  orientation="horizontal"
                  onClick={() => {
                    const dataInicio = new Date();
                    const dataFim = new Date();

                    dataInicio.setHours(0, 0, 0);
                    dataFim.setHours(23, 59, 59);

                    history.push('listarescalairso', {
                      id_categoria: categoria,
                      data_inicio: dataInicio,
                      data_fim: dataFim,
                    });
                  }}
                >
                  <Text>
                    {dadosHoje.qtdEscalas.toString().padStart(2, '0')}
                  </Text>
                  <Text>Total escalas</Text>
                </BoxPanel>

                <BoxPanel
                  icon={<GiPayMoney size={32} />}
                  bgIcon="red.500"
                  orientation="horizontal"
                >
                  <Text>
                    {new Intl.NumberFormat('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    }).format(dadosHoje.totalGasto)}
                  </Text>

                  <Text>Gasto</Text>
                </BoxPanel>

                <BoxPanel
                  icon={<GiPoliceOfficerHead color="white" size={32} />}
                  bgIcon="blue.500"
                  orientation="horizontal"
                >
                  <Text>
                    {dadosHoje.totalPoliciais.toString().padStart(2, '0')}
                  </Text>
                  <Text>Policiais</Text>
                </BoxPanel>
              </HStack>

              <TituloPagina title="Agora" />
              <Divider
                orientation="horizontal"
                border="1px solid"
                mt={-2}
                mb={2}
              />

              <HStack
                mb={4}
                spacing="calc((100%  - 3 * 230px) / 2)"
                alignContent="center"
              >
                <BoxPanel
                  icon={<FaList size={32} />}
                  bgIcon="gray.500"
                  orientation="horizontal"
                  onClick={() => {
                    const dataInicio = new Date();
                    const dataFim = new Date();

                    dataInicio.setHours(0, 0, 0);

                    history.push('listarescalairso', {
                      id_categoria: categoria,
                      data_inicio: dataInicio,
                      data_fim: dataFim,
                    });
                  }}
                >
                  <Text pl="5">
                    {dadosAgora.qtdEscalas.toString().padStart(2, '0')}
                  </Text>
                  <Text pl="5">Total escalas</Text>
                </BoxPanel>

                <BoxPanel
                  icon={<GiPoliceOfficerHead color="white" size={32} />}
                  bgIcon="blue.500"
                  orientation="horizontal"
                >
                  <Text pl="3">
                    {dadosAgora.totalPoliciais.toString().padStart(2, '0')}
                  </Text>
                  <Text pl="3">Policiais</Text>
                </BoxPanel>
              </HStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </BoxContent>
    </>
  );
};

export default Home;
