import React, { createContext, useCallback, useContext, useState } from 'react';

interface IEscalaContextData {
  idEscala?: number;
  updateIdEscala(id: number): Promise<void>;
}

const EscalaContext = createContext<IEscalaContextData>(
  {} as IEscalaContextData,
);

const EscalaProvider: React.FC = ({ children }) => {
  const [idEscala, setIdEscala] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-escalas:id_escala');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdEscala = useCallback(async (id: number) => {
    setIdEscala(id);
    sessionStorage.setItem('@pmce-cetic-escalas:id_escala', JSON.stringify(id));
  }, []);

  return (
    <EscalaContext.Provider value={{ updateIdEscala, idEscala }}>
      {children}
    </EscalaContext.Provider>
  );
};

const useEscala = (): IEscalaContextData => {
  const context = useContext(EscalaContext);
  if (!context) {
    throw new Error('useEscala must be used within an AuthProvider');
  }

  return context;
};

export { EscalaProvider, useEscala };
