import React, { createContext, useCallback, useState, useContext } from 'react';

interface ISubSubCategoriairsoContextData {
  idSubCategoriairso: number | undefined;
  updateIdSubCategoriairso(id: number): Promise<void>;
}

const SubCategoriairsoContext = createContext<ISubSubCategoriairsoContextData>(
  {} as ISubSubCategoriairsoContextData,
);

const SubCategoriairsoProvider: React.FC = ({ children }) => {
  const [idSubCategoriairso, setIdSubCategoriairso] = useState<
    number | undefined
  >(() => {
    const id = sessionStorage.getItem(
      '@pmce-cetic-escalas:id_subcategoriairso',
    );

    if (id) return JSON.parse(id);

    return undefined;
  });

  const updateIdSubCategoriairso = useCallback(async (id: number) => {
    setIdSubCategoriairso(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_subcategoriairso',
      JSON.stringify(id),
    );
  }, []);

  return (
    <SubCategoriairsoContext.Provider
      value={{ idSubCategoriairso, updateIdSubCategoriairso }}
    >
      {children}
    </SubCategoriairsoContext.Provider>
  );
};

function useSubCategoriairso(): ISubSubCategoriairsoContextData {
  const context = useContext(SubCategoriairsoContext);

  if (!context)
    throw new Error(
      'useSubCategoriairso must be used within an ModalidadeirsoProvider',
    );

  return context;
}

export { SubCategoriairsoProvider, useSubCategoriairso };
