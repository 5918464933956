import { Box } from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { useAuth } from '../../contexts/auth';
import Button from '../../components/form/Button';
import PanelBottomActions from '../../components/PanelBottomActions';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}
interface IUnidade {
  uni_nome: string;
  grandeComando: IUnidade;
  unidadePai: IUnidade;
}

interface ITiposOperacao {
  id_tipo: number;
  id_tipo_operacao: number;
  valor_transferencia: number;
  nome_tipo: string;
  criado_por: string;
  atualizado_por: string;
  pessoa: {
    pes_nome: string;
    pessoaPM?: { graduacao: { gra_sigla: string } };
  };
  uniCodigoOrigem: IUnidade;
  uniCodigoDestino: IUnidade;
  escala?: { nome: string };
  criado_em: string;
}

type OptionType = { label: string; value: string };

const ciclos = Array.from(Array(12).keys()).map<OptionType>((ciclo) => ({
  label: (ciclo + 1).toString().padStart(2, '0'),
  value: (ciclo + 1).toString().padStart(2, '0'),
}));

const anos = Array.from(Array(6).keys())
  .map((i) =>
    i < 5 ? new Date().getFullYear() - i : new Date().getFullYear() + 1,
  )
  .sort()
  .map<OptionType>((ano) => ({ label: ano.toString(), value: ano.toString() }));

const Saldos: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const [tiposOperacoes, setTiposOperacoes] = useState<OptionType[]>([]);
  const [categorias, setCategorias] = useState<OptionType[]>([]);
  const [loadedOptions, setLoadedOptions] = useState(false);

  const userCanRedirectCreditoCiclo = useMemo(
    () => ['CGO', 'CETIC'].includes(user.currentPerfil as string),
    [user.currentPerfil],
  );

  const columns: IColumns = [
    // {
    //   field: 'data_operacao_saldo_geral',
    //   text: 'Data',
    //   type: {
    //     name: 'date',
    //     format: 'dd/MM/yyyy',
    //   },
    // },

    // { field: 'mes_ciclo_tran', text: 'Mês', type: { name: 'text' } },
    // { field: 'ano_ciclo_tran', text: 'Ano', type: { name: 'text' } },

    {
      field: 'criado_em',
      text: 'Horário da Operação',
      type: { name: 'date', format: 'dd/MM/yyyy' },
      tooltip: ({ pessoa, criado_em }: ITiposOperacao) => {
        if (!pessoa) return undefined;

        const criadoEm = format(new Date(criado_em), 'HH:mm:ss');
        const { pessoaPM, pes_nome } = pessoa;
        return (
          `As ${criadoEm} por: ${
            pessoaPM?.graduacao.gra_sigla || 'Civil'
          } ${pes_nome}` || undefined
        );
      },
    },

    {
      field: 'escala.sequencia',
      text: 'Escala',
      type: { name: 'text' },
      tooltip: ({ escala }: ITiposOperacao) => {
        return escala ? `Nome da escala: ${escala?.nome}` : undefined;
      },
    },

    { field: 'categoria.nome', text: 'Categoria', type: { name: 'text' } },
    {
      field: 'uniCodigoOrigem.uni_sigla',
      text: 'OPM origem',
      type: { name: 'text' },
      tooltip: ({ uniCodigoOrigem }: ITiposOperacao) => {
        if (!uniCodigoOrigem) return undefined;
        return uniCodigoOrigem.uni_nome || undefined;
      },
    },
    {
      field: 'uniCodigoDestino.uni_sigla',
      text: 'OPM destino',
      type: { name: 'text' },
      tooltip: ({ uniCodigoDestino }: ITiposOperacao) => {
        if (!uniCodigoDestino) return undefined;
        return uniCodigoDestino.uni_nome || undefined;
      },
    },
    // {
    //   field: 'criado_por',
    //   text: 'Responsável',
    //   type: { name: 'text' },
    //   tooltip: ({ pessoa }: ITiposOperacao) => {
    //     if (!pessoa) return undefined;

    //     const { pessoaPM, pes_nome } = pessoa;
    //     return (
    //       `${pessoaPM?.graduacao.gra_sigla || 'Civil'} ${pes_nome}` || undefined
    //     );
    //   },
    // },

    { field: 'tipoOperacao.nome_tipo', text: 'Tipo', type: { name: 'text' } },

    {
      field: 'valor_transferencia',
      text: 'Valor',
      type: { name: 'currency' },
      tooltip: ({ id_tipo_operacao }: ITiposOperacao) => {
        if (id_tipo_operacao) return undefined;

        return 'Saldo Final';
      },
    },
  ];

  const options = {
    serverData: {
      url: '/saldos',
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
    },
    exportCsv: {
      visible: true,
      label: 'test',
      filename: 'extrato',
      columns: [
        { field: 'criado_em', title: 'Data', date: { format: 'dd/MM/yyyy' } },
        { field: 'criado_em', title: 'Hora', date: { format: 'HH:mm:ss' } },
        { field: 'tipoOperacao.nome_tipo', title: 'Tipo' },
        { field: 'escala.nome', title: 'Escala' },
        { field: 'categoria.nome', title: 'Categoria' },
        { field: 'uniCodigoOrigem.uni_nome', title: 'OPM origem' },
        { field: 'uniCodigoDestino.uni_nome', title: 'OPM origem' },
        { field: 'criado_por', title: 'Responsavel' },
        { field: 'valor_transferencia', title: 'Valor', number: true },
      ],
    },

    filters: [
      {
        type: 'select' as 'date' | 'select',

        field: 'ciclo',
        label: 'ciclo',
        options: [...ciclos],
        defaultOption: (new Date().getMonth() + 1).toString().padStart(2, '0'),
        cols: [1, 6, 12] as [number, number, number],
      },

      {
        type: 'select' as 'date' | 'select',

        field: 'ano',
        label: 'ano',
        options: [...anos],
        defaultOption: new Date().getFullYear().toString(),
        cols: [2, 6, 12] as [number, number, number],
      },
      {
        type: 'select' as 'date' | 'select',
        field: 'ids_categorias',
        label: 'Categorias',
        options: [...categorias],
        defaultOption: categorias[0]?.value || '',
        cols: [3, 6, 12] as [number, number, number],
      },

      {
        type: 'select' as 'date' | 'select',
        field: 'tipos_operacoes',
        label: 'Tipo Operação',
        options: [{ label: 'Todos', value: '' }, ...tiposOperacoes],
        defaultOption: '',

        cols: [4, 6, 12] as [number, number, number],
      },

      // {
      //   type: 'date' as 'date' | 'select',
      //   field: 'data_inicio',
      //   label: 'Data Início Operação',
      //   options: [],
      //   cols: [2, 6, 12] as [number, number, number],
      // },

      // {
      //   type: 'date' as 'date' | 'select',
      //   field: 'data_fim',
      //   label: 'Data Final Operação',
      //   options: [],
      //   cols: [2, 6, 12] as [number, number, number],
      // },
    ],
    highlightRow: ({
      id_tipo_operacao,
      valor_transferencia,
    }: ITiposOperacao) => {
      if (!id_tipo_operacao)
        return Number(valor_transferencia) >= 0 ? 'green' : '#f22929';
      return id_tipo_operacao === 5 || id_tipo_operacao === 2
        ? '#f22929'
        : undefined;
    },
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      const [
        {
          data: { items: itemsTiposOperacoes },
        },

        {
          data: { items: itemsCategorias },
        },
      ] = await Promise.all([
        api.get<{ items: ITiposOperacao[] }>('tipos_operacoes'),
        api.get<{ items: ICategoria[] }>('categorias'),
      ]);

      setTiposOperacoes(
        itemsTiposOperacoes.map((item) => ({
          label: item.nome_tipo,
          value: item.id_tipo.toString(),
        })) || [],
      );

      setCategorias(
        itemsCategorias.map((item) => ({
          value: item.id_categoria.toString(),
          label: item.nome,
        })) || [],
      );

      setLoadedOptions(true);
    };

    load();
  }, []);

  return (
    <>
      <TituloPagina title={`Extrato - ${user.currentOpm?.uni_sigla}`} />
      <BoxContent>
        <Box>
          {loadedOptions && (
            <>
              <DataTable columns={columns} options={options} />
            </>
          )}
          {userCanRedirectCreditoCiclo && (
            <PanelBottomActions>
              <Button
                icon={FaArrowLeft}
                color="red"
                onClick={() => history.push('/saldogeral/novo')}
              >
                Saldo Monetário
              </Button>
            </PanelBottomActions>
          )}
        </Box>
      </BoxContent>
    </>
  );
};

export default Saldos;
