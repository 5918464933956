import { Box } from '@chakra-ui/react';
import React from 'react';
import { useAuth } from '../../contexts/auth';
import BoxContent from '../../components/BoxContent';
import DataTable, { IColumns } from '../../components/DataTable';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';

type OptionType = { label: string; value: string };

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

const anos = Array.from(Array(6).keys())
  .map((i) =>
    i < 5 ? new Date().getFullYear() - i : new Date().getFullYear() + 1,
  )
  .sort()
  .map<OptionType>((ano) => ({ label: ano.toString(), value: ano.toString() }));

const ciclos = Array.from(Array(12).keys()).map<OptionType>((ciclo) => ({
  label: (ciclo + 1).toString().padStart(2, '0'),
  value: (ciclo + 1).toString(),
}));

const defaultFilters: IFilters[] = [
  {
    type: 'select' as 'date' | 'select',

    field: 'status',
    label: 'Status',
    options: [
      { value: 'TODOS', label: 'TODOS' },
      { value: '0', label: 'Escalado' },
      { value: '1', label: 'Presente' },
      { value: '2', label: 'Faltou' },
    ],
    defaultOption: '',
    cols: [2, 6, 12] as [number, number, number],
  },
  {
    type: 'select' as 'date' | 'select',
    field: 'ano',
    label: 'Ano',
    options: anos,
    defaultOption: new Date().getFullYear().toString(),

    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'select' as 'date' | 'select',
    field: 'mes',
    label: 'Mês',
    options: ciclos,
    defaultOption: (new Date().getMonth() + 1).toString(),

    cols: [2, 6, 12] as [number, number, number],
  },
];

export const ListarEscalasPolicial: React.FC = () => {
  const { user } = useAuth();

  const columns: IColumns = [
    {
      field: 'uni_sigla',
      text: 'Unidade',
      type: { name: 'text' },
      tooltip: ({ uni_nome }: any) => uni_nome,
    },

    {
      field: 'sequencia',
      text: 'Id',
      type: { name: 'text' },
      tooltip: ({ nome_escala }: any) =>
        nome_escala ? `Nome da escala: ${nome_escala}` : undefined,
    },
    {
      field: 'data_inicio',
      text: 'Início',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'escalas.data_inicio',
    },
    {
      field: 'data_fim',
      text: 'Fim',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'escalas.data_fim',
    },

    {
      field: 'qtd_horas',
      text: 'Total de horas',
      type: { name: 'text' },
      alias: 'escalas.qtd_horas',
    },

    {
      field: 'status_policial',
      text: 'Status',
      type: {
        name: 'enum',
        enum: { 0: 'Escalado', 1: 'Presente', 2: 'Faltou' },
      },
    },

    { field: 'nome_funcao', text: 'Função', type: { name: 'text' } },

    { field: 'valor_policial', text: 'Valor', type: { name: 'currency' } },
  ];

  const options = {
    serverData: {
      url: `/escalas/irsos/${user.matricula}/lista`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
    },

    filters: defaultFilters,

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['escalas.nome_equipe', 'escalas.nome_escala'],
      cols: [4, 6, 12] as [number, number, number],
    },
  };

  return (
    <>
      <TituloPagina title="Suas escalas de DRSO " />
      <BoxContent>
        <Box>
          <DataTable columns={columns} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};
