/* eslint-disable react/require-default-props */
import React from 'react';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input as ChakraInput,
  InputProps as ChakraInputProps,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  HStack,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Placement } from '@popperjs/core';
import { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import { FaQuestionCircle } from 'react-icons/fa';

interface IInputProps extends ChakraInputProps {
  name: string;
  label?: string;
  tipText?: string;
  tipPlacement?: Placement;
  error?: FieldError;
  required?: boolean;
}

const InputBase: React.ForwardRefRenderFunction<any, IInputProps> = (
  {
    name,
    label,
    tipText,
    tipPlacement = 'bottom',
    required = false,
    error = undefined,
    ...rest
  }: IInputProps,
  ref?: any,
) => {
  return (
    <FormControl isInvalid={!!error} isRequired={required}>
      <HStack alignItems="flex-start" spacing="0">
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        {tipText && (
          <Popover placement={tipPlacement}>
            <PopoverTrigger>
              <Button
                h="20px"
                p="0px"
                w="20px"
                bg="none"
                _hover={{ bg: 'none' }}
                _active={{ bg: 'none' }}
              >
                <FaQuestionCircle size="20px" color="blue" />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>{tipText}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      <ChakraInput
        id={name}
        name={name}
        type="file"
        focusBorderColor={error ? 'red.300' : '#999'}
        textTransform="uppercase"
        isInvalid={!!error}
        bgColor="#fff"
        borderColor="#ddd"
        errorBorderColor="red.300"
        _hover={{ borderColor: '#aaa' }}
        _disabled={{ bgColor: '#e3e3e3' }}
        ref={ref}
        {...rest}
      />
      {!!error && <FormErrorMessage mt="2px">{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const InputFile = forwardRef(InputBase);
