import { Button, useToast } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import BoxContent from '../../components/BoxContent';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import Row from '../../components/form/Row';

const RelatorioFaltasIrsoCicloAno: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Date>();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleRequestRelatorioCiclo = async (): Promise<void> => {
    if (!selectedDate)
      toast({
        title: 'Ocorreu um erro.',
        description: 'Data não selecionada',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    else {
      setIsLoading(true);
      try {
        const { data: documento } = await api.get(
          '/escalas/irsos/relatorios/ciclos_anos/faltas',
          {
            params: {
              ano: selectedDate.getFullYear(),
              ciclo: selectedDate.getMonth() + 1,
            },
            responseType: 'blob',
          },
        );

        if (documento) {
          const url = window.URL.createObjectURL(new Blob([documento]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Relatório de Faltas-${format(selectedDate, 'MM-yyyy')}.pdf`,
          );

          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode?.removeChild(link);
        }
      } catch (error) {
        if (error.response?.data) {
          const response = JSON.parse(
            await (error.response.data as Blob).text(),
          );
          toast({
            title: 'Ocorreu um erro.',
            description: response.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <TituloPagina title="Relatório Faltas" />
      <BoxContent>
        <TituloPagina title="Selecione o ciclo e o ano para obter o relatório" />
        <Row>
          <FormGroup cols={[4, 6, 12]}>
            <FormDatePicker
              selected={selectedDate}
              onChange={(e) => setSelectedDate(e as Date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              showFullMonthYearPicker
            />
          </FormGroup>

          <FormGroup cols={[4, 6, 12]}>
            <Button
              leftIcon={<HiOutlineDocumentReport />}
              colorScheme="blue"
              onClick={handleRequestRelatorioCiclo}
              isLoading={!!isLoading}
              loadingText="Gerando Relatorio..."
            >
              Gerar Relatório
            </Button>
          </FormGroup>
        </Row>
      </BoxContent>
    </>
  );
};

export default RelatorioFaltasIrsoCicloAno;
