// eslint-disable-next-line import/no-extraneous-dependencies
import { useToast } from '@chakra-ui/toast';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import * as Yup from 'yup';
import api from '../../../services/api';
import { IColumns } from '../../../components/DataTable';

type IAdicionarVoluntarioSchema = {
  funcao: number;
  pm_codigo: string;
};

type IContextPolicial = {
  schema: UseFormMethods<IAdicionarVoluntarioSchema>;
  equipesPoliciais: {
    id_equipe: number;
    policiais: any[];
  }[];
  columnsListDataTable: IColumns;

  addVoluntario: (idEquipe: number, data: any) => void;
  removeEquipePoliciais: (idEquipe: number) => void;
  removePolicial: (idPolicial: number) => Promise<boolean>;
  loadPoliciaisEquipes: (idsEquipes: number[]) => Promise<void>;

  clearPoliciaisOnChangeProcesso: (idEquipe: number) => void;
};
const adicionarVoluntarioSchema = Yup.object().shape({
  funcao: Yup.number()
    .required('Este campo é requerido')
    .typeError('Este campo é requerido'),
  pm_codigo: Yup.string()
    .required('Este campo é requerido')
    .min(7, 'No mínimo 7 caracteres')
    .max(11, 'No máximo 11 Caracteres'),
});

export const usePolicial = (idEscala: number): IContextPolicial => {
  const formAdicionarPolicial = useForm<IAdicionarVoluntarioSchema>({
    resolver: yupResolver(adicionarVoluntarioSchema),
  });

  const toast = useToast();

  const columnsListDataTable: IColumns = [
    {
      field: 'pm.graduacao.gra_sigla',
      text: 'Posto/Grad.',
      type: { name: 'text' },
    },

    { field: 'pm.pm_numero', text: 'Número', type: { name: 'text' } },

    { field: 'pm_codigo', text: 'Matrícula', type: { name: 'text' } },

    { field: 'pm.pessoa.pes_nome', text: 'Nome', type: { name: 'text' } },
    { field: 'pm.opm.uni_sigla', text: 'OPM', type: { name: 'text' } },

    {
      field: 'pm.situacaoPM.spm_nome',
      text: 'Situação',
      type: { name: 'text' },
    },

    {
      field: 'situacao',
      text: 'Voluntariedade',
      type: { name: 'enum', enum: { 0: 'Não voluntário', 1: 'Voluntário' } },
    },

    { field: 'qtd_horas', text: 'Qtd. Horas/Mês', type: { name: 'text' } },
  ];

  const [equipesPoliciais, setEquipesPoliciais] = useState<
    { id_equipe: number; policiais: any[] }[]
  >([]);

  const loadPoliciaisEquipes = useCallback(
    async (idsEquipes: number[]) => {
      const equipesPoliciaisResponse = await Promise.all(
        idsEquipes.map(async (idEquipe) => {
          const {
            data: { items: policiais },
          } = await api.get<{ items: any[] }>(
            `escalas/irsos/${idEscala}/equipes/${idEquipe}/equipes_policiais`,
          );

          return {
            id_equipe: idEquipe,
            policiais: policiais.map((item) => ({
              ...item,
              pm: {
                ...item.pm,
                graduacao: { gra_sigla: item.graduacao.gra_sigla },
              },
            })),
          };
        }),
      );

      setEquipesPoliciais(equipesPoliciaisResponse);
    },
    [idEscala],
  );

  const removeEquipePoliciais = useCallback(
    (idEquipe: number) => {
      setEquipesPoliciais(
        equipesPoliciais.filter(({ id_equipe }) => id_equipe !== idEquipe),
      );
    },
    [equipesPoliciais],
  );

  const removePolicial = useCallback(
    async (idPolicial: number) => {
      const indexEquipe = equipesPoliciais.findIndex(
        ({ policiais }) =>
          !!policiais.find(
            ({ id_equipe_policial }) => id_equipe_policial === idPolicial,
          ),
      );

      if (indexEquipe < 0) {
        toast({
          title: 'Erro.',
          description: 'Equipe não encontrada',
          status: 'error',
          duration: 10000,
          isClosable: true,
          position: 'top-right',
        });

        return false;
      }

      await api.delete(
        `escalas/irsos/${idEscala}/equipes/${equipesPoliciais[indexEquipe].id_equipe}/equipes_policiais/${idPolicial}`,
      );

      equipesPoliciais[indexEquipe].policiais = equipesPoliciais[
        indexEquipe
      ].policiais.filter(
        ({ id_equipe_policial }) => id_equipe_policial !== idPolicial,
      );
      const updatedEquipesPoliciais = [...equipesPoliciais];

      setEquipesPoliciais(updatedEquipesPoliciais);

      toast({
        title: 'Sucesso.',
        description: `Policial deletado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      return true;
    },
    [equipesPoliciais, toast, idEscala],
  );

  const clearPoliciaisOnChangeProcesso = useCallback(
    (idEquipe: number) => {
      const indexEquipePoliciais = equipesPoliciais.findIndex(
        ({ id_equipe }) => idEquipe === id_equipe,
      );

      if (indexEquipePoliciais > -1) {
        equipesPoliciais[indexEquipePoliciais].policiais = [];

        const updatedEquipePoliciais = [...equipesPoliciais];

        setEquipesPoliciais(updatedEquipePoliciais);
      }
    },
    [equipesPoliciais],
  );

  const addVoluntario = useCallback(
    (idEquipe: number, data: any) => {
      const indexEquipePolicial = equipesPoliciais.findIndex(
        ({ id_equipe }) => idEquipe === id_equipe,
      );

      if (indexEquipePolicial < 0)
        setEquipesPoliciais([
          ...equipesPoliciais,
          { id_equipe: idEquipe as number, policiais: [data] },
        ]);
      else {
        equipesPoliciais[indexEquipePolicial].policiais = [
          ...equipesPoliciais[indexEquipePolicial].policiais,
          data,
        ];

        const updatedEquipesPoliciais = [...equipesPoliciais];

        setEquipesPoliciais(updatedEquipesPoliciais);
      }
    },
    [equipesPoliciais],
  );

  return {
    schema: formAdicionarPolicial,
    equipesPoliciais,
    columnsListDataTable,
    loadPoliciaisEquipes,
    clearPoliciaisOnChangeProcesso,
    addVoluntario,
    removeEquipePoliciais,
    removePolicial,
  };
};
