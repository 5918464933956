import React from 'react';
import {
  Modal as ModalChakra,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

export interface IModalProps extends Omit<ModalProps, 'children'> {
  title: string;
  children?: React.ReactNode;
}

const Modal: React.FC<IModalProps> = ({
  isOpen,
  size,
  title,
  onClose,
  children,
  ...rest
}) => {
  return (
    <ModalChakra
      isOpen={isOpen}
      onClose={onClose}
      size={size}
      isCentered
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ModalChakra>
  );
};

export default Modal;
