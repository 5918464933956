import { Button, useToast, Box, Flex } from '@chakra-ui/react';
import React, { useState, useRef } from 'react';
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { SiMicrosoftexcel } from 'react-icons/si';
import { useAuth } from '../../contexts/auth';
import { Select } from '../../components/form/Select';
import { InputFile } from '../../components/form/InputFile';
import BoxContent from '../../components/BoxContent';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormGroup from '../../components/form/FormGroup';
import TituloPagina from '../../components/TituloPagina';

type OptionType = { label: string; value: string };

type IResponse = {
  pm_codigo: string;
  ciclo: string;
  ano: string;
  serial_number: string;
  valor_total_policial: string;
};

type IResponseMapPlanilha = { pm_codigo: string; valor_total_policial: number };

type IForm = { selected_date: Date; rubrica: string };

const optionsRubricas: OptionType[] = [
  { label: '000147 - Férias', value: '000147' },
  { label: '000211 - Horas Aula', value: '000211' },
  { label: '000109 - Cargo Comissionado', value: '000109' },
  { label: '000261 - Cargo Dfierença de cargo comissionado', value: '000261' },
  { label: '000470 - GPE', value: '000470' },
  { label: '000278 - Auxílio alimentação', value: '000278' },
  { label: '000347 - Armas', value: '000347' },
  { label: '000356 - DRSO', value: '000356' },
  { label: '000224 - Ajuda Fardamento', value: '000224' },
  { label: '000304 - Diferença sem incidência SUPSEC', value: '000304' },
  { label: '000179 - Diferença de ascensão', value: '000179' },
  {
    label: '000292 - Diferença de ascensão exercicio anterior',
    value: '000292',
  },
  { label: '000172 - Vantagem de adicional de férias', value: '000172' },
  { label: '000213 - Diferença de gratificação', value: '000213' },
  { label: '000412 - Diferença de auxílio alimentação', value: '000412' },
  { label: '002750 - Diferença DRSO', value: '002750' },
  { label: '003066 - Adicional DRSO 30%', value: '003066' },
].sort((a, b) => (a.value < b.value ? -1 : 1));

optionsRubricas.splice(0, 0, { label: 'Selecione...', value: '' });

export const converSionSchema = yup.object().shape({
  selected_date: yup.date().required('Este campo é requerido'),
  rubrica: yup
    .string()
    .required('Este campo é requerido')
    .oneOf(optionsRubricas.map((d) => d.value)),
});

export const ConverterPlanilha: React.FC = () => {
  const { user } = useAuth();
  const { handleSubmit, control, errors, watch } = useForm<IForm>({
    resolver: yupResolver(converSionSchema),
  });

  const toast = useToast();
  const refInput = useRef<HTMLInputElement | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const fileName = `conversao `;

  const exportToCSV = (dataCsv: any, filename: string): void => {
    const ws = XLSX.utils.json_to_sheet(dataCsv);
    // renameColumn(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
  };

  const canShowInputFile = !watch('selected_date') || !watch('rubrica');

  function exportSerialNumbers(serialNumbers: string[]): void {
    const blob = new Blob([serialNumbers.join('\n')], { type: 'text/plain' });
    FileSaver.saveAs(blob, 'conversao.txt');
  }

  const handleConversaoPlanilha = async ({
    selected_date,
    rubrica,
  }: IForm): Promise<void> => {
    const ano = selected_date?.getFullYear();
    const ciclo = (selected_date?.getMonth() || 0) + 1;

    setIsLoading(true);
    try {
      const itemsCSV: Record<string, any>[] = [
        {
          pm_codigo: user.matricula,
          ciclo,
          ano,
          valor_total_policial: 0.0,
        },
      ];

      exportToCSV(itemsCSV, `${fileName} ${ano}-${ciclo}`);
    } catch (error) {
      console.log(error);

      toast({
        title: 'Ocorreu um erro.',
        description:
          error.response?.data?.message ||
          'Relatório não pode ser gerado no momento',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUploadPlanilha = async (
    e: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    const file = e.currentTarget.files?.item(0);
    await handleSubmit(async ({ rubrica, selected_date }) => {
      try {
        if (!file) {
          throw new Error('Arquivo não selecionado');
        } else if (
          ![
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ].includes(file.type as string)
        ) {
          e.currentTarget.value = '';
          throw new Error('Arquivo não e csv');
        } else {
          const f = await file.arrayBuffer();
          const wb = XLSX.read(f, { type: 'array' });

          if (!wb.Sheets?.['data' as string])
            throw new Error('Nome da planilha deve ser data');

          const ano = selected_date.getFullYear().toString();
          const ciclo = (selected_date.getMonth() + 1)
            .toString()
            .padStart(2, '0');

          const text = ano.concat(ciclo).concat('0103').concat('00');

          const itemsPlanilha = XLSX.utils.sheet_to_json(
            wb.Sheets['data' as string],
          ) as IResponse[];

          const matriculasRepetidas: string[] = [];

          const itemTotalGroupbMatricula = itemsPlanilha.reduce<
            IResponseMapPlanilha[]
          >((items, itemPlanilha) => {
            const indexItem = items.findIndex((el) =>
              el.pm_codigo.toString().includes(itemPlanilha.pm_codigo),
            );

            const isRubricaArma = rubrica === '000347';

            const newItems = [...items];
            const valorPlanilha = Number.parseFloat(
              itemPlanilha.valor_total_policial.toString(),
            );

            if (indexItem < 0)
              newItems.push({
                ...itemPlanilha,
                valor_total_policial: valorPlanilha,
              });
            else if (!isRubricaArma) {
              matriculasRepetidas.push(itemPlanilha.pm_codigo);
            } else newItems[indexItem].valor_total_policial += valorPlanilha;

            return newItems;
          }, []);

          if (matriculasRepetidas.length) {
            toast({
              title: 'Aviso.',
              description: `Matriculas repetidas: ${matriculasRepetidas.join(
                ',',
              )}`,
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top-right',
            });
          } else {
            const items = itemTotalGroupbMatricula.map<string>((ele) =>
              text
                .concat(ele.pm_codigo || '<matricula>XXXXXXXX</matricula>')
                .concat(rubrica)
                .concat(
                  Number.parseFloat(ele.valor_total_policial.toString())
                    .toFixed(2)
                    .replace(/[^\d.-]/g, '')
                    .replace('.', '')
                    .padStart(11, '0'),
                )
                .concat('1')
                .concat('0')
                .padEnd(67),
            );

            exportSerialNumbers(items);
          }
        }
      } catch (error) {
        toast({
          title: 'Aviso.',
          description: (error as Error).message || 'Erro desconhecido',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    })();
  };

  return (
    <>
      <TituloPagina title="Conversão de Planilha" />
      <BoxContent>
        <TituloPagina title="Selecione o ciclo, ano e rúbrica para realizar conversão" />

        <Box onSubmit={handleSubmit(handleConversaoPlanilha)}>
          <Flex>
            <FormGroup name="Ciclo" cols={[2, 2, 12]}>
              <Controller
                control={control}
                name="selected_date"
                render={({ onChange, value, ...rest }) => (
                  <FormDatePicker
                    selected={value}
                    onChange={(e) => onChange(e as Date)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker
                    error={errors.selected_date?.message}
                    {...rest}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Rúbrica" cols={[3, 6, 12]}>
              <Controller
                control={control}
                name="rubrica"
                render={(field) => (
                  <Select
                    {...field}
                    options={optionsRubricas}
                    error={errors.rubrica}
                  />
                )}
              />
            </FormGroup>
          </Flex>
        </Box>

        <Flex my="4">
          <Button
            leftIcon={<SiMicrosoftexcel />}
            colorScheme="blue"
            onClick={() => handleSubmit(handleConversaoPlanilha)()}
            isLoading={isLoading}
            loadingText="Gerando Relatorio..."
            mr="4"
          >
            Planilha Modelo
          </Button>

          <InputFile
            name="file"
            onChange={handleUploadPlanilha}
            ref={refInput}
            isDisabled={canShowInputFile}
          />
        </Flex>
      </BoxContent>
    </>
  );
};
