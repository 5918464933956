import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  useToast,
  Button as ButtonChakra,
  Text,
  Divider,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { FaTrash, FaSave } from 'react-icons/fa';
import { format } from 'date-fns-tz';
import { useAuth } from '../../contexts/auth';
import Modal from '../../components/Modal';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import FormGroup from '../../components/form/FormGroup';
import Row from '../../components/form/Row';
import InputCurrency from '../../components/form/InputCurrency';
import api from '../../services/api';
import ReactSelect from '../../components/form/ReactSelect';
import FormDatePicker from '../../components/form/FormDatePicker';
import Button from '../../components/form/Button';
import DataTable, { IColumns } from '../../components/DataTable';

const dataAtual = new Date();

const schema = Yup.object().shape({
  ano_ciclo: Yup.date()
    .required('Este campo é requerido')
    .min(new Date(dataAtual.getFullYear(), 0, 1))
    .max(new Date(dataAtual.getFullYear() + 1, 11, 31))
    .typeError('Formato inválido de data'),

  id_categoria: Yup.number().required('Este campo é requerido'),
  valor: Yup.number().required('Este campo é requerido'),
});

type IPrevisaoSaldoSchema = {
  ano_ciclo: Date;
  id_categoria: number;
  valor: number;
};

type IPrevisaoSaldo = {
  mes_ciclo_previsao: string;
  ano_ciclo_previsao: string;
  id_categoria: number;
  valor_previsao: number;
  id_previsao_saldo: number;
};

type OptionType = { label: string; value: string };

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}

const PrevisaoSaldo: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const {
    handleSubmit,
    control,
    errors,
    reset,
  } = useForm<IPrevisaoSaldoSchema>({
    resolver: yupResolver(schema),
  });

  const [optionsCategorias, setOptionsCategorias] = useState<OptionType[]>([]);

  const {
    isOpen: isOpenDeletar,
    onClose: onCloseDeletar,
    onOpen: onOpenDeletar,
  } = useDisclosure();

  const [previsaoSaldoEditar, setPrevisaoSaldoEditar] = useState<
    IPrevisaoSaldoSchema & { id_previsao: number }
  >();

  const handleCreatePrevisao = async ({
    ano_ciclo,
    ...rest
  }: IPrevisaoSaldoSchema): Promise<void> => {
    try {
      const dataAnoCiclo = new Date(ano_ciclo);
      await api.post('previsoes', {
        ...rest,
        mes: format(dataAnoCiclo, 'MM', { timeZone: 'America/Fortaleza' }),
        ano: format(dataAnoCiclo, 'yyyy', { timeZone: 'America/Fortaleza' }),
      });

      reset();
      toast({
        title: 'Sucesso.',
        description: 'Previsão de saldo criada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleExcluirPrevisao = async (): Promise<void> => {
    try {
      await api.delete(
        `previsoes/${
          previsaoSaldoEditar?.id_categoria
        }/${previsaoSaldoEditar?.ano_ciclo
          .getUTCMonth()
          .toString()
          .padStart(
            2,
            '0',
          )}/${previsaoSaldoEditar?.ano_ciclo.getUTCFullYear()}`,
      );
      toast({
        title: 'Sucesso.',
        description: 'Previsão deletada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      onCloseDeletar();
    }
  };

  const colunas: IColumns = [
    {
      field: 'categoria.nome',
      text: 'Nome da Categoria',
      type: {
        name: 'text',
      },
    },
    {
      field: 'mes_ciclo_previsao',
      text: 'Ciclo',
      type: {
        name: 'text',
      },
    },

    {
      field: 'ano_ciclo_previsao',
      text: 'Ano',
      type: {
        name: 'text',
      },
    },

    {
      field: 'valor_previsao',
      text: 'Valor previsto',
      type: {
        name: 'currency',
      },
    },
  ];

  const options = {
    serverData: {
      url: `/previsoes`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ICategoria) => {
        //     console.log(uniforme);
        //   },
        // },

        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: ({
            ano_ciclo_previsao,
            id_categoria,
            id_previsao_saldo,
            mes_ciclo_previsao,
            valor_previsao,
          }: IPrevisaoSaldo) => {
            setPrevisaoSaldoEditar({
              id_categoria,
              valor: valor_previsao,
              ano_ciclo: new Date(
                Number(ano_ciclo_previsao),
                Number(mes_ciclo_previsao),
                1,
                0,
                0,
                0,
              ),
              id_previsao: id_previsao_saldo,
            });
            onOpenDeletar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nomeCategoria'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadCategorias = async (): Promise<void> => {
      const {
        data: { items: categorias },
      } = await api.get<{ items: ICategoria[] }>('categorias');
      setOptionsCategorias(
        categorias
          .filter((categoria) => categoria.situacao === '1')
          .map((categoria) => ({
            label: categoria.nome,
            value: categoria.id_categoria.toString(),
          })),
      );
    };

    loadCategorias();
  }, []);

  return (
    <>
      <>
        <TituloPagina title="Previsões de Saldos por Ciclos" />
        <BoxContent>
          <TituloPagina title="Cadastrar Previsão" />
          <form onSubmit={handleSubmit(handleCreatePrevisao)}>
            <Row>
              <FormGroup name="Categoria" cols={[2, 4, 12]} required>
                <Controller
                  control={control}
                  name="id_categoria"
                  render={({ onChange, value }) => (
                    <ReactSelect
                      optionsSelect={optionsCategorias}
                      onChange={(option: OptionType) =>
                        onChange(Number(option.value))
                      }
                      value={optionsCategorias.find(
                        (option) => option.value === value?.toString(),
                      )}
                      error={errors.id_categoria?.message}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup name="Ciclo/Ano" required cols={[1, 2, 12]}>
                <Controller
                  name="ano_ciclo"
                  control={control}
                  render={({ onChange, value }) => {
                    return (
                      <FormDatePicker
                        selected={value}
                        onChange={(e) => onChange(e)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        showFullMonthYearPicker
                        minDate={new Date(dataAtual.getFullYear(), 0, 1)}
                        maxDate={new Date(dataAtual.getFullYear() + 1, 11, 31)}
                        error={errors.ano_ciclo?.message}
                      />
                    );
                  }}
                />
              </FormGroup>

              <FormGroup cols={[2, 2, 12]} name="Valor" required>
                <Controller
                  name="valor"
                  control={control}
                  render={({ onChange, value }) => (
                    <InputCurrency
                      value={value}
                      onChange={(e, value2) => onChange(value2)}
                      error={errors.valor?.message}
                    />
                  )}
                />
              </FormGroup>
              <div
                style={{
                  padding: '4px 8px',
                  marginTop: '1.7rem',
                  height: '38px',
                }}
              >
                <Button color="green" icon={FaSave} type="submit">
                  Incluir
                </Button>
              </div>
            </Row>
          </form>

          <Divider orientation="horizontal" border="2px" />
          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
            >
              Listagem de Previsão Saldos - PMCE
            </Text>
          </Box>

          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        </BoxContent>

        <Modal
          isOpen={isOpenDeletar}
          onClose={onCloseDeletar}
          size="lg"
          title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
        >
          <h1>
            Voce esta prestes a deletar a Previsão de irso{' '}
            <strong>
              {previsaoSaldoEditar?.ano_ciclo
                .getMonth()
                .toString()
                .padStart(2, '0')}
              /{previsaoSaldoEditar?.ano_ciclo.getFullYear()}
            </strong>{' '}
            tem certeza da operação?
          </h1>
          <Flex mt="8" justify="center">
            <HStack spacing="4">
              <ButtonChakra onClick={onCloseDeletar} colorScheme="green">
                Não
              </ButtonChakra>

              <ButtonChakra onClick={handleExcluirPrevisao} colorScheme="red">
                Sim Quero Deletar!
              </ButtonChakra>
            </HStack>
          </Flex>
        </Modal>
      </>
    </>
  );
};

export default PrevisaoSaldo;
