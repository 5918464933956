import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import {
  Flex,
  HStack,
  SimpleGrid,
  VStack,
  Box,
  Button,
} from '@chakra-ui/react';
import FormGroup from 'components/form/FormGroup';
import FormInput from 'components/form/FormInput';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import BoxContent from '../../components/BoxContent';
import { Select } from '../../components/form/Select';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useCategoriairso } from '../../contexts/categoriairso';
import { useTipoTrava } from '../../contexts/tipotrava';

interface ITipoTrava {
  id_tipo_trava: number;
  nome: string;
  descricao: string;
  criado_por?: string;
  criado_em?: string;
  atualizado_em?: string;
  atualizado_por?: string;
}

const ShowTipoTrava: React.FC = () => {
  const { user } = useAuth();
  const { idCategoriairso } = useCategoriairso();
  const { idTipoTrava } = useTipoTrava();
  const history = useHistory();

  const [tipoTrava, setTipoTrava] = useState<ITipoTrava>();

  const schema = Yup.object().shape({
    nome: Yup.string()
      .required('Informe o nome da Categoria da DRSO')
      .min(3, 'Nome digitado muito pequeno'),
  });

  const { errors, handleSubmit, register } = useForm<ITipoTrava>({
    resolver: yupResolver(schema),
    defaultValues: {
      id_tipo_trava: tipoTrava?.id_tipo_trava,
      nome: tipoTrava?.nome,
      descricao: tipoTrava?.descricao,
      criado_por: tipoTrava?.criado_por,
      criado_em: tipoTrava?.criado_em,
      atualizado_em: tipoTrava?.atualizado_em,
      atualizado_por: tipoTrava?.atualizado_por,
    },
  });

  const handleSair = (): void => {
    history.push('/listartipotrava');
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      try {
        const categoriaGet = await api.get(`/tipotrava/${idTipoTrava}`);
        setTipoTrava(categoriaGet.data);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();
  }, [user]);
  return (
    <>
      {tipoTrava && (
        <>
          <TituloPagina title={`Categoria de DRSO - ${tipoTrava.nome}`} />
          <BoxContent>
            <Box
              as="form"
              flex="1"
              onSubmit={handleSubmit(() => console.log('teste'))}
            >
              <VStack spacing="4">
                <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
                  <FormGroup name="Nome">
                    <FormInput
                      name="nome"
                      disabled
                      defaultValue={tipoTrava.nome}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Descricao">
                    <FormInput
                      name="descricao"
                      disabled
                      defaultValue={tipoTrava.descricao}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Cadastrado Por">
                    <FormInput
                      name="criadoPor"
                      disabled
                      defaultValue={tipoTrava.criado_por}
                      register={register}
                    />
                  </FormGroup>
                </SimpleGrid>
                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Button as="a" colorScheme="red" onClick={handleSair}>
                      Voltar
                    </Button>
                  </HStack>
                </Flex>
              </VStack>
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ShowTipoTrava;
