import React, { useEffect, useState, useCallback } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { Button, Divider, Flex, useDisclosure } from '@chakra-ui/react';
import {
  Box,
  useToast,
  HStack,
  Button as ButtonChacrka,
  Text,
} from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import { format } from 'date-fns-tz';
import { parse } from 'date-fns';
import PanelBottomActions from '../../components/PanelBottomActions';
import api from '../../services/api';
import FormGroup from '../../components/form/FormGroup';
import AsyncSelect from '../../components/form/AsyncSelect';
import Row from '../../components/form/Row';
import { Select } from '../../components/form/Select';
import FormDatePicker from '../../components/form/FormDatePicker';
import FormInput from '../../components/form/FormInput';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type IGrandesComando = { uni_codigo: number; uni_sigla: string };

type OptionType = { label: string; value: string };

interface ITipotrava {
  nome: string;
}

interface IUnidade {
  uni_nome: string;
}

interface ITravaPm {
  id_trava_pm: number;
  pm_codigo: number;
  tipo_trava: number;
  tipoTrava: ITipotrava;
  unidade: IUnidade;
  criado_em: Date;
  criado_por: string;
  pessoaPm: { pessoa: { pes_nome: string } };
  data_inicio: string;
  data_fim: string;
  observacao?: string;
}

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

const defaultFilters: IFilters[] = [
  {
    type: 'date' as 'date' | 'select',
    field: 'data_inicio',
    label: 'Data Início',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'date' as 'date' | 'select',
    field: 'data_fim',
    label: 'Data Final',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },
];

interface ISchema {
  id_tipo_trava: number;
  data_inicio: Date;
  data_fim: Date;
  observacao?: string;
  pm_codigo: string;
}

interface ISchemaEditar {
  data_inicio: Date;
  data_fim: Date;
  observacao?: string;
}

const schema = Yup.object()
  .shape({
    id_tipo_trava: Yup.number()
      .required('Campo Obrigatório')
      .typeError('Campo obrigatorio'),
    data_inicio: Yup.date()
      .required('Campo Obrigatório')
      .typeError('Campo Obrigatório'),
    data_fim: Yup.date()
      .required('Campo Obrigatório')
      .typeError('Campo Obrigatório'),
    observacao: Yup.string().notRequired(),
    // eslint-disable-next-line no-control-regex
    // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
    pm_codigo: Yup.string()
      .min(7, 'Campo Obrigatório')
      .required('Campo Obrigatório'),
  })
  .required();

const schemaEditar = Yup.object().shape({
  data_inicio: Yup.date().required('Campo Obrigatório'),
  data_fim: Yup.date().required('Campo Obrigatório'),
  observacao: Yup.string().notRequired(),
});

const ListTravaPm: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [tipoTravaModal, setTipoTravaModal] = useState<ITravaPm>();
  const [grandesComandos, setGrandesComandos] = useState<OptionType[]>([]);
  const [tiposTravas, setTiposTravas] = useState<OptionType[]>([]);
  const [filters, setFilters] = useState<IFilters[]>(defaultFilters);
  const [pmFormSelected, setPmFormSelected] = useState<OptionType>();
  const [isDisabledRequest, setIsDisabledRequest] = useState(false);

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarTravaPM,
    onOpen: onOpenEditarTravaPM,
    onClose: onCloseEditarTravaPM,
  } = useDisclosure();

  const { errors, handleSubmit, reset, control } = useForm<ISchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      pm_codigo: '',
      data_fim: '',
      data_inicio: '',
      id_tipo_trava: Number.NaN,
      observacao: '',
    },
  });

  const {
    errors: errorsEditar,
    handleSubmit: handleSubmitEditar,
    control: controlEditar,
  } = useForm<ISchemaEditar>({
    resolver: yupResolver(schemaEditar),
  });

  const handleAdicionarTravaOpm = async ({
    data_fim,
    data_inicio,
    ...rest
  }: ISchema): Promise<any> => {
    const dados = {
      ...rest,
      data_fim: format(data_fim, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      data_inicio: format(data_inicio, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
    };

    setIsDisabledRequest(true);
    try {
      reset();
      await api.post(`/travapm`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Cadastro de tipo de trava inserido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setPmFormSelected(undefined);
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao inserir o trava Opm',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const hanldeUpdateTravaPM = async ({
    data_fim,
    data_inicio,
    ...rest
  }: ISchemaEditar): Promise<any> => {
    if (1) {
      const dados = {
        ...rest,
        data_fim: format(data_fim, 'yyyy-MM-dd HH:mm:ss', {
          timeZone: 'America/Fortaleza',
        }),
        data_inicio: format(data_inicio, 'yyyy-MM-dd HH:mm:ss', {
          timeZone: 'America/Fortaleza',
        }),
      };
      setIsDisabledRequest(true);
      try {
        await api.put(`/travapm/${tipoTravaModal?.id_trava_pm}`, dados);
        toast({
          title: 'Sucesso.',
          description: 'Trava Pm alterada com sucesso com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        onCloseEditarTravaPM();
      } catch (error) {
        toast({
          title: 'Erro',
          description: 'Erro ao atualizar a trava Pm',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      } finally {
        setIsDisabledRequest(false);
      }
    }
  };

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`pessoas?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.matricula,
          label: item.dados,
          opm: item.uni_codigo,
        };
      });

      // setPessoaList(response.data);

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  const handleExcluirTravaPm = async (data: ITravaPm): Promise<any> => {
    setIsDisabledRequest(true);
    try {
      await api.put(`/travapm/deletar/${data.id_trava_pm}`, {
        atualizado_por: user.matricula,
      });
      toast({
        title: 'Sucesso.',
        description: 'Trava de Pm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const colunas: IColumns = [
    {
      field: 'pm_codigo',
      text: 'Matricula',
      type: {
        name: 'text',
      },
      alias: 'travaPms.pm_codigo',
    },
    {
      field: 'pessoaPm.pessoa.pes_nome',
      text: 'Nome',
      type: {
        name: 'text',
      },
    },
    {
      field: 'tipoTrava.nome',
      text: 'Tipo de trava',
      type: {
        name: 'text',
      },
      alias: 'tipoTrava.nome',
    },
    {
      field: 'data_inicio',
      text: 'Data Inicial',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
      alias: 'travaPms.data_inicio',
    },
    {
      field: 'data_fim',
      text: 'Data Final',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
      alias: 'travaPms.data_fim',
    },
  ];

  const options = {
    serverData: {
      url: `/travapm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ITravaPm) => {
        //     handleClickShow(uniforme.id_trava_pm);
        //   },
        // },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: ITravaPm) => {
            setTipoTravaModal(uniforme);
            onOpenEditarTravaPM();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },

    filters,

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pessoaPm.pm_codigo', 'pessoa.pes_nome'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useEffect(() => {
    const loadGrandesComandos = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IGrandesComando[] }>(
        '/unidades/grandes_comandos',
      );
      const listaFornecedores = await api.get('/tipotrava');
      const optionsFornecedores = listaFornecedores.data.items.map(
        (fornecedor: any) => {
          return {
            value: fornecedor.id_tipo_trava,
            label: fornecedor.nome,
          };
        },
      );
      setTiposTravas([...optionsFornecedores]);

      setGrandesComandos(
        items.map((item) => ({
          label: item.uni_sigla,
          value: item.uni_codigo.toString(),
        })),
      );
    };

    loadGrandesComandos();
  }, []);

  useEffect(() => {
    if (Number(user.currentOpm?.uni_codigo as string) === -1) {
      setFilters([
        {
          type: 'select' as 'date' | 'select',
          field: 'grandes_comandos',
          label: 'Grande Comando',
          options: [{ label: 'Todos', value: '' }, ...grandesComandos],
          defaultOption: '',

          cols: [2, 6, 12] as [number, number, number],
        },
        ...defaultFilters,
      ]);
    }
  }, [user.currentOpm, grandesComandos]);

  return (
    <>
      <>
        <TituloPagina title="Trava de Policial Militar" />
        <BoxContent>
          <form
            onSubmit={handleSubmit(handleAdicionarTravaOpm)}
            style={{ marginBottom: 8 }}
          >
            <TituloPagina title="Cadastro de trava de PM" />
            <Row>
              <FormGroup name="Pesquisar Pm" cols={[8, 12, 12]}>
                <Controller
                  name="pm_codigo"
                  control={control}
                  render={({ onChange }) => (
                    <AsyncSelect
                      name="pm_codigo"
                      label="Pesquisar Pm"
                      value={pmFormSelected}
                      loadOptions={(inputValue: any) =>
                        delayedQuery(inputValue)
                      }
                      onChange={(value: any) => {
                        onChange(value.value);
                        setPmFormSelected(value);
                      }}
                      error={errors.pm_codigo?.message}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup name="Tipo Trava" cols={[4, 6, 12]}>
                <Controller
                  name="id_tipo_trava"
                  control={control}
                  render={({ onChange, value }) => (
                    <Select
                      placeholder="Selecione uma Opção"
                      name="id_tipo_trava"
                      onChange={(e) => {
                        const numberTrava = Number(
                          e.currentTarget.value === ''
                            ? Number.NaN
                            : e.currentTarget.value,
                        );

                        onChange(
                          Number.isNaN(numberTrava) ? undefined : numberTrava,
                        );
                      }}
                      value={value}
                      error={errors.id_tipo_trava}
                      options={tiposTravas}
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup cols={[3, 6, 12]} name="Data Inicial">
                <Controller
                  name="data_inicio"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      selected={value}
                      error={errors.data_inicio?.message}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(e.getFullYear(), e.getMonth(), e.getDate()),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup cols={[3, 6, 12]} name="Data Final">
                <Controller
                  name="data_fim"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      error={errors.data_fim?.message}
                      selected={value}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(
                            e.getFullYear(),
                            e.getMonth(),
                            e.getDate(),
                            23,
                            59,
                            59,
                          ),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>

              <FormGroup name="Observacao" cols={[6, 12, 12]}>
                <Controller
                  name="observacao"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormInput
                      /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                      onChange={onChange}
                      value={value?.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      )}
                      onPaste={(e) => {
                        const a = e.currentTarget?.value.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        );

                        onChange(a);
                      }}
                      error={errors.observacao?.message}
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <Button
              colorScheme="green"
              type="submit"
              isLoading={isDisabledRequest}
            >
              Salvar
            </Button>
          </form>

          <Divider orientation="horizontal" border="2px" />

          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
            >
              Listagem de Trava Pm - {user.currentOpm?.uni_sigla}{' '}
              {user.verSubunidade === '1' &&
                user.currentOpm?.uni_sigla !== '-1' &&
                'e subunidades'}
            </Text>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        </BoxContent>

        {tipoTravaModal && (
          <>
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="lg"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Trava de Pm{' '}
                <strong>{tipoTravaModal.tipoTrava.nome}</strong> do policial{' '}
                <strong>{tipoTravaModal.pm_codigo}</strong> tem certeza da
                operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <ButtonChacrka
                    onClick={onCloseVisualizar}
                    colorScheme="green"
                  >
                    Não
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() => handleExcluirTravaPm(tipoTravaModal)}
                    isLoading={isDisabledRequest}
                    colorScheme="red"
                  >
                    Sim Quero Deletar!
                  </ButtonChacrka>
                </HStack>
              </Flex>
            </Modal>

            <Modal
              isOpen={isOpenEditarTravaPM}
              onClose={onCloseEditarTravaPM}
              size="xl"
              title={`Editar trava PM - ${tipoTravaModal?.pessoaPm.pessoa.pes_nome}`}
            >
              <form onSubmit={handleSubmitEditar(hanldeUpdateTravaPM)}>
                <Row>
                  <FormGroup name="Data Inicial" cols={[4, 6, 12]}>
                    <Controller
                      name="data_inicio"
                      defaultValue={parse(
                        tipoTravaModal.data_inicio,
                        'dd/MM/yyyy',
                        new Date(),
                      )}
                      control={controlEditar}
                      render={({ value, onChange }) => (
                        <FormDatePicker
                          showYearDropdown
                          selected={value}
                          error={errorsEditar.data_inicio?.message}
                          onChange={(e: Date) =>
                            onChange(
                              new Date(
                                e.getFullYear(),
                                e.getMonth(),
                                e.getDate(),
                              ),
                              { timeZone: 'America/Fortaleza' },
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />
                  </FormGroup>

                  <FormGroup name="Data Final" cols={[4, 6, 12]}>
                    <Controller
                      name="data_fim"
                      defaultValue={parse(
                        tipoTravaModal.data_fim,
                        'dd/MM/yyyy',
                        new Date(),
                      )}
                      control={controlEditar}
                      render={({ value, onChange }) => (
                        <FormDatePicker
                          showYearDropdown
                          selected={value}
                          error={errorsEditar.data_fim?.message}
                          onChange={(e: Date) =>
                            onChange(
                              new Date(
                                e.getFullYear(),
                                e.getMonth(),
                                e.getDate(),
                              ),
                              { timeZone: 'America/Fortaleza' },
                            )
                          }
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />
                  </FormGroup>
                </Row>

                <Row>
                  <FormGroup name="Observacao" cols={[12, 12, 12]}>
                    <Controller
                      name="observacao"
                      defaultValue={tipoTravaModal?.observacao}
                      control={controlEditar}
                      render={({ value, onChange }) => (
                        <FormInput
                          name="observacao"
                          /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                          onChange={onChange}
                          value={value?.replaceAll(
                            // eslint-disable-next-line no-control-regex
                            /[^\x00-\x7F]/g,
                            '',
                          )}
                          onPaste={(e) => {
                            const a = e.currentTarget?.value.replaceAll(
                              // eslint-disable-next-line no-control-regex
                              /[^\x00-\x7F]/g,
                              '',
                            );

                            onChange(a);
                          }}
                        />
                      )}
                    />
                  </FormGroup>
                </Row>

                <PanelBottomActions>
                  <HStack spacing="4">
                    <ButtonChacrka
                      onClick={onCloseEditarTravaPM}
                      as="a"
                      colorScheme="red"
                    >
                      Voltar
                    </ButtonChacrka>

                    <Button
                      colorScheme="green"
                      type="submit"
                      isLoading={isDisabledRequest}
                    >
                      Salvar
                    </Button>
                  </HStack>
                </PanelBottomActions>
              </form>
            </Modal>
          </>
        )}
      </>
    </>
  );
};

export default ListTravaPm;
