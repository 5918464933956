import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HiDocumentDownload } from 'react-icons/hi';
import api from 'services/api';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';

type IDocumento = {
  title: string;
  url: string;
};

export const Documentos: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();

  const [documentos, setDocumentos] = useState<IDocumento[]>([]);

  const columns: IColumns = [
    { field: 'title', text: 'Título', type: { name: 'text' } },
  ];

  const options = {
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <HiDocumentDownload color="#3182CE" size={18} />,
          tooltip: 'Visualizar Documento',
          getRow: async (doc: IDocumento) => {
            try {
              const { data: documento } = await api.get(`files/${doc.url}`, {
                responseType: 'blob',
              });

              if (documento) {
                const url = window.URL.createObjectURL(
                  new Blob([documento], { type: 'application/pdf' }),
                );

                window.open(url, '_blank')?.focus();
              }
            } catch (error) {
              const response = JSON.parse(
                await (error.response.data as Blob).text(),
              );
              toast({
                title: 'Ocorreu um erro.',
                description: response.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top-right',
              });
            }
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['title'],
      cols: [4, 6, 12] as [number, number, number],
    },
  };

  useEffect(() => {
    const documentosAInserir: IDocumento[] = [
      {
        title: 'Voluntariedade e acompanhamento de escalas',
        url: 'irso-bcg.pdf',
      },
    ];

    if (user.currentPerfil !== 'USUÁRIO PADRÃO')
      documentosAInserir.push({
        title: 'Manual do Escalante/Validador',
        url: 'irso-revisado.pdf',
      });

    setDocumentos(documentosAInserir);
  }, [user.currentPerfil]);

  return (
    <>
      <TituloPagina title="Manuais | Legislações | Outros Documentos" />
      <BoxContent>
        <Accordion>
          <AccordionItem border="1px solid #ddd" mb={4}>
            <h2>
              <AccordionButton background="#eee">
                <Box as="span" flex="1" textAlign="left">
                  Manuais
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <DataTable
                columns={columns}
                options={options}
                data={documentos}
              />
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem border="1px solid #ddd" mb={4}>
            <h2>
              <AccordionButton background="#eee">
                <Box as="span" flex="1" textAlign="left">
                  Legislações
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Nenhum documento ainda presente
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </BoxContent>
    </>
  );
};
