import React, { useState } from 'react';
import { FaSave } from 'react-icons/fa';
import { useForm, Controller } from 'react-hook-form';
// import { useMovimentacao } from 'contexts/MovimentacaoContext';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { IoIosMail } from 'react-icons/io';
import {
  Divider,
  Flex,
  Button,
  useToast,
  Alert,
  AlertIcon,
  AlertDescription,
} from '@chakra-ui/react';
import UserImage from 'pages/_layouts/private/Header/UserImage';
import { BeatLoader } from 'react-spinners';
import apiSGA from 'services/apiSGA';
import { CheckBox } from '../form/CheckBox';
import { useAuth } from '../../contexts/auth';
import PanelBottomActions from '../PanelBottomActions';
import Modal, { IModalProps } from '../Modal';
import FormInput from '../form/FormInput';
import FormGroup from '../form/FormGroup';
// import Button from '../form/Button';
import { Content, Form, Post, UserCPF, UserContent } from './styles';

const schemaAssinatura = Yup.object().shape({
  assinatura: Yup.string().required('Este cmapo é requerido'),
  /* pin: Yup.string().required('Este cmapo é requerido'), */
  isConfirmedAgreement: Yup.string()
    .required('Este campo é requerido')
    .oneOf(['1'], 'Confirmar termos acima'),
});

/* const schemaAssinatura = Yup.object().shape({
  cargo: Yup.string().required('Este campo é obrigatorio'),
  pin_assinatura: Yup.string().required('Este campo é obrigatorio'),
  pin_24h: Yup.string()
    .required('Este campo é obrigatorio')
    .max(6, 'Não pode haver mais de 6 digitos'),
}); */

type IFormInputsAssinatura = { assinatura: string; isConfirmedAgreement: '1' };

interface IModalAssinaturaProps extends IModalProps {
  onSubmit: (data: IFormInputsAssinatura) => Promise<void>;
  agreementTerm?: string;
}

const ModalAssinatura: React.FC<Omit<IModalAssinaturaProps, 'title'>> = ({
  onClose,
  onSubmit: submit,
  agreementTerm,
  ...rest
}) => {
  const toast = useToast();
  const { user } = useAuth();
  const [botao, setBotao] = useState(true);
  const [botaoPin, setBotaoPin] = useState(true);
  const { handleSubmit, control, errors } = useForm<IFormInputsAssinatura>({
    resolver: yupResolver(schemaAssinatura),
    defaultValues: {
      assinatura: undefined,
      isConfirmedAgreement: agreementTerm?.trim().length
        ? (undefined as any)
        : '1',
      /* pin: undefined, */
    },
  });

  const onSubmit = async (data: IFormInputsAssinatura): Promise<void> => {
    setBotao(false);
    try {
      await submit(data);
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      // history.push('/listasdocumentosparaassinar');
    } finally {
      setBotao(true);
    }
  };

  const handleSolicitarAssinatura = async (): Promise<void> => {
    setBotaoPin(false);
    try {
      const createdPin = await apiSGA.post('assinaturas/esqueci', {
        pes_codigo: user.id_usuario,
      });

      toast({
        title: 'Sucesso!',
        description:
          createdPin.data.message ||
          'Email enviado para a redefinição de Assinatura Eletrônica. Por favor, verifique seu email',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setBotaoPin(true);
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description:
          error.response?.data?.message ||
          'Ocorreu um erro ao tentar redefinir a Assinatura Eletrônica. Por favor entrar em contato com o sargenteante',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setBotaoPin(true);
    }
  };

  const handleBotaoConfirmar = (): any => {
    if (botao) {
      return (
        <Button
          colorScheme="green"
          backgroundColor="#48b461"
          rightIcon={<FaSave size={18} />}
          icon={FaSave}
          type="submit"
        >
          Assinar
        </Button>
      );
    }
    return (
      <Button
        isLoading
        loadingText="Enviando"
        rightIcon={<FaSave size={18} />}
        spinner={<BeatLoader size={8} color="white" />}
        colorScheme="green"
        icon={FaSave}
        type="submit"
      >
        Assinar
      </Button>
    );
  };

  const handleBotaoPin = (): any => {
    if (botaoPin) {
      return (
        <Button
          colorScheme="blue"
          backgroundColor="#5ac4d0"
          type="button"
          rightIcon={<IoIosMail size={22} />}
          onClick={handleSolicitarAssinatura}
        >
          Redefinir Assinatura Eletrônica
        </Button>
      );
    }
    return (
      <Button
        colorScheme="blue"
        isLoading
        type="button"
        loadingText="Enviando"
        rightIcon={<IoIosMail size={22} />}
        spinner={<BeatLoader size={8} color="white" />}
      >
        Redefinir Assinatura Eletrônica
      </Button>
    );
  };

  return (
    <Modal title="Assinatura Eletronica PMCE" {...rest} onClose={onClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <UserContent>
          <UserImage size="lg" />
          <Flex direction="column" marginLeft="8px">
            <Post>{user.nome}</Post>
            <UserCPF>{user.matricula}</UserCPF>
          </Flex>
        </UserContent>

        <Content>
          {agreementTerm && (
            <Alert
              status="warning"
              alignContent="center"
              alignItems="center"
              textAlign="center"
              visibility={agreementTerm?.trim().length ? 'visible' : 'hidden'}
              variant={
                errors.isConfirmedAgreement?.message ? 'top-accent' : undefined
              }
            >
              <AlertIcon />
              <AlertDescription>{agreementTerm}</AlertDescription>
            </Alert>
          )}

          <Divider marginTop={agreementTerm ? 4 : 2} />

          <Flex
            direction="column"
            justifyContent="center"
            alignContent="center"
            alignItems="center"
            mt={agreementTerm ? 4 : undefined}
          >
            <Controller
              name="isConfirmedAgreement"
              control={control}
              render={({ onChange, value, name }) => (
                <CheckBox
                  name={name}
                  onChange={(e) =>
                    onChange(e.currentTarget.checked ? '1' : undefined)
                  }
                  isChecked={value === '1'}
                  display={agreementTerm?.trim().length ? undefined : 'none'}
                  error={errors.isConfirmedAgreement}
                  mt={4}
                  mb={4}
                  w="100%"
                >
                  Confirmo
                </CheckBox>
              )}
            />

            <FormGroup
              required
              name="Assinatura Eletrônica"
              cols={[12, 12, 12]}
            >
              <Controller
                name="assinatura"
                control={control}
                render={({ onChange, value }) => (
                  <FormInput
                    type="password"
                    onChange={onChange}
                    value={value}
                    error={errors.assinatura?.message}
                  />
                )}
              />
            </FormGroup>

            {/* <FormGroup required name="PIN 24H" cols={[4, 6, 6]}>
              <Controller
                name="pin"
                control={control}
                render={({ onChange, value }) => (
                  <FormInput
                    type="password"
                    onChange={onChange}
                    value={value}
                    error={errors.pin?.message}
                    maxLength={6}
                  />
                )}
              />
            </FormGroup> */}
          </Flex>

          <PanelBottomActions>
            <>{handleBotaoConfirmar()}</>
            <>{handleBotaoPin()}</>
          </PanelBottomActions>
        </Content>
      </Form>
    </Modal>
  );
};

export default ModalAssinatura;
