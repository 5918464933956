import React, { createContext, useCallback, useState, useContext } from 'react';

interface ITravaPmContextData {
  idTravaPm: number | undefined;
  updateIdTravaPm(id: number): Promise<void>;
}

const TravaPmContext = createContext<ITravaPmContextData>(
  {} as ITravaPmContextData,
);

const TravaPmProvider: React.FC = ({ children }) => {
  const [idTravaPm, setIdTravaPm] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-escalas:id_travapm');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdTravaPm = useCallback(async (id: number) => {
    setIdTravaPm(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_travapm',
      JSON.stringify(id),
    );
  }, []);

  return (
    <TravaPmContext.Provider
      value={{
        idTravaPm,
        updateIdTravaPm,
      }}
    >
      {children}
    </TravaPmContext.Provider>
  );
};

function useTravaPm(): ITravaPmContextData {
  const context = useContext(TravaPmContext);

  if (!context) {
    throw new Error('useTravaPm must be used within an ModalidadeirsoProvider');
  }

  return context;
}

export { TravaPmProvider, useTravaPm };
