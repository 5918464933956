// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react';
import {
  Route as ReactDomRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import DefaultLayout from '../pages/_layouts/default';
import PrivateLayout from '../pages/_layouts/private';

interface IRouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isPublicPrivate?: boolean;
  component?: any;
  perfis?: string[];
}

const Route: React.FC<IRouteProps> = (
  { isPrivate = false, isPublicPrivate = false, component: Component, perfis },
  ...rest
) => {
  const { user } = useAuth();

  if (user) {
    const isPrivateOrPublicPrivate = isPrivate || isPublicPrivate;

    if (!isPrivateOrPublicPrivate) return <Redirect to="/home" />;

    if (!Component) return <Redirect to="/home" />;

    if (perfis && !perfis.includes(user.currentPerfil as string)) {
      return <Redirect to="/home" />;
    }
  } else {
    const isPrivateOrComponentExists = isPrivate || !Component;

    if (isPrivateOrComponentExists) return <Redirect to="/" />;
  }

  const Layout = !!user && isPrivate ? PrivateLayout : DefaultLayout;
  return (
    <ReactDomRoute
      {...rest}
      render={(props) => (
        <Layout>
          <Component />
        </Layout>
      )}
    />
  );
};

export default Route;
