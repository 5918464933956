import React, { createContext, useCallback, useState, useContext } from 'react';

interface ITipoTravaContextData {
  idTipoTrava: number | undefined;
  updateIdTipoTrava(id: number): Promise<void>;
}

const TipoTravaContext = createContext<ITipoTravaContextData>(
  {} as ITipoTravaContextData,
);

const TipoTravaProvider: React.FC = ({ children }) => {
  const [idTipoTrava, setIdTipoTrava] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-escalas:id_tipotrava');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdTipoTrava = useCallback(async (id: number) => {
    setIdTipoTrava(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_tipotrava',
      JSON.stringify(id),
    );
  }, []);

  return (
    <TipoTravaContext.Provider
      value={{
        idTipoTrava,
        updateIdTipoTrava,
      }}
    >
      {children}
    </TipoTravaContext.Provider>
  );
};

function useTipoTrava(): ITipoTravaContextData {
  const context = useContext(TipoTravaContext);

  if (!context) {
    throw new Error(
      'useTipoTrava must be used within an ModalidadeirsoProvider',
    );
  }

  return context;
}

export { TipoTravaProvider, useTipoTrava };
