import { useCallback, useRef, useState } from 'react';

type IContextGoogleMaps = {
  mapa?: HTMLDivElement;
  place?: google.maps.places.AutocompletePrediction;
  placeDetails?: google.maps.places.PlaceResult;
  placeDetaisPosition?: google.maps.LatLngLiteral;
  handleUpdatePlace: (
    data?: google.maps.places.AutocompletePrediction | undefined,
  ) => void;
  handleUpdatePlaceDetails: (details?: google.maps.places.PlaceResult) => void;
  handleUpdatePlaceDetailsPosition: (
    details?: google.maps.LatLngLiteral,
  ) => void;

  handleUpdateNewPosition: (e: google.maps.MapMouseEvent) => Promise<void>;

  handleRequestFuntionPlaceService: (
    placeId: string,
    callback: (
      a: google.maps.places.PlaceResult | null,
      b: google.maps.places.PlacesServiceStatus,
    ) => void,
  ) => void;

  handleUpdateDetails: (
    data?: google.maps.places.PlaceResult | undefined,
  ) => void;
};

export const useGoogleMaps = (): IContextGoogleMaps => {
  const service = new google.maps.Geocoder();
  const mapa = document.getElementById('mapa') as HTMLDivElement | undefined;
  const place = useRef<google.maps.places.AutocompletePrediction>();
  const [
    placeDetails,
    setPlaceDetails,
  ] = useState<google.maps.places.PlaceResult>();
  const [
    placeDetaisPosition,
    setPlaceDetaisPosition,
  ] = useState<google.maps.LatLngLiteral>();

  const handleUpdatePlaceDetails = useCallback(
    (details?: google.maps.places.PlaceResult) => {
      setPlaceDetails(details);
    },
    [],
  );

  const handleUpdatePlaceDetailsPosition = useCallback(
    (details?: google.maps.LatLngLiteral) => {
      setPlaceDetaisPosition(details);
    },
    [],
  );

  const handleUpdatePlace = useCallback(
    (data?: google.maps.places.AutocompletePrediction) => {
      place.current = data;
    },
    [place],
  );

  const handleUpdateNewPosition = async (
    e: google.maps.MapMouseEvent,
  ): Promise<void> => {
    handleUpdatePlaceDetailsPosition({
      lat: e.latLng?.lat() as number,
      lng: e.latLng?.lng() as number,
    });
    const novaLocalizacaoRua = await service.geocode({
      location: e.latLng,
    });
    handleUpdatePlaceDetails(novaLocalizacaoRua.results[0]);
  };

  const handleRequestFuntionPlaceService = useCallback(
    (
      placeId: string,
      callback: (
        a: google.maps.places.PlaceResult | null,
        b: google.maps.places.PlacesServiceStatus,
      ) => void,
    ): void => {
      const servicePlaces = new google.maps.places.PlacesService(
        mapa as HTMLDivElement,
      );

      servicePlaces.getDetails(
        {
          placeId,
          fields: ['geometry', 'formatted_address', 'address_components'],
          sessionToken: process.env.GOOGLE_API_TOKEN,
        },
        callback,
      );
    },
    [mapa],
  );

  const handleUpdateDetails = useCallback(
    (data?: google.maps.places.PlaceResult) => {
      handleUpdatePlaceDetailsPosition({
        lat: data?.geometry?.location?.lat() as number,
        lng: data?.geometry?.location?.lng() as number,
      });
      handleUpdatePlaceDetails(data || undefined);
    },
    [handleUpdatePlaceDetails, handleUpdatePlaceDetailsPosition],
  );

  return {
    mapa,
    place: place.current,
    placeDetails,
    placeDetaisPosition,
    handleUpdatePlaceDetails,
    handleUpdatePlaceDetailsPosition,
    handleUpdatePlace,
    handleUpdateNewPosition,
    handleRequestFuntionPlaceService,
    handleUpdateDetails,
  };
};
