import React, { useCallback } from 'react';
import {
  MdEdit,
  MdDashboard,
  MdSearch,
  MdLibraryBooks,
  MdOutlineSettings,
} from 'react-icons/md';
import { RiAdminFill } from 'react-icons/ri';

import {
  Accordion,
  Center,
  Flex,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import { GiBookshelf } from 'react-icons/gi';
import Logo from '../components/Logo';
import MenuItem from './Menu';
import MenuDropdown from './MenuDropdown';
import LogoCetic from '../../../../assets/logo-cetic-35px.png';
import { Container, HeaderMenu, Footer } from './styles';
import { useAuth } from '../../../../contexts/auth';

interface ISideBarProps {
  activated: boolean;
  handleActiveSideBar(): void;
}

const cadastrosBCG = [
  { label: 'SITUAÇÂO - VOLUNTÁRIO', to: '/voluntariosituacoes' },
];

const cadastrosEscalantes = [
  { label: 'ESCALA - DRSO', to: '/criarescalairso' },
];

const cadastrosCGO = [{ label: 'FISCAL OPERAÇÃO', to: '/fiscaisoperacao' }];

const consultasControladoria = [
  { label: 'ESCALA DRSO', to: '/listarescalairso' },
];

const consultaHoraUnidade = [
  { label: 'TOTAL HORAS POR PM', to: '/horas_unidade' },
];

const consultasESCALANTES = [
  { label: 'VOLUNTÁRIOS', to: '/voluntariosopm' },
  { label: 'EXTRATO', to: '/saldo' },
];

const relatoriosCGO = [
  { label: 'PLANILHA - COAFI', to: '/relatorios/pagamento_cgo' },
  { label: 'ESCALAS - IMPRESSÃO', to: '/relatorios/imprimirescalas' },
  { label: 'ESCALAS - OPERAÇÕES', to: '/relatorios/operacoes' },
  // { label: 'ESCALAS - GASTOS', to: '/relatorios/gastos' },
];

const relatoriosCoafi = [
  { label: 'PLANILHA - CONVERSAO', to: '/converterplanilha' },
  // { label: 'ESCALAS - GASTOS', to: '/relatorios/gastos' },
];

const relatoriosCGOADM = [
  { label: 'PORTARIA - FINANCEIRO', to: '/relatorios/ciclo_ano' },
  {
    label: 'PORTARIA - PROMOÇÕES',
    to: '/relatorios/atualizar_escalas_promocoes',
  },
  {
    label: 'RELATORIO DRSO - FALTAS',
    to: '/relatorios/ciclo_ano/faltas',
  },
  { label: 'QUANTITATIVO DIÁRIO', to: '/relatorios/diario' },
];

const administracao = [
  { label: 'CICLO - CŔEDITO', to: '/saldogeral/novo' },
  { label: 'CICLO - PREVISÃO', to: '/previsaosaldo' },
  { label: 'ESCALA - CATEGORIA', to: '/categoriairso' },
  { label: 'ESCALA - OPERAÇÃO', to: '/subcategoriairso' },
  { label: 'ESCALA - PROCESSO', to: '/listarprocesso' },
  { label: 'ESCALA - FUNÇÕES', to: '/funcoes' },
  { label: 'TRAVA - TIPOS', to: '/listartipotrava' },
  { label: 'TRAVA - OPM', to: '/listartravaopm' },
  { label: 'TRAVA - OPM HORA', to: '/listartravaopmhora' },
  { label: 'TRAVA - PM', to: '/listartravapm' },
  { label: 'TRAVA - ESCALA HORAS', to: '/escalatravaabertura' },
];

const cetic = [{ label: 'TIPOS OPERAÇÕES', to: '/tiposoperacoes' }];

const perfisAdministracao = ['CETIC', 'CGO', 'CGO - GESTÃO'];

const SideBar: React.FC<ISideBarProps> = ({
  activated,
  handleActiveSideBar,
}) => {
  const { user } = useAuth();
  const bg = useColorModeValue('green.500', '#5b5b58');
  const color = useColorModeValue('gray.500', 'white');

  const MenuDropdownCadastros = useCallback(() => {
    const optionsCadastros = {
      CETIC:
        user.currentOpm?.uni_codigo.toString() !== '-1'
          ? [...cadastrosBCG, ...cadastrosEscalantes, ...cadastrosCGO]
          : [...cadastrosBCG, ...cadastrosCGO],
      'USUÁRIO PADRÃO': cadastrosBCG,
      ESCALANTE:
        user.currentOpm?.uni_codigo.toString() !== '-1'
          ? cadastrosEscalantes
          : undefined,
      CGO: cadastrosCGO,
    };

    const perfis =
      optionsCadastros[
        user.currentPerfil as 'CETIC' | 'USUÁRIO PADRÃO' | 'ESCALANTE'
      ];

    return (
      perfis !== undefined && (
        <MenuDropdown
          label="CADASTROS"
          icon={MdEdit}
          items={perfis}
          activated={activated}
          handleActiveSideBar={handleActiveSideBar}
        />
      )
    );
  }, [user.currentPerfil, activated, handleActiveSideBar, user.currentOpm]);

  const MenuDropdownConsultas = useCallback(() => {
    const optionsConsultas = {
      CONTROLADORIA: consultasControladoria,
      COMANDANTE: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
      ],
      ESCALANTE: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
      ],
      VALIDADOR: [...consultasControladoria, ...consultaHoraUnidade],
      CGO: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
        { label: 'ESCALAS - FERIADOS', to: '/escalasferiados' },
      ],
      CETIC: [
        ...consultasControladoria,
        ...consultasESCALANTES,
        ...consultaHoraUnidade,
        { label: 'ESCALAS - FERIADOS', to: '/escalasferiados' },
      ],
    };

    const consultas =
      optionsConsultas[
        user.currentPerfil as 'CETIC' | 'CGO' | 'CONTROLADORIA' | 'VALIDADOR'
      ];

    return (
      consultas !== undefined && (
        <MenuDropdown
          label="CONSULTA"
          icon={MdSearch}
          items={consultas}
          activated={activated}
          handleActiveSideBar={handleActiveSideBar}
        />
      )
    );
  }, [user.currentPerfil, activated, handleActiveSideBar]);

  const MenuDropdownRelatorios = useCallback(() => {
    const optionsPerfisRelatorios = {
      CGO: [...relatoriosCGO, ...relatoriosCGOADM, ...relatoriosCoafi],
      CETIC: [...relatoriosCGO, ...relatoriosCGOADM, ...relatoriosCoafi],
      'CGO - ADM': relatoriosCGOADM,
    };
    const relatorios =
      optionsPerfisRelatorios[user.currentPerfil as 'CGO' | 'CGO - ADM'];

    return (
      relatorios !== undefined && (
        <>
          <MenuDropdown
            label="RELATÓRIOS"
            icon={MdLibraryBooks}
            items={relatorios}
            activated={activated}
            handleActiveSideBar={handleActiveSideBar}
          />
        </>
      )
    );
  }, [user.currentPerfil, activated, handleActiveSideBar]);

  const MenuDropdownAdministracao = useCallback(() => {
    return (
      perfisAdministracao.includes(user.currentPerfil as string) && (
        <>
          <MenuDropdown
            label="Administração"
            icon={MdOutlineSettings}
            activated={activated}
            handleActiveSideBar={handleActiveSideBar}
            items={administracao}
          />
        </>
      )
    );
  }, [user.currentPerfil, activated, handleActiveSideBar]);

  return (
    <Container activated={activated}>
      <HeaderMenu activated={activated}>
        <Logo activated={activated} />
      </HeaderMenu>

      <Flex
        bg={bg}
        textColor="black"
        color={color}
        direction="column"
        alignItems="initial"
        flex="1"
      >
        <Accordion allowToggle>
          <MenuItem
            to="/home"
            label="INICIAL"
            icon={MdDashboard}
            activated={activated}
          />

          {MenuDropdownCadastros()}

          {MenuDropdownConsultas()}

          {MenuDropdownRelatorios()}

          {MenuDropdownAdministracao()}

          {user.currentPerfil === 'CETIC' && (
            <MenuDropdown
              label="CETIC"
              icon={RiAdminFill}
              activated={activated}
              handleActiveSideBar={handleActiveSideBar}
              items={cetic}
            />
          )}
        </Accordion>

        <MenuItem
          to="/documentos"
          label="DOCUMENTOS"
          icon={GiBookshelf}
          activated={activated}
        />
      </Flex>
      <Footer activated={activated}>
        <Center w="100%">
          {activated && <Image src={LogoCetic} alt="logo cetic" />}
        </Center>
      </Footer>
    </Container>
  );
};

export default SideBar;
