import React, { createContext, useCallback, useState, useContext } from 'react';

interface ITravaOpmContextData {
  idTravaOpm: number | undefined;
  updateIdTravaOpm(id: number): Promise<void>;
}

const TravaOpmContext = createContext<ITravaOpmContextData>(
  {} as ITravaOpmContextData,
);

const TravaOpmProvider: React.FC = ({ children }) => {
  const [idTravaOpm, setIdTravaOpm] = useState<number | undefined>(() => {
    const id = sessionStorage.getItem('@pmce-cetic-escalas:id_travaopm');

    if (id) {
      return JSON.parse(id);
    }

    return undefined;
  });

  const updateIdTravaOpm = useCallback(async (id: number) => {
    setIdTravaOpm(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_travaopm',
      JSON.stringify(id),
    );
  }, []);

  return (
    <TravaOpmContext.Provider
      value={{
        idTravaOpm,
        updateIdTravaOpm,
      }}
    >
      {children}
    </TravaOpmContext.Provider>
  );
};

function useTravaOpm(): ITravaOpmContextData {
  const context = useContext(TravaOpmContext);

  if (!context) {
    throw new Error(
      'useTravaOpm must be used within an ModalidadeirsoProvider',
    );
  }

  return context;
}

export { TravaOpmProvider, useTravaOpm };
