import React, { useEffect, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormGroup from 'components/form/FormGroup';
import debounce from 'debounce-promise';

import {
  VStack,
  SimpleGrid,
  Box,
  useToast,
  Stack,
  HStack,
  Flex,
  Button,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';
import FormInput from 'components/form/FormInput';
import FormTextArea from 'components/form/FormTextArea';
import { Controller } from 'react-hook-form';
import { formatDate } from '../../utils/formataData';
import DatePicker from '../../components/form/FormDatePicker';
import { Select } from '../../components/form/Select';
import BoxContent from '../../components/BoxContent';
import AsyncSelect from '../../components/form/AsyncSelect';
import api from '../../services/api';
import { useTravaOpm } from '../../contexts/travaopm';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

interface IEntrada {
  tipo_trava: number;
  data_inicial: Date;
  data_final: Date;
}
interface ITipotrava {
  nome: string;
}

interface IUnidade {
  uni_nome: string;
  uni_sigla: string;
}

interface ITravaOpm {
  id_trava_opm: number;
  uni_codigo: number;
  tipo_trava: number;
  tipoTrava: ITipotrava;
  unidade: IUnidade;
  data_inicial: Date;
  data_final: Date;
  criado_por?: string;
  criado_em?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}
type OptionType = { label: string; value: string };

const schema = Yup.object().shape({
  tipo_trava: Yup.number().required('Campo Obrigatório'),
  data_inicial: Yup.date().required('Campo Obrigatório'),
  data_final: Yup.date().required('Campo Obrigatório'),
});

const ShowTravaOpm: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();
  const [travaOpm, setTravaOpm] = useState<ITravaOpm>();
  const { idTravaOpm } = useTravaOpm();

  const { errors, handleSubmit, register, control } = useForm<IEntrada>({
    resolver: yupResolver(schema),
    defaultValues: {
      tipo_trava: travaOpm?.tipo_trava,
      data_inicial: travaOpm?.data_inicial,
      data_final: travaOpm?.data_final,
    },
  });
  const [pecaSelecionada, setPecaSelecionada] = useState<
    OptionType | undefined
  >(undefined);
  const [fornecedoresDisponiveis, setFornecedoresDisponiveis] = useState<any>(
    [],
  );

  const handleSair = (): void => {
    history.push('/listartravaopm');
  };

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`unidades/async?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.unidades.map((item: any) => {
        return {
          value: item.uni_codigo,
          label: item.uni_sigla,
        };
      });

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );
  const [erroItem, setErroItem] = useState(false);

  const handleAdicionarTravaOpm = async (data: any): Promise<any> => {
    if (pecaSelecionada) {
      setErroItem(false);
      const dados = {
        ...data,
        uni_codigo: pecaSelecionada.value,
        criado_por: user.matricula,
      };
      try {
        const inserirTipoTrava = await api.post(`/travaopm`, dados);
        toast({
          title: 'Sucesso.',
          description: 'Cadastro de tipo de trava inserido com sucesso',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/listartravaopm');
      } catch (error) {
        toast({
          title: 'Erro',
          description: 'Erro ao inserir o trava Opm',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    } else {
      setErroItem(true);
    }
  };

  useEffect(() => {
    const fornecedores = async (): Promise<any> => {
      const listaFornecedores = await api.get('/tipotrava');
      const optionsFornecedores = listaFornecedores.data.items.map(
        (fornecedor: any) => {
          return {
            value: fornecedor.id_tipo_trava,
            label: fornecedor.nome,
          };
        },
      );
      setFornecedoresDisponiveis(optionsFornecedores);
    };
    const loadCounts = async (): Promise<void> => {
      try {
        const categoriaGet = await api.get(`/travaopm/${idTravaOpm}`);
        setTravaOpm(categoriaGet.data);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();

    fornecedores();
  }, [idTravaOpm]);

  return (
    <>
      {travaOpm && (
        <Stack>
          <TituloPagina title="Trava de OPM" />
          <BoxContent>
            <Box>
              <VStack spacing="4">
                <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
                  <FormGroup name="Opm">
                    <FormInput
                      name="teste"
                      disabled
                      defaultValue={travaOpm?.unidade.uni_sigla}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Tipo Trava">
                    <FormInput
                      name="trava"
                      disabled
                      defaultValue={travaOpm?.tipoTrava.nome}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Data Inicial">
                    <FormInput
                      name="inicio"
                      disabled
                      defaultValue={formatDate(
                        String(travaOpm.data_inicial),
                        'dd/MM/yyyy',
                      )}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Data Final">
                    <FormInput
                      name="fim"
                      disabled
                      defaultValue={formatDate(
                        String(travaOpm.data_final),
                        'dd/MM/yyyy',
                      )}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Registro Cadastrado Por">
                    <FormInput
                      name="cadastro"
                      disabled
                      defaultValue={travaOpm?.criado_por}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Registro Criado Em">
                    <FormInput
                      name="cadastro"
                      disabled
                      defaultValue={formatDate(
                        String(travaOpm.criado_em),
                        'dd/MM/yyyy',
                      )}
                      register={register}
                    />
                  </FormGroup>
                </SimpleGrid>
                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Button onClick={handleSair} as="a" colorScheme="red">
                      Voltar
                    </Button>
                  </HStack>
                </Flex>
              </VStack>
            </Box>
          </BoxContent>
        </Stack>
      )}
    </>
  );
};

export default ShowTravaOpm;
