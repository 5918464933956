import {
  Button,
  Checkbox as ChakraCheckBox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react';
import React from 'react';
import { FieldError } from 'react-hook-form';
import { FaQuestionCircle } from 'react-icons/fa';

interface IInputProps extends CheckboxProps {
  name: string;
  label?: string;
  tipText?: string;
  tipPlacement?: Placement;
  mask?: string;
  error?: FieldError;
  required?: boolean;
}

const InputBase: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IInputProps
> = (
  {
    name,
    label,
    tipText,
    mask,
    tipPlacement = 'bottom',
    error = undefined,
    required = false,
    w,
    children,
    ...rest
  },
  ref,
) => {
  return (
    <FormControl isInvalid={!!error} isRequired={required} w={w}>
      <HStack alignItems="flex-start" spacing="0">
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
        {tipText && (
          <Popover placement={tipPlacement}>
            <PopoverTrigger>
              <Button
                h="20px"
                p="0px"
                w="20px"
                bg="none"
                _hover={{ bg: 'none' }}
                _active={{ bg: 'none' }}
              >
                <FaQuestionCircle size="20px" color="blue" />
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody>{tipText}</PopoverBody>
            </PopoverContent>
          </Popover>
        )}
      </HStack>
      <ChakraCheckBox
        id={name}
        name={name}
        mask={mask}
        focusBorderColor={error ? 'red.300' : '#999'}
        textTransform="uppercase"
        isInvalid={!!error}
        bgColor="#fff"
        borderColor="#ddd"
        errorBorderColor="red.300"
        _hover={{ borderColor: '#aaa' }}
        _disabled={{ bgColor: '#e3e3e3' }}
        ref={ref}
        {...rest}
      >
        {children}
      </ChakraCheckBox>
      {!!error && <FormErrorMessage mt="2px">{error.message}</FormErrorMessage>}
    </FormControl>
  );
};

export const CheckBox = React.forwardRef(InputBase);
