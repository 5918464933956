import {
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import get from 'lodash/get';
import { format, parseISO } from 'date-fns';
import { IColumns } from '../../DataTable';
import FormGroup from '../FormGroup';
import FormInput from '../FormInput';

interface IOptionsProp {
  serverData: {
    url: string;
    params?: Record<string, any>;
    headers?: string[][] | Headers | Record<string, string> | undefined;
  };
  actions: {
    headerText: string;
    items: {
      icon: JSX.Element;
      tooltip: string;
      getRow(row: Record<string, any>): void;
      handleShowAction?: (row: any) => boolean;
    }[];
  };
}

interface IProps {
  inputLabel?: string;
  options: IOptionsProp;
  isTableActive: boolean;
  columns: IColumns;
  errorMessage?: string;
  fieldManipulateArray?: string;
  canRequest: boolean;
  handleInput?: (inputValue: string) => Promise<any[]>;
  placeholder?: string;
}

const InputDataTable: React.FC<IProps> = ({
  inputLabel,
  columns,
  errorMessage,
  options: { serverData, actions },
  isTableActive,
  fieldManipulateArray,
  canRequest,
  handleInput,
  placeholder,
}) => {
  const [inputValue, setInputValue] = useState('');
  const [previousInputValue, setPreviousInputValue] = useState('');
  const [isTableVisible, setisTableVisible] = useState(false);
  const [datalist, setDatalist] = useState<any[]>([]);
  const ref = useRef<HTMLDivElement>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleClickOutside = (event: any): void => {
    if (ref.current && !ref.current.contains(event.target)) {
      setisTableVisible(false);
    }
  };

  useEffect((): (() => void) | undefined => {
    const loadTable = async (): Promise<void> => {
      let resultDataList = [];

      if (handleInput) {
        resultDataList = await handleInput(inputValue);
      } else {
        const { data: response } = await axios.get(serverData.url, {
          params: { ...serverData.params, query: inputValue },
          headers: { ...serverData.headers },
        });
        resultDataList = response;
      }
      setIsLoading(false);

      if (fieldManipulateArray)
        setDatalist(get(resultDataList, fieldManipulateArray));
      else setDatalist(resultDataList);
    };

    if (inputValue.trim().length > 0 && canRequest) {
      if (inputValue !== previousInputValue) {
        const timer = setTimeout(async () => {
          loadTable();
          setPreviousInputValue(inputValue);
        }, 1000);

        return () => clearTimeout(timer);
      }
    }

    return undefined;
  }, [
    serverData,
    inputValue,
    fieldManipulateArray,
    handleInput,
    canRequest,
    previousInputValue,
  ]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const formatValue = useCallback(
    (value, tipo) => {
      if (tipo.name === 'date') {
        try {
          return format(parseISO(value), tipo.format);
        } catch (error) {
          return value;
        }
      }
      return value;
    },

    [],
  );

  const getValue = useCallback(
    (row, column) => {
      switch (column.type.name) {
        case 'enum':
          return column.type.enum[get(row, column.field)];
        case 'date':
          return formatValue(get(row, column.field), column.type);

        case 'currency':
          try {
            return new Intl.NumberFormat('pt-BR', {
              currency: 'BRL',
              style: 'currency',
            }).format(
              !Number.isNaN(Number(String(get(row, column.field))))
                ? get(row, column.field)
                : 0,
            );
          } catch (error) {
            return get(row, column.field);
          }
        default:
          return get(row, column.field);
      }
    },
    [formatValue],
  );

  const msgPlaceHolder = placeholder || 'Digite algo...';

  return (
    <>
      {isTableActive && (
        <Flex alignItems="center" marginBottom="1rem">
          <FormGroup name={inputLabel} cols={[8, 12, 12]}>
            <FormInput
              value={inputValue}
              onChange={(e) => {
                setIsLoading(true);
                setInputValue(e.target.value);
              }}
              error={errorMessage}
              onClick={() => setisTableVisible(true)}
              placeholder={msgPlaceHolder}
            />
          </FormGroup>
        </Flex>
      )}

      <div
        style={{
          zIndex: 0,
          position: 'relative',
          width: '100%',
        }}
        ref={ref}
      >
        {isTableVisible && (
          <Table
            width="100%"
            variant="simple"
            colorScheme="green"
            backgroundColor="#f5fff5"
            borderRadius={10}
          >
            <Thead>
              <Tr>
                {columns.map((header, indexHeader) => (
                  <Th key={indexHeader}>{header.text}</Th>
                ))}
              </Tr>
            </Thead>

            <Tbody>
              {isLoading && !!inputValue ? (
                <Tr>
                  <Td colSpan={columns.length} textAlign="center">
                    <Spinner />
                  </Td>
                </Tr>
              ) : !datalist.length ? (
                <Tr>
                  <Td colSpan={columns.length} textAlign="center">
                    {inputValue
                      ? 'Nenhum resultado encontrado'
                      : msgPlaceHolder}
                  </Td>
                </Tr>
              ) : (
                datalist.map((result, index) => (
                  <Tr
                    _hover={{
                      background: 'white',
                      color: 'green.500',
                    }}
                    key={`resultLine${index}`}
                  >
                    {columns.map((resultColumn, indexColumn) => (
                      <Td key={`indexColumn${indexColumn}`}>
                        {getValue(result, resultColumn)}
                      </Td>
                    ))}

                    {actions.items.map(
                      (action, indexAction) =>
                        ((action.handleShowAction &&
                          !!action.handleShowAction(result)) ||
                          !action.handleShowAction) && (
                          <>
                            <Td id="actions">
                              <Tooltip
                                hasArrow
                                label={action.tooltip}
                                placement="left"
                                key={`action${indexAction}`}
                              >
                                <button
                                  type="button"
                                  onClick={() => {
                                    action.getRow(result);
                                    setInputValue('');
                                    setPreviousInputValue('');
                                    setisTableVisible(false);
                                    setDatalist([]);
                                  }}
                                >
                                  {action.icon}
                                </button>
                              </Tooltip>
                            </Td>
                          </>
                        ),
                    )}
                  </Tr>
                ))
              )}
            </Tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default InputDataTable;
