import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  Controller,
  FieldError,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Row from 'components/form/Row';
import { format, OptionsWithTZ } from 'date-fns-tz';
import FormGroup from 'components/form/FormGroup';
import ReactSelect from 'components/form/ReactSelect';
import {
  Box,
  Button,
  Flex,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
  useDisclosure,
} from '@chakra-ui/react';
import PanelBottomActions from 'components/PanelBottomActions';
import { FaSave, FaTrash, FaPlus, FaTimes } from 'react-icons/fa';
import debounce from 'debounce-promise';
import { FcCalendar } from 'react-icons/fc';
import { addHours, differenceInHours } from 'date-fns';
import { IColumns } from 'components/DataTable/RemoteSourceDataTable';
import DataTable from 'components/DataTable';
import Modal from 'components/Modal';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import api from '../../services/api';
import AsyncSelect from '../../components/form/AsyncSelect';
import DatePicker from '../../components/form/FormDatePicker';
import { Select } from '../../components/form/Select';

type OptionType = { label: string; value: string };

type ICategoria = { id_categoria: number; nome: string; situacao: string };

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

type ISubCategoria = {
  id_subcategoria: number;
  nome: string;
  situacao: '1' | '0';
  has_areas: '0' | '1';
};

type IAreaSubCategoria = { id_area_subcategoria: number; nome: string };

type ISchema = {
  id_categoria: number;
  id_subcategoria: number;
  data_inicio: Date;
  data_fim: Date;
  areas: { id_area_subcategoria: number; pm_codigo: string }[];
};

type IEquipeFiscalArea = {
  id_area_subcategoria: number;
  pm_codigo_fiscal: string;
  data_inicio: string;
  data_fim: string;
  fiscal: { dados: string };
};

const schema = Yup.object()
  .shape({
    id_categoria: Yup.number().required('Este campo é requerido'),
    id_subcategoria: Yup.number().required('Este campo é requerido'),
    data_inicio: Yup.date().required('Este campo é requerido'),
    data_fim: Yup.date().required('Este campo é requerido'),
    areas: Yup.array()
      .of(
        Yup.object({
          id_area_subcategoria: Yup.number().required('Este campo é requiredo'),
          pm_codigo: Yup.string().required('Este campo é requerido'),
        }).required(),
      )
      .required()
      .min(1),
  })
  .required();

const optionsEscalas = Array.from({ length: 11 }, (_, i) => ({
  label: (i + 2).toString(),
  value: (i + 2).toString(),
}));

const defaultFilters: IFilters[] = [
  {
    type: 'date' as 'date' | 'select',
    field: 'data_inicio',
    label: 'Data Início',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
    defaultOption: undefined,
  },

  {
    type: 'date' as 'date' | 'select',
    field: 'data_fim',
    label: 'Data Final',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
    defaultOption: undefined,
  },
];

export const FiscalOperacao: React.FC = () => {
  const toast = useToast();
  const [categorias, setCategorias] = useState<ICategoria[]>([]);
  const [subcategorias, setSubcategorias] = useState<ISubCategoria[]>([]);
  const areas = useRef<IAreaSubCategoria[]>([]);
  const opmsSelected = useRef<OptionType[]>([]);
  const [disabledRequest, setDisabledRequest] = useState(false);

  const refOptionEquipeFiscalArea = useRef<IEquipeFiscalArea>();

  const modalCriarFiscal = useDisclosure();
  const modalDeletarFiscal = useDisclosure();

  const {
    handleSubmit,
    control,
    errors,
    setValue,
    watch,
    reset,
  } = useForm<ISchema>({
    resolver: yupResolver(schema),
  });

  const { append, fields } = useFieldArray({ control, name: 'areas' });

  const [operacoes, setOperacoes] = useState<OptionType[]>([]);

  const optionsCategorias: OptionType[] = categorias.map((c) => ({
    label: c.nome,
    value: c.id_categoria.toString(),
  }));
  const optionsSubCategorias: OptionType[] = subcategorias.map((c) => ({
    label: c.nome,
    value: c.id_subcategoria.toString(),
  }));

  const filterOperacao: IFilters = {
    type: 'select' as 'date' | 'select',
    field: 'id_subcategoria',
    label: 'Operação',
    options: operacoes,
    cols: [2, 6, 12] as [number, number, number],
    defaultOption: undefined,
  };

  const dataInicio = watch('data_inicio');
  const dataFim = watch('data_fim');

  const columns: IColumns = [
    { field: 'nome_operacao', text: 'Operação', type: { name: 'text' } },

    {
      field: 'data_inicio',
      text: 'Início',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'fiscais.data_inicio',
    },
    {
      field: 'data_fim',
      text: 'Fim',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
      alias: 'fiscais.data_fim',
    },
    { field: 'fiscal.dados', text: 'Fiscal', type: { name: 'text' } },

    {
      field: 'nome',
      alias: 'fiscais.nome',
      text: 'Setor',
      type: { name: 'text' },
    },
  ];

  const options = {
    serverData: {
      url: `/fiscais_operacoes`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
    },

    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',
          getRow: async (data: IEquipeFiscalArea) => {
            refOptionEquipeFiscalArea.current = data;
            modalDeletarFiscal.onOpen();
          },
        },
      ],
    },
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['fiscal.dados', 'fiscais.nome', 'fiscais.nome_operacao'],
      cols: [4, 6, 12] as [number, number, number],
    },
    filters: [...defaultFilters, filterOperacao],
  };

  const handleCloseModalAddFiscal = (): void => {
    reset({
      areas: [],
      data_fim: undefined,
      data_inicio: undefined,
      id_categoria: undefined,
      id_subcategoria: undefined,
    });
    modalCriarFiscal.onClose();
  };

  const handleGetSubCategorias = async (id: number): Promise<void> => {
    const {
      data: { items },
    } = await api.get<{ items: ISubCategoria[] }>('subcategorias', {
      params: { ids_categorias: [id] },
    });

    setSubcategorias(
      items.filter((i) => i.has_areas === '1' && i.situacao === '1'),
    );
  };

  const handleGetAreas = async (id: number): Promise<void> => {
    const {
      data: { items },
    } = await api.get<{ items: IAreaSubCategoria[] }>(
      `subcategorias/${id}/areas`,
    );

    areas.current = items;
    items.forEach((i, index) => {
      append({});
    });

    items.forEach((i, index) => {
      setValue(`areas.${index}.id_area_subcategoria`, i.id_area_subcategoria);
      setValue(`areas.${index}.pm_codigo`, '');
    });
  };

  const handleSetFiscais = async ({
    data_inicio,
    data_fim,
    areas: a,
  }: ISchema): Promise<void> => {
    setDisabledRequest(true);
    const ops: OptionsWithTZ = { timeZone: 'America/Fortaleza' };

    try {
      await api.post('fiscais_operacoes', {
        data_inicio: format(data_inicio, 'yyyy-MM-dd HH:mm:ss', ops),
        data_fim: format(data_fim, 'yyyy-MM-dd HH:mm:ss', ops),
        areas: a,
      });

      toast({
        title: 'Sucesso.',
        description: 'Fiscais adicionados com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } catch (error) {
      toast({
        title: 'Ocorreu um erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  useEffect(() => {
    const load = async (): Promise<void> => {
      const {
        data: { items: cats },
      } = await api.get<{ items: ICategoria[] }>('categorias');

      setCategorias(
        cats.filter((categoriaResponse) => categoriaResponse.situacao === '1'),
      );

      const {
        data: { items },
      } = await api.get<{ items: ISubCategoria[] }>('subcategorias');

      setOperacoes([
        { value: 'TODOS', label: 'TODOS' },
        ...items
          .filter((a) => a.has_areas === '1')
          .map((i) => ({
            label: i.nome,
            value: i.id_subcategoria.toString(),
          })),
      ]);
    };

    load();
  }, []);

  const errorArea = (errors.areas as FieldError | undefined)?.message;

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`pessoas`, {
        params: { query: inputValue, gra_oficial: ['S', 'N'].join(',') },
      });

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.matricula,
          label: item.dados,
          opm: item.uni_codigo,
        };
      });

      // setPessoaList(response.data);

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  return (
    <>
      <TituloPagina title="Fiscal Operação - Irso" />
      <BoxContent>
        <FormGroup>
          <Button
            leftIcon={<FaPlus />}
            onClick={modalCriarFiscal.onOpen}
            colorScheme="blue"
          >
            Adicionar Fiscais
          </Button>
        </FormGroup>
        <TituloPagina title="" />

        <DataTable columns={columns} options={options} />
      </BoxContent>

      <Modal
        isOpen={modalCriarFiscal.isOpen}
        onClose={handleCloseModalAddFiscal}
        size="4xl"
        title="Criar Funções"
      >
        <Box as="form" onSubmit={handleSubmit(handleSetFiscais)}>
          <Row>
            <FormGroup name="Categoria" cols={[6, 6, 12]} required>
              <Controller
                control={control}
                name="id_categoria"
                render={({ onChange, value }) => (
                  <ReactSelect
                    optionsSelect={optionsCategorias}
                    onChange={(option: OptionType) => {
                      const parsedID = Number.parseInt(option.value, 10);
                      onChange(parsedID);

                      handleGetSubCategorias(parsedID);
                    }}
                    value={optionsCategorias.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errors.id_categoria?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Operação" cols={[6, 6, 12]} required>
              <Controller
                control={control}
                name="id_subcategoria"
                render={({ onChange, value }) => (
                  <ReactSelect
                    optionsSelect={optionsSubCategorias}
                    onChange={async (option: OptionType) => {
                      const idSubcategoria = Number.parseInt(option.value, 10);
                      onChange(idSubcategoria);
                      handleGetAreas(idSubcategoria);
                    }}
                    value={optionsSubCategorias.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errors.id_subcategoria?.message}
                  />
                )}
              />
            </FormGroup>
          </Row>

          <Row>
            <FormGroup name="Inicio" cols={[4, 6, 12]} required>
              <Flex direction="row" width="100%">
                <div style={{ width: '90%', marginRight: '3%' }}>
                  <Controller
                    control={control}
                    name="data_inicio"
                    render={({ onChange, value }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        selected={value}
                        onChange={(e: Date) => {
                          if (value && dataFim) {
                            const difference = differenceInHours(
                              dataFim,
                              value,
                            );
                            setValue('data_fim', addHours(e, difference));
                          }

                          onChange(e);
                        }}
                        error={errors.data_inicio?.message}
                        showTimeSelect
                      />
                    )}
                  />
                </div>
                <FcCalendar size={32} />
              </Flex>
            </FormGroup>

            <FormGroup name="Quantidade Horas" cols={[4, 6, 12]} required>
              <Select
                name="quantidade_horas_escala"
                options={[
                  { label: 'Selecione...', value: '' },
                  ...optionsEscalas,
                ]}
                onChange={(e) => {
                  const formatedValue = Number(e.currentTarget.value);

                  if (Number.isNaN(formatedValue))
                    setValue('data_fim', undefined);
                  else if (dataInicio) {
                    setValue('data_fim', addHours(dataInicio, formatedValue));
                  }
                }}
                isDisabled={!dataInicio}
              />
            </FormGroup>
            <FormGroup name="Fim" cols={[4, 6, 12]} required>
              <Flex direction="row" width="100%">
                <div style={{ width: '90%', marginRight: '3%' }}>
                  <Controller
                    control={control}
                    name="data_fim"
                    render={({ onChange, value }) => (
                      <DatePicker
                        dateFormat="dd/MM/yyyy HH:mm:ss"
                        minDate={new Date(watch('data_inicio'))}
                        selected={value}
                        onChange={(e: Date) =>
                          onChange(
                            new Date(
                              e.getFullYear(),
                              e.getMonth(),
                              e.getDate(),
                              e.getHours(),
                              e.getMinutes(),
                            ),
                          )
                        }
                        showTimeSelect
                        disabled
                        error={errors.data_fim?.message}
                      />
                    )}
                  />
                </div>
                <FcCalendar size={32} />
              </Flex>
            </FormGroup>
          </Row>

          <Table colorScheme={errorArea ? 'red' : 'green'} variant="striped">
            {errorArea && <TableCaption>{errorArea}</TableCaption>}
            <Thead>
              <Tr>
                <Th textAlign="center" verticalAlign="middle">
                  Setores
                </Th>
                <Th textAlign="center" verticalAlign="middle">
                  Fiscais
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {fields.map((f, index) => (
                <Tr key={f.id}>
                  <Td textAlign="center">
                    <Controller
                      control={control}
                      name={`areas.${index}.id_area_subcategoria`}
                      render={({ value }) => (
                        <Text mt="2.5rem">
                          {
                            areas.current.find(
                              (a) => a.id_area_subcategoria === value,
                            )?.nome
                          }
                        </Text>
                      )}
                    />
                  </Td>

                  <Td textAlign="center" verticalAlign="middle">
                    <Controller
                      control={control}
                      name={`areas.${index}.pm_codigo`}
                      render={({ value, onChange }) => (
                        <AsyncSelect
                          name="pm_codigo"
                          label="Pesquisar Pm"
                          value={opmsSelected.current.find(
                            (o) => o.value === value?.toString(),
                          )}
                          loadOptions={(inputValue: any) =>
                            delayedQuery(inputValue)
                          }
                          onChange={(valueOpm: OptionType) => {
                            opmsSelected.current.push(valueOpm);
                            onChange(valueOpm.value);
                          }}
                          error={errors.areas?.[index]?.pm_codigo?.message}
                        />
                      )}
                    />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>

          <PanelBottomActions>
            <Button
              leftIcon={<FaTimes />}
              colorScheme="yellow"
              type="button"
              onClick={handleCloseModalAddFiscal}
            >
              Cancelar
            </Button>

            <Button
              leftIcon={<FaSave />}
              colorScheme="green"
              type="submit"
              isLoading={disabledRequest}
            >
              Salvar
            </Button>
          </PanelBottomActions>
        </Box>
      </Modal>

      <Modal
        isOpen={modalDeletarFiscal.isOpen}
        onClose={modalDeletarFiscal.onClose}
        size="xl"
        title="Deletar Fiscal"
      >
        Você tem certeza de deletar este fiscal?
        <Text fontWeight="bold">
          {refOptionEquipeFiscalArea.current?.fiscal.dados}
        </Text>
        <Text fontWeight="bold">
          {' '}
          Inicio:{' '}
          {new Date(
            refOptionEquipeFiscalArea.current?.data_inicio as string,
          ).toLocaleString('pt-BR')}{' '}
          - Fim:{' '}
          {new Date(
            refOptionEquipeFiscalArea.current?.data_fim as string,
          ).toLocaleString('pt-BR')}
        </Text>
        <PanelBottomActions>
          <Button onClick={modalDeletarFiscal.onClose} colorScheme="green">
            Não
          </Button>

          <Button
            onClick={async () => {
              try {
                await api.delete(`fiscais_operacoes`, {
                  data: {
                    id_area_subcategoria:
                      refOptionEquipeFiscalArea.current?.id_area_subcategoria,
                    data_inicio: refOptionEquipeFiscalArea.current?.data_inicio,
                    data_fim: refOptionEquipeFiscalArea.current?.data_fim,
                    pm_codigo_fiscal:
                      refOptionEquipeFiscalArea.current?.pm_codigo_fiscal,
                  },
                });
                toast({
                  title: 'Sucesso.',
                  description: `Funções deletadas com sucesso!`,
                  status: 'success',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
                refOptionEquipeFiscalArea.current = undefined;
                modalDeletarFiscal.onClose();
              } catch (error) {
                toast({
                  title: 'Ocorreu um erro.',
                  description: error.response.data.message,
                  status: 'error',
                  duration: 5000,
                  isClosable: true,
                  position: 'top-right',
                });
              }
            }}
            colorScheme="red"
          >
            Sim Quero Deletar!
          </Button>
        </PanelBottomActions>
      </Modal>
    </>
  );
};
