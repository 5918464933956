import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';
import {
  Flex,
  HStack,
  SimpleGrid,
  VStack,
  Box,
  Button,
} from '@chakra-ui/react';
import FormGroup from 'components/form/FormGroup';
import FormInput from 'components/form/FormInput';

import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import BoxContent from '../../components/BoxContent';
import { Select } from '../../components/form/Select';

import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';
import { useCategoriairso } from '../../contexts/categoriairso';

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
  criado_por?: string;
  criado_em?: string;
  atualizado_em?: string;
  atualizado_por?: string;
}

const ShowCategoriaIrso: React.FC = () => {
  const { user } = useAuth();
  const { idCategoriairso } = useCategoriairso();
  const history = useHistory();

  const [categoria, setcategoria] = useState<ICategoria>();

  const optionsSitCategoriadeIrso = [
    { value: '1', label: 'Ativa' },
    { value: '0', label: 'Inativa' },
  ];

  const schema = Yup.object().shape({
    nomeCategoria: Yup.string()
      .required('Informe o nome da Categoria da DRSO')
      .min(3, 'Nome digitado muito pequeno'),
  });

  const { errors, handleSubmit, register } = useForm<ICategoria>({
    resolver: yupResolver(schema),
    defaultValues: {
      id_categoria: categoria?.id_categoria,
      nome: categoria?.nome,
      situacao: categoria?.situacao,
      criado_por: categoria?.criado_por,
      criado_em: categoria?.criado_em,
      atualizado_em: categoria?.atualizado_em,
      atualizado_por: categoria?.atualizado_por,
    },
  });

  const handleSair = (): void => {
    history.push('/categoriairso');
  };

  useEffect(() => {
    const loadCounts = async (): Promise<void> => {
      try {
        const categoriaGet = await api.get(`/categorias/${idCategoriairso}`);
        setcategoria(categoriaGet.data);
      } catch (error) {
        console.log('Ocorreu um erro');
      }
    };
    loadCounts();
  }, [user]);
  return (
    <>
      {categoria && (
        <>
          <TituloPagina title={`Categoria de DRSO - ${categoria.nome}`} />
          <BoxContent>
            <Box
              as="form"
              flex="1"
              onSubmit={handleSubmit(() => console.log('teste'))}
            >
              <VStack spacing="4">
                <SimpleGrid minChildWidth="300px" spacing={['2', '4']} w="100%">
                  <FormGroup name="Nome">
                    <FormInput
                      name="nomeCategoria"
                      disabled
                      defaultValue={categoria.nome}
                      register={register}
                    />
                  </FormGroup>
                  <FormGroup name="Situação">
                    <Select
                      placeholder="Selecione uma Opção"
                      isDisabled
                      name="situacaoProcesso"
                      defaultValue={categoria.situacao}
                      ref={register}
                      options={optionsSitCategoriadeIrso}
                    />
                  </FormGroup>
                  <FormGroup name="Cadastrado Por">
                    <FormInput
                      name="criadoPor"
                      disabled
                      defaultValue={categoria.criado_por}
                      register={register}
                    />
                  </FormGroup>
                </SimpleGrid>
                <Flex mt="8" justify="flex-end">
                  <HStack spacing="4">
                    <Button as="a" colorScheme="red" onClick={handleSair}>
                      Voltar
                    </Button>
                  </HStack>
                </Flex>
              </VStack>
            </Box>
          </BoxContent>
        </>
      )}
    </>
  );
};

export default ShowCategoriaIrso;
