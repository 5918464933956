import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Box,
  useToast,
  HStack,
  Button as ButtonChacrka,
  Text,
} from '@chakra-ui/react';
import { MdBlock } from 'react-icons/md';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns-tz';
import debounce from 'debounce-promise';
import { parse } from 'date-fns';
import PanelBottomActions from '../../components/PanelBottomActions';
import Row from '../../components/form/Row';
import FormGroup from '../../components/form/FormGroup';
import AsyncSelect from '../../components/form/AsyncSelect';
import api from '../../services/api';
import FormDatePicker from '../../components/form/FormDatePicker';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import { useTravaOpm } from '../../contexts/travaopm';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type IGrandesComando = {
  uni_codigo: number;
  uni_sigla: string;
};

interface ITipotrava {
  nome: string;
}

interface IUnidade {
  uni_nome: string;
  grandeComando: IUnidade;
  unidadePai: IUnidade;
}

interface ITravaOpm {
  id_trava_opm: number;
  uni_codigo: number;
  tipo_trava: number;
  tipoTrava: ITipotrava;
  unidade: IUnidade;
  data_inicial: string;
  data_final: string;
}

type OptionType = { label: string; value: string };

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

interface ISchema {
  data_inicial: Date;
  data_final: Date;
  subunidades: '0' | '1';
  uni_codigo: number;
}

interface ISchemaEditar {
  data_inicial: Date;
  data_final: Date;
  subunidades: '0' | '1';
}

interface IRequestEscalas {
  opm: number;
  data_inicio: Date;
  data_fim: Date;
  subunidades: '0' | '1';
}

const defaultFilters: IFilters[] = [
  {
    type: 'date' as 'date' | 'select',
    field: 'data_inicio',
    label: 'Data Início',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'date' as 'date' | 'select',
    field: 'data_fim',
    label: 'Data Final',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },
];

const schema = Yup.object().shape({
  data_inicial: Yup.date()
    .required('Campo Obrigatório')
    .typeError('Campo Obrigatório'),
  data_final: Yup.date()
    .required('Campo Obrigatório')
    .typeError('Campo Obrigatório'),
  subunidades: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf(['0', '1'], 'Este campo é obrigatório'),
  uni_codigo: Yup.number()
    .required('Este campo é obrigatório')
    .typeError('Este campo é obrigatório'),
});

const schemaEditar = Yup.object().shape({
  data_inicial: Yup.date().required('Campo Obrigatório'),
  data_final: Yup.date().required('Campo Obrigatório'),
  subunidades: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf(['0', '1'], 'Este campo é obrigatório'),
});

const ListTravaOpm: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [tipoTravaModal, setTipoTravaModal] = useState<ITravaOpm>();
  const [grandesComandos, setGrandesComandos] = useState<OptionType[]>([]);
  const [filters, setFilters] = useState<IFilters[]>(defaultFilters);
  const [disabledRequest, setDisabledRequest] = useState(false);

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isTravaSubUnidade,
    onOpen: onOpenTravaSubUnidade,
    onClose: onCloseTravaSubUnidade,
  } = useDisclosure();

  const {
    isOpen: isOpenVisualizarTrava,
    onOpen: onOpenVisualizarTrava,
    onClose: onCloseVisualizarTrava,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarTravaOPM,
    onOpen: onOpenEditarTravaOPM,
    onClose: onCloseEditarTravaOPM,
  } = useDisclosure();

  const { errors, handleSubmit, control, reset } = useForm<ISchema>({
    resolver: yupResolver(schema),
    defaultValues: {
      subunidades: '0',
      data_inicial: '',
      data_final: '',
      uni_codigo: Number.NaN,
    },
  });

  const {
    errors: errorsEditar,
    handleSubmit: handleSubmitEditar,
    control: controlEditar,
  } = useForm<ISchemaEditar>({
    resolver: yupResolver(schemaEditar),
    defaultValues: {
      subunidades: '0',
    },
  });

  const [opmFormSelected, setOpmFormSelected] = useState<OptionType>();

  const { updateIdTravaOpm, idTravaOpm } = useTravaOpm();

  const handleCheckEscalasBlocked = async (
    filtersEscalas: IRequestEscalas,
  ): Promise<void> => {
    const {
      data: { items },
    } = await api.get<{ items: any[] }>('/escalas/irsos', {
      params: {
        ...filtersEscalas,
        id_status_escala: 3,
        page: 1,
        perPage: 1,
      },
    });

    if (items.length > 1) {
      toast({
        title: 'Aviso.',
        description:
          'Há escalas fechadas no período incompatíveis com a trava solicitada, estas não foram afetadas',
        status: 'warning',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleAdicionarTravaOpm = async ({
    data_final,
    data_inicial,
    ...rest
  }: ISchema): Promise<any> => {
    const dados = {
      ...rest,
      tipo_trava: 1,
      data_final: format(data_final, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      data_inicial: format(data_inicial, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
    };
    try {
      await api.post(`/travaopm`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Cadastro de tipo de trava inserido com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      await handleCheckEscalasBlocked({
        opm: rest.uni_codigo,
        subunidades: rest.subunidades,
        data_fim: data_final,
        data_inicio: data_inicial,
      });
      reset();
      setOpmFormSelected(undefined);
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao inserir o trava Opm',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdateTravaOpm = async ({
    data_final,
    data_inicial,
    ...rest
  }: ISchemaEditar): Promise<any> => {
    const dados = {
      ...rest,
      tipo_trava: 1,
      data_final: format(data_final, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      data_inicial: format(data_inicial, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
    };
    try {
      setDisabledRequest(true);
      await api.put(`/travaopm/${tipoTravaModal?.id_trava_opm}`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Tipo de trava alterada com sucesso com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarTravaOPM();
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao atualizar tipo de trava',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }

    try {
      await handleCheckEscalasBlocked({
        opm: tipoTravaModal?.uni_codigo as number,
        subunidades: rest.subunidades,
        data_fim: data_final,
        data_inicio: data_inicial,
      });
    } catch (error) {
      console.log('deu erro');
    }
  };

  const handleExcluirTravaOpm = async (data: ITravaOpm): Promise<any> => {
    try {
      await api.put(`/travaopm/deletar/${data.id_trava_opm}`, {
        atualizado_por: user.matricula,
      });
      toast({
        title: 'Sucesso.',
        description: 'Trava de Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    }
  };

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`unidades/async?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.unidades.map((item: any) => {
        return {
          value: item.uni_codigo,
          label: item.uni_sigla,
        };
      });

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  const handleTravarSubunidades = async (): Promise<void> => {
    try {
      setDisabledRequest(true);
      await api.post(`travaopm/${idTravaOpm}/subunidades`);
      toast({
        title: 'Sucesso.',
        description: 'Trava de Horas Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseTravaSubUnidade();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response?.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }

    try {
      await handleCheckEscalasBlocked({
        opm: tipoTravaModal?.uni_codigo as number,
        subunidades: '1',
        data_fim: parse(
          tipoTravaModal?.data_final as string,
          'dd/MM/yyyy',
          new Date(),
        ),
        data_inicio: parse(
          tipoTravaModal?.data_inicial as string,
          'dd/MM/yyyy',
          new Date(),
        ),
      });
    } catch (error) {
      console.log('erro de requisicao');
    }
  };

  const handleExcluirTravaOpmSubunidades = async (
    data: ITravaOpm,
  ): Promise<any> => {
    try {
      setDisabledRequest(true);
      await api.delete(`/travaopm/${data.id_trava_opm}/subunidades`);
      toast({
        title: 'Sucesso.',
        description: 'Trava de Horas Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } finally {
      setDisabledRequest(false);
    }
  };

  const colunas: IColumns = [
    {
      field: 'unidade.uni_sigla',
      text: 'Opm',
      type: {
        name: 'text',
      },
      alias: 'unidade.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpm) => unidade.uni_nome,
    },
    {
      field: 'unidade.grandeComando.uni_sigla',
      text: 'Grande Comando',
      type: {
        name: 'text',
      },
      alias: 'grandeComando.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpm) => unidade.grandeComando.uni_nome,
    },
    {
      field: 'unidade.unidadePai.uni_sigla',
      text: 'Unidade Pai',
      type: {
        name: 'text',
      },
      alias: 'unidadePai.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpm) => unidade.unidadePai.uni_nome,
    },
    {
      field: 'data_inicial',
      text: 'Data Inicial',
      type: {
        name: 'text',
      },
      alias: 'travasOpms.data_inicial',
    },
    {
      field: 'data_final',
      text: 'Data Final',
      type: {
        name: 'text',
      },
      alias: 'travasOpms.data_final',
    },
  ];

  const options = {
    serverData: {
      url: `/travaopm`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ITravaOpm) => {
        //     setTipoTravaModal(uniforme);
        //     onOpenVisualizarTrava();
        //   },
        // },
        {
          icon: <MdBlock size={13} />,
          tooltip: 'Travar SubUnidades',

          getRow: (uniforme: ITravaOpm) => {
            onOpenTravaSubUnidade();
            updateIdTravaOpm(uniforme.id_trava_opm);
            setTipoTravaModal(uniforme);
          },
        },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: ITravaOpm) => {
            setTipoTravaModal(uniforme);
            onOpenEditarTravaOPM();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },

    filters,

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['unidade.uni_sigla', 'unidade.uni_nome'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  useLayoutEffect(() => {
    if (Number(user.currentOpm?.uni_codigo as string) === -1) {
      setFilters([
        {
          type: 'select' as 'date' | 'select',
          field: 'grandes_comandos',
          label: 'Grande Comando',
          options: [{ label: 'Todos', value: '' }, ...grandesComandos],
          defaultOption: '',

          cols: [2, 6, 12] as [number, number, number],
        },

        ...defaultFilters,
      ]);
    } else setFilters(defaultFilters);
  }, [user.currentOpm, grandesComandos]);

  useEffect(() => {
    const loadGrandesComandos = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IGrandesComando[] }>(
        '/unidades/grandes_comandos',
      );

      setGrandesComandos(
        items.map((item) => ({
          label: item.uni_sigla,
          value: item.uni_codigo.toString(),
        })),
      );
    };

    loadGrandesComandos();
  }, []);

  return (
    <>
      <>
        <TituloPagina title="Trava de OPM" />
        <BoxContent>
          <form
            onSubmit={handleSubmit(handleAdicionarTravaOpm)}
            style={{ marginBottom: 8 }}
          >
            <TituloPagina title="Cadastro" />

            <Row>
              <FormGroup name="Pesquisar Opm" cols={[4, 6, 12]}>
                <Controller
                  name="uni_codigo"
                  control={control}
                  render={({ onChange }) => (
                    <AsyncSelect
                      name="uni_codigo"
                      label="Pesquisar Opm"
                      value={opmFormSelected}
                      loadOptions={(inputValue: string) =>
                        delayedQuery(inputValue)
                      }
                      onChange={(value: any) => {
                        setOpmFormSelected(value);
                        onChange(value.value);
                      }}
                      error={errors.uni_codigo?.message}
                    />
                  )}
                />
              </FormGroup>
              <FormGroup name="SubUnidades" cols={[2, 2, 12]}>
                <Controller
                  name="subunidades"
                  control={control}
                  render={({ onChange, value }) => (
                    <Checkbox
                      isChecked={value === '1'}
                      onChange={() => onChange(value === '0' ? '1' : '0')}
                    >
                      Sim
                    </Checkbox>
                  )}
                />
              </FormGroup>

              <FormGroup name="Data Inicial" cols={[2, 6, 12]}>
                <Controller
                  name="data_inicial"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      selected={value}
                      error={errors.data_inicial?.message}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(e.getFullYear(), e.getMonth(), e.getDate()),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup name="Data Final" cols={[2, 6, 12]}>
                <Controller
                  name="data_final"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      error={errors.data_final?.message}
                      selected={value}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(
                            e.getFullYear(),
                            e.getMonth(),
                            e.getDate(),
                            23,
                            59,
                            59,
                          ),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <Button leftIcon={<FaSave />} colorScheme="green" type="submit">
              Salvar
            </Button>
          </form>

          <Divider orientation="horizontal" border="2px" />

          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
            >
              Listagem de Tipos de Trava - {user.currentOpm?.uni_sigla}{' '}
              {user.verSubunidade === '1' &&
                user.currentOpm?.uni_codigo.toString() !== '-1' &&
                'e subunidades'}
            </Text>
          </Box>

          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
          {tipoTravaModal && (
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="xl"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Trava de Opm{' '}
                <strong>{tipoTravaModal.tipoTrava.nome}</strong> da unidade{' '}
                <strong>{tipoTravaModal.unidade.uni_nome}</strong> tem certeza
                da operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <ButtonChacrka
                    onClick={onCloseVisualizar}
                    colorScheme="green"
                  >
                    Não
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() => handleExcluirTravaOpm(tipoTravaModal)}
                    colorScheme="red"
                  >
                    Deletar apenas OPM!
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() =>
                      handleExcluirTravaOpmSubunidades(tipoTravaModal)
                    }
                    colorScheme="red"
                    isLoading={disabledRequest}
                  >
                    Deletar OPM e subordinadas
                  </ButtonChacrka>
                </HStack>
              </Flex>
            </Modal>
          )}
        </BoxContent>

        <Modal
          isOpen={isOpenVisualizarTrava}
          onClose={onCloseVisualizarTrava}
          size="4xl"
          title={`Visualizar trava - ${tipoTravaModal?.unidade.uni_nome}`}
        >
          <Row>
            <FormGroup name="Data Inicial" cols={[3, 6, 12]}>
              <FormDatePicker
                selected={parse(
                  tipoTravaModal?.data_inicial as string,
                  'dd/MM/yyyy',
                  new Date(),
                )}
                onChange={() => {
                  console.log('aqui');
                }}
                dateFormat="dd/MM/yyyy"
                disabled
              />
            </FormGroup>

            <FormGroup name="Data Final" cols={[3, 6, 12]}>
              <FormDatePicker
                selected={parse(
                  tipoTravaModal?.data_final as string,
                  'dd/MM/yyyy',
                  new Date(),
                )}
                onChange={() => {
                  console.log('aqui');
                }}
                dateFormat="dd/MM/yyyy"
                disabled
              />
            </FormGroup>
          </Row>
        </Modal>

        <Modal
          isOpen={isOpenEditarTravaOPM}
          onClose={onCloseEditarTravaOPM}
          size="xl"
          title={`Editar trava - ${tipoTravaModal?.unidade.uni_nome}`}
        >
          <form onSubmit={handleSubmitEditar(handleUpdateTravaOpm)}>
            <Row>
              <FormGroup name="Subunidades" cols={[4, 6, 12]}>
                <Controller
                  name="subunidades"
                  control={controlEditar}
                  render={({ onChange, value }) => (
                    <Checkbox
                      isChecked={value === '1'}
                      onChange={() => onChange(value === '0' ? '1' : '0')}
                    >
                      Sim
                    </Checkbox>
                  )}
                />
              </FormGroup>

              <FormGroup name="Data Inicial" cols={[4, 6, 12]}>
                <Controller
                  name="data_inicial"
                  control={controlEditar}
                  defaultValue={parse(
                    tipoTravaModal?.data_inicial as string,
                    'dd/MM/yyyy',
                    new Date(),
                  )}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      selected={value}
                      error={errorsEditar.data_inicial?.message}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup name="Data Final" cols={[4, 6, 12]}>
                <Controller
                  name="data_final"
                  control={controlEditar}
                  defaultValue={parse(
                    tipoTravaModal?.data_final as string,
                    'dd/MM/yyyy',
                    new Date(),
                  )}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      error={errorsEditar.data_final?.message}
                      selected={value}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(
                            e.getFullYear(),
                            e.getMonth(),
                            e.getDate(),
                            23,
                            59,
                            59,
                          ),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <PanelBottomActions>
              <ButtonChacrka
                onClick={onCloseEditarTravaOPM}
                as="a"
                colorScheme="red"
              >
                Voltar
              </ButtonChacrka>

              <ButtonChacrka
                colorScheme="green"
                type="submit"
                isLoading={disabledRequest}
              >
                Salvar
              </ButtonChacrka>
            </PanelBottomActions>
          </form>
        </Modal>

        <Modal
          isOpen={isTravaSubUnidade}
          onClose={onCloseTravaSubUnidade}
          size="lg"
          title="Travar Subunidades"
        >
          <h1>
            Voce esta prestes a travar as subunidades da unidade{' '}
            <strong>{tipoTravaModal?.unidade.uni_nome}</strong>{' '}
          </h1>
          <Flex mt="8" justify="center">
            <HStack spacing="4">
              <ButtonChacrka onClick={onCloseVisualizar} colorScheme="red">
                Não
              </ButtonChacrka>

              <ButtonChacrka
                onClick={handleTravarSubunidades}
                colorScheme="green"
                isLoading={disabledRequest}
              >
                Sim!
              </ButtonChacrka>
            </HStack>
          </Flex>
        </Modal>

        <Modal
          isOpen={isTravaSubUnidade}
          onClose={onCloseTravaSubUnidade}
          size="lg"
          title="Travar Subunidades"
        >
          <h1>
            Voce esta prestes a travar as subunidades da unidade{' '}
            <strong>{tipoTravaModal?.unidade.uni_nome}</strong>{' '}
          </h1>
          <Flex mt="8" justify="center">
            <HStack spacing="4">
              <ButtonChacrka onClick={onCloseVisualizar} colorScheme="red">
                Não
              </ButtonChacrka>

              <ButtonChacrka
                onClick={handleTravarSubunidades}
                colorScheme="green"
                isLoading={disabledRequest}
              >
                Sim!
              </ButtonChacrka>
            </HStack>
          </Flex>
        </Modal>
      </>
    </>
  );
};

export default ListTravaOpm;
