import React from 'react';
import { Box } from '@chakra-ui/react';
import TituloPagina from '../../components/TituloPagina';
import BoxContent from '../../components/BoxContent';
import api from '../../services/api';
import { useAuth } from '../../contexts/auth';
import DataTable, { IColumns } from '../../components/DataTable';

const VoluntariosOpms: React.FC = () => {
  const { user } = useAuth();

  const textoTitulo = `Voluntarios da OPM ${user.currentOpm?.uni_sigla}`;

  const options = {
    serverData: {
      url: `/voluntarios/opms/${user.currentOpm?.uni_codigo}`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}`,
    },
    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['pessoa.pes_nome', 'voluntarios.pm_codigo'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  const columns: IColumns = [
    {
      field: 'data_voluntario',
      text: 'Data da situação',
      type: { name: 'date', format: 'dd/MM/yyyy HH:mm:ss' },
    },
    {
      field: 'pm.graduacao.gra_sigla',
      text: 'Graduação',
      type: { name: 'text' },
    },
    {
      field: 'pm.pessoa.pes_nome',
      text: 'Nome',
      type: { name: 'text' },
    },
    {
      field: 'pm.pm_codigo',
      text: 'Matrícula',
      type: { name: 'text' },
    },
  ];

  return (
    <>
      <TituloPagina
        title={`${textoTitulo} ${
          user.verSubunidade === '1' ? 'e subunidades' : ''
        }`}
      />

      <BoxContent>
        <Box>
          <DataTable columns={columns} options={options} />
        </Box>
      </BoxContent>
    </>
  );
};

export default VoluntariosOpms;
