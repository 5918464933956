/* eslint-disable import/no-extraneous-dependencies */
import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { Controller, useForm, FieldError } from 'react-hook-form';
import * as Yup from 'yup';
import { FcCalendar } from 'react-icons/fc';
import { addHours, isSameDay } from 'date-fns';
import { useToast } from '@chakra-ui/toast';
import { Button } from '@chakra-ui/button';
import { FaSave } from 'react-icons/fa';

import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Checkbox,
  Switch,
} from '@chakra-ui/react';
import debounce from 'debounce-promise';
import { useAuth } from '../../../contexts/auth';
import { Select } from '../../../components/form/Select';
import PanelBottomActions from '../../../components/PanelBottomActions';
import FormGroup from '../../../components/form/FormGroup';
import Row from '../../../components/form/Row';
import BoxContent from '../../../components/BoxContent';
import api from '../../../services/api';
import ReactSelect from '../../../components/form/ReactSelect';
import Input from '../../../components/form/FormInput';
import DatePicker from '../../../components/form/FormDatePicker';
import TituloPagina from '../../../components/TituloPagina';
import { useEscala } from '../../../contexts/escala';
import AsyncSelect from '../../../components/form/AsyncSelect';

const criarEscalaSchema = Yup.object().shape({
  id_categoria: Yup.number().required('Este campo é requerido'),
  id_subcategoria: Yup.number().required('Este campo é requerido'),
  nome: Yup.string()
    .required('Este campo é requerido')
    .min(10, 'Digite pelo menos 10 caracteres')
    .max(50, 'Digite no máximo 50 caracteres'),
  // eslint-disable-next-line no-control-regex
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
  data_inicio: Yup.date()
    .required('Este campo é requerido')
    .typeError('Data em formato invalido'),
  quantidade_horas_escala: Yup.number()
    .required('Este campo é requerido')
    .min(2, 'No mínimo duas horas'),
  data_fim: Yup.date()
    .required('Este campo é requerido')
    .typeError('Data em formato invalido'),
  voluntario_obrigatorio: Yup.string()
    .required('Este campo é requerido')
    .oneOf(['0', '1'], 'Este campo é requerido'),
  isValidateAreas: Yup.boolean().required(),

  ids_areas: Yup.array().of(Yup.number().required()).notRequired(),

  pm_codigo_fiscal_local: Yup.string().notRequired(),
  is_feriado_inicio: Yup.string().required().oneOf(['0', '1']),
  is_feriado_fim: Yup.string().notRequired().oneOf(['0', '1']),
});

type OptionType = { label: string; value: string };
type IAreaSubCategoria = { id_area_subcategoria: number; nome: string };

type ICriarEscalaSchema = {
  id_categoria: number;
  nome: string;
  data_inicio: Date;
  data_fim: Date;
  quantidade_horas_escala: number;
  voluntario_obrigatorio: '0' | '1';
  id_subcategoria: number;
  isValidateAreas: boolean;
  ids_areas?: number[];
  pm_codigo_fiscal_local?: string;
  is_feriado_inicio: '0' | '1';
  is_feriado_fim: '0' | '1';
};

interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
}

const optionsEscalas = Array.from({ length: 11 }, (_, i) => ({
  label: (i + 2).toString(),
  value: (i + 2).toString(),
}));

type IResponseCreateEscala = { id_escala: number };

const optionsObrigatoriedadeIrso: OptionType[] = [
  { label: 'Selecione uma opção...', value: '' },
  { label: 'Sim', value: '1' },
  { label: 'Não', value: '0' },
];

const NovaEscalaIrso: React.FC = () => {
  const {
    control,
    errors,
    handleSubmit,
    watch,
    setValue,
    trigger,
  } = useForm<ICriarEscalaSchema>({
    resolver: yupResolver(criarEscalaSchema),
    defaultValues: {
      id_categoria: undefined,
      nome: '',
      voluntario_obrigatorio: '0',
      is_feriado_inicio: '0',
      is_feriado_fim: '0',
    },
  });

  const dataInicio = watch('data_inicio');
  const dataFim = watch('data_fim');
  const categoria = watch('id_categoria');
  const subCategoria = watch('id_subcategoria');
  const isValidateAreas = watch('isValidateAreas');
  const hasFieldsDates = !!dataInicio && !!dataFim;
  const isFeriadoInicio = watch('is_feriado_inicio');

  const isSameDayEscala = hasFieldsDates && isSameDay(dataInicio, dataFim);

  const [optionsAreas, setOptionsAreas] = useState<OptionType[]>([]);

  const [disabledRequest, setDisabledRequest] = useState(false);

  const refPm = useRef<OptionType>();

  const { updateIdEscala } = useEscala();
  const { user } = useAuth();
  const toast = useToast();
  const history = useHistory();

  const handleEscala = async (data: ICriarEscalaSchema): Promise<void> => {
    setDisabledRequest(true);

    try {
      const {
        data: { id_escala },
      } = await api.post<IResponseCreateEscala>('escalas/irsos', {
        ...data,
        unidade: user.currentOpm?.uni_codigo,
      });

      toast({
        title: 'Sucesso.',
        description: `Escala criado com sucesso!`,
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      updateIdEscala(id_escala);

      history.push('/editarescalairso');
    } catch (error) {
      toast({
        title: 'Erro.',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setDisabledRequest(false);
    }
  };

  const [optionsCategorias, setOptionsCategorias] = useState<OptionType[]>([]);
  const [subcategorias, setSubcategorias] = useState<any[]>([]);

  const optionsSubCategorias = useMemo(
    () =>
      subcategorias.map((item: Record<string, any>) => ({
        label: item.nome,
        value: item.id_subcategoria.toString(),
      })),
    [subcategorias],
  );

  const subCategoriaWatch = subcategorias.find(
    (s) => s.id_subcategoria === subCategoria,
  );
  const valueHasAreasEscala = subCategoriaWatch?.has_areas !== '0';

  const handleGetAreas = useCallback(async (id: number) => {
    const {
      data: { items },
    } = await api.get<{ items: IAreaSubCategoria[] }>(
      `subcategorias/${id}/areas`,
    );

    setOptionsAreas(
      items.map((i) => ({
        label: i.nome,
        value: i.id_area_subcategoria.toString(),
      })),
    );
  }, []);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      toast({
        title: 'Erro.',
        description:
          'Campos obrigatórios não preenchidos ou campos obrigatórios em formato inválido',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  }, [errors, toast]);

  useEffect(() => {
    const load = async (): Promise<void> => {
      const {
        data: { items: categorias },
      } = await api.get<{ items: ICategoria[] }>('categorias');

      setOptionsCategorias(
        categorias
          .filter((categoriaResponse) => categoriaResponse.situacao === '1')
          .map((categoriaResponse) => ({
            label: categoriaResponse.nome,
            value: categoriaResponse.id_categoria.toString(),
          })),
      );
    };

    load();
  }, []);

  useEffect(() => {
    const loadSubCategorias = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: any[] }>('subcategorias', {
        params: { ids_categorias: [categoria] },
      });

      setSubcategorias(items);
    };

    if (categoria) loadSubCategorias();
  }, [categoria]);

  useEffect(() => {
    const loadTrigger = async (): Promise<void> => {
      await trigger('ids_areas');
    };
    if (isValidateAreas) loadTrigger();
  }, [isValidateAreas, trigger]);

  useEffect(() => {
    if (subCategoria) {
      setValue('isValidateAreas', valueHasAreasEscala);
    }
  }, [subCategoria, setValue, valueHasAreasEscala]);

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`pessoas`, {
        params: { query: inputValue, gra_oficial: ['S', 'N'].join(',') },
      });

      const data = response.data || [];

      const responseFormated = data.map((item: any) => {
        return {
          value: item.matricula,
          label: item.dados,
          opm: item.uni_codigo,
        };
      });

      // setPessoaList(response.data);

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  useEffect(() => {
    if (!isSameDayEscala) {
      setValue('is_feriado_fim', isFeriadoInicio);
    } else setValue('is_feriado_fim', isFeriadoInicio);
  }, [isSameDayEscala, setValue, isFeriadoInicio]);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  return (
    <>
      <TituloPagina title="Cadastro de escala" />
      <BoxContent>
        <Accordion allowToggle defaultIndex={0}>
          <AccordionItem border="1px solid #ddd">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton background="#eee">
                    <Box flex="1" textAlign="left">
                      Configurações da Escala
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} minH={isExpanded ? 400 : undefined}>
                  <form
                    onSubmit={handleSubmit(handleEscala)}
                    acceptCharset="iso-8859-1"
                  >
                    <Flex>
                      <FormGroup name="Categoria" cols={[4, 6, 12]} required>
                        <Controller
                          control={control}
                          name="id_categoria"
                          render={({ onChange, value }) => (
                            <ReactSelect
                              optionsSelect={optionsCategorias}
                              onChange={(option: OptionType) => {
                                onChange(Number(option.value));
                                setValue('id_subcategoria', undefined);
                              }}
                              value={optionsCategorias.find(
                                (option) => option.value === value?.toString(),
                              )}
                              error={errors.id_categoria?.message}
                            />
                          )}
                        />
                      </FormGroup>

                      <FormGroup name="Operação" cols={[4, 6, 12]} required>
                        <Controller
                          control={control}
                          name="id_subcategoria"
                          render={({ onChange, value }) => (
                            <ReactSelect
                              optionsSelect={optionsSubCategorias}
                              onChange={(option: OptionType) => {
                                const idSubCategoria = Number.parseInt(
                                  option.value,
                                  10,
                                );

                                handleGetAreas(idSubCategoria);
                                setValue('ids_areas', undefined);

                                onChange(idSubCategoria);
                              }}
                              value={optionsSubCategorias.find(
                                (option) => option.value === value?.toString(),
                              )}
                              error={errors.id_subcategoria?.message}
                              isDisabled={!categoria}
                            />
                          )}
                        />
                      </FormGroup>

                      <FormGroup
                        name="Nome da Escala"
                        cols={[4, 6, 12]}
                        required
                      >
                        <Controller
                          control={control}
                          name="nome"
                          render={({ onChange, value }) => (
                            <Input
                              /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                              onChange={onChange}
                              value={value?.replaceAll(
                                // eslint-disable-next-line no-control-regex
                                /[^\x00-\x7F]/g,
                                '',
                              )}
                              onPaste={(e) => {
                                const a = e.currentTarget?.value.replaceAll(
                                  // eslint-disable-next-line no-control-regex
                                  /[^\x00-\x7F]/g,
                                  '',
                                );
                                onChange(a);
                              }}
                              error={errors.nome?.message}
                              placeholder="Digite apenas letras, sem caracteres especiais"
                            />
                          )}
                        />
                      </FormGroup>
                    </Flex>

                    <TituloPagina title="Data e horario" />
                    <Row>
                      <FormGroup name="Inicio" cols={[3, 6, 12]} required>
                        <Flex direction="row" width="100%">
                          <div style={{ width: '90%', marginRight: '3%' }}>
                            <Controller
                              control={control}
                              name="data_inicio"
                              render={({ onChange, value }) => (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  selected={value}
                                  onChange={(e: Date) => {
                                    const dateSelected = new Date(
                                      e.getFullYear(),
                                      e.getMonth(),
                                      e.getDate(),
                                      e.getHours(),
                                      e.getMinutes(),
                                    );
                                    onChange(dateSelected);

                                    const qtdHorasEscalas = watch(
                                      'quantidade_horas_escala',
                                    );
                                    if (qtdHorasEscalas) {
                                      setValue(
                                        'data_fim',
                                        addHours(dateSelected, qtdHorasEscalas),
                                      );
                                    }
                                  }}
                                  showTimeSelect
                                  timeIntervals={60}
                                  error={errors.data_inicio?.message}
                                />
                              )}
                            />
                          </div>
                          <FcCalendar size={32} />
                        </Flex>
                      </FormGroup>
                      <FormGroup name="Feriado" cols={[1, 3, 6]}>
                        <Controller
                          control={control}
                          name="is_feriado_inicio"
                          render={({ onChange, value }) => (
                            <Switch
                              onChange={(e) => {
                                onChange(value === '1' ? '0' : '1');
                              }}
                              isChecked={value === '1'}
                            />
                          )}
                        />
                      </FormGroup>

                      <FormGroup
                        name="Quantidade Horas"
                        cols={[2, 6, 12]}
                        required
                      >
                        <Controller
                          name="quantidade_horas_escala"
                          control={control}
                          render={({ onChange, value }) => (
                            <>
                              <Select
                                name="quantidade_horas_escala"
                                options={[
                                  { label: 'Selecione...', value: '' },
                                  ...optionsEscalas,
                                ]}
                                onChange={(e) => {
                                  const formatedValue = Number(
                                    e.currentTarget.value,
                                  );

                                  if (Number.isNaN(formatedValue))
                                    onChange(undefined);
                                  else {
                                    if (dataInicio) {
                                      setValue(
                                        'data_fim',
                                        addHours(dataInicio, formatedValue),
                                      );
                                    }
                                    onChange(formatedValue);
                                  }
                                }}
                                value={value}
                                error={errors.quantidade_horas_escala}
                              />
                            </>
                          )}
                        />
                      </FormGroup>

                      <FormGroup name="Fim" cols={[3, 6, 12]} required>
                        <Flex direction="row" width="100%">
                          <div style={{ width: '90%', marginRight: '3%' }}>
                            <Controller
                              control={control}
                              name="data_fim"
                              render={({ onChange, value }) => (
                                <DatePicker
                                  dateFormat="dd/MM/yyyy HH:mm:ss"
                                  minDate={new Date(watch('data_inicio'))}
                                  selected={value}
                                  onChange={(e: Date) =>
                                    onChange(
                                      new Date(
                                        e.getFullYear(),
                                        e.getMonth(),
                                        e.getDate(),
                                        e.getHours(),
                                        e.getMinutes(),
                                      ),
                                    )
                                  }
                                  showTimeSelect
                                  disabled
                                  error={errors.data_fim?.message}
                                />
                              )}
                            />
                          </div>
                          <FcCalendar size={32} />
                        </Flex>
                      </FormGroup>
                      {!isSameDayEscala && (
                        <FormGroup name="Feriado" cols={[1, 3, 6]}>
                          <Controller
                            control={control}
                            name="is_feriado_fim"
                            render={({ onChange, value }) => (
                              <Switch
                                onChange={(e) => {
                                  onChange(value === '1' ? '0' : '1');
                                }}
                                isChecked={value === '1'}
                              />
                            )}
                          />
                        </FormGroup>
                      )}

                      <FormGroup
                        name="Escala Obrigatória"
                        cols={[2, 6, 12]}
                        required
                      >
                        <Controller
                          name="voluntario_obrigatorio"
                          control={control}
                          render={({ onChange, value }) => (
                            <>
                              <Select
                                name="voluntario_obrigatorio"
                                options={optionsObrigatoriedadeIrso}
                                onChange={onChange}
                                value={value}
                                error={errors.voluntario_obrigatorio}
                              />
                            </>
                          )}
                        />
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup
                        name="Setores da Operação (Adicione os setores da operação cujas equipes irão trabalhar)"
                        cols={[12, 12, 12]}
                      >
                        <Controller
                          control={control}
                          name="ids_areas"
                          defaultValue={[]}
                          render={({ onChange, value }) => (
                            <>
                              <ReactSelect
                                isMulti
                                onChange={(e: OptionType[]) => {
                                  onChange(
                                    e?.map((opt) =>
                                      Number.parseInt(
                                        opt.value || (opt as any),
                                        10,
                                      ),
                                    ),
                                  );
                                }}
                                value={value?.map((v: number) =>
                                  optionsAreas.find(
                                    (o) => v === Number.parseInt(o.value, 10),
                                  ),
                                )}
                                optionsSelect={optionsAreas}
                                error={
                                  (errors?.ids_areas as FieldError | undefined)
                                    ?.message
                                }
                                isDisabled={
                                  subCategoriaWatch?.has_areas === '0'
                                }
                              />
                            </>
                          )}
                        />
                      </FormGroup>

                      <Controller
                        control={control}
                        name="isValidateAreas"
                        render={({ onChange, value }) => (
                          <Checkbox readOnly isChecked={value} hidden />
                        )}
                      />
                    </Row>

                    <Row>
                      <FormGroup
                        name="Fiscal Local (Preencha com o nome do oficial que vai fiscalizar a irso na sua OPM, estando ou não, ele de irso)"
                        cols={[8, 12, 12]}
                      >
                        <Controller
                          name="pm_codigo_fiscal_local"
                          control={control}
                          render={({ onChange }) => (
                            <AsyncSelect
                              name="pm_codigo"
                              label="Pesquisar Pm"
                              value={refPm.current}
                              loadOptions={(inputValue: any) =>
                                delayedQuery(inputValue)
                              }
                              onChange={(value: OptionType) => {
                                refPm.current = value;
                                onChange(value.value);
                              }}
                              error={errors.pm_codigo_fiscal_local?.message}
                            />
                          )}
                        />
                      </FormGroup>
                    </Row>

                    <PanelBottomActions>
                      <Button
                        leftIcon={<FaSave />}
                        colorScheme="green"
                        type="submit"
                        isLoading={disabledRequest}
                      >
                        Salvar
                      </Button>
                    </PanelBottomActions>
                  </form>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </BoxContent>
    </>
  );
};

export default NovaEscalaIrso;
