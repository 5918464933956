import { Button, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import debounce from 'debounce-promise';
import React, { useRef } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FaMoneyBill, FaPlus, FaRegTrashAlt, FaUndo } from 'react-icons/fa';
import * as Yup from 'yup';
import { useAuth } from '../../../contexts/auth';
import api from '../../../services/api';
import FormGroup from '../../../components/form/FormGroup';
import Row from '../../../components/form/Row';
import PanelBottomActions from '../../../components/PanelBottomActions';
import InputCurrency from '../../../components/form/InputCurrency';
import AsyncSelect from '../../../components/form/AsyncSelectOpm';

type IFormTransferenciaSaldo = {
  transferencias: {
    unidade_destino: number;
    valor: number;
    observacao?: string;
  }[];
};

type IOption = { value: string; label: string };
type IUnidadeSaldo = IOption & { valor: number };

type IUnidade = { uni_codigo: number; uni_sigla: string };

type IProps = {
  saldo: number;
  defaultValue?: IUnidadeSaldo[];
  handleValidForm: (data: IFormTransferenciaSaldo) => Promise<boolean>;
  isLoadingSubmit: boolean;
};

const transferenciasSaldo = Yup.object()
  .shape({
    transferencias: Yup.array()
      .of(
        Yup.object({
          unidade_destino: Yup.number().required('Este campo é requerido'),
          valor: Yup.number().required('Este campo é requerido'),
          observacao: Yup.string().notRequired().nullable(true),
        }),
      )
      .min(1, 'No minimo um'),
  })
  .noUnknown()
  .required();

export const FormTransferirDinheiro: React.FC<IProps> = ({
  saldo,
  defaultValue,
  handleValidForm,
  isLoadingSubmit,
}) => {
  const opmsValues = useRef<IOption[]>(defaultValue || []);
  const { user } = useAuth();

  const {
    control: controlTransferencia,
    errors: errorsTransferencia,
    handleSubmit: handleSubmitTransferencia,
    reset: resetTransferencia,
    watch: watchTransferencia,
  } = useForm<IFormTransferenciaSaldo>({
    resolver: yupResolver(transferenciasSaldo),
    defaultValues: {
      transferencias: defaultValue
        ? defaultValue.map(({ value, valor }) => ({
            unidade_destino: Number.parseInt(value, 10),
            valor,
          }))
        : [{}],
    },
  });

  const { fields, append, remove } = useFieldArray<{
    unidade_destino: number;
    valor: number;
  }>({
    control: controlTransferencia,
    name: 'transferencias',
  });

  const handleSubmitTransferencias = async (
    data: IFormTransferenciaSaldo,
  ): Promise<void> => {
    const response = await handleValidForm(data);

    if (response) {
      resetTransferencia();
      opmsValues.current = [];
    }
  };

  const delayedQuery = debounce(async (query: string) => {
    const {
      data: { unidades },
    } = await api.get<{ unidades: IUnidade[] }>('unidades/async', {
      params: { query },
    });

    return unidades
      ?.filter(
        (unidade) =>
          unidade.uni_codigo.toString() !==
          user.currentOpm?.uni_codigo.toString(),
      )
      .map<IOption>((unidade) => ({
        label: unidade.uni_sigla,
        value: unidade.uni_codigo.toString(),
      }));
  }, 500);

  const valorSaldo =
    saldo -
    watchTransferencia('transferencias').reduce(
      (valor, valorSaldo2) => valor + (valorSaldo2?.valor || 0),
      0,
    );

  return (
    <form onSubmit={handleSubmitTransferencia(handleSubmitTransferencias)}>
      {fields.map((field, index) => (
        <Row key={field.id}>
          <FormGroup name="Opm" required cols={[5, 5, 12]}>
            <Controller
              name={`transferencias.${index}.unidade_destino`}
              control={controlTransferencia}
              render={({ onChange }) => (
                <AsyncSelect
                  key={field.id}
                  isClearable
                  loadOptions={(value: string) => delayedQuery(value.trim())}
                  error={
                    errorsTransferencia.transferencias?.[index]?.unidade_destino
                      ?.message
                  }
                  onChange={(option: any) => {
                    onChange(Number(option?.value));
                    if (!opmsValues.current[index]) {
                      opmsValues.current = [...opmsValues.current, option];
                    } else {
                      opmsValues.current[index] = option;
                    }
                  }}
                  value={opmsValues.current[index]}
                />
              )}
            />
          </FormGroup>
          <FormGroup name="Valor" required cols={[5, 5, 12]}>
            <Controller
              name={`transferencias.${index}.valor`}
              control={controlTransferencia}
              render={({ onChange, value }) => (
                <InputCurrency
                  key={field.id}
                  value={value}
                  onChange={(e, value2) => onChange(value2)}
                  error={
                    errorsTransferencia.transferencias?.[index]?.valor?.message
                  }
                />
              )}
            />
          </FormGroup>

          <Button
            marginTop="7"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              remove(index);
              opmsValues.current = opmsValues.current.filter(
                (opm) =>
                  watchTransferencia('transferencias').filter(
                    (transferencia) =>
                      transferencia.unidade_destino?.toString() === opm.value,
                  )[0],
              );
            }}
          >
            <FaRegTrashAlt size={16} />
          </Button>
        </Row>
      ))}

      <Text>
        Saldo após as transferências:{' '}
        <span style={{ color: valorSaldo >= 0 ? 'green' : 'red' }}>
          {new Intl.NumberFormat('pt-BR', {
            currency: 'BRL',
            style: 'currency',
          }).format(Number(valorSaldo))}
        </span>
      </Text>

      <PanelBottomActions>
        <Button
          leftIcon={<FaPlus />}
          colorScheme="blue"
          type="button"
          onClick={() => append({})}
        >
          Adicionar OPM
        </Button>

        <Button
          colorScheme="yellow"
          type="button"
          onClick={() => resetTransferencia()}
          leftIcon={<FaUndo />}
        >
          Limpar
        </Button>

        <Button
          leftIcon={<FaMoneyBill />}
          type="submit"
          colorScheme="green"
          // isLoading={!handleRequest}
          isLoading={isLoadingSubmit}
        >
          Transferir
        </Button>
      </PanelBottomActions>
    </form>
  );
};
