import React, { createContext, useCallback, useState, useContext } from 'react';

interface ICategoriairsoContextData {
  idCategoriairso: number | undefined;
  updateIdCategoriairso(id: number): Promise<void>;
}

const CategoriairsoContext = createContext<ICategoriairsoContextData>(
  {} as ICategoriairsoContextData,
);

const CategoriairsoProvider: React.FC = ({ children }) => {
  const [idCategoriairso, setIdCategoriairso] = useState<number | undefined>(
    () => {
      const id = sessionStorage.getItem('@pmce-cetic-escalas:id_categoriairso');

      if (id) {
        return JSON.parse(id);
      }

      return undefined;
    },
  );

  const updateIdCategoriairso = useCallback(async (id: number) => {
    setIdCategoriairso(id);
    sessionStorage.setItem(
      '@pmce-cetic-escalas:id_categoriairso',
      JSON.stringify(id),
    );
  }, []);

  return (
    <CategoriairsoContext.Provider
      value={{
        idCategoriairso,
        updateIdCategoriairso,
      }}
    >
      {children}
    </CategoriairsoContext.Provider>
  );
};

function useCategoriairso(): ICategoriairsoContextData {
  const context = useContext(CategoriairsoContext);

  if (!context) {
    throw new Error(
      'useCategoriairso must be used within an ModalidadeirsoProvider',
    );
  }

  return context;
}

export { CategoriairsoProvider, useCategoriairso };
