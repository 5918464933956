/* eslint-disable no-control-regex */
import React, { useState } from 'react';
import { FaSave, FaEdit, FaTrash } from 'react-icons/fa';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  Text,
  Box,
  useToast,
  HStack,
  Button as ButtonChacrka,
  Divider,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';
import api from '../../services/api';
import Row from '../../components/form/Row';
import PanelBottomActions from '../../components/PanelBottomActions';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import FormInput from '../../components/form/FormInput';
import FormGroup from '../../components/form/FormGroup';
import BoxContent from '../../components/BoxContent';
import Select from '../../components/form/ReactSelect';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type OptionType = { label: string; value: string };
interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}

const optionsSitCategoriadeIrso = [
  { value: '1', label: 'Ativa' },
  { value: '0', label: 'Inativa' },
];

const schema = Yup.object().shape({
  nomeCategoria: Yup.string()
    .required('Informe o nome da Categoria da DRSO')
    .min(3, 'No mínimo 3 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
});

const schemaEditar = Yup.object().shape({
  nome: Yup.string()
    .required('Informe o nome da Categoria da DRSO ')
    .min(3, 'Nome digitado muito pequeno'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),

  situacao: Yup.string().required('Informe o nome da Categoria da DRSO '),
});

type IEditSchema = {
  nome: string;
  situacao: string;
};

const ListCategoriaIrso: React.FC = () => {
  const { user } = useAuth();

  const toast = useToast();
  const [categoriaModal, setcategoriaModal] = useState<ICategoria>();

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarCategoria,
    onOpen: onOpenEditarCategoria,
    onClose: onCloseEditarCategoria,
  } = useDisclosure();

  const [isDisabledRequest, setIsDisabledRequest] = useState(false);

  const { handleSubmit, errors, control, setValue } = useForm<any>({
    resolver: yupResolver(schema),
    defaultValues: { nomeCategoria: undefined, situacaoCategoria: undefined },
  });

  const {
    handleSubmit: handleSubmitEditarCategoria,
    errors: errorsEditarCategoria,
    control: controlEditarCategoria,
  } = useForm<IEditSchema>({
    resolver: yupResolver(schemaEditar),
    defaultValues: {},
  });

  const submitFormData = async (data: any): Promise<void> => {
    const dados = {
      ...data,
      nome: String(data.nomeCategoria).toUpperCase(),
      criado_por: user.matricula,
      situacao: '1',
    };

    setIsDisabledRequest(true);
    try {
      await api.post('/categorias', dados);
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso inserida com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      setValue('nomeCategoria', '');
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleUpdateCategoria = async (data: IEditSchema): Promise<void> => {
    const dados = {
      ...data,
      nome: String(data.nome).toUpperCase(),
    };

    setIsDisabledRequest(true);
    try {
      await api.put(`/categorias/${categoriaModal?.id_categoria}`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Atualizada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarCategoria();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleExcluirCategoria = async (data: ICategoria): Promise<any> => {
    setIsDisabledRequest(true);
    try {
      await api.put(`/categorias/deletar/${data.id_categoria}`);
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  // const handleSetActive = useCallback(
  //   async (categoria: ICategoria) => {
  //     try {
  //       await api.put(`/categorias/${categoria.id_categoria}`, {
  //         situacao: categoria.situacao === '0' ? '1' : '0',
  //       });
  //       toast({
  //         title: 'Sucesso.',
  //         description: 'Categoria de Irso Atualizada com sucesso',
  //         status: 'success',
  //         duration: 5000,
  //         isClosable: true,
  //         position: 'top-right',
  //       });
  //       history.push('/categoriairso');
  //     } catch (error) {
  //       toast({
  //         title: 'Erro',
  //         description: error?.response.data.message,
  //         status: 'error',
  //         duration: 5000,
  //         isClosable: true,
  //         position: 'top-right',
  //       });
  //       console.error(error);
  //     }
  //   },
  //   [history, toast],
  // );

  const colunas: IColumns = [
    { field: 'nome', text: 'Nome da Categoria', type: { name: 'text' } },
    {
      field: 'situacao',
      text: 'Situação da Categoria',
      type: { name: 'enum', enum: { '1': 'Ativa', '0': 'Inativa' } },
    },
  ];

  const options = {
    serverData: {
      url: `/categorias`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ICategoria) => {
        //     handleClickShow(uniforme.id_categoria);
        //   },
        // },
        // {
        //   icon: <MdRefresh size={18} />,
        //   tooltip: 'Ativar/Desativar',

        //   getRow: handleSetActive,
        // },

        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: ICategoria) => {
            setcategoriaModal(uniforme);
            onOpenEditarCategoria();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setcategoriaModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nomeCategoria'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  return (
    <>
      <>
        <TituloPagina title="Categoria Irso" />
        <BoxContent>
          <TituloPagina title="Cadastrar Categoria" />
          <form onSubmit={handleSubmit(submitFormData)}>
            <FormGroup name="Nova Categoria">
              <Flex flexDirection="column">
                <Controller
                  name="nomeCategoria"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormInput
                      style={{ minWidth: 350, marginBottom: '10px' }}
                      placeholder="Digite o nome da Categoria"
                      /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                      onChange={onChange}
                      value={value?.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      )}
                      onPaste={(e) => {
                        const a = e.currentTarget?.value.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        );

                        onChange(a);
                      }}
                      error={errors.nomeCategoria?.message}
                    />
                  )}
                />
              </Flex>
              <ButtonChacrka
                colorScheme="green"
                icon={FaSave}
                type="submit"
                isLoading={isDisabledRequest}
              >
                Incluir
              </ButtonChacrka>
            </FormGroup>
          </form>

          <Divider orientation="horizontal" border="2px" />
          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
            >
              Lista de Categorias
              {user.verSubunidade === '1' && 'e subunidades'}
            </Text>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
          {categoriaModal && (
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="lg"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Categoria de irso{' '}
                <strong>{categoriaModal.nome}</strong> tem certeza da operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <ButtonChacrka
                    onClick={onCloseVisualizar}
                    colorScheme="green"
                  >
                    Não
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() => handleExcluirCategoria(categoriaModal)}
                    colorScheme="red"
                    isLoading={isDisabledRequest}
                  >
                    Sim Quero Deletar!
                  </ButtonChacrka>
                </HStack>
              </Flex>
            </Modal>
          )}
        </BoxContent>
      </>

      <Modal
        isOpen={isOpenEditarCategoria}
        onClose={onCloseEditarCategoria}
        size="lg"
        title="Editar Categoria"
      >
        <form onSubmit={handleSubmitEditarCategoria(handleUpdateCategoria)}>
          <Row>
            <FormGroup name="Nome" cols={[6, 12, 12]}>
              <Controller
                name="nome"
                control={controlEditarCategoria}
                defaultValue={categoriaModal?.nome}
                render={({ onChange, value }) => (
                  <FormInput
                    placeholder="Digite o nome da Categoria"
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                    error={errorsEditarCategoria.nome?.message}
                  />
                )}
              />
            </FormGroup>
            <FormGroup name="Situação" cols={[6, 12, 12]}>
              <Controller
                name="situacao"
                defaultValue={categoriaModal?.situacao}
                control={controlEditarCategoria}
                render={({ value, onChange }) => (
                  <Select
                    placeholder="Selecione uma Opção"
                    onChange={({ value: changeValue }: OptionType) =>
                      onChange(changeValue)
                    }
                    value={optionsSitCategoriadeIrso.find(
                      (option) => option.value === value,
                    )}
                    options={optionsSitCategoriadeIrso}
                  />
                )}
              />
            </FormGroup>
          </Row>

          <PanelBottomActions>
            <ButtonChacrka colorScheme="red" onClick={onCloseEditarCategoria}>
              Voltar
            </ButtonChacrka>

            <ButtonChacrka
              colorScheme="green"
              type="submit"
              isLoading={isDisabledRequest}
            >
              Atualizar
            </ButtonChacrka>
          </PanelBottomActions>
        </form>
      </Modal>
    </>
  );
};

export default ListCategoriaIrso;
