/* eslint-disable no-control-regex */
import React, { lazy, Suspense } from 'react';
import { FaEdit, FaTrash, FaSearch, FaPlus } from 'react-icons/fa';
import { Tooltip, useDisclosure } from '@chakra-ui/react';
import { Text, Box, Button as ButtonChacrka } from '@chakra-ui/react';
import { IProcesso, useProcesso } from '../../contexts/processo';
import api from '../../services/api';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

const ModalAddProcesso = lazy(() => import('./_modal.create.processo'));
const ModalEditProcesso = lazy(() => import('./_modal.edit.processo'));
const ModalDeletarProcesso = lazy(() => import('./_modal.delete.processo'));
const ModalVisualizeProcesso = lazy(
  () => import('./_modal.visualize.processo'),
);

const ListProcessoIrso: React.FC = () => {
  const { user } = useAuth();
  const { updateIdProcesso } = useProcesso();

  const {
    isOpen: isOpenDeletar,
    onOpen: onOpenDeletar,
    onClose: onCloseDeletar,
  } = useDisclosure();

  const {
    isOpen: isOpenAdicionar,
    onOpen: onOpenAdicionar,
    onClose: onCloseAdicionar,
  } = useDisclosure();

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarProcesso,
    onOpen: onOpenEditarProcesso,
    onClose: onCloseEditarProcesso,
  } = useDisclosure();

  const colunas: IColumns = [
    { field: 'nome', text: 'Nome da Processo', type: { name: 'text' } },
    {
      field: 'situacao',
      text: 'Situação da Processo',
      type: { name: 'enum', enum: { '1': 'Ativa', '0': 'Inativa' } },
    },
  ];

  const options = {
    serverData: {
      url: `/processo`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
    },
    actions: {
      headerText: 'Ações',
      items: [
        {
          icon: <FaSearch size={13} />,
          tooltip: 'Visualizar',

          getRow: async ({ id_processo }: IProcesso) => {
            await updateIdProcesso(id_processo);
            onOpenVisualizar();
          },
        },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: async (processo: IProcesso) => {
            await updateIdProcesso(processo.id_processo);
            onOpenEditarProcesso();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: async ({ id_processo }: IProcesso) => {
            await updateIdProcesso(id_processo);
            onOpenDeletar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['nomeProcesso'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  return (
    <>
      <>
        <TituloPagina title="Processo Irso" />
        <BoxContent>
          <Tooltip label="Adicionar Processo">
            <ButtonChacrka
              leftIcon={<FaPlus />}
              onClick={onOpenAdicionar}
              size="md"
              maxW="15rem"
              colorScheme="telegram"
            >
              Adicionar Processo
            </ButtonChacrka>
          </Tooltip>
          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
              mr={4}
            >
              Lista de Processos
            </Text>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
        </BoxContent>
      </>

      <Suspense fallback={<div>Carregando modal...</div>}>
        <ModalDeletarProcesso
          isOpen={isOpenDeletar}
          onClose={onCloseDeletar}
          size="sm"
          isCentered
        />

        <ModalAddProcesso
          isOpen={isOpenAdicionar}
          onClose={onCloseAdicionar}
          size="lg"
          isCentered
        />

        <ModalEditProcesso
          isOpen={isOpenEditarProcesso}
          onClose={onCloseEditarProcesso}
          size="lg"
          isCentered
        />

        <ModalVisualizeProcesso
          isOpen={isOpenVisualizar}
          onClose={onCloseVisualizar}
          size="lg"
          isCentered
        />
      </Suspense>
    </>
  );
};

export default ListProcessoIrso;
