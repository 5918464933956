/* eslint-disable no-control-regex */
import React, { useState, useEffect } from 'react';
import {
  FaSave,
  FaEdit,
  FaTrash,
  FaPlus,
  FaTimes,
  FaMinus,
} from 'react-icons/fa';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import {
  Text,
  Box,
  useToast,
  HStack,
  Button as ButtonChacrka,
  Flex,
  useDisclosure,
} from '@chakra-ui/react';

import { BsPinMapFill } from 'react-icons/bs';
import api from '../../services/api';
import PanelBottomActions from '../../components/PanelBottomActions';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import FormInput from '../../components/form/FormInput';
import FormGroup from '../../components/form/FormGroup';
import BoxContent from '../../components/BoxContent';
import Select from '../../components/form/ReactSelect';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type OptionType = { label: string; value: string };
interface ICategoria {
  id_categoria: number;
  nome: string;
  situacao: string;
  criadoPor?: string;
  criadoEm?: string;
  atualizadoEm?: string;
  atualizadoPor?: string;
}

type ISubCategoria = {
  id_subcategoria: number;
  situacao: '0' | '1';
  has_areas?: '0' | '1';
  id_categoria: number;
  nome: string;
  categoria: ICategoria;
};

const optionsSitCategoriadeIrso = [
  { value: '1', label: 'Ativa' },
  { value: '0', label: 'Inativa' },
];

const schema = Yup.object().shape({
  id_categoria: Yup.number().required().typeError('Este campo é requerido'),
  areasNomes: Yup.array()
    .of(Yup.string().required('Este campo é requerido'))
    .required()
    .min(1),
  nome: Yup.string()
    .required('Informe o nome da Operação da DRSO')
    .min(3, 'No mínimo 3 caracteres'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),
});

const schemaAreas = Yup.object().shape({
  areasNomes: Yup.array()
    .of(Yup.object({ name: Yup.string().required('Este campo é requerido') }))
    .required()
    .min(1),
});

const schemaEditar = Yup.object().shape({
  nome: Yup.string()
    .required('Informe o nome da Categoria da DRSO')
    .min(3, 'Nome digitado muito pequeno'),
  // .matches(/[\x00-\x7F]/g, 'Texto com caracteres inválidos'),

  situacao: Yup.string().required('Informe o nome da Categoria da DRSO'),
});

type ICreateSchema = {
  id_categoria: number;
  nome: string;
  areasNomes: string[];
};

type IEditSchemaAreas = { areasNomes: { name: string }[] };

type IEditSchema = ICreateSchema & {
  situacao: string;
};

export const SubCategoriaIrso: React.FC = () => {
  const { user } = useAuth();
  const toast = useToast();
  const [categoriaModal, setcategoriaModal] = useState<ISubCategoria>();

  const [optionsCategorias, setOptionsCategorias] = useState<OptionType[]>([]);
  const [isAreaToValidate, setIsAreaToValidate] = useState(false);

  const {
    isOpen: isOpenModalCriarSubCategoria,
    onOpen: onOpenModalCriarSubCategoria,
    onClose: onCloseModalCriarSubCategoria,
  } = useDisclosure();

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarCategoria,
    onOpen: onOpenEditarCategoria,
    onClose: onCloseEditarCategoria,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarAreasCategoria,
    onOpen: onOpenEditarAreasCategoria,
    onClose: onCloseEditarAreasCategoria,
  } = useDisclosure();

  const [isDisabledRequest, setIsDisabledRequest] = useState(false);

  const { handleSubmit, errors, control, reset } = useForm<ICreateSchema>({
    resolver: yupResolver(schema),
    defaultValues: { nome: '', id_categoria: undefined, areasNomes: [''] },
  });

  const fieldArrayCreate = useFieldArray({
    control,
    name: 'areasNomes',
  });

  const {
    handleSubmit: handleSubmitEditarCategoria,
    errors: errorsEditarCategoria,
    control: controlEditarCategoria,
  } = useForm<IEditSchema>({
    resolver: yupResolver(schemaEditar),
  });

  const {
    handleSubmit: handleSubmitEditarAreasCategoria,
    errors: errorsEditarAreasCategoria,
    control: controlEditarAreasCategoria,
    reset: resetEditarAreasCategoria,
    trigger: triggerEditarCategoria,
  } = useForm<IEditSchemaAreas>({
    resolver: yupResolver(schemaAreas),
  });

  const handleCloseModalEditarAreasCategoria = (): void => {
    resetEditarAreasCategoria();
    setcategoriaModal(undefined);
    onCloseEditarAreasCategoria();
  };

  const handleCloseModalEditarCategoria = (): void => {
    setcategoriaModal(undefined);
    onCloseEditarCategoria();
  };

  const fieldArrayEdit = useFieldArray({
    control: controlEditarAreasCategoria,
    name: 'areasNomes',
  });

  const submitFormData = async ({
    id_categoria,
    ...data
  }: ICreateSchema): Promise<void> => {
    const dados = {
      ...data,
      nome: String(data.nome).toUpperCase(),
    };

    setIsDisabledRequest(true);
    try {
      await api.post(`/categorias/${id_categoria}/subcategorias`, dados);
      toast({
        title: 'Sucesso.',
        description: 'SubCategoria de Irso inserida com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });

      reset();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleUpdateAreasCategoria = async ({
    areasNomes,
  }: IEditSchemaAreas): Promise<void> => {
    setIsDisabledRequest(true);
    try {
      await api.put(
        `/categorias/${categoriaModal?.id_categoria}/subcategorias/${categoriaModal?.id_subcategoria}`,
        { ...categoriaModal, areasNomes: areasNomes.map((a) => a.name) },
      );
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Atualizada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarCategoria();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleUpdateCategoria = async (data: IEditSchema): Promise<void> => {
    const dados = {
      ...data,
      nome: data.nome.toUpperCase(),
    };

    setIsDisabledRequest(true);
    try {
      await api.put(
        `/categorias/${categoriaModal?.id_categoria}/subcategorias/${categoriaModal?.id_subcategoria}`,
        dados,
      );
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Atualizada com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarCategoria();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  const handleExcluirCategoria = async (data: ISubCategoria): Promise<any> => {
    setIsDisabledRequest(true);
    try {
      await api.delete(`/subcategorias/${data.id_subcategoria}`);
      toast({
        title: 'Sucesso.',
        description: 'Categoria de Irso Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    } finally {
      setIsDisabledRequest(false);
    }
  };

  // const handleSetActive = useCallback(
  //   async (categoria: ICategoria) => {
  //     try {
  //       await api.put(`/categorias/${categoria.id_categoria}`, {
  //         situacao: categoria.situacao === '0' ? '1' : '0',
  //       });
  //       toast({
  //         title: 'Sucesso.',
  //         description: 'Categoria de Irso Atualizada com sucesso',
  //         status: 'success',
  //         duration: 5000,
  //         isClosable: true,
  //         position: 'top-right',
  //       });
  //       history.push('/categoriairso');
  //     } catch (error) {
  //       toast({
  //         title: 'Erro',
  //         description: error?.response.data.message,
  //         status: 'error',
  //         duration: 5000,
  //         isClosable: true,
  //         position: 'top-right',
  //       });
  //       console.error(error);
  //     }
  //   },
  //   [history, toast],
  // );

  const colunas: IColumns = [
    { field: 'nome', text: 'Operação', type: { name: 'text' } },
    { field: 'categoria.nome', text: 'Categoria', type: { name: 'text' } },
    {
      field: 'situacao',
      text: 'Situação',
      type: { name: 'enum', enum: { '1': 'Ativa', '0': 'Inativa' } },
    },
  ];

  const options = {
    serverData: {
      url: `/subcategorias`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      // params: `subunidades=${user.verSubunidade}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (subcategoria: ICategoria) => {
        //     handleClickShow(subcategoria.id_categoria);
        //   },
        // },
        // {
        //   icon: <MdRefresh size={18} />,
        //   tooltip: 'Ativar/Desativar',

        //   getRow: handleSetActive,
        // },

        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: async (subcategoria: ISubCategoria) => {
            setcategoriaModal(subcategoria);
            onOpenEditarCategoria();

            const hasAreas = subcategoria.has_areas !== '0';
            setIsAreaToValidate(!hasAreas);
          },
        },
        {
          icon: <BsPinMapFill size={13} />,
          tooltip: 'Alterar areas',

          getRow: async (subcategoria: ISubCategoria) => {
            setcategoriaModal(subcategoria);
            onOpenEditarAreasCategoria();

            const {
              data: { items },
            } = await api.get<{ items: Record<string, any>[] }>(
              `/subcategorias/${subcategoria.id_subcategoria}/areas`,
            );

            items.forEach((i) => {
              fieldArrayEdit.append({ name: i?.nome ?? '' });
            });
          },
          handleShowAction: (row: ISubCategoria) => {
            return !row.has_areas || row.has_areas !== '0';
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (subcategoria: ISubCategoria) => {
            setcategoriaModal(subcategoria);
            onOpenVisualizar();
          },
        },
      ],
    },

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['subCategorias.nome', 'categoria.nome'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: { visible: true, label: 'Ordem' },
  };

  useEffect(() => {
    const loadCategorias = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: ICategoria[] }>('categorias');

      setOptionsCategorias(
        items.map(({ nome, id_categoria }) => ({
          label: nome,
          value: id_categoria.toString(),
        })),
      );
    };

    loadCategorias();
  }, []);

  useEffect(() => {
    const handleTrigger = async (): Promise<void> => {
      await triggerEditarCategoria('areasNomes');
    };

    if (isOpenEditarAreasCategoria) handleTrigger();
  }, [isAreaToValidate, isOpenEditarAreasCategoria, triggerEditarCategoria]);

  return (
    <>
      <>
        <TituloPagina title="Operação Irso" />
        <BoxContent>
          <FormGroup>
            <ButtonChacrka
              leftIcon={<FaPlus />}
              onClick={onOpenModalCriarSubCategoria}
              mb={4}
              colorScheme="blue"
            >
              Adicionar Operação
            </ButtonChacrka>
          </FormGroup>
          <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
            <Text
              color="#666"
              fontWeight="600"
              fontSize={{ sm: '1rem', lg: '1.3rem' }}
            >
              Lista de Operações
              {user.verSubunidade === '1' && 'e subunidades'}
            </Text>
          </Box>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>
          {categoriaModal && (
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="lg"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Categoria de irso{' '}
                <strong>{categoriaModal.nome}</strong> tem certeza da operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <ButtonChacrka
                    onClick={onCloseVisualizar}
                    colorScheme="green"
                  >
                    Não
                  </ButtonChacrka>

                  <ButtonChacrka
                    onClick={() => handleExcluirCategoria(categoriaModal)}
                    colorScheme="red"
                    isLoading={isDisabledRequest}
                  >
                    Sim Quero Deletar!
                  </ButtonChacrka>
                </HStack>
              </Flex>
            </Modal>
          )}
        </BoxContent>
      </>

      <Modal
        isOpen={isOpenModalCriarSubCategoria}
        onClose={onCloseModalCriarSubCategoria}
        size="2xl"
        title="Cadastrar Operação"
      >
        <Box as="form" onSubmit={handleSubmit(submitFormData)}>
          <Flex>
            <FormGroup name="Categoria" cols={[5, 6, 12]} required>
              <Controller
                control={control}
                name="id_categoria"
                render={({ onChange, value }) => (
                  <Select
                    optionsSelect={optionsCategorias}
                    onChange={(option: OptionType) =>
                      onChange(Number(option.value))
                    }
                    value={optionsCategorias.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errors.id_categoria?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Nome da Operação" cols={[4, 6, 12]} required>
              <Controller
                name="nome"
                control={control}
                render={({ onChange, value }) => (
                  <FormInput
                    style={{ minWidth: 350, marginBottom: '10px' }}
                    placeholder="Digite o nome da Operação"
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                    error={errors.nome?.message}
                  />
                )}
              />
            </FormGroup>
          </Flex>
          {fieldArrayCreate.fields.map((field, i) => (
            <Flex key={field.id}>
              <FormGroup cols={[7, 10, 12]} name={`Setor ${i + 1}`}>
                <Controller
                  name={`areasNomes.${i}`}
                  control={control}
                  render={({ onChange, value }) => (
                    <>
                      <FormInput
                        style={{ minWidth: 350, marginBottom: '10px' }}
                        placeholder="Digite o nome do setor"
                        /* onChange={(e) => {
                            // eslint-disable-next-line no-control-regex
                            const pattern = /[^\x00-\x7F]/g;
                            const filteredString = e.currentTarget?.value.replaceAll(
                              pattern,
                              '',
                            );
                            onChange(filteredString);
                          }}
                          eslint-disable-next-line no-control-regex
                          value={value?.replace(/[^\x00-\x7F]/g, '')} */
                        onChange={onChange}
                        value={value?.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        )}
                        onPaste={(e) => {
                          const a = e.currentTarget?.value.replaceAll(
                            // eslint-disable-next-line no-control-regex
                            /[^\x00-\x7F]/g,
                            '',
                          );

                          onChange(a);
                        }}
                        error={errors.areasNomes?.[i]?.message}
                      />
                    </>
                  )}
                />
              </FormGroup>
              <ButtonChacrka
                type="button"
                mt={7}
                onClick={() => fieldArrayCreate.remove(i)}
              >
                <FaMinus />
              </ButtonChacrka>
            </Flex>
          ))}
          <Flex>
            <PanelBottomActions>
              <ButtonChacrka
                colorScheme="telegram"
                leftIcon={<FaPlus />}
                type="button"
                onClick={() => fieldArrayCreate.append('' as any)}
              >
                Adicionar setor
              </ButtonChacrka>
            </PanelBottomActions>
          </Flex>

          <PanelBottomActions>
            <ButtonChacrka
              colorScheme="yellow"
              leftIcon={<FaTimes />}
              type="button"
              onClick={onCloseModalCriarSubCategoria}
            >
              Fechar
            </ButtonChacrka>
            <ButtonChacrka
              colorScheme="green"
              leftIcon={<FaSave />}
              type="submit"
              isLoading={isDisabledRequest}
            >
              Incluir
            </ButtonChacrka>
          </PanelBottomActions>
        </Box>
      </Modal>

      <Modal
        isOpen={isOpenEditarCategoria}
        onClose={handleCloseModalEditarCategoria}
        size="4xl"
        title="Editar Operação"
      >
        <Box
          as="form"
          onSubmit={handleSubmitEditarCategoria(handleUpdateCategoria)}
        >
          <Flex>
            <FormGroup name="Categoria" cols={[4, 12, 12]} required>
              <Controller
                control={control}
                name="id_categoria"
                defaultValue={categoriaModal?.id_categoria}
                render={({ onChange, value }) => (
                  <Select
                    optionsSelect={optionsCategorias}
                    onChange={(option: OptionType) =>
                      onChange(Number(option.value))
                    }
                    value={optionsCategorias.find(
                      (option) => option.value === value?.toString(),
                    )}
                    error={errors.id_categoria?.message}
                  />
                )}
              />
            </FormGroup>

            <FormGroup name="Nome" cols={[4, 12, 12]}>
              <Controller
                name="nome"
                control={controlEditarCategoria}
                defaultValue={categoriaModal?.nome}
                render={({ onChange, value }) => (
                  <FormInput
                    placeholder="Digite o nome da Categoria"
                    /* onChange={(e) => {
                              // eslint-disable-next-line no-control-regex
                              const pattern = /[^\x00-\x7F]/g;
                              const filteredString = e.currentTarget?.value.replaceAll(
                                pattern,
                                '',
                              );
                              onChange(filteredString);
                            }}
                            eslint-disable-next-line no-control-regex
                            value={value?.replace(/[^\x00-\x7F]/g, '')} */
                    onChange={onChange}
                    value={value?.replaceAll(
                      // eslint-disable-next-line no-control-regex
                      /[^\x00-\x7F]/g,
                      '',
                    )}
                    onPaste={(e) => {
                      const a = e.currentTarget?.value.replaceAll(
                        // eslint-disable-next-line no-control-regex
                        /[^\x00-\x7F]/g,
                        '',
                      );

                      onChange(a);
                    }}
                    error={errorsEditarCategoria.nome?.message}
                  />
                )}
              />
            </FormGroup>
            <FormGroup name="Situação" cols={[4, 12, 12]}>
              <Controller
                name="situacao"
                defaultValue={categoriaModal?.situacao}
                control={controlEditarCategoria}
                render={({ value, onChange }) => (
                  <Select
                    placeholder="Selecione uma Opção"
                    onChange={({ value: changeValue }: OptionType) =>
                      onChange(changeValue)
                    }
                    value={optionsSitCategoriadeIrso.find(
                      (option) => option.value === value,
                    )}
                    options={optionsSitCategoriadeIrso}
                  />
                )}
              />
            </FormGroup>
          </Flex>

          <PanelBottomActions>
            <ButtonChacrka colorScheme="red" onClick={onCloseEditarCategoria}>
              Voltar
            </ButtonChacrka>

            <ButtonChacrka
              colorScheme="green"
              type="submit"
              isLoading={isDisabledRequest}
            >
              Atualizar
            </ButtonChacrka>
          </PanelBottomActions>
        </Box>
      </Modal>

      <Modal
        isOpen={isOpenEditarAreasCategoria}
        onClose={handleCloseModalEditarAreasCategoria}
        size="4xl"
        title="Editar Setores Operação"
      >
        <Box
          as="form"
          onSubmit={handleSubmitEditarAreasCategoria(
            handleUpdateAreasCategoria,
          )}
        >
          {fieldArrayEdit.fields.map((field, i) => (
            <Flex key={field.id}>
              <FormGroup cols={[12, 12, 12]} name={`Setor ${i + 1}`} required>
                <Controller
                  name={`areasNomes.${i}.name`}
                  control={controlEditarAreasCategoria}
                  defaultValue={field.name}
                  render={({ value, onChange }) => (
                    <>
                      <FormInput
                        style={{ minWidth: 350, marginBottom: '10px' }}
                        placeholder="Digite o nome do setor"
                        onChange={onChange}
                        value={value?.replaceAll(
                          // eslint-disable-next-line no-control-regex
                          /[^\x00-\x7F]/g,
                          '',
                        )}
                        onPaste={(e) => {
                          const a = e.currentTarget?.value.replaceAll(
                            // eslint-disable-next-line no-control-regex
                            /[^\x00-\x7F]/g,
                            '',
                          );

                          onChange(a);
                        }}
                        error={
                          errorsEditarAreasCategoria.areasNomes?.[i]?.name
                            ?.message
                        }
                      />
                    </>
                  )}
                />
              </FormGroup>
              <ButtonChacrka
                type="button"
                mt={7}
                onClick={() => fieldArrayEdit.remove(i)}
              >
                <FaMinus />
              </ButtonChacrka>
            </Flex>
          ))}

          <Flex>
            <PanelBottomActions>
              <ButtonChacrka
                colorScheme="telegram"
                leftIcon={<FaPlus />}
                type="button"
                onClick={() => fieldArrayEdit.append({ name: '' })}
              >
                Adicionar setor
              </ButtonChacrka>
            </PanelBottomActions>
          </Flex>

          <PanelBottomActions>
            <ButtonChacrka
              colorScheme="yellow"
              leftIcon={<FaTimes />}
              type="button"
              onClick={handleCloseModalEditarAreasCategoria}
            >
              Fechar
            </ButtonChacrka>
            <ButtonChacrka
              colorScheme="green"
              leftIcon={<FaSave />}
              type="submit"
              isLoading={isDisabledRequest}
            >
              Incluir
            </ButtonChacrka>
          </PanelBottomActions>
        </Box>
      </Modal>
    </>
  );
};
