/* eslint-disable import/no-extraneous-dependencies */
import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from 'react';
import { FaEdit, FaTrash, FaSave } from 'react-icons/fa';
import { Divider, Flex } from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/hooks';
import { Checkbox } from '@chakra-ui/checkbox';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { Box, HStack, Text } from '@chakra-ui/react';
import { Button } from '@chakra-ui/button';
import { Tooltip } from '@chakra-ui/tooltip';
import { useToast } from '@chakra-ui/toast';
import { useHistory } from 'react-router-dom';
import { MdBlock } from 'react-icons/md';
import { format } from 'date-fns-tz';
import debounce from 'debounce-promise';
import { parseISO } from 'date-fns';
import PanelBottomActions from '../../components/PanelBottomActions';
import api from '../../services/api';
import FormGroup from '../../components/form/FormGroup';
import AsyncSelect from '../../components/form/AsyncSelect';
import Row from '../../components/form/Row';
import FormDatePicker from '../../components/form/FormDatePicker';
import Modal from '../../components/Modal';
import DataTable, { IColumns } from '../../components/DataTable';
import BoxContent from '../../components/BoxContent';
import { useTravaOpmHora } from '../../contexts/travaopmhora';
import ReactSelect from '../../components/form/ReactSelect';
import TituloPagina from '../../components/TituloPagina';
import { useAuth } from '../../contexts/auth';

type IGrandesComando = {
  uni_codigo: number;
  uni_sigla: string;
};

// interface ITipotrava {
//   nome: string;
// }

interface IUnidade {
  uni_nome: string;
  grandeComando: IUnidade;
  unidadePai: IUnidade;
}

interface ITravaOpmHora {
  id_trava_opm_hora: number;
  uni_codigo: number;
  quantidade_horas: number;
  unidade: IUnidade;
  criadoPor?: string;
  criadoEm?: string;

  data_inicial: string;
  data_final: string;
}
type OptionType = { label: string; value: string };

type IFilters = {
  type: 'date' | 'select';
  field: string;
  label: string;
  options: OptionType[];
  defaultOption?: string;
  cols: [number, number, number];
};

interface ISChema {
  quantidade_horas: number;
  data_inicial: Date;
  data_final: Date;
  subunidades: '0' | '1';
  uni_codigo: number;
}

interface ISChemaEditar {
  quantidade_horas: number;
  data_inicial: Date;
  data_final: Date;
  subunidades: '0' | '1';
}

interface IRequestEscalas {
  opm: number;
  data_inicio: Date;
  data_fim: Date;
  subunidades: '0' | '1';
  tota_hora: number;
}

const defaultFilters: IFilters[] = [
  {
    type: 'date' as 'date' | 'select',
    field: 'data_inicio',
    label: 'Data Início',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },

  {
    type: 'date' as 'date' | 'select',
    field: 'data_fim',
    label: 'Data Final',
    options: [],
    cols: [2, 6, 12] as [number, number, number],
  },
];

type IEscala = {
  id_escala: number;
  nome: string;
  data_inicio: string;
  data_fim: string;
  total_hora: number;
  valor_escala: number;
};

const schema = Yup.object().shape({
  quantidade_horas: Yup.number().required('Campo Obrigatório'),
  data_inicial: Yup.date().required('Campo Obrigatório'),
  data_final: Yup.date().required('Campo Obrigatório'),
  subunidades: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf(['0', '1'], 'Este campo é obrigatório'),
  uni_codigo: Yup.number().required(),
});

const schemaEditar = Yup.object().shape({
  quantidade_horas: Yup.number().required('Campo Obrigatório'),
  data_inicial: Yup.date().required('Campo Obrigatório'),
  data_final: Yup.date().required('Campo Obrigatório'),
  subunidades: Yup.string()
    .required('Este campo é obrigatório')
    .oneOf(['0', '1'], 'Este campo é obrigatório'),
});

const optionsEscalas = Array.from({ length: 11 }, (_, i) => ({
  label: (i + 2).toString(),
  value: (i + 2).toString(),
}));

const ListTravaOpmHora: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const toast = useToast();
  const [tipoTravaModal, setTipoTravaModal] = useState<ITravaOpmHora>();
  const { updateIdTravaOpmHora, idTravaOpmHora } = useTravaOpmHora();
  const [filters, setFilters] = useState<IFilters[]>(defaultFilters);
  const [grandesComandos, setGrandesComandos] = useState<OptionType[]>([]);
  const [disabledRequest, setDisabledRequest] = useState(false);

  const {
    isOpen: isOpenVisualizar,
    onOpen: onOpenVisualizar,
    onClose: onCloseVisualizar,
  } = useDisclosure();

  const {
    isOpen: isTravaSubUnidade,
    onOpen: onOpenTravaSubUnidade,
    onClose: onCloseTravaSubUnidade,
  } = useDisclosure();

  const {
    isOpen: isOpenVisualizarTrava,
    onOpen: onOpenVisualizarTrava,
    onClose: onCloseVisualizarTrava,
  } = useDisclosure();

  const {
    isOpen: isOpenEditarTrava,
    onOpen: onOpenEditarTrava,
    onClose: onCloseEditarTrava,
  } = useDisclosure();

  const { handleSubmit, errors, control, reset } = useForm<ISChema>({
    resolver: yupResolver(schema),
    defaultValues: {
      subunidades: '0',
      quantidade_horas: undefined,
      data_inicial: undefined,
      data_final: undefined,
    },
  });

  const {
    handleSubmit: handleSubmitEditarTrava,
    errors: errorsEditarTrava,
    control: controlEditarTrava,
  } = useForm<ISChemaEditar>({
    resolver: yupResolver(schemaEditar),
    defaultValues: {
      subunidades: '0',
    },
  });

  const [opmFormSelected, setOpmFormSelected] = useState<OptionType>();

  const handleCheckEscalasBlocked = async ({
    tota_hora,
    ...restFiltersEscalas
  }: IRequestEscalas): Promise<void> => {
    const {
      data: { items },
    } = await api.get<{ items: IEscala[] }>('/escalas/irsos', {
      params: {
        ...restFiltersEscalas,
        id_status_escala: 3,
      },
    });

    if (
      items.filter(({ total_hora: total }) => total && total >= tota_hora)
        .length > 0
    ) {
      toast({
        title: 'Aviso.',
        description:
          'Há escalas fechadas no período incompatíveis com a trava solicitada, estas não foram afetadas',
        status: 'warning',
        duration: 10000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleCreateTravaOpmHora = async ({
    data_final,
    data_inicial,
    ...rest
  }: ISChema): Promise<void> => {
    const dados = {
      ...rest,
      data_final: format(data_final, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      data_inicial: format(data_inicial, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
    };

    try {
      await api.post(`/travaopmhora`, dados);
      toast({
        title: 'Sucesso.',
        description: 'Trava Opm hora cadastrada com sucesso',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });

      await handleCheckEscalasBlocked({
        opm: rest.uni_codigo,
        subunidades: rest.subunidades,
        data_fim: data_final,
        data_inicio: data_inicial,
        tota_hora: rest.quantidade_horas,
      });
      reset();
      setOpmFormSelected(undefined);
    } catch (error) {
      toast({
        title: 'Erro',
        description: error?.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }
  };

  const handleUpdateTravaOpm = async ({
    data_final,
    data_inicial,
    ...rest
  }: ISChema): Promise<any> => {
    const dados = {
      ...rest,
      data_final: format(data_final, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      data_inicial: format(data_inicial, 'yyyy-MM-dd HH:mm:ss', {
        timeZone: 'America/Fortaleza',
      }),
      uni_codigo: tipoTravaModal?.uni_codigo,
    };
    try {
      setDisabledRequest(true);
      await api.put(
        `/travaopmhora/${tipoTravaModal?.id_trava_opm_hora}`,
        dados,
      );
      toast({
        title: 'Sucesso.',
        description: 'Trava Opm alterada com sucesso com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseEditarTrava();
    } catch (error) {
      toast({
        title: 'Erro',
        description: 'Erro ao atualizar trava opm hora',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }

    try {
      await handleCheckEscalasBlocked({
        opm: tipoTravaModal?.uni_codigo as number,
        subunidades: rest.subunidades,
        data_fim: data_final,
        data_inicio: data_inicial,
        tota_hora: rest.quantidade_horas,
      });
    } catch (error) {
      console.log('deu erro');
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleExcluirTravaOpm = async (data: ITravaOpmHora): Promise<any> => {
    try {
      const deletarModalidade = await api.put(
        `/travaopmhora/deletar/${data.id_trava_opm_hora}`,
      );
      toast({
        title: 'Sucesso.',
        description: 'Trava de Horas Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listartravaopmhora');
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listartravaopmhora');
    }
  };

  const handleExcluirTravaOpmSubunidades = async (
    data: ITravaOpmHora,
  ): Promise<any> => {
    try {
      setDisabledRequest(true);
      const deletarModalidade = await api.delete(
        `/travaopmhora/${data.id_trava_opm_hora}/subunidades`,
      );
      toast({
        title: 'Sucesso.',
        description: 'Trava de Horas Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listartravaopmhora');
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseVisualizar();
      history.push('/listartravaopmhora');
    } finally {
      setDisabledRequest(false);
    }
  };

  const handleTravarSubunidades = async (): Promise<void> => {
    try {
      await api.post(`travaopmhora/${idTravaOpmHora}/subunidades`);
      toast({
        title: 'Sucesso.',
        description: 'Trava de Horas Opm Excluída com sucesso',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
      onCloseTravaSubUnidade();
    } catch (error) {
      toast({
        title: 'Erro',
        description: error.response.data.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top-right',
      });
    }

    try {
      await handleCheckEscalasBlocked({
        opm: tipoTravaModal?.uni_codigo as number,
        subunidades: '1',
        data_fim: new Date(tipoTravaModal?.data_final as string),
        data_inicio: new Date(tipoTravaModal?.data_inicial as string),
        tota_hora: tipoTravaModal?.quantidade_horas as number,
      });
    } catch (error) {
      console.log('erro de requisicao');
    }
  };

  const colunas: IColumns = [
    {
      field: 'unidade.uni_sigla',
      text: 'Opm',
      type: {
        name: 'text',
      },
      alias: 'unidade.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpmHora) => unidade.uni_nome,
    },
    {
      field: 'unidade.grandeComando.uni_sigla',
      text: 'Grande Comando',
      type: {
        name: 'text',
      },
      alias: 'grandeComando.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpmHora) => unidade.grandeComando.uni_nome,
    },
    {
      field: 'unidade.unidadePai.uni_sigla',
      text: 'Unidade Pai',
      type: {
        name: 'text',
      },
      alias: 'unidadePai.uni_prioridade',
      tooltip: ({ unidade }: ITravaOpmHora) => unidade.unidadePai.uni_nome,
    },
    {
      field: 'data_inicial',
      text: 'Data Inicial',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
      alias: 'travasOpmsHoras.data_inicial',
    },
    {
      field: 'data_final',
      text: 'Data Final',
      type: {
        name: 'date',
        format: 'dd/MM/yyyy',
      },
      alias: 'travasOpmsHoras.data_final',
    },
    {
      field: 'quantidade_horas',
      text: 'Quantidade Horas',
      type: {
        name: 'text',
      },
      alias: 'travasOpmsHoras.quantidade_horas',
    },
  ];

  const options = {
    serverData: {
      url: `/travaopmhora`,
      headers: { Authorization: api.defaults.headers.authorization },
      serverPagination: true,
      params: `subunidades=${user.verSubunidade}&opm=${user.currentOpm?.uni_codigo}`,
    },
    actions: {
      headerText: 'Ações',
      items: [
        // {
        //   icon: <FaSearch size={13} />,
        //   tooltip: 'Visualizar',

        //   getRow: (uniforme: ITravaOpmHora) => {
        //     setTipoTravaModal(uniforme);
        //     onOpenVisualizarTrava();
        //   },
        // },
        {
          icon: <MdBlock size={13} />,
          tooltip: 'Travar SubUnidades',

          getRow: (uniforme: ITravaOpmHora) => {
            onOpenTravaSubUnidade();
            updateIdTravaOpmHora(uniforme.id_trava_opm_hora);
            setTipoTravaModal(uniforme);
          },
        },
        {
          icon: <FaEdit size={13} />,
          tooltip: 'Editar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenEditarTrava();
          },
        },
        {
          icon: <FaTrash size={13} />,
          tooltip: 'Deletar',

          getRow: (uniforme: any) => {
            setTipoTravaModal(uniforme);
            onOpenVisualizar();
          },
        },
      ],
    },
    filters,

    search: {
      searchable: true,
      label: 'Pesquisar',
      fields: ['unidade.uni_sigla', 'unidade.uni_nome'],
      cols: [4, 6, 12] as [number, number, number],
    },
    columnOrder: {
      visible: true,
      label: 'Ordem',
    },
  };

  const promiseItens = useCallback(async (inputValue: string): Promise<
    OptionType[] | undefined
  > => {
    try {
      const response = await api.get(`unidades/async?query=${inputValue}`);

      const data = response.data || [];

      const responseFormated = data.unidades.map((item: any) => {
        return {
          value: item.uni_codigo,
          label: item.uni_sigla,
        };
      });

      return responseFormated;
    } catch (error) {
      return undefined;
    }
  }, []);

  const delayedQuery = useCallback(
    debounce((query: string) => promiseItens(query), 500),
    [promiseItens],
  );

  useLayoutEffect(() => {
    if (Number(user.currentOpm?.uni_codigo as string) === -1) {
      setFilters([
        {
          type: 'select' as 'date' | 'select',
          field: 'grandes_comandos',
          label: 'Grande Comando',
          options: [{ label: 'Todos', value: '' }, ...grandesComandos],
          defaultOption: '',

          cols: [2, 6, 12] as [number, number, number],
        },

        ...defaultFilters,
      ]);
    } else setFilters(defaultFilters);
  }, [user.currentOpm, grandesComandos]);

  useEffect(() => {
    const loadGrandesComandos = async (): Promise<void> => {
      const {
        data: { items },
      } = await api.get<{ items: IGrandesComando[] }>(
        '/unidades/grandes_comandos',
      );

      setGrandesComandos(
        items.map((item) => ({
          label: item.uni_sigla,
          value: item.uni_codigo.toString(),
        })),
      );
    };

    loadGrandesComandos();
  }, []);
  return (
    <>
      <>
        <TituloPagina title="Limite de Horas DRSO" />
        <BoxContent>
          <form
            onSubmit={handleSubmit(handleCreateTravaOpmHora)}
            style={{ marginBottom: 8 }}
          >
            <TituloPagina title="Cadastro de trava opm hora" />

            <Row>
              <FormGroup name="Pesquisar opm" cols={[4, 6, 12]}>
                <Controller
                  name="uni_codigo"
                  control={control}
                  render={({ onChange }) => (
                    <AsyncSelect
                      label="Pesquisar Opm"
                      value={opmFormSelected}
                      loadOptions={(inputValue: string) =>
                        delayedQuery(inputValue)
                      }
                      onChange={(option: any) => {
                        onChange(Number(option.value));
                        setOpmFormSelected(option);
                      }}
                      error={errors.uni_codigo?.message}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup name="Subunidades" cols={[4, 6, 12]}>
                <Controller
                  name="subunidades"
                  control={control}
                  render={({ onChange, value }) => (
                    <Checkbox
                      isChecked={value === '1'}
                      onChange={() => onChange(value === '0' ? '1' : '0')}
                    >
                      Sim
                    </Checkbox>
                  )}
                />
              </FormGroup>

              <FormGroup
                name="Duração máxima da escala em horas"
                cols={[4, 12, 12]}
              >
                <Controller
                  name="quantidade_horas"
                  control={control}
                  render={({ onChange, value }) => (
                    <ReactSelect
                      optionsSelect={optionsEscalas}
                      value={optionsEscalas.find(
                        (option) => option.value === value?.toString(),
                      )}
                      onChange={(option: OptionType) =>
                        onChange(Number(option.value))
                      }
                      error={errors.quantidade_horas?.message}
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <Row>
              <FormGroup name="Data Inicial" cols={[4, 6, 12]}>
                <Controller
                  name="data_inicial"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      selected={value}
                      error={errors.data_inicial?.message}
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
              <FormGroup name="Data Final" cols={[4, 6, 12]}>
                <Controller
                  name="data_final"
                  control={control}
                  render={({ onChange, value }) => (
                    <FormDatePicker
                      showYearDropdown
                      error={errors.data_final?.message}
                      selected={value}
                      onChange={(e: Date) =>
                        onChange(
                          new Date(
                            e.getFullYear(),
                            e.getMonth(),
                            e.getDate(),
                            23,
                            59,
                            59,
                          ),
                          { timeZone: 'America/Fortaleza' },
                        )
                      }
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </FormGroup>
            </Row>

            <Button colorScheme="green" type="submit" leftIcon={<FaSave />}>
              Salvar
            </Button>
          </form>

          <Divider orientation="horizontal" border="2px" />
          <Tooltip
            label="A OPM que não constar nesta lista no intervalo de datas pode realizar DRSO até o limite legal de 12h."
            placement="bottom-start"
          >
            <Box pl={{ sm: '0px', lg: '8px' }} mb="8px">
              <Text
                color="#666"
                fontWeight="600"
                fontSize={{ sm: '1rem', lg: '1.3rem' }}
              >
                Listagem de Trava de Horas - {user.currentOpm?.uni_sigla}{' '}
                {user.verSubunidade === '1' &&
                  user.currentOpm?.uni_codigo !== (-1).toString() &&
                  'e subunidades'}
              </Text>
            </Box>
          </Tooltip>
          <Box maxWidth="calc(100vw - 50px)">
            <DataTable columns={colunas} options={options} />
          </Box>

          <Modal
            isOpen={isOpenEditarTrava}
            onClose={onCloseEditarTrava}
            size="4xl"
            title={`Editar Trava OPM Hora - ${tipoTravaModal?.unidade.uni_nome}`}
          >
            <form onSubmit={handleSubmitEditarTrava(handleUpdateTravaOpm)}>
              <Row>
                <FormGroup name="Subunidades" cols={[4, 6, 12]}>
                  <Controller
                    name="subunidades"
                    control={controlEditarTrava}
                    render={({ onChange, value }) => (
                      <Checkbox
                        isChecked={value === '1'}
                        onChange={() => onChange(value === '0' ? '1' : '0')}
                      >
                        Sim
                      </Checkbox>
                    )}
                  />
                </FormGroup>

                <FormGroup name="Quantidade Horas" cols={[4, 12, 12]}>
                  <Controller
                    name="quantidade_horas"
                    control={controlEditarTrava}
                    defaultValue={tipoTravaModal?.quantidade_horas}
                    render={({ onChange, value }) => (
                      <ReactSelect
                        optionsSelect={optionsEscalas}
                        value={optionsEscalas.find(
                          (option) => option.value === value?.toString(),
                        )}
                        onChange={(option: OptionType) =>
                          onChange(Number(option.value))
                        }
                        error={errors.quantidade_horas?.message}
                      />
                    )}
                  />
                </FormGroup>
              </Row>

              <Row>
                <FormGroup name="Data Inicial" cols={[4, 6, 12]}>
                  <Controller
                    name="data_inicial"
                    control={controlEditarTrava}
                    defaultValue={
                      new Date(tipoTravaModal?.data_inicial as string)
                    }
                    render={({ onChange, value }) => (
                      <FormDatePicker
                        showYearDropdown
                        selected={value}
                        error={errorsEditarTrava.data_inicial?.message}
                        onChange={onChange}
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </FormGroup>
                <FormGroup name="Data Final" cols={[4, 6, 12]}>
                  <Controller
                    name="data_final"
                    control={controlEditarTrava}
                    defaultValue={
                      new Date(tipoTravaModal?.data_final as string)
                    }
                    render={({ onChange, value }) => (
                      <FormDatePicker
                        showYearDropdown
                        error={errorsEditarTrava.data_final?.message}
                        selected={value}
                        onChange={(e: Date) =>
                          onChange(
                            new Date(
                              e.getFullYear(),
                              e.getMonth(),
                              e.getDate(),
                              23,
                              59,
                              59,
                            ),
                            { timeZone: 'America/Fortaleza' },
                          )
                        }
                        dateFormat="dd/MM/yyyy"
                      />
                    )}
                  />
                </FormGroup>
              </Row>

              <PanelBottomActions>
                <Button onClick={onCloseEditarTrava} as="a" colorScheme="red">
                  Voltar
                </Button>

                <Button
                  colorScheme="green"
                  type="submit"
                  isLoading={disabledRequest}
                >
                  Salvar
                </Button>
              </PanelBottomActions>
            </form>
          </Modal>

          <Modal
            isOpen={isOpenVisualizarTrava}
            onClose={onCloseVisualizarTrava}
            size="4xl"
            title={`Visualizar Trava - ${tipoTravaModal?.unidade.uni_nome}`}
          >
            <Row>
              <FormGroup name="Data Inicial" cols={[3, 6, 12]}>
                <FormDatePicker
                  selected={parseISO(tipoTravaModal?.data_inicial as string)}
                  onChange={() => {
                    console.log('aqui');
                  }}
                  dateFormat="dd/MM/yyyy hh:mm:ss"
                  disabled
                />
              </FormGroup>

              <FormGroup name="Data Final" cols={[3, 6, 12]}>
                <FormDatePicker
                  selected={new Date(tipoTravaModal?.data_final as string)}
                  onChange={() => {
                    console.log('aqui');
                  }}
                  dateFormat="dd/MM/yyyy hh:mm:ss"
                  disabled
                />
              </FormGroup>
              <FormGroup name="Quantidade de Horas" cols={[3, 6, 12]}>
                <ReactSelect
                  optionsSelect={optionsEscalas}
                  value={optionsEscalas.find(
                    (option) =>
                      option.value ===
                      tipoTravaModal?.quantidade_horas?.toString(),
                  )}
                  error={errors.quantidade_horas?.message}
                  isDisabled
                />
              </FormGroup>
            </Row>
          </Modal>

          {tipoTravaModal && (
            <Modal
              isOpen={isOpenVisualizar}
              onClose={onCloseVisualizar}
              size="2xl"
              title={`Solicitação de Exclusao (Usuário: ${user.graduacao?.gra_sigla} ${user.pm_apelido})`}
            >
              <h1>
                Voce esta prestes a deletar a Trava de Opm da unidade{' '}
                <strong>{tipoTravaModal.unidade.uni_nome}</strong>{' '}
                <strong>{tipoTravaModal.quantidade_horas}</strong> Horas tem
                certeza da operação?
              </h1>
              <Flex mt="8" justify="center">
                <HStack spacing="4">
                  <Button onClick={onCloseVisualizar} colorScheme="green">
                    Não
                  </Button>

                  <Button
                    onClick={() => handleExcluirTravaOpm(tipoTravaModal)}
                    colorScheme="red"
                  >
                    Deletar apenas OPM
                  </Button>

                  <Button
                    onClick={() =>
                      handleExcluirTravaOpmSubunidades(tipoTravaModal)
                    }
                    colorScheme="red"
                    isLoading={disabledRequest}
                  >
                    Deletar OPM e subordinadas
                  </Button>
                </HStack>
              </Flex>
            </Modal>
          )}
        </BoxContent>

        <Modal
          isOpen={isTravaSubUnidade}
          onClose={onCloseTravaSubUnidade}
          size="lg"
          title="Travar Subunidades"
        >
          <h1>
            Voce esta prestes a travar as subunidades da unidade{' '}
            <strong>{tipoTravaModal?.unidade.uni_nome}</strong>{' '}
            <strong>{tipoTravaModal?.quantidade_horas}</strong> Horas tem
            certeza da operação?
          </h1>
          <Flex mt="8" justify="center">
            <HStack spacing="4">
              <Button onClick={onCloseVisualizar} colorScheme="red">
                Não
              </Button>

              <Button
                onClick={handleTravarSubunidades}
                colorScheme="green"
                isLoading={disabledRequest}
              >
                Sim!
              </Button>
            </HStack>
          </Flex>
        </Modal>
      </>
    </>
  );
};

export default ListTravaOpmHora;
